import React from "react";
import Box from "app/components/Box/Box";
import Button from "app/components/Buttons/Button";
import FormComponent from "app/components/FormComponent/FormComponent";

import styles from "./ForgotPassword.scss";

import Api from "app/js/api";

import { useSafeState } from "app/js/hooks";

export default function ForgotPassword(props) {
  const [email, setEmail] = useSafeState("");
  const [message, setMessage] = useSafeState(undefined);
  const [error, setError] = useSafeState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    setMessage(undefined);
    try {
      await Api.user().forgotPassword({ email });
      setEmail("");
      setMessage("Please check your Email for a recover link.");
      setError(null);
    } catch (error) {
      setError(error);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className={styles.container}>
      {!message ? (
        <Box title="Forgot Password" error={error}>
          <FormComponent>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              name="email"
              onChange={handleChange}
              value={email}
              placeholder="user@example.org"
            />
          </FormComponent>
          <Button onClick={onSubmit}>Send Email</Button>
        </Box>
      ) : (
        <div>{message}</div>
      )}
    </div>
  );
}
