import React from "react";
import { Creatable } from "react-select";
import styles from "app/pages/AppConfigs/ActionBar/ActionBar.scss";
import { Option } from "app/js/types";

interface ActionBarProps {
  additionalNodes?: React.ReactNode;
  buttons: React.ReactNode;
  onVersionFilterChange?: (update: Option<number>) => void;
  versionOptions?: Option<number>[];
  versionFilterProps?: Record<string, any>;
  select?: React.ReactNode;
}

const isValidNewOption = (inputValue) => {
  return !!/^[0-9a-fA-F]{64}$/.exec(inputValue);
};

const formatCreateLabel = (inputValue) => {
  return `Filter for: ${inputValue}`;
};

const ActionBar: React.FC<ActionBarProps> = ({
  additionalNodes,
  buttons,
  onVersionFilterChange,
  versionOptions,
  select,
}) => {
  return (
    <div className={styles.actionBar}>
      <label className={styles.label}>Version</label>
      {select || (
        <Creatable
          className={styles.select}
          escapeClearsValue
          formatCreateLabel={formatCreateLabel}
          isClearable
          isValidNewOption={isValidNewOption}
          onChange={onVersionFilterChange}
          options={versionOptions}
          placeholder="Filter..."
        />
      )}
      {additionalNodes}
      <div className={styles.buttonGroup}>{buttons}</div>
    </div>
  );
};

export default ActionBar;
