import React, { useEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

import Api from "app/js/api";
import { Card, CardDetails } from "app/components/Card";
import CardList from "app/components/CardList/CardList";
import FormComponent from "app/components/FormComponent/FormComponent";
import GalleryModal from "app/components/Modal/GalleryModal";
import Paginator from "app/components/Paginator/Paginator";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import Select from "react-select";
import SelectDataset from "app/components/Selects/SelectDataset";
import SelectLabellingJob, {
  LabelingJobForSelect,
} from "app/components/Selects/SelectLabellingJob";
import Button from "app/components/Buttons/Button";
import PageSizeChanger, {
  readPageLengthCookie,
} from "app/components/PageSizeChanger/PageSizeChanger";

import { useSafeState, useUserCallback } from "app/js/hooks";
import {
  Dataset,
  Frame,
  LabelVersionFilters,
  LabelVersionMode,
  Option,
} from "app/js/types";
import { CardImage, CardTrashButton } from "app/components/Card";

const parseDate = (value) => (!!value ? moment(value).format() : "");

export default function DatasetDetailImages({ match, setDataset, dataset }) {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // Aliases for individual params to use as hook dependencies
  const paramsPage = params.get("page");
  const paramsMode = params.get("mode") as LabelVersionMode;
  const paramsLabellingJob = params.get("labellingJob");
  const paramsFrameBefore = params.get("frameBefore");
  const paramsFrameAfter = params.get("frameAfter");
  const paramsLabellingBefore = params.get("labellingBefore");
  const paramsLabellingAfter = params.get("labellingAfter");
  // End of aliases
  const datasetId = parseInt(match.params.id);
  // paginator state data
  const PAGE_LENGTH_COOKIE = "moonvision-datasetImagesPageLength";
  const [pageLength, setPageLength] = useSafeState<number>(
    readPageLengthCookie(PAGE_LENGTH_COOKIE),
  );
  const offset =
    paramsPage === null ? 0 : (parseInt(paramsPage) - 1) * pageLength;
  const [count, setCount] = useSafeState<number>(0);

  // filters
  // select mode
  const modeOptions = [
    { value: "", label: "No Mode" },
    { value: "user_latest", label: "User" },
    { value: "auto", label: "Auto" },
  ];

  // select detection labels (OR)
  const [labels, setLabels] = useSafeState<Option<string>[]>([]);
  const [labelOptions, setLabelOptions] = useSafeState<Option<string>[]>([]);
  const [loadingLabelOptions, setLoadingLabelOptions] = useSafeState<boolean>(
    false,
  );

  // loading
  const [loading, setLoading] = useSafeState<boolean>(false);

  // error state
  const [error, setError] = useSafeState(null);

  // frames that are displayed
  const [frames, setFrames] = useSafeState<Frame[]>([]);

  // img that is shown in it's full size when clicking on the images
  const [bigFrameId, setBigFrameId] = useSafeState<number | null>(null);

  // data needed for the /movedataset/ endpoint
  const [destinationDataset, setDestinationDataset] = useSafeState<Dataset>(
    null,
  );
  const [destinationLabellingJob, setDestinationLabellingJob] = useSafeState<
    LabelingJobForSelect
  >(null);

  const labelsStr =
    labels.length > 0 ? JSON.stringify(labels.map((l) => l.value)) : null;
  const loadFrames = useUserCallback(async () => {
    setLoading(true);
    try {
      const mode = paramsMode;
      const labelsHook = JSON.parse(labelsStr);
      const getAllFramesResponse = await Api.frame().all({
        job_ids: paramsLabellingJob ? [parseInt(paramsLabellingJob)] : null,
        dataset_ids: [datasetId],
        created_before: paramsFrameBefore,
        created_after: paramsFrameAfter,
        label_version_created_before: paramsLabellingBefore,
        label_version_created_after: paramsLabellingAfter,
        mode: mode,
        or_labels: labelsHook,
        limit: pageLength,
        offset: offset,
        with_entities: mode === "user_latest" || mode === "auto",
      });
      setFrames(getAllFramesResponse.data.results);
      setCount(getAllFramesResponse.data.count);
      setError(null);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [
    datasetId,
    labelsStr,
    offset,
    pageLength,
    paramsFrameAfter,
    paramsFrameBefore,
    paramsLabellingAfter,
    paramsLabellingBefore,
    paramsLabellingJob,
    paramsMode,
    setCount,
    setError,
    setFrames,
    setLoading,
  ]);

  useEffect(() => {
    loadFrames();
  }, [loadFrames]);

  const deleteFrame = async (frameId) => {
    const deleteConfirmation = confirm(
      "Are you sure you want to delete this frame? All entities from the frame will also be deleted.",
    );
    if (!deleteConfirmation) {
      return;
    }

    try {
      setError(null);
      await Api.frame(frameId).destroy();
      setDataset({
        ...dataset,
        frames_count: dataset.frames_count - 1,
      });
    } catch (error) {
      setError(error);
      return;
    }
    loadFrames();
  };

  useEffect(() => {
    const loadLabelOptions = async () => {
      setLoadingLabelOptions(true);
      setLabels([]);

      try {
        const filters: LabelVersionFilters = {
          mode: paramsMode,
        };
        if (paramsMode == null || paramsMode === "auto") {
          filters["dataset_ids"] = [datasetId];
        }
        if (paramsMode === "user_latest" && paramsLabellingJob) {
          filters["job_ids"] = [parseInt(paramsLabellingJob)];
        }
        const response = await Api.labelversion().labels(filters);
        setLabelOptions(
          Object.keys(response.data.results)
            .sort()
            .map((l) => ({ label: l, value: l })),
        );
      } catch (error) {
        setError(error);
      }
      setLoadingLabelOptions(false);
    };

    loadLabelOptions();
  }, [
    datasetId,
    paramsMode,
    paramsLabellingJob,
    setError,
    setLabelOptions,
    setLabels,
    setLoadingLabelOptions,
  ]);

  const loadFrameIds = async () => {
    try {
      const idListResponse = await Api.frame().id_list({
        job_ids: paramsLabellingJob ? [parseInt(paramsLabellingJob)] : null,
        dataset_ids: [datasetId],
        created_before: paramsFrameBefore,
        created_after: paramsFrameAfter,
        label_version_created_before: paramsLabellingBefore,
        label_version_created_after: paramsLabellingAfter,
        mode: paramsMode,
        or_labels: labels.length > 0 ? labels.map((l) => l.value) : null,
        limit: pageLength,
      });
      return idListResponse.data.results;
    } catch (error) {
      setError(error);
      alert(error);
    }
  };

  const postMoveFrames = async (frameIdsList) => {
    // setLoading(false);
    try {
      setLoading(true);
      const response = await Api.frame().move({
        frames: frameIdsList,
        destination_dataset: destinationDataset.id,
        labeling_job: destinationLabellingJob.id,
      });
      if (response.data.success) {
        loadFrames();
        alert(
          `Successfully moved ${frameIdsList.length} ${
            frameIdsList.length > 1 ? "frames" : "frame"
          } to ${destinationDataset.name} (id: ${destinationDataset.id})`,
        );

        setDataset({
          ...dataset,
          frames_count: dataset.frames_count - frameIdsList.length,
        });
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      alert(error);
    }
    setLoading(false);
  };

  const moveFrames = async () => {
    const frameIdsList = await loadFrameIds();
    const moveFramesConfirmation = [];
    moveFramesConfirmation.push(
      `In total ${frameIdsList.length} frame${
        frameIdsList.length > 1 ? "s" : ""
      } will be moved to Dataset "${destinationDataset.name} (id: ${
        destinationDataset.id
      })" and Labelling Job udpated to "${destinationLabellingJob.name} (id : ${
        destinationLabellingJob.id
      })"`,
    );
    moveFramesConfirmation.push("Filters:");
    const filterParams = [
      ["Mode", paramsMode],
      ["Labelling Job", paramsLabellingJob],
      [
        "Frame created before",
        paramsFrameBefore ? moment(paramsFrameBefore).format("LLL") : null,
      ],
      [
        "Frame created after",
        paramsFrameAfter ? moment(paramsFrameAfter).format("LLL") : null,
      ],
      [
        "Labelling created before",
        paramsLabellingBefore
          ? moment(paramsLabellingBefore).format("LLL")
          : null,
      ],
      [
        "Labelling created after",
        paramsLabellingAfter
          ? moment(paramsLabellingAfter).format("LLL")
          : null,
      ],
      [
        "Detection Labels (OR)",
        labels.length > 0 ? labels.map((l) => l.label) : null,
      ],
    ];
    filterParams.forEach(([name, value]) => {
      if (value) {
        moveFramesConfirmation.push(`  ${name}: ${value}`);
      }
    });
    if (moveFramesConfirmation.length === 2) {
      moveFramesConfirmation.push("  None");
    }
    if (confirm(moveFramesConfirmation.join("\n"))) {
      await postMoveFrames(frameIdsList);
    }
  };

  const resetOffset = () => {
    // Reset to the first page on any filter change to avoid requesting pages that exceed maximum
    params.set("page", "1");
  };

  const updateParamsData = (key, value) => {
    if (value !== "") {
      params.set(key, value);
    } else {
      params.delete(key);
    }
    resetOffset();
    history.push({
      ...location,
      search: params.toString(),
    });
  };

  return (
    <div style={{ padding: "25px", overflowY: "auto" }}>
      <FormComponent>
        <label>Mode</label>
        <Select
          options={modeOptions}
          value={modeOptions.find((option) => option.value === paramsMode)}
          onChange={(e: Option<string>) => updateParamsData("mode", e.value)}
        />
        {paramsMode === "user_latest" && (
          <Fragment>
            <label>Labelling Job</label>
            <SelectLabellingJob
              value={parseInt(paramsLabellingJob)}
              setLabellingJob={(labellingJob) =>
                updateParamsData(
                  "labellingJob",
                  labellingJob ? labellingJob.id : "",
                )
              }
              datasetId={datasetId}
            />
          </Fragment>
        )}
        <label>Frame created before:</label>
        <input
          type="datetime-local"
          value={
            paramsFrameBefore
              ? moment(paramsFrameBefore).format(
                  moment.HTML5_FMT.DATETIME_LOCAL,
                )
              : ""
          }
          onChange={(e) => {
            updateParamsData("frameBefore", parseDate(e.target.value));
          }}
        />
        <label>Frame created after:</label>
        <input
          type="datetime-local"
          value={
            paramsFrameAfter
              ? moment(paramsFrameAfter).format(moment.HTML5_FMT.DATETIME_LOCAL)
              : ""
          }
          onChange={(e) => {
            updateParamsData("frameAfter", parseDate(e.target.value));
          }}
        />
        <label>Labelling created before:</label>
        <input
          type="datetime-local"
          value={
            paramsLabellingBefore
              ? moment(paramsLabellingBefore).format(
                  moment.HTML5_FMT.DATETIME_LOCAL,
                )
              : ""
          }
          onChange={(e) => {
            updateParamsData("labellingBefore", parseDate(e.target.value));
          }}
        />
        <label>Labelling created after:</label>
        <input
          type="datetime-local"
          value={
            paramsLabellingAfter
              ? moment(paramsLabellingAfter).format(
                  moment.HTML5_FMT.DATETIME_LOCAL,
                )
              : ""
          }
          onChange={(e) => {
            updateParamsData("labellingAfter", parseDate(e.target.value));
          }}
        />
        {loadingLabelOptions ? (
          <Loading />
        ) : (
          <Fragment>
            <label>Detection Labels (OR):</label>
            <Select
              value={labels}
              onChange={(newLabels: Option<string>[]) => {
                setLabels(newLabels);
                resetOffset();
              }}
              options={labelOptions}
              isMulti
            />
          </Fragment>
        )}
      </FormComponent>
      {frames.length > 0 && (
        <Fragment>
          <h3>Move Selected Frames</h3>
          <FormComponent>
            <label>Move Frames to Dataset</label>
            <SelectDataset
              excludeDatasetId={datasetId}
              value={destinationDataset ? destinationDataset.id : null}
              setDataset={setDestinationDataset}
            />
            {destinationDataset && destinationDataset.id && (
              <Fragment>
                <label>
                  Move Label Versions
                  <br />
                  to Labelling Job
                </label>
                <SelectLabellingJob
                  value={
                    destinationLabellingJob ? destinationLabellingJob.id : null
                  }
                  setLabellingJob={setDestinationLabellingJob}
                  datasetId={destinationDataset.id}
                />
              </Fragment>
            )}
            {destinationDataset && destinationDataset.id && (
              <Button onClick={() => moveFrames()}>Move Frames</Button>
            )}
          </FormComponent>
        </Fragment>
      )}
      {loading && <Loading />}
      {error && <ErrorMessage error={error} />}
      {!loading && frames.length > 0 ? (
        <Fragment>
          {count > pageLength && (
            <Paginator
              count={count}
              offset={offset}
              pageLength={pageLength}
              disabled={loading}
            />
          )}
          <CardList>
            {frames.map((frame) => (
              <Card
                key={frame.id}
                media={<CardImage frame={frame} />}
                onClick={() => {
                  setBigFrameId(frame.id);
                }}
                hoverButtons={
                  <CardTrashButton
                    onClick={() => {
                      deleteFrame(frame.id);
                    }}
                  />
                }
              >
                <CardDetails>
                  Uploaded: {moment(frame.create_time).format("LLL")}
                  <br />
                  Frame ID: {frame.id}
                  <br />
                  Camera: {frame.stream_name || "-"}
                </CardDetails>
              </Card>
            ))}
          </CardList>
          <PageSizeChanger
            pageLength={pageLength}
            setPageLength={setPageLength}
            count={count}
            cookieName={PAGE_LENGTH_COOKIE}
          />
        </Fragment>
      ) : (
        !loading && <Fragment>No Images in Dataset</Fragment>
      )}
      <GalleryModal
        frames={frames}
        activeFrame={bigFrameId}
        setActiveFrame={setBigFrameId}
        loading={loading}
        pageLength={pageLength}
        count={count}
        drawEntities={true}
      />
    </div>
  );
}
