import React from "react";
import Box from "app/components/Box/Box";

export default function () {
  return (
    <Box title="Latest Updates">
      <ul>
        <li>
          <strong>19.10.2020</strong>
          <ul>
            <li>Visual improvements in the polygon labelling</li>
            <li>Bug fixes in the dataset download</li>
            <li>Possibility to move frames between datasets</li>
            <li>Video upload fixes to support non-mp4 videos</li>
            <li>Downloading fingerprints with more filter parameters</li>
            <li>Bug fixes in the video processing tasks</li>
            <li>Base labels for detectors saved to the database</li>
          </ul>
        </li>
        <li>
          <strong>5.3.2020</strong>
          <ul>
            <li>Change dates to be in European format (dd.mm.yy)</li>
            <li>Undoing deleted shape now keeps shape's label</li>
            <li>Links now followed after signing in</li>
            <li>
              Dataset download "Include Unlabelled Images" now shows unlabelled
              images that will be donwloaded
            </li>
            <li>
              Now show shape size next to cursor while drawing and next to label
              input
            </li>
            <li>Tooltips now show up faster</li>
          </ul>
        </li>
        <li>
          <strong>28.1.2020</strong>
          <ul>
            <li>Can now skip an image you do not want to label</li>
            <li>
              Added a hover background change to cards to make more clear they
              can be clicked
            </li>
            <li>Fix images showing the upload time in datasets</li>
            <li>
              Added download button to labelling screen to download image being
              labelled
            </li>
            <li>
              Added maintenance screen to disable the site while undergoing
              maintenance
            </li>
          </ul>
        </li>
        <li>
          <strong>12.12.2019</strong>
          <ul>
            <li>Active sprite is now underlined after clicking on it</li>
            <li>Dataset images tab now shows detailed image information</li>
            <li>Fix image and video count not updating on the dataset page</li>
            <li>
              Camera images can now be filtered by time and detected labels
            </li>
            <li>Fixed linking problem in sprites</li>
          </ul>
        </li>
        <li>
          <strong>27.11.2019</strong>
          <ul>
            <li>Improved support chat</li>
            <li>
              Dataset Download: Added images list that will be downloaded with
              the given filters
            </li>
            <li>Added remaining images in labelling job overview</li>
            <li>Fixed bug with labelling user accepting invitations</li>
            <li>
              Camera now has an "Images" tab to see images captured with the
              camera
            </li>
          </ul>
        </li>
        <li>
          <strong>6.11.2019</strong>
          <ul>
            <li>
              In dataset entity view, can select auto entity and enter labelling
              job to label with
            </li>
            <li>Changed hotkeys for creating shapes while labelling</li>
            <li>
              "Latest" filter will now always return user updates if they exist
            </li>
          </ul>
        </li>
        <li>
          <strong>17.10.2019</strong>
          <ul>
            <li>Polygon is now moveable</li>
            <li>Partially outside entities are now allowed</li>
            <li>Renamed "History" tab to "Labelled Images" in Labelling Job</li>
            <li>Added "Remaining Images" tab in Labelling Job</li>
          </ul>
        </li>
        <li>
          <strong>10.10.2019</strong>
          <ul>
            <li>
              Images can now be deleted in datasets, all entities for image will
              also be deleted
            </li>
            <li>Labels of entities detected now show in camera log</li>
            <li>Vertices of polygon now scale with zoom while labelling</li>
            <li>
              Images upload is now sequential to prevent overloading the server
            </li>
          </ul>
        </li>
        <li>
          <strong>01.10.2019</strong>
          <ul>
            <li>Better action script example</li>
            <li>Camera label image endpoint log viewing and usage graph</li>
            <li>Camera label image endpoint documentation update</li>
          </ul>
        </li>
        <li>
          <strong>25.09.2019</strong>
          <ul>
            <li>
              Labelling Job History: Added entity preview on images and version
              filtering
            </li>
            <li>
              Action scripts can now be seen by all team owners but need to be a
              premium team to create or update.
            </li>
          </ul>
        </li>
        <li>
          <strong>24.09.2019</strong>
          <ul>
            <li>
              Labelling Jobs can now be reset to have all images labelled again.
            </li>
            <li>Camera create/edit form fixes.</li>
          </ul>
        </li>
        <li>
          <strong>18.09.2019</strong>
          <ul>
            <li>Platform speed and robustness improvements</li>
            <li>Varrious Bug fixes</li>
          </ul>
        </li>
        <li>
          <strong>23.07.2019</strong>
          <ul>
            <li>Pending task list loader replaced with a dot loader</li>
            <li>
              Table not display anymore if there are no labels in the Data Sets
              Overview
            </li>
            <li>Renaming entities now possible</li>
            <li>
              Redirecting back when viewing sprites from Data Sets entities and
              clicking on the "Save & Continue" button
            </li>
            <li>
              Navigation (going back) fixed for Labelling Jobs and Data Sets
              pages
            </li>
            <li>Added filter properties when Downloading Data</li>
          </ul>
        </li>
        <li>
          <strong>03.07.2019</strong>
          <ul>
            <li>Dataset evaluation endpoint</li>
            <li>Cluster entities performance update</li>
            <li>DaskManager.compute performance update</li>
            <li>Frame scoring and sorting based on frame score</li>
            <li>Bug fixes and improved performance</li>
          </ul>
        </li>
        <li>
          <strong>07.06.2019</strong>
          <ul>
            <li>Added a "Select mode" in labelling.</li>
            <li>
              The cancel button in the Labelling view only appears if a shape
              has been selected.
            </li>
            <li>Bugfixes in Labelling Jobs and Data Sets.</li>
          </ul>
        </li>
        <li>
          <strong>15.05.2019</strong>
          <ul>
            <li>
              Change Subscription Setup, minor Bugfixes. Major upgrade of yarn
              packages like fabric, plotly.
            </li>
          </ul>
        </li>
        <li>
          <strong>25.04.2019</strong>
          <ul>
            <li>
              Add Graph Detection Pipeline for more sophistication to Camera.
            </li>
          </ul>
        </li>
        <li>
          <strong>23.04.2019</strong>
          <ul>
            <li>
              Alpha Version of Overview for a more visual project
              representation.
            </li>
            <li>Bugfix in Labelling View.</li>
          </ul>
        </li>
        <li>
          <strong>16.04.2019</strong>
          <ul>
            <li>
              SpriteView always is visible when navigating through Labels.
            </li>
            <li>
              LabelView stays in Viewport when handling lots of entities on a
              single Image. (better scroll, better focus)
            </li>
            <li>Performance Improvements for Realtimehandling in Cameras.</li>
          </ul>
        </li>
      </ul>
    </Box>
  );
}
