import React, { Fragment } from "react";

import Card from "app/components/Card/Card";
import CardList from "app/components/CardList/CardList";
import Header from "app/components/Header/Header";

export default function ProcessingTaskCreate({ processingTasks, onClick }) {
  return (
    <Fragment>
      <Header>
        <h1>Processing Tasks - Create</h1>
      </Header>
      <CardList style={{ padding: "25px" }}>
        {processingTasks.map((task) => (
          <Card key={task.name} title={task.name} onClick={() => onClick(task)}>
            <p>{task.description}</p>
          </Card>
        ))}
      </CardList>
    </Fragment>
  );
}
