import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import Api from "app/js/api";
import Header from "app/components/Header/Header";
import Table from "app/components/Table/Table";
import Paginator from "app/components/Paginator/Paginator";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

import {
  LoadedDataCallback,
  useLoadedData,
  useSafeState,
  useUserCallback,
} from "app/js/hooks";
import { useUserStore } from "app/js/stores";
import { ActionScript } from "app/js/types";

export default function ActionscriptList({ match, history }) {
  const [user] = useUserStore();
  const PAGE_LENGTH = 10;
  const [offset, setOffset] = useSafeState(0);

  const actionscriptCallback: LoadedDataCallback<
    ActionScript[]
  > = useUserCallback(
    async (setValue, setCount) => {
      const response = await Api.actionscript().all({
        limit: PAGE_LENGTH,
        offset,
      });
      setValue(response.data.results);
      setCount(response.data.count);
    },
    [offset],
  );
  const {
    value: actionscripts,
    count,
    loading,
    error,
    requestCallback: loadActionscripts,
  } = useLoadedData<ActionScript[]>([], actionscriptCallback);

  return (
    <Fragment>
      <Header
        basePath={match.path}
        showCreate={
          user.data.is_staff || user.data.subscriptions.indexOf("premium") >= 0
        }
      >
        <h1>Actionscripts</h1>
        {count > PAGE_LENGTH && (
          <Paginator
            onChange={setOffset}
            count={count}
            offset={offset}
            pageLength={PAGE_LENGTH}
            disabled={loading}
          />
        )}
      </Header>
      {error && <ErrorMessage error={error} />}
      {loading && <Loading />}
      {!loading && actionscripts.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Streams</th>
              <th>Date</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {actionscripts.map((script) => (
              <tr key={script.id}>
                <td>
                  <Link to={`${match.url}/${script.id}`}>{script.name}</Link>
                </td>
                <td>used by {script.cameras.length} streams</td>
                <td>
                  {moment(script.update_time).format(moment.HTML5_FMT.DATE)}{" "}
                  (created:{" "}
                  {moment(script.create_time).format(moment.HTML5_FMT.DATE)})
                </td>
                <td
                  style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                >
                  <Link to={`${match.url}/${script.id}/edit`}>edit</Link>{" "}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      if (confirm("Are you sure?")) {
                        Api.actionscript(script.id)
                          .destroy()
                          .then((e) => {
                            loadActionscripts();
                          });
                      }
                    }}
                  >
                    delete
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        !loading && <p style={{ padding: "0 25px 0 25px" }}>Empty</p>
      )}
    </Fragment>
  );
}
