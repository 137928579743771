import React, { useEffect, Fragment } from "react";
import moment from "moment";

import {
  Card,
  CardDetails,
  CardAddButton,
  CardImage,
} from "app/components/Card";
import CardList from "app/components/CardList/CardList";
import Paginator from "app/components/Paginator/Paginator";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

import { useOnUnmount, useParams, useSafeState } from "app/js/hooks";
import Api from "app/js/api";
import { Frame, LabelingJob } from "app/js/types";
import PageSizeChanger, {
  readPageLengthCookie,
} from "app/components/PageSizeChanger/PageSizeChanger";
import GalleryModal from "app/components/Modal/GalleryModal";
import { Form, FormFields } from "app/components/Form";
import { GenericForm, useFormStore } from "app/js/stores";

interface RemainingImagesFormData extends GenericForm {
  created_after: string | null;
  stream_id: string | null;
  image_type: "all" | "auto_only";
}

interface LabellingJobRemainingImagesProps {
  job: LabelingJob;
}

export default function LabellingJobRemainingImages({
  job,
}: LabellingJobRemainingImagesProps): React.ReactElement {
  const [loading, setLoading] = useSafeState<boolean>(false);
  const [error, setError] = useSafeState(null);
  const PAGE_LENGTH_COOKIE = "moonvision-unlabelledImagesPageLength";
  const [pageLength, setPageLength] = useSafeState<number>(
    readPageLengthCookie(PAGE_LENGTH_COOKIE),
  );
  const params = useParams();
  const paramsPage = parseInt(params.get("page")) || 1;
  const [offset, setOffset] = useSafeState<number>(
    (paramsPage - 1) * pageLength,
  );
  const [count, setCount] = useSafeState<number>(0);

  const [images, setImages] = useSafeState<Frame[]>([]);
  const [bigFrameId, setBigFrameId] = useSafeState<number | null>(null);

  const [formData, formActions] = useFormStore<RemainingImagesFormData>(
    "remainingImages",
  );

  // It is possible, that after switching to some other route and returning back old formData is kept
  // This leads to making a request with old form data first, and then second time with default values again
  // We don't really preserve selected filters anywhere, so I'll just clean a form before leaving the page
  useOnUnmount(() => {
    formActions.cleanForm();
  });

  const formIsEmpty = !formData || Object.keys(formData).length === 0;

  useEffect(() => {
    const loadImages = async () => {
      setLoading(true);

      // Intentionally returning only after setting loading=true
      // If it is null - we need to wait, until all components render and update their parameters
      if (formIsEmpty) {
        return;
      }
      const { image_type, ...requestFilters } = formData;
      if (image_type === "auto_only") {
        requestFilters.auto_only = true;
      }

      try {
        const response = await Api.job(job.id)
          .framesToLabel()
          .all({
            with_entities: true,
            limit: pageLength,
            offset,
            ...requestFilters,
          });
        setImages(response.data.results);
        setCount(response.data.count);
        setError(null);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    loadImages();
  }, [
    job.id,
    offset,
    pageLength,
    formData,
    formIsEmpty,
    setCount,
    setError,
    setImages,
    setLoading,
  ]);

  return (
    <div style={{ overflowY: "auto", height: "100%" }}>
      <div style={{ padding: "25px" }}>
        <Form<RemainingImagesFormData>
          formName={"remainingImages"}
          layout={"horizontal"}
        >
          <FormFields.DatetimeLocalField
            label="Start from"
            isUsedInParams
            paramName="created_after"
          />
          <FormFields.CameraField
            label="Camera"
            isUsedInParams
            paramName="stream_id"
            datasetId={job.dataset}
          />
          <FormFields.SelectField
            label="Images to show"
            isUsedInParams
            paramName="image_type"
            options={[
              { label: "All", value: "all" },
              { label: "Only auto", value: "auto_only" },
            ]}
            defaultValue={{ label: "All", value: "all" }}
          />
        </Form>
        {loading && <Loading />}
        {error && <ErrorMessage error={error} />}
        {!loading && images.length > 0 ? (
          <Fragment>
            {count > pageLength && (
              <Paginator
                onChange={setOffset}
                count={count}
                offset={offset}
                pageLength={pageLength}
                disabled={loading}
                supportOpeningInNewTab={true}
              />
            )}
            <CardList>
              {images.map((frame) => (
                <Card
                  key={frame.id}
                  showLabelStatus={frame.status === "auto"}
                  media={<CardImage frame={frame} />}
                  onClick={() => {
                    setBigFrameId(frame.id);
                  }}
                  hoverButtons={
                    <CardAddButton
                      link={`/labelling-jobs/${job.id}/frame/${
                        frame.id
                      }?redirect=/labelling-jobs/${
                        job.id
                      }/remaining-images&redirect_parameters=page=${
                        offset / pageLength + 1
                      }`}
                      title={"Label Image"}
                    />
                  }
                >
                  <CardDetails>
                    Uploaded: {moment(frame.create_time).format("LLL")}
                    <br />
                    Frame ID: {frame.id}
                    <br />
                    Camera: {frame.stream_name || "-"}
                  </CardDetails>
                </Card>
              ))}
            </CardList>
            <PageSizeChanger
              pageLength={pageLength}
              setPageLength={setPageLength}
              count={count}
              cookieName={PAGE_LENGTH_COOKIE}
            />
          </Fragment>
        ) : (
          !loading && <Fragment>No Images Remaining</Fragment>
        )}
        <GalleryModal
          frames={images}
          activeFrame={bigFrameId}
          setActiveFrame={setBigFrameId}
          loading={loading}
          pageLength={pageLength}
          count={count}
          drawEntities={true}
        />
      </div>
    </div>
  );
}
