import React, { useEffect, useMemo, useCallback } from "react";
import { RouteComponentProps } from "react-router";
import { useLocation, withRouter } from "react-router-dom";
import { createUltimatePagination } from "react-ultimate-pagination";
import styles from "./Paginator.scss";
import { isLeftClick } from "app/js/util";

interface PageLinkProps {
  onClick: () => void;
  // TODO: It was `<a disabled={isDisabled}>` everywhere, which actually does nothing
  //       Consider a proper implementation of the disabled state
  isDisabled: boolean;
}

interface PageProps extends PageLinkProps {
  value: string;
  isActive: boolean;
}

// I didn't find, how to pass required parameters via props without reimplementing all intermediate components.
// Because of that, I wrap component creation into a function instead.
const createPageComponent = (
  supportOpeningInNewTab: boolean,
  queryParam: string,
): React.FC<PageProps> => {
  return ({ value, isActive, onClick, isDisabled }) => {
    const handleClick = (e) => {
      if (isLeftClick(e)) {
        if (supportOpeningInNewTab) {
          e.preventDefault();
        }
        onClick();
      }
    };
    const location = useLocation();
    const additionalProps: Record<string, unknown> = {};
    if (supportOpeningInNewTab) {
      additionalProps.href = `${location.pathname}?${queryParam}=${value}`;
    }
    return (
      <li>
        <a
          onClick={handleClick}
          className={isActive ? styles.active : ""}
          {...additionalProps}
        >
          {value}
        </a>
      </li>
    );
  };
};

const Ellipsis: React.FC<PageLinkProps> = ({ onClick, isDisabled }) => (
  <li>
    <a onClick={onClick}>...</a>
  </li>
);

const FirstPageLink: React.FC<PageLinkProps> = ({ onClick, isDisabled }) => (
  <li>
    <a onClick={onClick}>First</a>
  </li>
);

const PreviousPageLink: React.FC<PageLinkProps> = ({ onClick, isDisabled }) => (
  <li>
    <a onClick={onClick}>Previous</a>
  </li>
);

const NextPageLink: React.FC<PageLinkProps> = ({ onClick, isDisabled }) => (
  <li>
    <a onClick={onClick}>Next</a>
  </li>
);

const LastPageLink: React.FC<PageLinkProps> = ({ onClick, isDisabled }) => (
  <li>
    <a onClick={onClick}>Last</a>
  </li>
);

const Wrapper: React.FC = ({ children }) => (
  <ul className={styles.pagination}>{children}</ul>
);

const createDashPagination = (
  supportOpeningInNewTab: boolean,
  queryParam: string,
) => {
  const itemTypeToComponent = {
    PAGE: createPageComponent(supportOpeningInNewTab, queryParam),
    ELLIPSIS: Ellipsis,
    FIRST_PAGE_LINK: FirstPageLink,
    PREVIOUS_PAGE_LINK: PreviousPageLink,
    NEXT_PAGE_LINK: NextPageLink,
    LAST_PAGE_LINK: LastPageLink,
  };

  return createUltimatePagination({
    itemTypeToComponent,
    WrapperComponent: Wrapper,
  });
};

type PaginatorComponentProps = RouteComponentProps<string> & {
  count: number;
  offset: number;
  pageLength: number;
  loading?: boolean;
  queryParam?: string;
  supportOpeningInNewTab?: boolean;
  [otherProp: string]: any;
};

const PaginatorComponent: React.FC<PaginatorComponentProps> = ({
  location,
  history,
  count,
  offset,
  pageLength,
  loading,
  queryParam = "page",
  supportOpeningInNewTab = false,
  ...props
}) => {
  const params = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const paramsPage = parseInt(params.get(queryParam));

  const onChange = useCallback(
    (value) => {
      params.set(queryParam, value);
      history.push({
        search: params.toString(),
      });

      if (props.onChange) {
        props.onChange((value - 1) * pageLength);
      }
    },
    [history, pageLength, params, props, queryParam],
  );

  useEffect(() => {
    if (paramsPage === Math.floor(offset / pageLength) + 1) {
      return;
    }
    if (!paramsPage) {
      params.set(queryParam, "1");
      history.replace({
        search: params.toString(),
      });

      onChange(1);
    } else {
      if ((paramsPage - 1) * pageLength > count) {
        onChange(1);
      } else {
        onChange(paramsPage);
      }
    }
  }, [
    count,
    history,
    offset,
    onChange,
    pageLength,
    params,
    paramsPage,
    queryParam,
  ]);

  const currentPage = Math.floor(offset / pageLength) + 1;
  const totalPages = Math.max(Math.ceil(count / pageLength), 1);

  const DashPagination = createDashPagination(
    supportOpeningInNewTab,
    queryParam,
  );
  return DashPagination({
    ...props,
    onChange,
    currentPage: currentPage,
    totalPages: totalPages,
  });
};

export default withRouter(PaginatorComponent);
