import React from "react";

import styles from "./CardDetails.scss";

interface CardDetailsProps {
  children: React.ReactNode;
}

export default function CardDetails({
  children,
}: CardDetailsProps): React.ReactElement {
  return (
    <p
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.cardDetails}
    >
      {children}
    </p>
  );
}
