import React from "react";

export const debounce = (func: () => void, wait: number, immediate = false) => {
  let timeout: ReturnType<typeof setTimeout>;
  return function (...args: any[]) {
    const bound = func.bind(this);
    const later = function () {
      timeout = null;
      if (!immediate) bound(args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) bound(args);
  };
};

export const screenSmall = (): boolean => {
  const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
  return width < 480;
};

type UrlParam = string | null;

export const getRedirectParameters = (
  params: URLSearchParams,
  paramNames: string[],
  handleCustomParams?: (name: string, value: UrlParam) => UrlParam,
): string => {
  const redirectParametersObj = {};
  for (const paramName of paramNames) {
    let paramValue: UrlParam = params.get(paramName);
    if (handleCustomParams) {
      paramValue = handleCustomParams(paramName, paramValue);
    }
    if (paramValue != null && paramValue !== "") {
      redirectParametersObj[paramName] = paramValue;
    }
  }

  return Object.entries(redirectParametersObj)
    .reduce((acc, [key, value]) => {
      acc.push(`${key}=${value}`);
      return acc;
    }, [] as string[])
    .join(",");
};

export const serializeUrlParameters = (
  params: Record<string, unknown>,
  dropNulls = false,
): string => {
  const stringParams: Record<string, string> = {};
  for (const [name, value] of Object.entries(params)) {
    if (value !== null || !dropNulls) {
      stringParams[name] = `${value}`;
    }
  }
  const urlParams = new URLSearchParams(stringParams);
  return urlParams.toString();
};

export const makeUrlWithParams = (
  url: string,
  params: Record<string, unknown>,
  dropNulls = false,
): string => {
  const serializedParams = serializeUrlParameters(params, dropNulls);
  if (serializedParams) {
    return `${url}?${serializedParams}`;
  } else {
    return url;
  }
};

export const isLeftClick = (event: React.MouseEvent<HTMLElement>): boolean => {
  // I'm not checking for useragent and simply ignore both Cmd/Win+Click and Ctrl+Click
  return (
    event.button === 0 &&
    // Cmd on Mac; WinKey on Windows (and Linux, I guess)
    !event.metaKey &&
    // Ctrl everywhere
    !event.ctrlKey
  );
};
