import React, { Fragment } from "react";
import styles from "./ToolMenu.scss";
import Rect from "./icons/rect";
import Circle from "./icons/circle";
import Polygon from "./icons/polygon";
// import Magic from "./icons/magic";
import Redo from "./icons/redo";
import DownloadIcon from "app/components/Icons/Download";
import Button from "app/components/Buttons/Button";
import ReactTooltip from "react-tooltip";
export default function ToolMenu({
  action,
  setAction,
  goBack,
  goForward,
  next,
  skip,
  className,
  loading,
  stopLabelling,
  imageUrl,
  setActiveEntityId,
}) {
  return (
    <nav className={`${styles.container} ${className}`}>
      <ul>
        <li>
          <Button
            data-tip="Stop Labelling"
            disabled={loading}
            style={{ marginRight: "20px" }}
            onClick={stopLabelling}
          >
            Stop Labelling
          </Button>
        </li>
        <li>
          <a
            data-tip="Download Image"
            onClick={() => {
              window.open(imageUrl);
            }}
          >
            <DownloadIcon />
          </a>
        </li>
        <li className={styles.seperator} />
        <li className={!goBack ? styles.disabled : ""}>
          <a
            data-tip="Undo Change (Ctrl + Z)"
            onClick={goBack ? goBack : () => undefined}
          >
            <Redo undo={true} />
          </a>
        </li>
        <li className={!goForward ? styles.disabled : ""}>
          <a
            data-tip="Redo Change (Ctrl + Y)"
            onClick={goForward ? goForward : () => undefined}
          >
            <Redo />
          </a>
        </li>
        <li className={styles.seperator} />
        <li>
          <a
            data-tip="Draw Rectangle (Opt/Alt + R)"
            className={`${action.type === "Rect" ? styles.active : ""}`}
            onClick={() => {
              setAction({ edit: true, type: "Rect" });
              setActiveEntityId(null);
            }}
          >
            <Rect />
          </a>
        </li>
        <li>
          <a
            data-tip="Draw Circle (Opt/Alt + C)"
            className={`${action.type === "Circle" ? styles.active : ""}`}
            onClick={() => {
              setAction({ edit: true, type: "Circle" });
              setActiveEntityId(null);
            }}
          >
            <Circle />
          </a>
        </li>
        <li>
          <a
            data-tip="Draw Polygon (Opt/Alt + P)"
            className={`${action.type === "Polygon" ? styles.active : ""}`}
            onClick={() => {
              setAction({ edit: true, type: "Polygon" });
              setActiveEntityId(null);
            }}
          >
            <Polygon />
          </a>
        </li>
        {action.edit === true ? (
          <Fragment>
            <li className={styles.seperator} />
            <li>
              <a
                data-tip="Cancel (Esc)"
                className="disabled"
                onClick={() => {
                  setAction({ edit: false, type: null });
                }}
              >
                Cancel
              </a>
            </li>
          </Fragment>
        ) : null}
        <li style={{ marginLeft: "auto", marginRight: "20px" }}>
          <Button
            data-tip="Skip labelling image"
            onClick={skip}
            disabled={loading}
            style={{ marginRight: "20px" }}
          >
            Skip
          </Button>
          <Button
            data-tip="Save the Image (ctrl + S)"
            onClick={next}
            disabled={loading}
          >
            Save & Continue
          </Button>
        </li>
      </ul>
      <ReactTooltip place="bottom" type="dark" effect="float" />
    </nav>
  );
}
