import { saveAs } from "file-saver";
import { AppConfig } from "app/js/types";

export const downloadText = (
  text: string,
  filename: string,
  mimeType: string,
) => {
  const blob = new Blob([text], {
    type: mimeType,
  });
  saveAs(blob, filename);
};

const downloadConfigResponse = (configEntity: AppConfig) => {
  if (configEntity?.configuration) {
    const applicationName = configEntity.application;
    const instanceId = configEntity.application_instance;
    const filename = `${applicationName}_${instanceId}_i${configEntity.id}.json`.replace(
      " ",
      "-",
    );
    const config = JSON.stringify(configEntity.configuration, null, 4);
    downloadText(config, filename, "application/json;charset=utf-8");
  } else {
    alert("No configuration found!");
  }
};

export { downloadConfigResponse };
