import React from "react";
import { Switch, Route } from "react-router-dom";

import DatasetViewList from "./DatasetViewList";
import DatasetViewDetail from "./Detail/Routes";

export default function Routes({ match }) {
  return (
    <Switch>
      <Route path={match.path} exact component={DatasetViewList} />
      <Route path={`${match.path}/:id`} component={DatasetViewDetail} />
    </Switch>
  );
}
