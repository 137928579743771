import React, { useEffect, Fragment } from "react";
import { Prompt } from "react-router-dom";
import { Link } from "react-router-dom";

import Api from "app/js/api";
import Step from "app/components/Step/Step";
import Button from "app/components/Buttons/Button";
import MessageBox from "app/components/MessageBox/MessageBox";
import FormComponent from "app/components/FormComponent/FormComponent";
import Card from "app/components/Card/Card";
import Dropzone from "app/components/Dropzone/Dropzone";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import styles from "./QuickstartLabel.scss";

import { useSafeState } from "app/js/hooks";
import { Dataset } from "app/js/types";
import { CardImage } from "app/components/Card";

const UPLOAD_PREVENT_NAVIGATION =
  "File upload in progress. Stopping will cause only " +
  "some of the images to be uplaoded. Are you sure you want to leave?";

export default function QuickstartLabel({ history }) {
  const [name, setName] = useSafeState("");
  const [streamId, setStreamId] = useSafeState(null);
  const [jobId, setJobId] = useSafeState(null);
  const [datasetId, setDatasetId] = useSafeState(null);
  const defaultDetector = parseInt(document.env.defaultSegmentor);

  const [messages, setMessages] = useSafeState([]);

  // loadings
  const [processLoading, setProcessLoading] = useSafeState(false);

  const addMessage = (value) => {
    setMessages((prev) => [...prev, value]);
  };

  const quickLoad = async () => {
    setProcessLoading(true);
    setMessages([]);

    // Validation
    if (name.length < 3) {
      addMessage(`Please select a name longer than 3 Characters`);
      setProcessLoading(false);
      return;
    }

    // Dataset
    let dataset: Dataset;
    try {
      const response = await Api.dataset().store({ name });
      dataset = response.data;
      setDatasetId(dataset.id);
      addMessage(
        `Created Dataset ${name}, it is the container for the images you will upload.`,
      );
    } catch (error) {
      addMessage(`ERROR while creating Dataset ${name}: ${error.message}`);
      setProcessLoading(false);
      return;
    }

    // Stream
    try {
      const stream = await Api.stream().store({
        name: name,
        dataset: dataset.id,
      });
      setStreamId(stream.data.stream);
      addMessage(
        `Created Camera ${name}, you now have an API endpoint for calling your model later.`,
      );
    } catch (error) {
      addMessage(`ERROR while creating Camera ${name}: ${error.message}`);
      setProcessLoading(false);
      return;
    }

    // Detector
    addMessage(
      `We use the general Embedder ${defaultDetector} for recognition.`,
    );

    // Labelling Job
    try {
      const job = await Api.job().store(
        name,
        "Labelling Job for working with image data.",
        "team_members",
        dataset.id,
        defaultDetector,
        "time_newest_first",
      );
      setJobId(job.data.id);
      addMessage(
        `Created Job ${name}, it allows you and your mates to label the images later.`,
      );
    } catch (error) {
      addMessage(`ERROR while creating Job ${name}: ${error.message}`);
      setProcessLoading(false);
      return;
    }
    setProcessLoading(false);
  };

  const [files, setFiles] = useSafeState([]);
  const [progress, setProgress] = useSafeState([]);
  const [error, setError] = useSafeState("");
  const [uploadInProgress, setUploadInProgress] = useSafeState(false);

  window.onbeforeunload = function () {
    if (!uploadInProgress) {
      return null;
    }
    return UPLOAD_PREVENT_NAVIGATION;
  };

  const onDrop = async (rawFiles) => {
    window.matomo.push(["trackGoal", 1]);
    setUploadInProgress(true);

    setFiles((prev) => {
      const newFiles = rawFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          uniqueName: file.name + Math.random(),
        }),
      );
      return [...prev, ...newFiles];
    });
    for (let i = 0; i < rawFiles.length; ++i) {
      const file = rawFiles[i];
      await Api.dataset(datasetId)
        .images()
        .store({
          stream_id: streamId,
          file: file,
          progress: (evt) => {
            if (evt.lengthComputable) {
              setProgress((prev) => ({
                ...prev,
                [file.uniqueName]: Math.round((evt.loaded / evt.total) * 100),
              }));
            }
          },
        })
        .then((response) => {
          setProgress((prev) => ({
            ...prev,
            [file.uniqueName]: "Upload Complete",
          }));
        })
        .catch((error) => {
          setProgress((prev) => ({
            ...prev,
            [file.uniqueName]: "Upload failed",
          }));
          setError(error);
        });
    }
    setUploadInProgress(false);
  };

  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      window.onbeforeunload = undefined;
    };
    // It worked before - I don't want to touch it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Step number={1} headline="Start a Labelling Project" active={!jobId}>
        {messages.length > 0 && <MessageBox messages={messages} />}
        {!processLoading && !jobId && (
          <FormComponent>
            <label>Name your Project</label>
            <input
              placeholder="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              type="text"
            />
            <Button
              onClick={(e) => {
                quickLoad();
              }}
            >
              Let's get started
            </Button>
          </FormComponent>
        )}
      </Step>
      <Step number={2} headline="Upload Images to train with" active={jobId}>
        {!jobId && <p>Please decide on a name first.</p>}
        {error && <ErrorMessage error={{ message: error }} />}
        {jobId && (
          <Fragment>
            {files.length > 0 && (
              <p>
                Uploaded{" "}
                {files.reduce(
                  (sum, file) =>
                    progress[file.uniqueName] === "Upload Complete"
                      ? sum + 1
                      : sum,
                  0,
                )}{" "}
                files out of {files.length}
              </p>
            )}
            <Prompt
              when={uploadInProgress}
              // prompt is shown twice because we add the team to the location the second time, only show for first
              message={(location) =>
                location.search === "" ? UPLOAD_PREVENT_NAVIGATION : true
              }
            />
            <Dropzone onDrop={onDrop} accept="image/*" />
            <div style={{ maxHeight: "500px", overflow: "auto" }}>
              {files.map((file) => (
                <Card
                  key={file.uniqueName}
                  media={<CardImage source={file.preview} />}
                  className={styles.card}
                  title={
                    progress[file.uniqueName] < 100
                      ? `Uploading ${progress[file.uniqueName]}%`
                      : progress[file.uniqueName]
                  }
                />
              ))}
            </div>
          </Fragment>
        )}
      </Step>
      <Step number={3} headline="Start Labelling" active={files.length > 0}>
        {files.length === 0 && <p>Please upload some images first</p>}
        {files.length > 0 && (
          <div>
            <p>
              You can now start to annotate your images and get your data ready
              for training.
            </p>
            <Link to={`/labelling-jobs/${jobId}/start`}>Start Labelling</Link>
          </div>
        )}
      </Step>
    </Fragment>
  );
}
