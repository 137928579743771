import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Api from "app/js/api";
import CardList from "app/components/CardList/CardList";
import VideoCard from "app/components/Card/VideoCard";
import Loading from "app/components/Loading/Loading";
import Paginator from "app/components/Paginator/Paginator";

import { useSafeState } from "app/js/hooks";
import { Video } from "app/js/types";

interface SelectVideosProps {
  datasetId: number;
  selectedVideos: Video[];
  setSelectedVideos: (videos: Video[]) => void;
  sampleVideo: Video;
  setSampleVideo: (video: Video) => void;
}

export default function SelectVideos({
  datasetId,
  selectedVideos,
  setSelectedVideos,
  sampleVideo,
  setSampleVideo,
}: SelectVideosProps) {
  const [videos, setVideos] = useSafeState<Video[]>([]);
  const [loading, setLoading] = useSafeState(false);
  const PAGE_LENGTH = 10;
  const [offset, setOffset] = useSafeState<number>(0);
  const [count, setCount] = useSafeState<number>(0);

  useEffect(() => {
    const loadVideos = async () => {
      setLoading(true);
      const response = await Api.video().all({
        dataset_ids: [datasetId],
        limit: PAGE_LENGTH,
        offset,
      });

      const newVideos = response.data.results.map((video) => {
        video["date"] = new Date(video.create_time);
        return video;
      });
      setVideos(newVideos);
      setCount(response.data.count);
      setLoading(false);
    };

    if (datasetId) {
      loadVideos();
    }
  }, [datasetId, offset, setCount, setLoading, setVideos]);

  const toggleVideo = (video) => {
    /* multiple video select
    let newSelectedVideos = JSON.parse(JSON.stringify(selectedVideos));
    // add it to the selected container
    if (!newSelectedVideos.find(entry => entry.id === video.id)) {
      newSelectedVideos.push(video);
    } else {
      newSelectedVideos = newSelectedVideos.filter(
        entry => entry.id !== video.id,
      );
    }

    // 1. if user removes sample video from selected videos, use first video from selected (if empty its null and its not shown)
    // 2. if the first video is added (array with length 1), then put it as sample as well
    if (
      (sampleVideo && sampleVideo.id === video.id) ||
      newSelectedVideos.length === 1
    ) {
      setSampleVideo(newSelectedVideos[0]);
    }

    setSelectedVideos(newSelectedVideos);
    */
    // single select
    setSelectedVideos([video]);
    setSampleVideo(video);
  };

  return (
    <div>
      {!datasetId && <p>Please select a dataset first.</p>}
      {loading && <Loading />}
      {!loading && datasetId && videos.length === 0 && (
        <p>
          This dataset does not have any Videos. You can upload videos on the{" "}
          <Link to={`/data-sets/${datasetId}/upload-videos`}>
            Dataset Detail Page
          </Link>
          .
        </p>
      )}
      {videos.length > 0 && (
        <Fragment>
          <CardList>
            {videos.map((video) => (
              <VideoCard
                key={video.id}
                video={video}
                selected={
                  !!selectedVideos.find((entry) => entry.id === video.id)
                }
                onClick={() => {
                  toggleVideo(video);
                }}
              />
            ))}
          </CardList>
          {count > PAGE_LENGTH && (
            <Paginator
              onChange={setOffset}
              count={count}
              offset={offset}
              pageLength={PAGE_LENGTH}
              disabled={loading}
            />
          )}
        </Fragment>
      )}
      {/*multiple select, can be added back with backend support
      {selectedVideos.length > 1 && (
        <Fragment>
          <h2>Selected Videos</h2>
          {selectedVideos.map(video => (
            <div key={video.id} className={styles.video}>
              <video
                src={video.video}
                onClick={() => {
                  setSampleVideo(video);
                }}
              />
              <div
                className={styles.remove}
                onClick={() => {
                  toggleVideo(video);
                }}
              >
                x
              </div>
            </div>
          ))}
        </Fragment>
      )}
      */}
      {videos.length > 0 && (
        <Fragment>
          <h2>Representative</h2>
          {!sampleVideo && (
            <p>
              Select a Video above, that serves as configuration video. (We
              process all selected though)
            </p>
          )}
        </Fragment>
      )}
    </div>
  );
}
