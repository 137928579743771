import React from "react";
import { useSafeState } from "app/js/hooks";
import { Option } from "app/js/types";

export const defaultResponseTransformer = (response) => {
  const results = response?.data?.results;
  results.sort((a, b) => a.id > b.id);
  return results
    ? results.map((item) => ({
        value: item.id,
        label: `${item.name} [${item.id}]`,
      }))
    : undefined;
};

export const streamResponseTransformer = (response) => {
  const results = response?.data?.results;
  results.sort((a, b) => a.id > b.id);
  return results
    ? results.map((item) => ({
        value: item.stream,
        label: `${item.name} [${item.stream}]`,
      }))
    : undefined;
};

export const withResource = (
  SelectComponent,
  resource,
  responseTransformer = defaultResponseTransformer,
  data = {},
) => {
  return (props) => {
    const { value, onChange, className } = props;
    const [options, setOptions] = useSafeState<Option<number | string>[]>(null);
    React.useEffect(() => {
      resource.all(data).then(responseTransformer).then(setOptions);
    }, [setOptions]);

    const _onChange = React.useCallback(
      (event) => {
        onChange(event?.value);
      },
      [onChange],
    );

    return (
      <SelectComponent
        options={options || undefined}
        value={
          options ? options.find((item) => item.value === value) : undefined
        }
        onChange={_onChange}
        isClearable
        id={props.id}
        className={className}
      />
    );
  };
};
