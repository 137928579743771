import React from "react";

import Table from "app/components/Table/Table";
import { LabellingUser } from "app/js/types";

interface UserLabellingTableProps {
  users: LabellingUser[];
}

const UserLabellingTable: React.FC<UserLabellingTableProps> = ({ users }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>User</th>
          <th>Unique Latest Images Labelled</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {users &&
          users.map((user) => (
            <tr key={user.user_id}>
              <td>
                {user.user__first_name} {user.user__last_name}
              </td>
              <td>{user.label_version_count}</td>
              <td />
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
export default UserLabellingTable;
