import React, { Fragment, useEffect } from "react";
import Select from "react-select";

import Api from "app/js/api";
import Step from "app/components/Step/Step";
import FormComponent from "app/components/FormComponent/FormComponent";
import Button from "app/components/Buttons/Button";
import Header from "app/components/Header/Header";
import Loading from "app/components/Loading/Loading";
import SelectDetector from "app/components/Selects/SelectDetector";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import moment from "moment";
import SelectDataset from "app/components/Selects/SelectDataset";

import { useSafeState } from "app/js/hooks";
import { useUserStore } from "app/js/stores";
import { LabelingJobOrder, LabelingJobPermissions, Option } from "app/js/types";

const orderOptions = [
  {
    value: "time_newest_first",
    label: "Time - Newest First",
  },
  {
    value: "time_oldest_first",
    label: "Time - Oldest First",
  },
  {
    value: "random",
    label: "Random",
  },
  {
    value: "score_task",
    label: "Score Task",
  },
];

interface FormLabelingJob {
  name: string;
  description: string;
  dataset: number | null;
  indexer: number | null;
  order: LabelingJobOrder;
  permissions: LabelingJobPermissions;
  score_task: string | null;
  permission_users: Option<number>[];
}

export default function LabellingJobEdit({ match, history }) {
  const jobId = match.params.labellingJobId;
  const [user] = useUserStore();
  const [loading, setLoading] = useSafeState(jobId != null);
  const [error, setError] = useSafeState(null);

  // Load Job Info
  const [job, setJob] = useSafeState<FormLabelingJob>({
    name: "",
    description: "",
    indexer: null,
    permissions: "team_members",
    permission_users: [],
    order: "time_newest_first",
    score_task: null,
    dataset: null,
  });
  // score_task options only when "Frame Score" option in Advanced is selected
  const [scoreTaskOptions, setScoreTaskOptions] = useSafeState<
    Option<string>[]
  >(null);

  useEffect(() => {
    const loadJob = async () => {
      setLoading(true);
      try {
        const response = await Api.job(jobId).show();
        const loadedJob = response.data;
        setJob({
          name: loadedJob.name,
          description: loadedJob.description || "",
          order: loadedJob.order,
          indexer: loadedJob.indexer,
          permissions: loadedJob.permissions,
          dataset: loadedJob.dataset,
          score_task: loadedJob.score_task,
          permission_users: loadedJob.permission_users.map(
            (permissionUser) => ({
              label: `${permissionUser.name} (${permissionUser.email})`,
              value: permissionUser.id,
            }),
          ),
        });
        setError(null);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    if (jobId) {
      loadJob();
    }
  }, [jobId, setError, setJob, setLoading, user.data]);

  const handleJobChange = (e) => {
    if (e.target.value === "team_members") {
      setJob({ ...job, [e.target.name]: e.target.value, permission_users: [] });
    } else {
      setJob({ ...job, [e.target.name]: e.target.value });
    }
  };
  const handleSelectChange = (field, option) => {
    setJob({
      ...job,
      [field]: option.id,
    });
  };

  const handleJobSubmit = async (e) => {
    setLoading(true);
    try {
      //name, description, permissions, dataset, detector, data
      const {
        name,
        description,
        permissions,
        dataset,
        indexer,
        order,
        ...data
      } = job;
      const permission_users = data.permission_users.map(
        (permissionUser) => permissionUser.value,
      );
      // edit mode
      if (jobId) {
        const response = await Api.job(jobId).update(
          name,
          description,
          permissions,
          dataset,
          indexer,
          order,
          {
            ...data,
            permission_users,
          },
        );
        return history.push(`/labelling-jobs/${response.data.id}`);
      }
      const response = await Api.job().store(
        name,
        description,
        permissions,
        dataset,
        indexer,
        order,
        {
          ...data,
          permission_users,
        },
      );
      history.push(`/labelling-jobs/${response.data.id}`);
    } catch (error) {
      console.log(error);
      setError(error);
    }
    setLoading(false);
  };

  // Detectors
  const [_detectors, setDetectors] = useSafeState([]);
  useEffect(() => {
    const loadDetectors = async () => {
      try {
        setLoading(true);
        const response = await Api.detector().all({
          include_public: true,
          trained_for: "detection",
        });
        const newDetectors = response.data.results.map((detector) => ({
          label: `${detector.name} (id: ${detector.id})`,
          value: detector.id,
        }));
        setDetectors(newDetectors);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    loadDetectors();
  }, [setDetectors, setError, setLoading, user.data]);

  // Users
  const [users, setUsers] = useSafeState<Option<number>[]>([]);
  useEffect(() => {
    const loadUsers = async () => {
      try {
        setLoading(true);
        const response = await Api.team(user.data.team_id).members().all();
        const userOptions = response.data.results.map((userOption) => ({
          label: `${userOption.user.name} (${userOption.user.email})`,
          value: userOption.user.id,
        }));
        setUsers(userOptions);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    loadUsers();
  }, [setError, setLoading, setUsers, user.data]);

  // Load Tasks
  useEffect(() => {
    const loadTask = async () => {
      setLoading(true);
      try {
        const response = await Api.task().all({
          graph_names: JSON.stringify(["FrameScoreDaskGraph"]),
          limit: 500,
        });
        const data = response.data.results.map((entry) => ({
          value: entry.task_key,
          label: `Mock Score Task at ${moment(entry.create_time).format(
            "LLL",
          )}`,
        }));
        setScoreTaskOptions(data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    if (job.order === "score_task") {
      loadTask();
    }
  }, [job.order, setError, setLoading, setScoreTaskOptions, user.data]);

  return (
    <Fragment>
      <Header>
        <h1>Labelling Job - {jobId ? job.name : "Create"}</h1>
      </Header>
      {error && <ErrorMessage error={error} />}
      <Step number={1} headline="General information">
        <FormComponent>
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={job.name}
            onChange={handleJobChange}
          />
          <label>Description</label>
          <input
            type="text"
            name="description"
            value={job.description}
            onChange={handleJobChange}
          />
        </FormComponent>
      </Step>
      <Step number={2} headline="Toolchain" active={!!job.name}>
        <FormComponent>
          <label>Embedder</label>
          <SelectDetector
            required={true}
            value={job.indexer}
            setDetector={(option) =>
              handleJobChange({
                target: {
                  name: "indexer",
                  value: option.id,
                },
              })
            }
            trainedFor={"embedding"}
            isDisabled={!job.name}
          />
          <label htmlFor="datasets">Datasets</label>
          <SelectDataset
            required={true}
            value={job.dataset}
            setDataset={(option) => {
              handleSelectChange("dataset", option);
            }}
            isDisabled={!job.name || jobId}
          />
        </FormComponent>
      </Step>
      <Step number={3} headline="Permissions" active={!!job.indexer}>
        <FormComponent>
          <label>Only Owners Can Label</label>
          <input
            type="radio"
            name="permissions"
            value="team_members"
            checked={job.permissions === "team_members"}
            onChange={handleJobChange}
          />
          <label>Only specific User can Label (Owners can always label)</label>
          <input
            type="radio"
            name="permissions"
            value="specific_users"
            checked={job.permissions === "specific_users"}
            onChange={handleJobChange}
          />
          {job.permissions === "specific_users" && (
            <Fragment>
              <label>Following users can label:</label>
              <div>
                <Select
                  value={job.permission_users}
                  onChange={(users) =>
                    handleJobChange({
                      target: {
                        name: "permission_users",
                        value: users,
                      },
                    })
                  }
                  options={users}
                  isMulti={true}
                />
              </div>
            </Fragment>
          )}
        </FormComponent>
      </Step>
      <Step number={4} headline="Advanced" active={!!job.indexer}>
        <FormComponent>
          <label>Ordering</label>
          <Select
            value={orderOptions.find((option) => option.value === job.order)}
            defaultValue={{
              label: "Sequential",
              value: "sequential",
            }}
            onChange={(order: Option<string>) =>
              handleJobChange({
                target: {
                  name: "order",
                  value: order.value,
                },
              })
            }
            options={orderOptions}
          />
        </FormComponent>
        {job.order === "score_task" ? (
          <FormComponent>
            <label>Task Score</label>
            <Select
              value={
                scoreTaskOptions &&
                scoreTaskOptions.find(
                  (scoreTask) => scoreTask.value === job.score_task,
                )
              }
              onChange={(scoreTask: Option<string>) =>
                handleJobChange({
                  target: {
                    name: "score_task",
                    value: scoreTask.value,
                  },
                })
              }
              options={scoreTaskOptions || []}
            />
          </FormComponent>
        ) : null}
        {loading && <Loading />}
        {!loading && <Button onClick={handleJobSubmit}>Save</Button>}
      </Step>
    </Fragment>
  );
}
