import React, { useEffect, Fragment } from "react";
import Api from "app/js/api";
import FormComponent from "app/components/FormComponent/FormComponent";
import Card from "app/components/Card/Card";
import CardList from "app/components/CardList/CardList";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import Dropzone from "app/components/Dropzone/Dropzone";

import { useSafeState } from "app/js/hooks";
import { CardVideo } from "app/components/Card";
export default function DatasetDetailVideosUpload({
  match,
  dataset,
  setDataset,
}) {
  const datasetId = match.params.id;
  const [error, setError] = useSafeState(null);
  // Video Upload
  const [fps, setFps] = useSafeState<number | string>("");
  const [files, setFiles] = useSafeState([]);
  const [progress, setProgress] = useSafeState([]);

  const onDrop = (rawFiles) => {
    setError("");
    setFiles((prev) => {
      const newFiles = rawFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      return [...prev, ...newFiles];
    });
    rawFiles.forEach((file) => {
      Api.dataset(datasetId)
        .videos()
        .store({
          dataset_id: datasetId,
          fps: fps,
          video: file,
          progress: (evt) => {
            if (evt.lengthComputable) {
              setProgress((prev) => ({
                ...prev,
                [file.name]: Math.round((evt.loaded / evt.total) * 100),
              }));
            }
          },
        })
        .then((response) => {
          setProgress((prev) => ({
            ...prev,
            [file.name]: "Upload finished",
          }));
          setError(null);
        })
        .then((response) => {
          setDataset({ ...dataset, videos_count: ++dataset.videos_count });
        })
        .catch((error) => {
          setProgress((prev) => ({
            ...prev,
            [file.name]: "upload failed",
          }));
          setError(error);
        });
    });
  };
  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
    // I don't want to debug this, so leaving as is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div style={{ margin: "25px" }}>
        <FormComponent>
          <label>FPS (Optional - not necessary for MP4):</label>
          <input
            value={fps}
            type="number"
            onChange={(e) => {
              setFps(e.target.value);
            }}
          />
        </FormComponent>
        {error && <ErrorMessage error={error} />}
        <Dropzone onDrop={onDrop} accept="video/*" />
        <CardList>
          {files.map((file) => (
            <Card
              key={file.name}
              media={<CardVideo source={file.preview} />}
              title={
                progress[file.name] < 100
                  ? `Uploading ${progress[file.name]}%`
                  : "File uploaded"
              }
            />
          ))}
        </CardList>
      </div>
    </Fragment>
  );
}
