import React, { useEffect, Fragment } from "react";

import Header from "app/components/Header/Header";
import FormComponent from "app/components/FormComponent/FormComponent";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import { Error } from "app/components/ErrorMessage/types";
import Loading from "app/components/Loading/Loading";
import Button from "app/components/Buttons/Button";
import SelectDataset from "app/components/Selects/SelectDataset";
import { FullCameraSelectStream } from "app/components/Selects/SelectStream";
import TaskStatus from "app/components/TaskStatus/TaskStatus";
import Paginator from "app/components/Paginator/Paginator";
import Card from "app/components/Card/Card";
import CardList from "app/components/CardList/CardList";
import { useSafeState } from "app/js/hooks";
import { DaskTask, LabelVersion } from "app/js/types";
import Api from "app/js/api";
import styles from "./AutoEntities.scss";
import { GenerateEntities } from "./types";
import { CardImage } from "app/components/Card";

const PAGE_LENGTH = 10;
const loadImageVersions = async (
  done,
  offset,
  setError,
  setImageVersions,
  setLoadingVersions,
  task,
) => {
  if (!done) {
    return;
  }

  setLoadingVersions(true);
  try {
    const response = await Api.labelversion().all({
      task_key: task.task_key,
      include_entities: true,
      limit: PAGE_LENGTH,
      mode: "auto",
      offset,
    });
    setImageVersions(response.data.results);
    setError(null);
  } catch (error) {
    setError(error);
  }
  setLoadingVersions(false);
};

export default function ProcessingTaskCreateAutoEntities({ processingTask }) {
  const [loading, setLoading] = useSafeState<boolean>(true);
  const [error, setError] = useSafeState<Error | null>(null);
  const [done, setDone] = useSafeState<boolean>(undefined);
  const [generateEntity, setGenerateEntity] = useSafeState<GenerateEntities>({
    id: 0,
    dataset: null,
    camera: null,
    created_before: "",
    created_after: "",
  });
  const [task, setTask] = useSafeState<DaskTask | { status: undefined }>(null);

  const loadTask = React.useCallback(async () => {
    const response = await Api.task()
      .autoEntities()
      .all({ processing_task_id: processingTask.id });
    const responseGraph = await Api.task()
      .autoEntitiesGraph()
      .all({ processing_task_id: processingTask.id });
    if (response.data.count > 0) {
      setGenerateEntity(response.data.results[0]);
      setTask(response.data.results[0].task);
    } else if (responseGraph.data.count > 0) {
      setGenerateEntity(responseGraph.data.results[0]);
      setTask(responseGraph.data.results[0].task);
    } else {
      // Default
      setGenerateEntity({
        id: 0,
        dataset: null,
        camera: null,
        created_before: "",
        created_after: "",
      });
    }
    setLoading(false);
  }, [processingTask.id, setGenerateEntity, setLoading, setTask]);

  useEffect(() => {
    loadTask();
  }, [loadTask]);

  const handleChange = (e) => {
    setGenerateEntity({
      ...generateEntity,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelectChange = (field, option) => {
    setGenerateEntity({
      ...generateEntity,
      [field]: option.id,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setDone(undefined);
    setTask({ status: undefined });
    try {
      console.log(generateEntity.camera.detection_type);
      if (generateEntity.camera.detection_type === "general") {
        const response = await Api.task()
          .autoEntities()
          .create({
            dataset: generateEntity.dataset,
            camera: generateEntity.camera.id,
            indexer: generateEntity.camera.general_detection_details.indexer,
            created_before: generateEntity.created_before || undefined,
            created_after: generateEntity.created_after || undefined,
            processing_task: processingTask.id,
          });
        setTask(response.data.task);
        setDone(response.data.task.result);
        loadImageVersions(
          done,
          offset,
          setError,
          setImageVersions,
          setLoadingVersions,
          task,
        );
      } else if (generateEntity.camera.detection_type === "graph") {
        const response = await Api.task()
          .autoEntitiesGraph()
          .create({
            dataset: generateEntity.dataset,
            camera: generateEntity.camera.id,
            cache_dataset: generateEntity.camera.dataset,
            indexers: generateEntity.camera.graph_detection_details.indexers,
            redis_indexer:
              generateEntity.camera.graph_detection_details.redis_indexer,
            graph_sequence: generateEntity.camera.graph_detection_details.graph,
            created_before: generateEntity.created_before || undefined,
            created_after: generateEntity.created_after || undefined,
            processing_task: processingTask.id,
          });
        setTask(response.data.task);
        setDone(response.data.task.result);
        loadImageVersions(
          done,
          offset,
          setError,
          setImageVersions,
          setLoadingVersions,
          task,
        );
      } else {
        console.error("Unsupported camera detection type");
      }
    } catch (error) {
      setError(error);
      setDone(undefined);
      setTask(null);
    }
  };

  const [loadingVersions, setLoadingVersions] = useSafeState<boolean>(true);
  const [imageVersions, setImageVersions] = useSafeState<LabelVersion[]>([]);
  const [offset, setOffset] = useSafeState<number>(0);

  const taskFinished = React.useCallback(
    (data) => {
      setDone(data.result);
      loadImageVersions(
        done,
        offset,
        setError,
        setImageVersions,
        setLoadingVersions,
        task,
      );
    },
    [
      done,
      offset,
      setDone,
      setError,
      setImageVersions,
      setLoadingVersions,
      task,
    ],
  );

  useEffect(() => {
    loadImageVersions(
      done,
      offset,
      setError,
      setImageVersions,
      setLoadingVersions,
      task,
    );
  }, [done, offset, setError, setImageVersions, setLoadingVersions, task]);

  const isReadOnly = generateEntity && generateEntity.id > 0;
  const count = imageVersions.length;

  return (
    <Fragment>
      <Header>
        <h1>Processing Tasks - Auto Entities</h1>
      </Header>
      <div style={{ padding: "25px" }}>
        {error && <ErrorMessage error={error} />}
        {loading && <Loading />}
        {!loading && (
          // I have no idea what is the purpoce of this `form` attribute
          // eslint-disable-next-line tsc/config
          <form form="autoEntities" onSubmit={handleSubmit}>
            <FormComponent>
              <label>Dataset</label>
              <SelectDataset
                required={true}
                value={generateEntity.dataset}
                setDataset={(option) => {
                  handleSelectChange("dataset", option);
                }}
                isDisabled={isReadOnly}
              />
              <label>Camera</label>
              <FullCameraSelectStream
                required={true}
                value={
                  generateEntity.camera ? generateEntity.camera.stream : null
                }
                setStream={(option) => {
                  setGenerateEntity({
                    ...generateEntity,
                    camera: option,
                  });
                }}
                isDisabled={isReadOnly}
              />
              <label htmlFor="from">Date from</label>
              <input
                id="from"
                name="created_after"
                type="datetime-local"
                value={generateEntity.created_after}
                onChange={handleChange}
                disabled={isReadOnly}
              />
              <label htmlFor="to">Date to</label>
              <input
                id="to"
                name="created_before"
                type="datetime-local"
                value={generateEntity.created_before}
                onChange={handleChange}
                disabled={isReadOnly}
              />
            </FormComponent>
            {done == null && <Button disabled={isReadOnly}>Save</Button>}
          </form>
        )}
        <TaskStatus rawTask={task} statusUpdate={taskFinished} />
        {done && (
          <Fragment>
            {loadingVersions && <Loading />}
            {!loadingVersions && imageVersions.length > 0 ? (
              <Fragment>
                {count > PAGE_LENGTH && (
                  <Paginator
                    onChange={setOffset}
                    count={count}
                    offset={offset}
                    pageLength={PAGE_LENGTH}
                    disabled={loadingVersions}
                  />
                )}
                <CardList>
                  {imageVersions &&
                    imageVersions.map((version) => (
                      <Card className={styles.card} key={version.id}>
                        <CardImage labelVersion={version} />
                      </Card>
                    ))}
                </CardList>
              </Fragment>
            ) : (
              !loadingVersions && <Fragment>No Versions Found</Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
