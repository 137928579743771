import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useParams, useSafeState } from "app/js/hooks";
import { useFormStore } from "app/js/stores";

import { FormContext } from "../context";

// Ideally, we pass either initialValue, or all other param-related props
// But it is a bit inconvenient to describe overrides so that Typescript didn't
// produce false-positive warnings
interface UseParamValueProps<T> {
  paramName: string;
  initialValue?: T | null;
  isUsedInParams?: boolean;
  convertFromParam?: ((v: string) => T) | null;
  // Returning null is for the case when we unset value
  convertToParam?: ((v: T) => string | null) | null;
  onChange?: ((newValue: T | null) => void) | null;
}

export function useParamValue<T = any>({
  paramName,
  initialValue = null,
  isUsedInParams = false,
  convertFromParam = null,
  convertToParam = null,
  onChange = null,
}: UseParamValueProps<T>): [value: T, setValue: (v: T) => void] {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const formName = React.useContext(FormContext);
  const [_formData, formActions] = useFormStore(formName);

  let stateValue = initialValue;
  const paramValue = params.get(paramName);
  if (isUsedInParams) {
    stateValue = paramValue !== null ? convertFromParam(paramValue) : null;
  }
  const [value, setValue] = useSafeState<T>(stateValue);
  const valueRef = React.useRef(value);
  React.useEffect(() => {
    formActions.setField(paramName, stateValue);
    // So, we leave the logic of parsing field value from query and setting it to query to each specific field
    // This works fine for updating field values: we have `updateValue` handler for this
    // But there is one more case, when `updateValue` does not work and won't be called:
    // the mounting process, when we parse an initial value.
    // The problem here is that we can easily parse it and save into state in this hook -
    // but I also want to save this parsed value into Form's state somehow
    // That's why i use store and that's why this useEffect is here: on field mounting, when
    // we have that parsed initial value, I want to pass it back to the Form - and at the
    // same time I don't want, that Form would know anything about field-specific logic
    // Since I want to execute this only on mount, I don't include a value into dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateValue = (newValue: T | null) => {
    if (typeof newValue === "string" && newValue === "") {
      // I don't distinguish empty strings and null: in most cases it is safe to treat both in the same way
      newValue = null;
    }
    if (newValue === valueRef.current) {
      return;
    }
    setValue(newValue);
    valueRef.current = newValue;
    if (onChange !== null) {
      onChange(newValue);
    }
    formActions.setField(paramName, newValue);
    if (isUsedInParams) {
      if (newValue !== null) {
        params.set(paramName, convertToParam(newValue));
      } else {
        params.delete(paramName);
      }
      history.push({
        ...location,
        search: params.toString(),
      });
    }
  };

  return [value, updateValue];
}
