import React, { useEffect, Fragment } from "react";
import Select from "react-select";

import { prepSceneData } from "./helper";
import Header from "app/components/Header/Header";
import Button from "app/components/Buttons/Button";
import Step from "app/components/Step/Step";
import FormComponent from "app/components/FormComponent/FormComponent";
import TaskStatus from "app/components/TaskStatus/TaskStatus";
import SelectDataset from "app/components/Selects/SelectDataset";
import InterestingArea from "./components/InterestingArea";
import GraphActivity from "./components/GraphActivity";
import GraphScene from "./components/GraphScene";
import SelectVideos from "./components/SelectVideos";
import SceneConfiguration from "./components/SceneConfiguration";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import Loading from "app/components/Loading/Loading";
import Paginator from "app/components/Paginator/Paginator";
import Card from "app/components/Card/Card";
import CardList from "app/components/CardList/CardList";
import ImageModal from "app/components/Modal/ImageModal";

import Api from "app/js/api";
import { useSafeState, useInterval } from "app/js/hooks";
import {
  DaskTask,
  Dataset,
  ExtractImagesFromScenesMinimalMotionData,
  Frame,
  Motion,
  Option,
  Region,
  Video,
} from "app/js/types";
import { Layout, SceneData, SceneInput } from "./types";
import { Error } from "app/components/ErrorMessage/types";
import { CardImage } from "app/components/Card";

const PAGE_LENGTH = 10;
const loadFrames = async (
  imageTask,
  offset,
  setCount,
  setExtractedFrames: (frames: Frame[]) => void,
  setExtractedFramesError,
  setExtractedFramesLoading,
) => {
  setExtractedFramesLoading(true);

  try {
    const response = await Api.frame().all({
      frame_task_key: imageTask.task_key,
      limit: PAGE_LENGTH,
      offset,
    });
    setExtractedFrames(response.data.results);
    setCount(response.data.count);
    setExtractedFramesError(null);
  } catch (error) {
    setExtractedFramesError(error);
    console.error(error);
  }
  setExtractedFramesLoading(false);
};

export default function ExtractImages({ processingTask }) {
  const [readOnly, setReadOnly] = useSafeState<boolean>(false);
  const [dataset, setDataset] = useSafeState<Dataset>(
    processingTask.metadata ? processingTask.metadata.dataset : {},
  );
  const [selectedVideos, setSelectedVideos] = useSafeState<Video[]>(
    processingTask.metadata ? processingTask.metadata.selectedVideos : [],
  );
  const [sampleVideo, setSampleVideo] = useSafeState<Video | null>(
    processingTask.metadata ? processingTask.metadata.sampleVideo : null,
  );
  const [imageTaskRunning, setImageTaskRunning] = useSafeState<boolean>(false);
  const [imageTaskDots, setImageTaskDots] = useSafeState<string>(".");

  // interesting Area
  const [interestingArea, setInterestingArea] = useSafeState<
    Region | Record<string, never>
  >(null);
  const [interestingData, setInterestingData] = useSafeState<number[]>([]);

  const options = [
    "magnitude",
    "x_sum",
    "x_sum_neg",
    "left_right",
    "right_left",
    "zoom_in",
    "zoom_in_compensated",
  ];
  const [movement, setMovement] = useSafeState<string>("magnitude");
  const [threshold, setThreshold] = useSafeState<number>(null);

  useEffect(() => {
    const setMetadata = async () => {
      const metadata = {
        dataset,
        selectedVideos,
        sampleVideo,
      };
      await Api.processingTask(processingTask.id).update({ metadata });
    };

    if (!readOnly) {
      setMetadata();
    }
  }, [dataset, processingTask.id, readOnly, sampleVideo, selectedVideos]);

  const [interestingTask, setInterestingTask] = useSafeState<
    DaskTask | { status: null }
  >(null);
  const calculateInterestingsness = async () => {
    setInterestingTask({ status: null });
    const data = {
      method: movement,
      video: sampleVideo.id,
      region: interestingArea,
      threshold: threshold,
      processing_task: processingTask.id,
    };
    try {
      const response = await Api.task().calculateInterestingness().create(data);
      setInterestingTask(response.data.task);
    } catch (error) {
      console.error(error);
    }
  };

  const [layout, setLayout] = useSafeState<Layout>({
    xaxis: { title: "Frame Number" },
    yaxis: { title: "Activity", fixedrange: true },
    margin: { r: 0 },
  });

  const interestingTaskFinished = React.useCallback(
    (data) => {
      setInterestingData(data.result);
      setLayout((prev) => ({
        ...prev,
        title: `Activity for ${data.result.length} frames`,
      }));
    },
    [setInterestingData, setLayout],
  );

  const [sceneInput, setSceneInput] = useSafeState<SceneInput>(null);
  const [sceneData, setSceneData] = useSafeState<SceneData | null>(null);
  const [sceneTask, setSceneTask] = useSafeState<DaskTask | { status: null }>(
    null,
  );
  //which Motiontype to show
  const [motion, setMotion] = useSafeState<Motion>(null);
  const loadSceneData = async ({
    movementTime,
    windowSize,
    cutOff,
    smooth,
    motionType,
  }) => {
    setSceneTask({ status: null });
    setMotion(motionType);
    const data = {
      interestingness_vector: interestingData,
      min_frames: Math.round(movementTime.min * sampleVideo.fps),
      max_frames: Math.round(movementTime.max * sampleVideo.fps),
      candidate_window_size: Math.round(windowSize * sampleVideo.fps),
      smoothness: smooth,
      cut_off_mod: cutOff,
      mode: motionType,
      processing_task: processingTask.id,
    };
    try {
      const response = await Api.task().generateScenes().create(data);
      setSceneTask(response.data.task);
    } catch (error) {
      console.error(error);
    }
  };
  const sceneTaskFinished = React.useCallback(
    (data) => {
      const result = prepSceneData(data.result, interestingData, motion);
      setSceneData(result);
    },
    [interestingData, motion, setSceneData],
  );

  const [imageTask, setImageTask] = useSafeState<
    DaskTask | { status: undefined }
  >(null);

  // Extract Images Possibilities
  const [extractScenesType, _setExtractScenesType] = useSafeState<string>(
    "minimalmotion_extraction",
  );
  const [loadParams, setLoadParams] = useSafeState<string>("{}");
  const [scoringParams, setScoringParams] = useSafeState<string>("{}");
  const [minimalFrame, setMinimalFrame] = useSafeState<number>(1);
  const [topK, setTopK] = useSafeState<number>(1);
  const [invertScoring, setInvertScoring] = useSafeState<boolean>(false);
  const [sendInterestingArea, setSendInterestingArea] = useSafeState<boolean>(
    false,
  );

  const generateImages = async () => {
    setImageTask({ status: undefined });
    try {
      const data: ExtractImagesFromScenesMinimalMotionData = {
        scenes: sceneData.scenes.interesting_scenes.map((entry) => ({
          cue: entry.cue,
          length: entry.length,
        })),
        video: sampleVideo.id,
        dataset: dataset.id,
        processing_task: processingTask.id,
      };

      switch (extractScenesType) {
        case "minimalmotion_extraction":
          data["minimal_frame_interval"] = Math.round(
            minimalFrame * sampleVideo.fps,
          );
          if (sendInterestingArea) {
            data["region"] = interestingArea;
          }
          data["top_k"] = topK;
          data["invert_scoring"] = invertScoring;
          break;
        case "detector_extraction":
          data["load_params"] = loadParams;
          data["scoring_params"] = scoringParams;
          break;
        default:
          console.warn(`Extraction type ${extractScenesType} is not supported`);
      }
      const response = await Api.task().extractImagesFromScenes().create(data);
      setImageTask(response.data.tasks[0]);
      setImageTaskRunning(true);
      setReadOnly(true);
    } catch (error) {
      console.error(error);
    }
  };

  const loadInterestingTask = React.useCallback(async () => {
    const response = await Api.task()
      .calculateInterestingness()
      .all({ processing_task_id: processingTask.id });
    if (response.data.count > 0) {
      const result = response.data.results[0];
      setMovement(result.method);
      setThreshold(result.threshold);
      setInterestingArea(result.region);
      setInterestingTask(result.task);
      setImageTaskRunning(true);
    } else {
      setInterestingArea({});
    }
  }, [
    processingTask.id,
    setImageTaskRunning,
    setInterestingArea,
    setInterestingTask,
    setMovement,
    setThreshold,
  ]);

  const loadSceneTask = React.useCallback(async () => {
    const response = await Api.task()
      .generateScenes()
      .all({ processing_task_id: processingTask.id });
    if (response.data.count > 0) {
      const result = response.data.results[0];
      setSceneInput({
        movementTime: {
          min: Math.round((result.min_frames / sampleVideo.fps) * 1e1) / 1e1,
          max: Math.round((result.max_frames / sampleVideo.fps) * 1e1) / 1e1,
        },
        windowSize: Math.round(result.candidate_window_size / sampleVideo.fps),
        advancedOptions: false,
        cutOff: result.cut_off_mod,
        smooth: result.smoothness,
        motionType: result.mode,
      });
      setSceneTask(result.task);
    } else {
      setSceneInput({
        movementTime: { min: 1, max: 5 },
        windowSize: 2.5,
        advancedOptions: false,
        cutOff: 0,
        smooth: 0,
        motionType: "avg_activity",
      });
    }
  }, [processingTask.id, sampleVideo?.fps, setSceneInput, setSceneTask]);

  const loadExtractTask = React.useCallback(async () => {
    const response = await Api.task()
      .extractImagesFromScenes()
      .all({ processing_task_id: processingTask.id });
    if (response.data.count > 0) {
      const result = response.data.results[0];
      setReadOnly(true);
      setMinimalFrame(
        Math.round((result.minimal_frame_interval / result.video.fps) * 1e1) /
          1e1,
      );
      setTopK(result.top_k);
      setSendInterestingArea(result.region != null);
      setInvertScoring(result.invert_scoring);
      setImageTask(result.tasks[0]);
      setImageTaskRunning(false);
    }
  }, [
    processingTask.id,
    setImageTask,
    setImageTaskRunning,
    setInvertScoring,
    setMinimalFrame,
    setReadOnly,
    setSendInterestingArea,
    setTopK,
  ]);

  useEffect(() => {
    loadInterestingTask();
    loadSceneTask();
    loadExtractTask();
  }, [loadExtractTask, loadInterestingTask, loadSceneTask]);

  const [extractedFramesLoading, setExtractedFramesLoading] = useSafeState<
    boolean
  >(false);
  const [offset, setOffset] = useSafeState<number>(0);
  const [count, setCount] = useSafeState<number>(0);
  const [extractedFramesError, setExtractedFramesError] = useSafeState<Error>(
    null,
  );

  const [extractedFrames, setExtractedFrames] = useSafeState<Frame[]>([]);
  const [bigImg, setBigImg] = useSafeState<string>(undefined);

  useEffect(() => {
    if (imageTask) {
      loadFrames(
        imageTask,
        offset,
        setCount,
        setExtractedFrames,
        setExtractedFramesError,
        setExtractedFramesLoading,
      );
    }
  }, [
    imageTask,
    offset,
    setCount,
    setExtractedFrames,
    setExtractedFramesError,
    setExtractedFramesLoading,
  ]);

  const imageTaskFinished = React.useCallback(
    (data) => {
      setImageTaskRunning(false);
      loadFrames(
        imageTask,
        offset,
        setCount,
        setExtractedFrames,
        setExtractedFramesError,
        setExtractedFramesLoading,
      );
    },
    [
      imageTask,
      offset,
      setCount,
      setExtractedFrames,
      setExtractedFramesError,
      setExtractedFramesLoading,
      setImageTaskRunning,
    ],
  );

  useInterval(
    () => {
      loadFrames(
        imageTask,
        offset,
        setCount,
        setExtractedFrames,
        setExtractedFramesError,
        setExtractedFramesLoading,
      );
      const newDots = imageTaskDots.length > 2 ? "." : imageTaskDots + ".";
      setImageTaskDots(newDots);
    },
    imageTaskRunning ? 1000 : null,
  );

  return (
    <Fragment>
      <Header>
        {" "}
        <h1>Processing Tasks - Create - Extract Images</h1>
      </Header>
      <Step number={1} headline="Select a Dataset">
        <SelectDataset
          required={true}
          value={dataset.id}
          setDataset={setDataset}
          isDisabled={readOnly}
        />
      </Step>
      <Step number={2} headline="Videos" active={dataset.id != null}>
        <SelectVideos
          datasetId={dataset.id}
          selectedVideos={selectedVideos}
          setSelectedVideos={setSelectedVideos}
          sampleVideo={sampleVideo}
          setSampleVideo={setSampleVideo}
        />
        {interestingArea != null && (
          <InterestingArea
            video={sampleVideo}
            initialInterestingArea={interestingArea}
            setInterestingArea={setInterestingArea}
          />
        )}
        {/*interestingArea && (
          <pre>{JSON.stringify(interestingArea)}</pre>
        )*/}
      </Step>
      <Step
        number={3}
        headline="Interestingness"
        active={interestingArea && Object.keys(interestingArea).length > 0}
      >
        {(!interestingArea || Object.keys(interestingArea).length === 0) && (
          <p>Select first an area to analyse</p>
        )}
        {interestingArea && Object.keys(interestingArea).length > 0 && (
          <Fragment>
            <FormComponent>
              <label>Movement Type:</label>
              <Select
                onChange={(e: Option<string>) => {
                  setMovement(e.value);
                }}
                options={options.map((entry) => ({
                  value: entry,
                  label: entry,
                }))}
                value={{ value: movement, label: movement }}
                isDisabled={readOnly}
              />
              <label>Static Threshold:</label>
              <input
                type="number"
                placeholder="Only enter if you know what you are doing..."
                onChange={(e) => {
                  setThreshold(parseInt(e.target.value));
                }}
                value={threshold || ""}
                disabled={readOnly}
              />
              <Button
                onClick={calculateInterestingsness}
                disabled={!movement || readOnly}
              >
                Calculate
              </Button>
            </FormComponent>
            <TaskStatus
              rawTask={interestingTask}
              statusUpdate={interestingTaskFinished}
            />
            {interestingData.length > 0 && (
              <GraphActivity
                layout={layout}
                setLayout={setLayout}
                interestingData={interestingData}
              />
            )}
          </Fragment>
        )}
      </Step>
      <Step number={4} headline="Scenes" active={!!interestingData.length}>
        {!interestingData.length && (
          <p>Calculate the scenes in the video first.</p>
        )}
        {interestingData.length > 0 && (
          <Fragment>
            <SceneConfiguration
              sceneInput={sceneInput}
              setSceneInput={setSceneInput}
              sampleVideo={sampleVideo}
              loadSceneData={loadSceneData}
              disabled={readOnly}
            />
            <TaskStatus rawTask={sceneTask} statusUpdate={sceneTaskFinished} />
            {sceneData && <GraphScene sceneData={sceneData} />}
          </Fragment>
        )}
      </Step>
      <Step number={5} headline="Generate Images" active={!!sceneData}>
        <FormComponent>
          {/*
          <label>Extract type:</label>
          <Select
            onChange={event => {
              setExtractScenesType(event.value);
            }}
            options={[
              {
                label: "I don't have a Detector",
                value: "minimalmotion_extraction"
              },
              {
                label: "I have a detector",
                value: "detector_extraction"
              }
            ]}
          />
          */}
          {extractScenesType === "minimalmotion_extraction" && (
            <Fragment>
              <label>Minimal distance between good frames (seconds)</label>
              <input
                type="number"
                value={minimalFrame}
                onChange={(e) => {
                  const value = e.target.value;
                  setMinimalFrame(
                    Number.isInteger(parseInt(value))
                      ? Math.max(0, parseInt(value))
                      : undefined,
                  );
                }}
                disabled={readOnly}
              />
              <label>Maximum number of frames per scene</label>
              <input
                type="number"
                value={topK}
                onChange={(e) => {
                  const value = e.target.value;
                  setTopK(
                    Number.isInteger(parseInt(value))
                      ? Math.max(0, parseInt(value))
                      : undefined,
                  );
                }}
                disabled={readOnly}
              />
              <label>Invert Scoring</label>
              <input
                type="checkbox"
                checked={invertScoring}
                onChange={() => {
                  setInvertScoring((prev) => !prev);
                }}
                disabled={readOnly}
              />
              <label>Send Interesting Area</label>
              <input
                type="checkbox"
                checked={sendInterestingArea}
                onChange={() => {
                  setSendInterestingArea((prev) => !prev);
                }}
                disabled={readOnly}
              />
            </Fragment>
          )}
          {extractScenesType === "detector_extraction" && (
            <Fragment>
              <label>Load Params</label>
              <textarea
                value={loadParams}
                onChange={(e) => {
                  setLoadParams(e.target.value);
                }}
                disabled={readOnly}
              />
              <label>Scoring Params</label>
              <textarea
                value={scoringParams}
                onChange={(e) => {
                  setScoringParams(e.target.value);
                }}
                disabled={readOnly}
              />
            </Fragment>
          )}
          <Button
            onClick={(event) => {
              generateImages();
            }}
            disabled={readOnly}
          >
            Generate Images
          </Button>
        </FormComponent>
        <TaskStatus rawTask={imageTask} statusUpdate={imageTaskFinished} />
      </Step>
      {imageTask && (
        <div style={{ padding: "25px", overflowY: "auto" }}>
          {imageTaskRunning ? (
            <h2>
              No Images Extracted{" "}
              {imageTaskRunning ? `Yet${imageTaskDots}` : null}
            </h2>
          ) : (
            <Fragment>
              {extractedFramesLoading && <Loading />}
              {extractedFramesError && (
                <ErrorMessage error={extractedFramesError} />
              )}
              {!extractedFramesLoading && extractedFrames.length > 0 ? (
                <Fragment>
                  {imageTaskRunning ? (
                    <h2>Task Running...</h2>
                  ) : (
                    <h2>Task Finished</h2>
                  )}
                  <p>Extracted {count} Images</p>
                  {count > PAGE_LENGTH && (
                    <Paginator
                      onChange={setOffset}
                      count={count}
                      offset={offset}
                      pageLength={PAGE_LENGTH}
                      disabled={extractedFramesLoading}
                    />
                  )}
                  <CardList>
                    {extractedFrames.map((frame) => (
                      <Card
                        key={frame.id}
                        media={<CardImage source={frame.image.image} />}
                        onClick={() => {
                          setBigImg(frame.image.image);
                        }}
                      />
                    ))}
                  </CardList>
                  <ImageModal img={bigImg} setImg={setBigImg} />
                </Fragment>
              ) : (
                extractedFramesLoading && <h2>No Images Extracted</h2>
              )}
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
}
