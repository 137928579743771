import React, { useEffect, Fragment } from "react";
import Api from "app/js/api";
import Table from "app/components/Table/Table";
import Loading from "app/components/Loading/Loading";
import Paginator from "app/components/Paginator/Paginator";
import moment from "moment";

import StreamImageTracking from "./components/StreamImageTracking";
import Button from "app/components/Buttons/Button";
import { useSafeState } from "app/js/hooks";
import { useUserStore } from "app/js/stores";
import LogModal from "app/components/Modal/LogModal";
import styles from "./StreamLog.scss";
import { TrackedCameraImage } from "app/js/types";

interface ActiveLog {
  log: string[];
  title: "getLabels" | "processEntities";
}

export default function StreamLog({ match }) {
  const [user] = useUserStore();
  const streamId = match.params.id;
  const [loading, setLoading] = useSafeState(false);
  const [log, setLog] = useSafeState<TrackedCameraImage[]>([]);
  const PAGE_LENGTH = 10;
  const [offset, setOffset] = useSafeState(0);
  const [count, setCount] = useSafeState(0);
  const [activeLog, setActiveLog] = useSafeState<ActiveLog>(null);

  useEffect(() => {
    const loadLog = async () => {
      setLoading(true);
      const response = await Api.stream(streamId).tracking({
        limit: PAGE_LENGTH,
        offset,
      });
      setLog(response.data.results);
      setCount(response.data.count);
      setLoading(false);
    };

    loadLog();
  }, [offset, setCount, setLoading, setLog, streamId, user.data]);

  const showLog = (
    entry: TrackedCameraImage,
    funct: "getLabels" | "processEntities",
  ) => {
    setActiveLog({ log: entry.logs[funct] || [], title: funct });
  };

  const entityCounts = (entities) => {
    const counts = entities
      .map((entity) => entity.label)
      .reduce(function (acc, curr) {
        if (typeof acc[curr] == "undefined") {
          acc[curr] = 1;
        } else {
          acc[curr] += 1;
        }

        return acc;
      }, {});
    return Object.keys(counts).map((key) => ({
      label: key,
      count: counts[key],
    }));
  };

  return (
    <div className={styles.container}>
      <StreamImageTracking streamId={streamId} />
      <h2>Detection Log</h2>
      {loading && <Loading />}
      {!loading && log.length > 0 ? (
        <Fragment>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Detection Time (ms)</th>
                <th>Entities</th>
                <th>Action Script Logs</th>
              </tr>
            </thead>
            <tbody>
              {log.map((entry) => (
                <tr key={entry.id}>
                  <td>{entry.id}</td>
                  <td>{moment(entry.start_time).format("lll")}</td>
                  <td>
                    <p>{entry.duration_ms}</p>
                  </td>
                  <td>
                    <div>
                      {entry.entities
                        ? entityCounts(entry.entities).map((count) => (
                            <p key={count.label}>
                              "{count.label}" detected {count.count} time(s)
                            </p>
                          ))
                        : null}
                    </div>
                  </td>
                  <td>
                    {entry.logs.getLabels ? (
                      <Button
                        onClick={() => showLog(entry, "getLabels")}
                        style={{ margin: "0 5px" }}
                      >
                        getLabels
                      </Button>
                    ) : null}
                    {entry.logs.processEntities ? (
                      <Button
                        onClick={() => showLog(entry, "processEntities")}
                        style={{ margin: "0 5px" }}
                      >
                        processEntities
                      </Button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {count > PAGE_LENGTH && (
            <Paginator
              onChange={setOffset}
              count={count}
              offset={offset}
              pageLength={PAGE_LENGTH}
              disabled={loading}
            />
          )}
          {activeLog && (
            <LogModal
              log={activeLog.log}
              title={activeLog.title}
              onRemoveLog={() => setActiveLog(null)}
            />
          )}
        </Fragment>
      ) : (
        !loading && <Fragment>No Log yet.</Fragment>
      )}
    </div>
  );
}
