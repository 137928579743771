import React, { useEffect } from "react";

import Box from "app/components/Box/Box";
import Button from "app/components/Buttons/Button";
import Loading from "app/components/Loading/Loading";
import {
  LoadedDataCallback,
  useLoadedData,
  useSafeState,
  useUserCallback,
} from "app/js/hooks";
import Api from "app/js/api";
import { useTeamStore, useUserStore } from "app/js/stores";
import { DeviceAuthRequest, TeamMember } from "app/js/types";
import { PendingAuthRequestsTable } from "app/pages/Settings/TeamSettings";
import styles from "app/pages/Settings/Settings.scss";

const Notifications: React.FC = () => {
  // Invites
  const [invites, setInvites] = useSafeState<TeamMember[]>([]);
  const [loading, setLoading] = useSafeState(false);
  const [_teams, teamActions] = useTeamStore();
  const [user, _userActions] = useUserStore();

  const loadInvites = React.useCallback(async () => {
    setLoading(true);
    // load invites
    const invitesResponse = await Api.team().invites().user(user.data.user_id);
    setInvites(invitesResponse.data.results);
    setLoading(false);
  }, [setInvites, setLoading, user.data.user_id]);

  useEffect(() => {
    loadInvites();
  }, [loadInvites, user]);

  const isStaffOrOwner = user.data.is_staff || user.data.role === "owner";
  // Pending Auth Requests
  const pendingAuthRequestsCallback: LoadedDataCallback<
    DeviceAuthRequest[]
  > = useUserCallback(
    async (setValue, setCount) => {
      if (!isStaffOrOwner) {
        return;
      }
      const response = await Api.deviceAuthorizationRequest().pending(
        user.data.team_id,
      );
      setValue(response.data.results);
      setCount(response.data.count);
    },
    [isStaffOrOwner, user.data.team_id],
  );
  const {
    value: pendingAuthRequests,
    count: pendingAuthRequestsCount,
    loading: pendingAuthRequestsLoading,
  } = useLoadedData<DeviceAuthRequest[]>([], pendingAuthRequestsCallback);

  const acceptInvite = async (id: number) => {
    await Api.team().invites(id).accept();
    loadInvites();
    teamActions.loadTeams();
  };

  const rejectInvite = async (id: number) => {
    await Api.team().invites(id).destroy();
    loadInvites();
  };

  const isLoading = user.loading || loading || pendingAuthRequestsLoading;
  const hasInvites = invites && invites.length > 0;
  const hasAppRequests = pendingAuthRequests && pendingAuthRequestsCount > 0;

  return (
    <Box title="Notifications">
      {isLoading ? (
        <Loading color="white" />
      ) : (
        <React.Fragment>
          {hasInvites && (
            <table>
              <thead>
                <tr>
                  <th>Team Name</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {invites.map((invite) => (
                  <tr key={invite.id}>
                    <td>{invite.team.name}</td>
                    <td>
                      <Button onClick={() => acceptInvite(invite.id)}>
                        Accept
                      </Button>
                    </td>
                    <td>
                      <Button onClick={() => rejectInvite(invite.id)}>
                        Reject
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {hasAppRequests && (
            <PendingAuthRequestsTable
              pendingAuthRequests={pendingAuthRequests}
              className={styles.table}
            />
          )}
          {!hasInvites && !hasAppRequests && <p>No Notifications to show</p>}
        </React.Fragment>
      )}
    </Box>
  );
};
export default Notifications;
