import React from "react";

import { GenericForm, useFormStore } from "app/js/stores";

import FormComponent from "app/components/FormComponent/FormComponent";

import { FormContext } from "./context";
import { useDeepEqualRef } from "app/js/hooks";

interface FormProps<FormData> {
  formName: string;
  layout: "vertical" | "horizontal";
  onFormUpdate?: (formData: FormData) => void | null;
  children: React.ReactNode;
}

export default function Form<FormData extends GenericForm>({
  formName,
  layout = "vertical",
  onFormUpdate = null,
  children,
}: FormProps<FormData>): React.ReactElement {
  const [formData] = useFormStore<FormData>(formName);
  const formDataRef = useDeepEqualRef<FormData>(formData);

  React.useEffect(() => {
    console.log(
      "Form data changed, triggering update handler. Create a bug, if this message appears too much without data changing",
      formData,
    );
    if (onFormUpdate !== null) {
      onFormUpdate(formData);
    }
    // Deps are correct - just using ref instead of object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataRef.current, onFormUpdate]);

  return (
    <FormContext.Provider value={formName}>
      <FormComponent layout={layout}>{children}</FormComponent>
    </FormContext.Provider>
  );
}
