import React from "react";
import FormComponent from "app/components/FormComponent/FormComponent";

interface WeekdayInput {
  weekdays: number[];
  onWeekdaysUpdate: (weekdays: number[]) => void;
}

const WeekdayInput: React.FC<WeekdayInput> = ({
  weekdays,
  onWeekdaysUpdate,
}) => {
  const allWeekdays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  return (
    <FormComponent>
      <label>Filter Days:</label>
      <div>
        {allWeekdays.map((entry, index) => (
          <label key={entry}>
            <input
              type="checkbox"
              checked={weekdays.includes(index + 1)}
              onChange={(e) => {
                // it is not in the array yet
                if (!weekdays.includes(index + 1)) {
                  weekdays.push(index + 1);
                  // need to return a new list to trigger render
                  onWeekdaysUpdate([...weekdays]);
                  return;
                }

                // it is in the array
                const key = weekdays.indexOf(index + 1);
                weekdays.splice(key, 1);
                // need to return a new list to trigger render
                onWeekdaysUpdate([...weekdays]);
                return;
              }}
            />
            {entry}
          </label>
        ))}
      </div>
    </FormComponent>
  );
};
export default WeekdayInput;
