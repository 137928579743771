import React from "react";
import styles from "./Submenu.scss";

const Submenu: React.FC = ({ children }) => {
  return (
    <nav className={styles.container}>
      <ul>{children}</ul>
    </nav>
  );
};
export default Submenu;
