import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import Select from "react-select";
import Api from "app/js/api";
import Loading from "app/components/Loading/Loading";

import { useSafeState } from "app/js/hooks";
import { LabelingJob, Option } from "app/js/types";

interface ElementOption<T> {
  value: T;
  label: string | React.ReactElement;
}

export interface LabelingJobForSelect
  extends Pick<LabelingJob, "id" | "name" | "team" | "dataset"> {
  [key: string]: any;
}

interface SelectLabellingJobProps {
  required?: boolean;
  value: number;
  setLabellingJob: (labellingJob: LabelingJobForSelect) => void;
  isDisabled?: boolean;
  datasetId: number;
  preloadedJobs?: LabelingJobForSelect[] | null;
  preferredJobId?: number | null;
}

const SelectLabellingJob: React.FC<SelectLabellingJobProps> = ({
  required = false,
  value,
  setLabellingJob,
  isDisabled,
  datasetId,
  // A hack for DatasetView pages: we should have jobs from the DatasetView object already,
  // so there is no need to re-request them
  preloadedJobs = null,
  preferredJobId = null,
}) => {
  const hasPreloadedJobs = preloadedJobs !== null;
  const [labellingJobs, setLabellingJobs] = useSafeState<
    LabelingJobForSelect[]
  >(preloadedJobs || []);
  const [loading, setLoading] = useSafeState(false);

  useEffect(() => {
    const loadLabellingJobs = async () => {
      setLoading(true);
      const response = await Api.job().all({
        limit: 500,
        dataset_id: datasetId,
      });
      setLabellingJobs(response.data.results);
      setLoading(false);
    };

    if (!hasPreloadedJobs) {
      loadLabellingJobs();
    }
  }, [datasetId, hasPreloadedJobs, setLabellingJobs, setLoading]);

  const handleChange = React.useCallback(
    (newJobId: number) => {
      if (labellingJobs.length > 0) {
        const newLabellingJob = labellingJobs.find(
          (entry) => entry.id === newJobId,
        );
        setLabellingJob(newLabellingJob);
      }
    },
    [labellingJobs, setLabellingJob],
  );

  React.useEffect(() => {
    // If we don't have preloadedJobs, automatically select job after loading
    if (!hasPreloadedJobs && preferredJobId && !value && labellingJobs) {
      handleChange(preferredJobId);
    }
  }, [handleChange, hasPreloadedJobs, labellingJobs, preferredJobId, value]);

  let jobsToRender = [...labellingJobs];
  if (preferredJobId) {
    jobsToRender = jobsToRender
      .filter((job) => job.id === preferredJobId)
      .concat(jobsToRender.filter((job) => job.id !== preferredJobId));
  }
  let options: ElementOption<number>[] = required
    ? []
    : [{ value: null, label: "no Labelling Job" }];
  options = [
    ...options,
    ...jobsToRender.map((entry) => ({
      label:
        entry.id === preferredJobId ? (
          <span style={{ fontWeight: "bold" }}>
            {`${entry.name} (id:${entry.id})`}
          </span>
        ) : (
          `${entry.name} (id:${entry.id})`
        ),
      value: entry.id,
    })),
  ];

  return (
    <Fragment>
      {loading && <Loading />}
      {!loading && jobsToRender.length === 0 && (
        <Fragment>
          {datasetId ? (
            <p>
              You don't have a Labelling Job for dataset {datasetId}. Create one
              in the <Link to={`/labelling-jobs`}>Labelling Job Section</Link>.
            </p>
          ) : (
            <p>
              You don't have any Labelling Jobs yet. You can go and create a
              Labelling Job in the{" "}
              <Link to={`/labelling-jobs`}>Labelling Job Section</Link>.
            </p>
          )}
        </Fragment>
      )}
      {!loading && jobsToRender.length > 0 && (
        <Select
          value={jobsToRender
            .filter((entry) => entry.id === value)
            .map((entry) => ({
              value: entry.id,
              label: `${entry.name} (id:${entry.id})`,
            }))}
          onChange={(e: Option<number>) => {
            handleChange(e.value);
          }}
          options={options}
          isDisabled={isDisabled}
        />
      )}
    </Fragment>
  );
};
export default SelectLabellingJob;
