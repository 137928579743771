import React from "react";
import StreamView from "app/components/StreamView/StreamView";
import Loading from "app/components/Loading/Loading";

export default function StreamRealtime({ stream }) {
  if (stream == null) {
    return <Loading />;
  }
  return (
    <div style={{ padding: "0 25px 0 25px" }}>
      <StreamView stream={stream.stream} />
    </div>
  );
}
