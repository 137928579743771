import React, { Fragment } from "react";
import classNames from "classnames";
import Select from "react-select";
import { Link, useRouteMatch } from "react-router-dom";
import Api from "app/js/api";
import { useSafeState } from "app/js/hooks";
import { LabelVersionFilters, LabelVersionLabels, Option } from "app/js/types";
import styles from "app/components/Menus/LabellingMenu/LabellingMenu.scss";
import { Error } from "app/components/ErrorMessage/types";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import { makeUrlWithParams } from "app/js/util";

export interface DatasetViewLabelVersionFilters extends LabelVersionFilters {
  datasetViewId?: "overview" | number;
}

interface LabellingMenuProps {
  baseUrl: string;
  mode?: string;
  setMode?: (mode: string) => void;
  jobId?: number | null;
  requestedLabels?: string[] | null;
  labelFilters: DatasetViewLabelVersionFilters;
  className: string;
  labelSearchTerm?: string;
}

const LabellingMenu: React.FC<LabellingMenuProps> = ({
  baseUrl,
  mode,
  setMode,
  jobId = null,
  requestedLabels,
  labelFilters,
  className,
  labelSearchTerm = "",
}) => {
  const rename_enabled = jobId !== null;
  const [loading, setLoading] = useSafeState<boolean>(false);
  const [labelsChanged, setLabelsChanged] = useSafeState<boolean>(false);
  const [labels, setLabels] = useSafeState<LabelVersionLabels>({});
  const [error, setError] = useSafeState<Error | null>(null);

  React.useEffect(() => {
    const loadLabels = async () => {
      setLoading(true);
      setError(null);
      try {
        const { datasetViewId, ...filters } = labelFilters;
        const response =
          datasetViewId === undefined
            ? await Api.labelversion().labels(filters)
            : await Api.datasetView(datasetViewId).labels(filters);
        setLabels(response.data.results);
      } catch (error) {
        setError(error);
      }
      setLabelsChanged(false);
      setLoading(false);
    };

    if (labelFilters.mode != null || labelsChanged) {
      loadLabels();
    }
  }, [
    labelFilters,
    labelsChanged,
    setError,
    setLabels,
    setLabelsChanged,
    setLoading,
  ]);

  const allKeys = React.useMemo(() => {
    let _allKeys = Object.keys(labels);
    if (requestedLabels) {
      _allKeys.push(
        ...requestedLabels.filter(
          (requestedLabel) => !(requestedLabel in labels),
        ),
      );
    }
    if (labelSearchTerm) {
      const rawPattern = labelSearchTerm
        .replace(/[.+?^${}()|[\]\\]/g, "\\$&")
        .replace(/\*/g, ".*");
      const labelPattern = new RegExp(rawPattern, "i");
      _allKeys = _allKeys.filter((requestedLabel) =>
        labelPattern.test(requestedLabel),
      );
    }
    return _allKeys;
  }, [labels, labelSearchTerm, requestedLabels]);
  const match = useRouteMatch<{ label: string }>(`${baseUrl}/:label`);

  const options = [
    { label: "Latest", value: "latest" },
    {
      label: "All user labelled",
      value: "user_all",
    },
    { label: "User Latest", value: "user_latest" },
    { label: "Auto", value: "auto" },
    {
      label: "Auto unlabelled Images",
      value: "auto_unlabelled_images",
    },
  ];
  const [newLabel, setNewLabel] = useSafeState<string>(null);
  const [initialLabel, setInitialLabel] = useSafeState<string>(null);

  const submitRename = async (e) => {
    e.preventDefault();
    const validation = /^$|[a-zA-ZÄÖÜäöü0-9_. ]+$/;
    if (!validation.test(newLabel)) {
      alert(
        "Only alphanumerical characters allowed with the addition of dot(.) and underscore(-)",
      );
      return false;
    }
    const response = await Api.entities().store({
      label: initialLabel,
      new_label: newLabel,
      job_id: jobId,
    });
    if (response.status === 200) {
      setInitialLabel(null);
      setLabelsChanged(true);
    }
  };

  const handleFormEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      submitRename(e);
    }
  };

  const labelStats: Record<
    string,
    {
      count: number;
      requested?: boolean;
      // requested && count == 0
      missing?: boolean;
    }
  > = React.useMemo(() => {
    const _labelStats = {};
    allKeys.forEach((labelName) => {
      const count = labels[labelName] || 0;
      _labelStats[labelName] = {
        count,
      };
      if (requestedLabels) {
        const requested = requestedLabels.indexOf(labelName) >= 0;
        _labelStats[labelName].requested = requested;
        _labelStats[labelName].missing = requested && count === 0;
      }
    });
    return _labelStats;
  }, [allKeys, requestedLabels, labels]);

  const labelSortFunc = React.useCallback(
    (labelA: string, labelB: string) => {
      const scoreA =
        (labelStats[labelA].requested ? 0 : 100) -
        (labelStats[labelA].missing ? 0 : 10);
      const scoreB =
        (labelStats[labelB].requested ? 0 : 100) -
        (labelStats[labelB].missing ? 0 : 10);
      return scoreA - scoreB + (labelA > labelB ? 1 : -1);
    },
    [labelStats],
  );

  return (
    <div className={className}>
      {loading ? (
        <Loading color="white" />
      ) : (
        <Fragment>
          {mode && (
            <div style={{ margin: "5px" }}>
              <Select
                value={options.find((entry) => entry.value === mode)}
                onChange={(e: Option<string>) => {
                  setMode(e.value);
                }}
                options={options}
              />
            </div>
          )}
          {error !== null ? (
            <ErrorMessage error={error} />
          ) : (
            <Fragment>
              {allKeys.length === 0 ? (
                <div style={{ padding: "25px", textAlign: "center" }}>
                  No Labels found
                </div>
              ) : (
                <ul>
                  {allKeys.sort(labelSortFunc).map((entry) => (
                    <li key={entry}>
                      {rename_enabled &&
                      initialLabel &&
                      initialLabel === entry ? (
                        <form
                          onSubmit={submitRename}
                          style={{ display: "inline-block" }}
                          id="renameForm"
                          onKeyPress={handleFormEnterKeyPress}
                        >
                          <input
                            id="renameInput"
                            type="text"
                            defaultValue={entry}
                            onChange={(e) => setNewLabel(e.target.value)}
                          />
                        </form>
                      ) : (
                        <Link
                          to={makeUrlWithParams(
                            `${baseUrl}/${entry}`,
                            {
                              mode: mode || null,
                              search: labelSearchTerm || null,
                              search_triggered: "false",
                            },
                            true,
                          )}
                          style={{
                            textDecoration:
                              match &&
                              match.params &&
                              match.params.label === entry
                                ? "underline"
                                : null,
                          }}
                          className={classNames(styles.labelLink, {
                            [styles.highlightRequests]: requestedLabels,
                            [styles.requested]: labelStats[entry].requested,
                            [styles.missing]: labelStats[entry].missing,
                          })}
                        >
                          {entry + " (" + labelStats[entry].count + ")"}
                        </Link>
                      )}
                      {rename_enabled ? (
                        newLabel && initialLabel === entry ? (
                          <Fragment>
                            <button
                              className="cancel"
                              name="cancel"
                              onClick={(e) => setInitialLabel(null)}
                              form="renameForm"
                              style={{
                                float: "right",
                                display: "inline-block",
                              }}
                            >
                              &#10006;
                            </button>
                            <button
                              className="confirm"
                              name="confirm"
                              style={{
                                float: "right",
                                display: "inline-block",
                              }}
                              form="renameForm"
                            >
                              &#10004;
                            </button>
                          </Fragment>
                        ) : (
                          <button
                            className="rename"
                            name="rename"
                            data-id={entry}
                            onClick={(e) =>
                              setInitialLabel(e.currentTarget.dataset.id)
                            }
                            style={{
                              cursor: "pointer",
                              float: "right",
                              display: "inline-block",
                            }}
                          >
                            &#9998;
                          </button>
                        )
                      ) : null}
                    </li>
                  ))}
                </ul>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default LabellingMenu;
