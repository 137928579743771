import React from "react";
import { Switch, Route } from "react-router-dom";

import StreamList from "./StreamList";
import StreamForm from "./StreamForm";
import StreamDetail from "./StreamDetail/Routes";

export default function Routes({ match }) {
  return (
    <Switch>
      <Route path={match.path} exact component={StreamList} />
      <Route path={`${match.path}/create`} exact component={StreamForm} />
      <Route path={`${match.path}/:id/edit`} component={StreamForm} />
      <Route path={`${match.path}/:id`} component={StreamDetail} />
    </Switch>
  );
}
