import Client from "./client";
import * as types from "app/js/types";

// `any`, because `unknown` causes some issues with interfaces, and I didn't find how to fix them
type FilterData = Record<string, any>;

const processValue = (value: unknown, isGetQuery = false): unknown => {
  // Only null/undefined for now. It might be useful in some cases to treat empty
  // strings/arrays/objects as "empty" too - but in some other cases that may be wrong
  if (value == null) {
    return null;
  }
  if (isGetQuery) {
    // It is safer and more convenient to require certain types for such parameters, than
    // just strings. But we cannot put them into GET request as is, of course
    return Array.isArray(value) ? JSON.stringify(value) : value;
    // TODO: Add support for dates? And for generic objects?
  }
  return value;
};

const processData = (
  data: FilterData | null,
  {
    dropEmpty = true,
    //Some fields are nullable and must not be dropped even with dropEmpty = true
    nullableFields = [],
    // If that's a GET request, there should be no arrays, objects, etc. in data
    isGetQuery = false,
  }: {
    dropEmpty?: boolean;
    nullableFields?: string[];
    isGetQuery?: boolean;
  } = {},
): FilterData | null => {
  if (data == null) {
    return null;
  }

  return Object.entries(data).reduce((obj: FilterData, [key, value]) => {
    const newValue = processValue(value, isGetQuery);
    if (!dropEmpty || newValue !== null || nullableFields.includes(key)) {
      obj[key] = newValue;
    }
    return obj;
  }, {});
};

export class ApiModels {
  client: Client;
  constructor(client: Client) {
    this.client = client;
  }
  // ACTIONSCRIPT
  actionscript(actionscriptId: number = null) {
    return {
      all: (
        data: types.ListFilters,
      ): types.AxiosListPromise<types.ActionScript> =>
        this.client.client.get("/api/actionscripts/", {
          params: processData(data, { isGetQuery: true }),
        }),
      store: (
        data: types.ActionScriptData,
      ): types.AxiosPromise<types.ActionScript> =>
        this.client.client.post("/api/actionscripts/", data),
      show: (): types.AxiosPromise<types.ActionScript> =>
        this.client.client.get(`/api/actionscripts/${actionscriptId}/`),
      update: (
        data: types.ActionScriptData,
      ): types.AxiosPromise<types.ActionScript> =>
        this.client.client.put(
          `/api/actionscripts/${actionscriptId}/`,
          processData(data),
        ),
      destroy: (): types.AxiosNoContentPromise =>
        this.client.client.delete(`/api/actionscripts/${actionscriptId}/`),
    };
  }

  // IMAGES
  images(imageId: types.NumericId = null) {
    return {
      destroy: (): types.AxiosNoContentPromise =>
        this.client.client.delete(`/api/images/${imageId}/`),
    };
  }

  // DATASET
  dataset(datasetId: types.NumericId = null) {
    return {
      all: (data: types.ListFilters): types.AxiosListPromise<types.Dataset> =>
        this.client.client.get("/api/datasets/", {
          params: processData(data, { isGetQuery: true }),
        }),
      store: (data: types.DatasetData): types.AxiosPromise<types.Dataset> =>
        this.client.client.post("/api/datasets/", processData(data)),
      show: (): types.AxiosPromise<types.Dataset> =>
        this.client.client.get(`/api/datasets/${datasetId}/`),
      update: (data: types.DatasetData): types.AxiosPromise<types.Dataset> =>
        this.client.client.put(
          `/api/datasets/${datasetId}/`,
          processData(data),
        ),
      destroy: (): types.AxiosNoContentPromise =>
        this.client.client.delete(`/api/datasets/${datasetId}/`),
      labelHistory: (
        data: types.LabelHistoryFilters,
      ): types.AxiosPromise<types.LabelHistory[]> =>
        this.client.client.get(`/api/datasets/${datasetId}/history/label/`, {
          params: processData(data, { isGetQuery: true }),
        }),
      download: (
        data: types.DatasetDownloadData,
      ): types.AxiosPromise<{ results: { task: types.DaskTask } }> =>
        this.client.client.get(`/api/datasets/${datasetId}/save_data/`, {
          params: processData(data, { isGetQuery: true }),
        }),
      // Entities
      entities: ({ mode }: { mode: types.LabelVersionMode }) => ({
        all: (data: {
          dataset_id: types.NumericId;
        }): types.AxiosListPromise<types.Entity> =>
          this.client.client.get(`/api/entities/`, {
            params: processData({ ...data, mode }, { isGetQuery: true }),
          }),
      }),
      // IMAGES
      images: () => ({
        store: ({
          stream_id,
          file,
          progress,
        }: types.AddImageBody): types.AxiosPromise<types.Frame> => {
          const formData = new FormData();
          formData.append("image", file, file.name);
          formData.append("stream_id", stream_id);
          return this.client.client.post(
            `/api/datasets/${datasetId}/add_image/`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: progress,
            },
          );
        },
      }),

      // VIDEOS
      videos: () => ({
        store: ({
          video,
          progress,
          fps,
          dataset_id,
        }: types.AddVideoData): types.AxiosPromise<types.Video> => {
          const formData = new FormData();
          formData.append("video", video);
          formData.append("codec", "1");
          formData.append("dataset_id", `${dataset_id}`);
          if (fps) {
            formData.append("fps", `${fps}`);
          }
          return this.client.client.post(`/api/videos/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: progress,
          });
        },
        add: (data: {
          ids: number[];
        }): types.AxiosPromise<{ videos_added: number[] }> =>
          this.client.client.post(
            `/api/datasets/${datasetId}/add_existing_videos/`,
            data,
          ),
      }),
    };
  }

  // DATASET VIEW
  datasetView(id?: "overview" | types.NumericId) {
    return {
      all: (
        filters: types.ListFilters,
      ): types.AxiosListPromise<types.DatasetView> =>
        this.client.client.get(`/api/dataset-views/`, {
          params: processData(filters),
        }),
      store: (
        data: types.DatasetViewSave,
      ): types.AxiosPromise<types.DatasetView> =>
        this.client.client.post(`/api/dataset-views/`, data),
      show: (): types.AxiosPromise<types.DatasetView> =>
        this.client.client.get(`/api/dataset-views/${id}/`),
      update: (
        data: types.DatasetViewSave,
      ): types.AxiosPromise<types.DatasetView> =>
        this.client.client.put(`/api/dataset-views/${id}/`, data),
      destroy: (): types.AxiosNoContentPromise =>
        this.client.client.delete(`/api/dataset-views/${id}/`),
      labels: (
        filters: types.LabelVersionFilters,
      ): types.AxiosPromise<{ results: types.LabelVersionLabels }> =>
        this.client.client.get(`/api/dataset-views/${id}/labels/`, {
          params: processData(filters, { isGetQuery: true }),
        }),
      jobsWithEntities: (
        filters: types.EntityFilters,
      ): types.AxiosPromise<number[]> =>
        this.client.client.get(`/api/dataset-views/${id}/jobs_with_entities/`, {
          params: processData(filters, { isGetQuery: true }),
        }),
      sprite: (
        filters: types.EntitySpriteFilters,
      ): types.AxiosPromise<types.ListSprite> =>
        this.client.client.get(`/api/dataset-views/${id}/sprite/`, {
          params: processData(filters, { isGetQuery: true }),
        }),
      frames: (
        filters: types.FrameFilters,
      ): types.AxiosListPromise<types.DatasetViewFrame> =>
        this.client.client.get(`/api/dataset-views/${id}/frames/`, {
          params: processData(filters, { isGetQuery: true }),
        }),
      deleteFrame: (frameId: types.NumericId): types.AxiosNoContentPromise =>
        this.client.client.delete(
          `/api/dataset-views/${id}/frames/${frameId}/`,
        ),
      download: (
        data: types.DatasetViewDownloadData,
      ): types.AxiosPromise<{ results: { task: types.DaskTask } }> =>
        this.client.client.get(`/api/dataset-views/${id}/save_data/`, {
          params: processData(data, { isGetQuery: true }),
        }),
    };
  }

  // DETECTOR
  detector(id?: number) {
    return {
      all: (
        data: types.VisionModelFilters,
      ): types.AxiosListPromise<types.VisionModel> =>
        this.client.client.get("/api/detectors/", {
          params: processData(data, { isGetQuery: true }),
        }),
    };
  }

  // LABELLING JOBS
  job(jobId: types.NumericId = null) {
    return {
      all: (
        data: types.LabelingJobFilter,
      ): types.AxiosListPromise<types.LabelingJob> =>
        this.client.client.get(`/api/labelingjobs/`, {
          params: processData(data, { isGetQuery: true }),
        }),
      show: (
        with_frame_counts = false,
      ): types.AxiosPromise<types.LabelingJob> =>
        this.client.client.get(`/api/labelingjobs/${jobId}/`, {
          params: { with_frame_counts: with_frame_counts },
        }),
      update: (
        name: string,
        description: string,
        permissions: types.LabelingJobPermissions,
        dataset: number,
        indexer: number,
        order: types.LabelingJobOrder,
        data: {
          permission_users: number[];
          score_task: string | null;
        },
      ): types.AxiosPromise<types.LabelingJob> =>
        this.client.client.patch(`/api/labelingjobs/${jobId}/`, {
          name,
          description,
          dataset,
          indexer,
          permissions,
          order,
          ...processData(data),
        }),
      store: (
        name: string,
        description: string,
        permissions: types.LabelingJobPermissions,
        dataset: number,
        indexer: number,
        order: types.LabelingJobOrder,
        data: {
          permission_users: number[];
          score_task: string | null;
        } = null,
      ): types.AxiosPromise<types.LabelingJob> =>
        this.client.client.post(`/api/labelingjobs/`, {
          name,
          description,
          permissions,
          dataset,
          indexer,
          order,
          ...processData(data),
        }),
      destroy: (): types.AxiosNoContentPromise =>
        this.client.client.delete(`/api/labelingjobs/${jobId}/`),
      resetLabelJob: (): types.AxiosPromise<"Reset"> =>
        this.client.client.post(`/api/labelingjobs/${jobId}/reset_label_job/`),

      // FRAMES TO LABEL
      framesToLabel: (frameId: number = null) => ({
        all: (data: types.FrameFilters): types.AxiosListPromise<types.Frame> =>
          this.client.client.get(`/api/labelingjobs/${jobId}/next_frames/`, {
            params: processData(data, { isGetQuery: true }),
          }),
        show: (): types.AxiosPromise<{
          remaining: number;
          frame: types.Frame | null;
        }> => this.client.client.get(`/api/labelingjobs/${jobId}/next_frame/`),
        store: ({
          entities,
        }: {
          entities: types.Entity[];
        }): types.AxiosPromise<types.LabelingJobStoreEntitiesResponse> =>
          this.client.client.post(
            `/api/labelingjobs/${jobId}/save_entities/`,
            processData({
              entities,
              frame_id: frameId,
            }),
          ),
        skip: (): types.AxiosPromise<"Success"> =>
          this.client.client.post(
            `/api/labelingjobs/${jobId}/skip_frame/`,
            processData({
              frame_id: frameId,
            }),
          ),
        bulkSkip: (frameIds: number[]): types.AxiosPromise<"Success"> =>
          this.client.client.post(
            `/api/labelingjobs/${jobId}/skip_frames/`,
            processData({
              frame_ids: frameIds,
            }),
          ),
        bulkUnskip: (frameIds: number[]): types.AxiosPromise<"Success"> =>
          this.client.client.post(
            `/api/labelingjobs/${jobId}/unskip_frames/`,
            processData({
              frame_ids: frameIds,
            }),
          ),
        unskipAll: (): types.AxiosPromise<"Success"> =>
          this.client.client.post(
            `/api/labelingjobs/${jobId}/unskip_all_frames/`,
          ),
        unqualifyAll: (): types.AxiosPromise<"Success"> =>
          this.client.client.post(
            `/api/labelingjobs/${jobId}/unqualify_all_frames/`,
          ),
        skippedImages: (
          data: types.SkippedImagesFilters,
        ): types.AxiosListPromise<types.Frame> =>
          this.client.client.get(`/api/labelingjobs/${jobId}/skipped_frames/`, {
            params: processData(data, { isGetQuery: true }),
          }),
        recommendations: (
          data: types.RecommendLabelsFilter,
        ): types.AxiosPromise<types.RecommendLabelsResponse> =>
          this.client.client.get(`/api/frames/${frameId}/recommend_labels/`, {
            params: processData(data, { isGetQuery: true }),
          }),
      }),
    };
  }

  // ENTITIES
  entities() {
    return {
      all: (data: types.EntityFilters): types.AxiosListPromise<types.Entity> =>
        this.client.client.get(`/api/entities/`, {
          params: processData(data, { isGetQuery: true }),
        }),
      store: (
        data: types.EntityRenameData,
      ): types.AxiosPromise<{ renamed_count: number }> =>
        this.client.client.post("/api/entities/rename/", processData(data)),
      sprite: (
        data: types.EntitySpriteFilters,
      ): types.AxiosPromise<types.ListSprite> =>
        this.client.client.get("/api/entities/sprite/", {
          params: processData(data, { isGetQuery: true }),
        }),
    };
  }

  // LABEL VERSION
  labelversion(labelVersionId: number = null) {
    return {
      all: (
        filters: types.LabelVersionFilters,
      ): types.AxiosListPromise<types.LabelVersion> =>
        this.client.client.get(`/api/labelversions/`, {
          params: processData(filters, { isGetQuery: true }),
        }),
      show: (
        include_entities: boolean = null,
      ): types.AxiosPromise<types.LabelVersion> =>
        this.client.client.get(`/api/labelversions/${labelVersionId}/`, {
          params: processData({ include_entities }, { isGetQuery: true }),
        }),
      labels: (
        filters: types.LabelVersionFilters,
      ): types.AxiosPromise<{ results: types.LabelVersionLabels }> =>
        this.client.client.get(`/api/labelversions/labels/`, {
          params: processData(filters, { isGetQuery: true }),
        }),
      users: (
        filters: types.LabelVersionFilters,
      ): types.AxiosPromise<{ results: types.LabellingUser[] }> =>
        this.client.client.get(`/api/labelversions/users/`, {
          params: processData(filters, { isGetQuery: true }),
        }),
    };
  }

  // FRAMES
  frame(frameId: types.NumericId = null) {
    return {
      all: (filters: types.FrameFilters): types.AxiosListPromise<types.Frame> =>
        this.client.client.get(`/api/frames/`, {
          params: processData(filters, {
            isGetQuery: true,
          }),
        }),
      id_list: (
        filters: types.FrameFilters,
      ): types.AxiosPromise<{ results: number[] }> =>
        this.client.client.get(`/api/frames/id_list/`, {
          params: processData(filters, { isGetQuery: true }),
        }),
      move: (
        data: types.MoveFrameData,
      ): types.AxiosPromise<{ success: true }> =>
        this.client.client.post(`/api/frames/move/`, processData(data)),
      show: (): types.AxiosPromise<types.Frame> =>
        this.client.client.get(`/api/frames/${frameId}/`),
      destroy: (): types.AxiosNoContentPromise =>
        this.client.client.delete(`/api/frames/${frameId}/`),
    };
  }

  // STREAMS
  stream(streamId: string = null) {
    return {
      all: (data: types.CameraFilters): types.AxiosListPromise<types.Camera> =>
        this.client.client.get("/api/cameras/", {
          params: processData(data, { isGetQuery: true }),
        }),
      selectOptions: (
        data: types.CameraFilters,
      ): types.AxiosListPromise<types.CameraSelectOption> =>
        this.client.client.get("/api/cameras/options_for_select/", {
          params: processData(data, {
            isGetQuery: true,
          }),
        }),
      show: (): types.AxiosPromise<types.Camera> =>
        this.client.client.get(`/api/cameras/${streamId}/`),
      store: (data: types.CameraData): types.AxiosPromise<types.Camera> =>
        this.client.client.post(
          "/api/cameras/",
          processData(
            {
              ...data,
              general_detection_details: processData(
                data.general_detection_details,
              ),
              graph_detection_details: processData(
                data.graph_detection_details,
              ),
            },
            { nullableFields: ["scale"] },
          ),
        ),
      update: (data: types.CameraData): types.AxiosPromise<types.Camera> => {
        if (data.general_detection_details || data.graph_detection_details) {
          return this.client.client.put(
            `/api/cameras/${streamId}/`,
            processData(
              {
                ...data,
                general_detection_details: processData(
                  data.general_detection_details,
                ),
                graph_detection_details: processData(
                  data.graph_detection_details,
                ),
              },
              { nullableFields: ["scale"] },
            ),
          );
        } else {
          return this.client.client.patch(
            `/api/cameras/${streamId}/`,
            processData(data, { nullableFields: ["scale"] }),
          );
        }
      },
      destroy: (): types.AxiosNoContentPromise =>
        this.client.client.delete(`/api/cameras/${streamId}/`),
      tracking: (
        data: types.ListFilters,
      ): types.AxiosListPromise<types.TrackedCameraImage> =>
        this.client.client.get(`/api/trackedcameraimages/`, {
          params: processData(
            { ...data, stream_id: streamId },
            { isGetQuery: true },
          ),
        }),
      trackingHistogram: (
        data: types.TrackingHistogramFilters,
      ): types.AxiosPromise<types.TrackingHistogram> =>
        this.client.client.get(`/api/cameras/${streamId}/tracking_histogram/`, {
          params: processData(data, { isGetQuery: true }),
        }),
    };
  }

  processingTask(taskId: types.NumericId = null) {
    return {
      all: (
        filters: types.ProcessingTaskFilters,
      ): types.AxiosListPromise<types.ProcessingTask> =>
        this.client.client.get(`/api/processingtasks/`, {
          params: processData(filters, { isGetQuery: true }),
        }),
      show: (): types.AxiosPromise<types.ProcessingTask> =>
        this.client.client.get(`/api/processingtasks/${taskId}/`),
      store: (
        type: types.ProcessingTaskType,
      ): types.AxiosPromise<types.ProcessingTask> =>
        this.client.client.post(`/api/processingtasks/`, processData({ type })),
      update: (
        data: types.ProcessingTaskUpdateData,
      ): types.AxiosPromise<types.ProcessingTask> =>
        this.client.client.patch(`/api/processingtasks/${taskId}/`, data),
    };
  }

  // TASK
  task(taskId: string = null) {
    return {
      all: (
        data: types.DaskTaskFilters,
      ): types.AxiosListPromise<types.DaskTask> =>
        this.client.client.get(`/api/tasks/`, {
          params: processData(data, { isGetQuery: true }),
        }),
      show: (): types.AxiosPromise<types.DaskTask> =>
        this.client.client.get(`/api/tasks/${taskId}/`),
      autoEntities: () => ({
        all: (
          filters: types.GenerateAutoEntitiesFilters,
        ): types.AxiosListPromise<types.GenerateAutoEntities> =>
          this.client.client.get(`/api/tasks/generateautoentities/`, {
            params: processData(filters, { isGetQuery: true }),
          }),
        create: (
          data: types.GenerateAutoEntitiesData,
        ): types.AxiosPromise<types.GenerateAutoEntities> =>
          this.client.client.post(
            "/api/tasks/generateautoentities/",
            processData(data),
          ),
      }),
      autoEntitiesGraph: () => ({
        all: (
          filters: types.ProcessingTaskIdFilter,
        ): types.AxiosListPromise<types.GenerateAutoEntitiesGraph> =>
          this.client.client.get(`/api/tasks/generateautoentitiesgraph/`, {
            params: processData(filters, { isGetQuery: true }),
          }),
        create: (
          data: types.GenerateAutoEntitiesGraphData,
        ): types.AxiosPromise<types.GenerateAutoEntitiesGraph> =>
          this.client.client.post(
            "/api/tasks/generateautoentitiesgraph/",
            processData(data),
          ),
      }),
      calculateInterestingness: () => ({
        all: (
          filters: types.ProcessingTaskIdFilter,
        ): types.AxiosListPromise<types.CalculateInterestingness> =>
          this.client.client.get(`/api/tasks/calculateinterestingness/`, {
            params: processData(filters, { isGetQuery: true }),
          }),
        create: (
          data: types.CalculateInterestingnessData,
        ): types.AxiosPromise<types.CalculateInterestingness> =>
          this.client.client.post(
            "/api/tasks/calculateinterestingness/",
            processData(data, { dropEmpty: false }),
          ),
      }),
      generateScenes: () => ({
        all: (
          filters: types.ProcessingTaskIdFilter,
        ): types.AxiosListPromise<types.GenerateScenes> =>
          this.client.client.get(`/api/tasks/generatescenes/`, {
            params: processData(filters, { isGetQuery: true }),
          }),
        create: (
          data: types.GenerateScenesData,
        ): types.AxiosPromise<types.GenerateScenes> =>
          this.client.client.post(
            "/api/tasks/generatescenes/",
            processData(data),
          ),
      }),
      extractImagesFromScenes: () => ({
        all: (
          filters: types.ProcessingTaskIdFilter,
        ): types.AxiosListPromise<types.ExtractImagesFromScenesMinimalMotion> =>
          this.client.client.get(
            `/api/tasks/extractimagesfromscenesminimalmotion/`,
            {
              params: processData(filters, { isGetQuery: true }),
            },
          ),
        create: (
          data: types.ExtractImagesFromScenesMinimalMotionData,
        ): types.AxiosPromise<types.ExtractImagesFromScenesMinimalMotion> =>
          this.client.client.post(
            "/api/tasks/extractimagesfromscenesminimalmotion/",
            processData(data),
          ),
      }),
    };
  }

  team(teamId: number = null) {
    return {
      all: (all = false): types.AxiosListPromise<types.Team> =>
        this.client.client.get(
          `/api/teams/?limit=500${all ? "&all_teams=true" : ""}`,
        ),
      show: (): types.AxiosPromise<types.Team> =>
        this.client.client.get(`/api/teams/${teamId}/?all_teams=true`),
      switch: (): types.AxiosPromise<{ token: string; team_id: number }> =>
        this.client.client.post(`/api/token-refresh/`, { team_id: teamId }),
      store: (data: { name: string }): types.AxiosPromise<types.Team> =>
        this.client.client.post(`/api/teams/`, processData(data)),
      update: (data: { name: string }): types.AxiosPromise<types.Team> =>
        this.client.client.put(`/api/teams/${teamId}`, data),
      destroy: (): types.AxiosNoContentPromise =>
        this.client.client.delete(`/api/teams/${teamId}`),

      // MEMBERS
      members: (teamMemberId: types.NumericId = null) => ({
        all: (
          role: string = undefined,
        ): types.AxiosListPromise<types.TeamMember> =>
          this.client.client.get(`/api/teammembers/`, {
            params: {
              pending: false,
              team_id: teamId,
              limit: 500,
              ...processData({ role }, { isGetQuery: true }),
            },
          }),
        update: (data: {
          email?: string;
          team?: number;
          role?: string;
        }): types.AxiosListPromise<types.TeamMember> =>
          this.client.client.patch(`/api/teammembers/${teamMemberId}/`, data),
        destroy: (): types.AxiosNoContentPromise =>
          this.client.client.delete(`/api/teammembers/${teamMemberId}/`),
      }),

      // TEAM INVITES
      invites: (teamMemberId: types.NumericId = null) => ({
        all: (): types.AxiosListPromise<types.TeamMember> =>
          this.client.client.get(
            `/api/teammembers/?pending=true&team_id=${teamId}`,
          ),
        user: (userId: number): types.AxiosListPromise<types.TeamMember> =>
          this.client.client.get(
            `/api/teammembers/?pending=true&user_id=${userId}`,
          ),
        store: (
          email: string,
          role: string,
        ): types.AxiosListPromise<types.TeamMember> =>
          this.client.client.post(
            `/api/teammembers/`,
            processData({
              team: teamId,
              email,
              role,
            }),
          ),
        destroy: (): types.AxiosNoContentPromise =>
          this.client.client.delete(`/api/teammembers/${teamMemberId}/`),
        accept: (): types.AxiosPromise<types.TeamMember> =>
          this.client.client.patch(`/api/teammembers/${teamMemberId}/`, {
            pending: false,
          }),
      }),
    };
  }

  // USER
  user(id: types.NumericId = null) {
    return {
      show: (): types.AxiosPromise<types.User> =>
        this.client.client.get(`/api/users/${id}/`),
      login: (
        email: string,
        password: string,
      ): types.AxiosPromise<{ token: string }> =>
        this.client.client.post("api/token-login/", {
          email,
          password,
        }),
      refreshToken: (
        teamId: number,
      ): types.AxiosPromise<{ token: string; team_id: number }> =>
        this.client.client.post(`/api/token-refresh/`, { team_id: teamId }),
      self: (): types.AxiosPromise<types.User> =>
        this.client.client.get("/api/users/current/"),
      isLoggedIn: () => client.getToken(),
      register: (
        data: types.SignupRequest,
      ): types.AxiosPromise<types.SignupResponse> =>
        this.client.client.post(`/api/users/signup/`, data),
      forgotPassword: (data: {
        email: string;
      }): types.AxiosPromise<{ email: string }> =>
        this.client.client.post(`/api/users/password_reset/`, data),
      resetPassword: (
        token: string,
        data: { new_password: string },
      ): types.AxiosPromise<"Success"> =>
        this.client.client.post(
          `/api/users/password_reset/${token}/update_password/`,
          data,
        ),
      update: (
        data: types.UserUpdateRequestBody,
      ): types.AxiosPromise<types.User> =>
        this.client.client.patch(`/api/users/${id}/`, data),
    };
  }

  // VIDEO
  video(id: types.NumericId = null) {
    return {
      all: (filters: types.VideoFilters): types.AxiosListPromise<types.Video> =>
        this.client.client.get(`/api/videos/`, {
          params: processData(filters, { isGetQuery: true }),
        }),
      show: (): types.AxiosPromise<types.Video> =>
        this.client.client.get(`/api/videos/${id}/`),
    };
  }

  moonboxApplication(name: string = null) {
    return {
      all: (
        query: types.ListFilters,
      ): types.AxiosListPromise<types.MoonboxApplication> =>
        this.client.client.get("/api/moonboxapplications/", {
          params: processData(query, { isGetQuery: true }),
        }),
    };
  }

  applicationInstance(id: types.NumericId = null) {
    return {
      all: (
        query: types.AppInstanceFilters,
      ): types.AxiosListPromise<types.AppInstance> =>
        this.client.client.get("/api/applicationinstances/", {
          params: processData(query, { isGetQuery: true }),
        }),
      show: (): types.AxiosPromise<types.AppInstance> =>
        this.client.client.get(`/api/applicationinstances/${id}/`),
      store: (
        data: types.AppInstanceCreateData,
      ): types.AxiosPromise<types.AppInstance> =>
        this.client.client.post("/api/applicationinstances/", data),
    };
  }

  applicationConfiguration(id: types.NumericId = null) {
    return {
      all: (
        query: types.AppConfigFilters,
      ): types.AxiosListPromise<types.AppConfig> =>
        this.client.client.get("/api/applicationconfigurations/", {
          params: processData(query, { isGetQuery: true }),
        }),
      show: (): types.AxiosPromise<types.AppConfig> =>
        this.client.client.get(`/api/applicationconfigurations/${id}/`),
      store: (
        data: types.AppConfigCreateData,
      ): types.AxiosPromise<types.AppConfig> =>
        this.client.client.post(
          "/api/applicationconfigurations/",
          processData(data),
        ),
    };
  }

  configTemplate(id: string = null) {
    return {
      show: (): types.AxiosPromise<types.ConfigTemplate> =>
        this.client.client.get(`/api/configtemplates/${id}/`),
    };
  }

  configTemplateVersion(id: types.NumericId = null) {
    return {
      all: (
        query: types.ConfigTemplateVersionFilters,
      ): types.AxiosListPromise<types.ConfigTemplateVersion> =>
        this.client.client.get("/api/configtemplateversions/", {
          params: processData(query, { isGetQuery: true }),
        }),
      show: (): types.AxiosPromise<types.ConfigTemplateVersion> =>
        this.client.client.get(`/api/configtemplateversions/${id}/`),
    };
  }

  deviceAuthorizationRequest() {
    return {
      pending: (
        teamId: number,
      ): types.AxiosListPromise<types.DeviceAuthRequest> =>
        this.client.client.get("/api/device_auth_requests/", {
          params: { status: "pending", team: teamId },
        }),
      approved: (
        teamId: number,
      ): types.AxiosListPromise<types.DeviceAuthRequest> =>
        this.client.client.get("/api/device_auth_requests/", {
          // TODO: Improve pagination and allow to request all items
          params: { status: "approved", team: teamId, limit: 500 },
        }),
      show: (
        id: types.NumericId,
      ): types.AxiosPromise<types.DeviceAuthRequest> =>
        this.client.client.get(`/api/device_auth_requests/${id}/`),
      update: (
        id: types.NumericId,
        user_code: string,
        status: types.DeviceAuthRequestStatus,
      ): types.AxiosPromise<types.DeviceAuthRequest> =>
        this.client.client.patch(`/api/device_auth_requests/${id}/`, {
          user_code,
          status,
        }),
      updateNoId: (
        application: string,
        user_code: string,
        status: types.DeviceAuthRequestStatus,
      ): types.AxiosPromise<types.DeviceAuthRequest> =>
        this.client.client.patch(
          `/api/device_auth_requests/update_by_user_code/`,
          {
            application,
            user_code,
            status,
          },
        ),
    };
  }

  visualSampleSet(id?: types.NumericId) {
    return {
      all: (
        query: types.VisualSampleSetFilters = {},
      ): types.AxiosListPromise<types.VisualSampleSet> =>
        this.client.client.get("/api/visual_sample_sets/", {
          params: processData(query, { isGetQuery: true }),
        }),
      store: (
        body: types.VisualSampleSetCreateData,
      ): types.AxiosPromise<types.VisualSampleSet> =>
        this.client.client.post("/api/visual_sample_sets/", processData(body)),
      show: (): types.AxiosPromise<types.VisualSampleSet> =>
        this.client.client.get(`/api/visual_sample_sets/${id}/`),
      filterChoices: (
        query: types.VisualSampleSetFilterChoicesQuery = {},
      ): types.AxiosPromise<types.VisualSampleSetFilterChoices[]> =>
        this.client.client.get("/api/visual_sample_sets/filter_choices/", {
          params: processData(query, { isGetQuery: true }),
        }),
    };
  }
}

const client = new Client().useModels(ApiModels).build();

export default client;
