import React, { Component, Fragment } from "react";
import { fabric } from "fabric";
import { stringToColor } from "app/pages/LabellingJobs/Labelling/helper/colors";

interface EditPolygonProps {
  canvas: any;
}
class EditPolygon extends Component<EditPolygonProps> {
  canvas: any;

  constructor(props) {
    super(props);
    this.canvas = props.canvas;
    this.resetCircles = this.resetCircles.bind(this);
    this.checkPolygonCheck = this.checkPolygonCheck.bind(this);
  }
  componentDidMount() {
    this.canvas.on("selection:created", (event) => {
      this.checkPolygonCheck(event.target);
    });
    this.canvas.on("selection:updated", (event) => {
      if (event.target.type !== "draggableCircle") {
        this.resetCircles();
      }
      this.checkPolygonCheck(event.target);
    });
    this.canvas.on("selection:cleared", (event) => {
      this.resetCircles();
    });
    this.canvas.on("mouse:move", (event) => {
      if (event.isClick && event.target && event.target.type === "Polygon") {
        this.resetCircles();
        this.checkPolygonCheck(event.target);
      }
    });
  }
  resetCircles() {
    this.canvas.getObjects("draggableCircle").forEach((klass) => {
      this.canvas.remove(klass);
    });
  }
  checkPolygonCheck(activeObject) {
    if (activeObject.type !== "Polygon") {
      return false;
    }
    const circles = [];

    const points = activeObject.points;
    points.forEach((point, index) => {
      const circle = new fabric.Circle({
        left: point.x + activeObject.aCoords.tl.x,
        top: point.y + activeObject.aCoords.tl.y,
        radius: activeObject.polygonPointSize,
        data: {
          circleNo: index,
          targetId: activeObject.data.id,
        },
        hasBorders: false,
        type: "draggableCircle",
        hasControls: false,
        fill:
          activeObject.label && activeObject.label !== ""
            ? stringToColor(activeObject.label, 1)
            : "rgb(255,255,0)",
        hasRotatingPoint: false,
        originX: "center",
        objectCaching: false,
        originY: "center",
        excludeFromExport: true,
      });
      circles.push(circle);

      // when moving the circles we change the polygon shape
      const that = this; // eslint-disable-line @typescript-eslint/no-this-alias
      circle.on("moving", function (e) {
        points[this.data?.circleNo].x = this.left - activeObject.aCoords.tl.x;
        points[this.data?.circleNo].y = this.top - activeObject.aCoords.tl.y;
        activeObject.set({
          points: points,
        });
        that.canvas.renderAll();
      });
    });

    this.canvas.add(...circles);
    activeObject.set({
      selected: false,
    });
    this.canvas.renderAll();
  }
  render() {
    return <Fragment />;
  }
}

export default EditPolygon;
