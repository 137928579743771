import React, { Fragment, useEffect, useCallback, useState } from "react";

import Api from "app/js/api";
import Header from "app/components/Header/Header";
import FormComponent from "app/components/FormComponent/FormComponent";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import Loading from "app/components/Loading/Loading";
import Button from "app/components/Buttons/Button";
import SelectDataset from "app/components/Selects/SelectDataset";
import SelectDetector from "app/components/Selects/SelectDetector";
import SelectDetectorMulti from "app/components/Selects/SelectDetectorMulti";
import SelectActionscript from "app/components/Selects/SelectActionscript";
import AreaPaid from "app/componentConstraints/AreaPaid";
import Select from "react-select";

import { useSafeState } from "app/js/hooks";
import { useUserStore } from "app/js/stores";
import { Camera, Option } from "app/js/types";

interface CameraWithIndexers extends Camera {
  indexer?: number | null;
  graph?: string;
  redis_indexer?: number;
  indexers?: number[] | null;
}

export default function StreamForm({ match, history }) {
  const [user] = useUserStore();
  const streamId = match.params.id;
  const [stream, setStream] = useState<CameraWithIndexers>({
    id: null,
    team: null,
    dataset: null,
    stream: null,
    scale: null,
    name: "",
    source_url: "",
    indexers: [],
    save_image: false,
    detection_type: null,
  });
  const [loading, setLoading] = useSafeState<boolean>(false);
  const [error, setError] = useSafeState<Error>(null);

  const detectionTypeOptions = [
    {
      label: "General Detection",
      value: "general",
    },
    { label: "No Detection", value: null },
    user.data.is_staff && {
      label: "Graph Detection",
      value: "graph",
    },
  ];

  const loadStream = useCallback(async () => {
    try {
      const response = await Api.stream(streamId).show();
      setStream({
        ...stream,
        ...response.data,
        indexer: response.data.general_detection_details
          ? response.data.general_detection_details.indexer
          : null,
        graph: response.data.graph_detection_details
          ? JSON.stringify(response.data.graph_detection_details.graph)
          : "",
        redis_indexer: response.data.graph_detection_details
          ? response.data.graph_detection_details.redis_indexer
          : null,
        indexers: response.data.graph_detection_details
          ? response.data.graph_detection_details.indexers
          : [],
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [setError, setLoading, stream, streamId]);

  useEffect(() => {
    // Load Stream When in Edit Mode
    if (streamId) {
      loadStream();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamId]);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setStream({ ...stream, [name]: value });
  };

  const handleSelectChange = (field, option) => {
    option = option ? option.id : null;
    setStream({
      ...stream,
      [field]: option,
    });
  };

  const handleMultiSelectChange = (field, option) => {
    setStream({
      ...stream,
      [field]: option,
    });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setError(null);

      let streamInput = stream;
      if (streamInput.source_url === "") {
        streamInput.source_url = null;
      }
      if (stream.detection_type === "general") {
        streamInput = {
          ...stream,
          general_detection_details: { indexer: stream.indexer },
          graph_detection_details: undefined,
        };
        if (stream.indexer === null) {
          setError({ name: "", message: "Please choose a detector/indexer" });
          setLoading(false);
          return;
        }
      }
      if (stream.detection_type === "graph" && user.data.is_staff) {
        try {
          const jsonGraph = JSON.parse(stream.graph);
          streamInput = {
            ...stream,
            graph_detection_details: {
              graph: jsonGraph,
              redis_indexer: stream.redis_indexer,
              indexers: stream.indexers,
            },
            general_detection_details: undefined,
          };
        } catch (error) {
          setError({ name: "", message: "Graph Sequence must be valid JSON" });
          setLoading(false);
          return;
        }
      }
      if (!streamInput.scale && streamInput.scale !== 0) {
        streamInput.scale = null;
      }

      try {
        if (streamId) {
          // Edit mode
          await Api.stream(streamId).update(streamInput);
          history.push(`/streams/${streamId}`);
        } else {
          // Create Mode
          const response = await Api.stream().store(streamInput);
          history.push(`/streams/${response.data.stream}`);
        }
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    },
    [history, setError, setLoading, stream, streamId, user.data.is_staff],
  );

  const fieldMap = {
    name: "Name",
    scale: "Absolute scale, dots per meter",
    source_url: "URL of the Web Stream",
    dataset: "Dataset",
    detection_type: "Type of Detection",
    indexer: "Detector",
    indexers: "Models List",
    redis_indexer: "Redis Indexer",
    graph: "Graph Sequence",
    save_image: "Store Images sent via API",
    action_script: "Actionscripts",
  };

  return (
    <Fragment>
      <Header>
        {!loading && stream && (
          <h1>Camera - {streamId && stream.name ? stream.name : "Create"}</h1>
        )}
        {loading && <h1>Loading...</h1>}
      </Header>
      {error && <ErrorMessage error={error} fieldMap={fieldMap} />}
      {loading && <Loading />}
      {!loading && stream && (
        <form onSubmit={handleSubmit} style={{ padding: "25px" }}>
          <FormComponent>
            <label>Name</label>
            <input name="name" value={stream.name} onChange={handleChange} />
            <label>Absolute scale, dots per meter</label>
            <input
              name="scale"
              value={stream.scale || ""}
              onChange={handleChange}
            />
            <label>URL of the Web Stream</label>
            <input
              name="source_url"
              value={stream.source_url}
              onChange={handleChange}
            />
            <label>Dataset</label>
            <SelectDataset
              required={true}
              value={stream.dataset}
              setDataset={(option) => {
                handleSelectChange("dataset", option);
              }}
            />
            <label>Type of Detection</label>
            <Select
              value={detectionTypeOptions.find(function (entry) {
                return entry.value === stream.detection_type;
              })}
              options={detectionTypeOptions}
              onChange={(option: Option<string>) => {
                handleSelectChange("detection_type", {
                  id: option.value,
                });
              }}
              isDisabled={
                !user.data.is_staff && stream.detection_type === "graph"
              }
            />
            {stream.detection_type === "general" && (
              <Fragment>
                <label>Detector</label>
                <SelectDetector
                  required={true}
                  value={stream.indexer}
                  setDetector={(option) => {
                    handleSelectChange("indexer", option);
                  }}
                  trainedFor={"detection"}
                />
              </Fragment>
            )}
            {stream.detection_type === "graph" && (
              <Fragment>
                <label>Models List</label>
                <SelectDetectorMulti
                  value={stream.indexers}
                  setDetectorIds={(e) => {
                    handleMultiSelectChange("indexers", e);
                  }}
                  isDisabled={!user.data.is_staff}
                />
                <label>Redis Indexer</label>
                <SelectDetector
                  value={stream.redis_indexer}
                  setDetector={(option) => {
                    handleSelectChange("redis_indexer", option);
                  }}
                  trainedFor={"embedding"}
                  isDisabled={!user.data.is_staff}
                />
                <label>Graph Sequence</label>
                <textarea
                  onChange={handleChange}
                  name="graph"
                  value={stream.graph}
                  disabled={!user.data.is_staff}
                />
              </Fragment>
            )}
            <label>Store Images sent via API</label>
            <input
              name="save_image"
              type="checkbox"
              checked={stream.save_image}
              onChange={handleChange}
            />
            <AreaPaid>
              <label>Actionscripts</label>
              <SelectActionscript
                value={stream.action_script}
                setActionscript={(option) => {
                  handleSelectChange("action_script", option);
                }}
              />
            </AreaPaid>
          </FormComponent>
          <Button>Save</Button>
        </form>
      )}
    </Fragment>
  );
}
