import React from "react";

import moment from "moment";

import { useParamValue } from "../hooks";
import { BaseField, OmitConflictingProps } from "../types";
import Field from "../Field";

export type DatetimeLocalFieldProps = BaseField<string> &
  OmitConflictingProps<React.InputHTMLAttributes<HTMLInputElement>>;

export function DatetimeLocalField({
  label,
  isRequired,
  isDisabled,
  isUsedInParams,
  paramName,
  value,
  onChange,
  ...inputProps
}: DatetimeLocalFieldProps): React.ReactElement {
  const convertFromParam = (v: string, format: string = null): string =>
    !!v ? moment(v).format(format) : "";
  const convertToParam = (v: string): string => {
    return !!v ? moment(v).format() : "";
  };

  const [fieldValue, setFieldValue] = useParamValue<string>({
    paramName,
    initialValue: value,
    isUsedInParams,
    convertFromParam,
    convertToParam,
    onChange,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = convertToParam(event.target.value);
    setFieldValue(newValue);
  };

  return (
    <Field label={label}>
      <input
        type="datetime-local"
        value={convertFromParam(fieldValue, moment.HTML5_FMT.DATETIME_LOCAL)}
        onChange={handleChange}
        required={isRequired}
        disabled={isDisabled}
        {...inputProps}
      />
    </Field>
  );
}
