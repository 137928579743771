import React, { HTMLInputTypeAttribute } from "react";
import { Widget } from "@rjsf/core";

const FixedValue: Widget = ({ value, onChange, readonly, options, schema }) => {
  React.useEffect(() => {
    if (value !== schema.const || schema.default) {
      onChange(schema.const || schema.default);
    }
  }, [value, onChange, schema]);
  return (
    <input
      value={(value || "") as string | number}
      type={options.type as HTMLInputTypeAttribute}
      readOnly={readonly}
      className="form-control"
    />
  );
};

export default FixedValue;
