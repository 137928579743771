import React from "react";
import classNames from "classnames";
import styles from "./Button.scss";

interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  className?: string;
  big?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  disabled,
  big,
  ...props
}) => {
  return (
    <button
      className={classNames(styles.button, className, { [styles.big]: big })}
      role="button"
      disabled={disabled ? true : null}
      {...props}
    >
      {children}
    </button>
  );
};
export default Button;
