import React from "react";

import Api from "app/js/api";
import Header from "app/components/Header/Header";
import Box from "app/components/Box/Box";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-javascript";

import {
  LoadedDataCallback,
  useLoadedData,
  useUserCallback,
} from "app/js/hooks";
import { useUserStore } from "app/js/stores";
import { ActionScript } from "app/js/types";

export default function ActionscriptDetail({ match, history }) {
  const actionscriptId = match.params.id;

  const [user] = useUserStore();

  const actionscriptCallback: LoadedDataCallback<ActionScript> = useUserCallback(
    async (setValue, setCount) => {
      const response = await Api.actionscript(actionscriptId).show();
      setValue(response.data);
    },
    [actionscriptId],
  );
  const {
    value: actionscript,
    loading,
    error,
  } = useLoadedData<ActionScript | null>(null, actionscriptCallback);

  const code = highlight(
    actionscript?.code || "",
    languages.javascript,
    "javascript",
  );

  return (
    <div>
      <Header
        basePath={match.url}
        showEdit={
          user.data.is_staff || user.data.subscriptions.indexOf("premium") >= 0
        }
      >
        {loading || actionscript === null ? (
          <h1>Loading ...</h1>
        ) : (
          <h1>
            Actionscript {actionscript.id} - {actionscript.name}
          </h1>
        )}
      </Header>
      <div style={{ padding: "25px" }}>
        {error && <ErrorMessage error={error} />}
        {loading && <Loading />}
        {!loading && actionscript && (
          <Box>
            <p>
              <strong>Name:</strong> {actionscript.name}
              <br />
              <strong>Created at:</strong> {actionscript.create_time}
              <br />
              <strong>Updated at:</strong> {actionscript.update_time}
              <br />
              <strong>Used by Streams:</strong>{" "}
              {JSON.stringify(actionscript.cameras)}
            </p>
            <p>
              <strong>Code:</strong>
            </p>
            <pre dangerouslySetInnerHTML={{ __html: code }} />
          </Box>
        )}
        {!loading && (
          <button
            onClick={() => {
              if (confirm("Are you sure?")) {
                Api.actionscript(actionscriptId)
                  .destroy()
                  .then((response) => {
                    history.push("/actionscripts");
                  });
              }
            }}
          >
            Delete
          </button>
        )}
      </div>
    </div>
  );
}
