import React, { useEffect, Fragment } from "react";
import Select from "react-select";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import moment, { unitOfTime } from "moment";
import { History } from "history";

import { useSafeState } from "app/js/hooks";
import Api from "app/js/api";
import {
  LabelHistory,
  LabelHistoryFrequency,
  LabelingJob,
  LabellingUser,
} from "app/js/types";
import UserLabellingTable from "app/components/Table/UserLabellingTable";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

import styles from "./LabellingJobDetail.scss";

const Plot = createPlotlyComponent(Plotly);

type LabelHistoryTimeFilter = {
  label: string;
  value: unitOfTime.Base;
  interval: LabelHistoryFrequency;
  xAxisOffsetHours: number;
};

const labelHistoryTimeFilterOptions: LabelHistoryTimeFilter[] = [
  {
    label: "Day",
    value: "day",
    interval: "hour",
    xAxisOffsetHours: 0.8,
  },
  {
    label: "Week",
    value: "week",
    interval: "day",
    xAxisOffsetHours: 8,
  },
  {
    label: "Month",
    value: "month",
    interval: "day",
    xAxisOffsetHours: 12,
  },
  {
    label: "Year",
    value: "year",
    interval: "day",
    xAxisOffsetHours: 24,
  },
];

interface LabellingJobDetailProps {
  job: LabelingJob;
  history: History;
}

export default function LabellingJobDetail({
  job,
  history,
}: LabellingJobDetailProps) {
  const [error, setError] = useSafeState(null);
  const [labelHistoryTimeFilter, setLabelHistoryTimeFilter] = useSafeState(
    labelHistoryTimeFilterOptions[1],
  );
  const [labellingUsers, setLabellingUsers] = useSafeState<LabellingUser[]>([]);
  const [labellingLabels, setLabellingLabels] = useSafeState(null);
  const [historyDates, setHistoryDates] = useSafeState([]);
  const [userHistoryValues, setUserHistoryValues] = useSafeState<
    LabelHistory[]
  >([]);
  const [startTime, setStartTime] = useSafeState(
    moment()
      .subtract(1, labelHistoryTimeFilter["value"])
      .startOf(labelHistoryTimeFilter["interval"])
      .format(),
  );
  const [endTime, setEndTime] = useSafeState(moment().format());
  const [loadingPlot, setLoadingPlot] = useSafeState(false);

  const loadLabellingUsers = React.useCallback(async () => {
    try {
      const response = await Api.labelversion().users({
        dataset_ids: [job.dataset],
        job_ids: [job.id],
        mode: "user_latest",
      });
      setLabellingUsers(response.data.results);
    } catch (error) {
      setError(error);
    }
  }, [job.dataset, job.id, setError, setLabellingUsers]);

  const loadLabellingLabels = React.useCallback(async () => {
    try {
      const response = await Api.labelversion().labels({
        job_ids: [job.id],
        mode: "user_latest",
      });
      setLabellingLabels(response.data.results);
    } catch (error) {
      setError(error);
    }
  }, [job.id, setError, setLabellingLabels]);

  const loadLabelHistory = React.useCallback(async () => {
    setLoadingPlot(true);
    const timeInterval = labelHistoryTimeFilter["interval"];
    const buckets = Math.ceil(
      moment.duration(moment(endTime).diff(moment(startTime))).as(timeInterval),
    );
    const dates = Array.apply(0, new Array(buckets)).map((v, i) => {
      const d = moment(startTime).startOf(timeInterval);
      d.add(i, timeInterval);
      return d.format();
    });
    setHistoryDates(dates);

    try {
      const history = await Api.dataset(job.dataset).labelHistory({
        job_id: job.id,
        frequency: timeInterval,
        start_time: startTime,
        end_time: endTime,
      });
      setUserHistoryValues(history.data);
    } catch (error) {
      setError(error);
      console.error(error);
    }
    setLoadingPlot(false);
  }, [
    endTime,
    job.dataset,
    job.id,
    labelHistoryTimeFilter,
    setError,
    setHistoryDates,
    setLoadingPlot,
    setUserHistoryValues,
    startTime,
  ]);

  useEffect(() => {
    loadLabellingUsers();
    loadLabellingLabels();
    loadLabelHistory();
  }, [loadLabelHistory, loadLabellingLabels, loadLabellingUsers]);

  const onClickUserTime = (event) => {
    const clickedPoint = event["points"][0];
    const dateClicked = clickedPoint["x"];
    const timeInterval = labelHistoryTimeFilter["interval"];
    const queryParams = new URLSearchParams({
      user: clickedPoint["data"]["userId"],
      after: encodeURIComponent(
        moment(dateClicked).startOf(timeInterval).format(),
      ),
      before: encodeURIComponent(
        moment(dateClicked).endOf(timeInterval).format(),
      ),
    });
    history.push(
      `/labelling-jobs/${job.id}/labelled-images/?${queryParams.toString()}`,
    );
  };

  const onLabelHistoryTimeFilterChange = (newFilter) => {
    const timeFilter = newFilter["value"];
    const timeInterval = newFilter["interval"];
    setStartTime(
      moment().subtract(1, timeFilter).startOf(timeInterval).format(),
    );
    setEndTime(moment().format());
    setLabelHistoryTimeFilter(newFilter);
  };

  const onResetLabelledImagesClick = async () => {
    try {
      if (confirm("Are you sure you want to reset this labelling job?")) {
        await Api.job(job.id).resetLabelJob();
        alert("Labelling Job Reset.");
      }
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const verboseMode = labelHistoryTimeFilter.interval;
  const verboseStartTime = moment(startTime).format(
    labelHistoryTimeFilter.interval === "hour"
      ? "dddd, MMMM Do YYYY, HH:mm"
      : "dddd, MMMM Do YYYY",
  );
  const verboseTimezone = moment(endTime).format("Z");
  const startTimeNotice = `You are viewing values created per ${verboseMode} in your timezone (${verboseTimezone}). The first ${verboseMode} is ${verboseStartTime}`;
  return (
    <Fragment>
      {error && <ErrorMessage error={error} />}
      <div style={{ height: "100%", overflowY: "hidden", display: "grid" }}>
        <div style={{ padding: "25px", overflowY: "auto" }}>
          <p>
            {/*
            <strong>Created:</strong> {job.created_at}
            <br />
            */}
            <strong>User Count:</strong>{" "}
            {labellingUsers && labellingUsers.length}
            <br />
            <strong>Entity Count:</strong>{" "}
            {labellingLabels &&
              Object.keys(labellingLabels)
                .map((key) => labellingLabels[key])
                .reduce((a, b) => a + b, 0)}
            <br />
            <strong>Labelled Images:</strong> {job.labelled_images}
            <br />
            <strong>Remaining Images:</strong> {job.remaining_images}
            <br />
            <strong>Skipped Images:</strong> {job.skipped_images}
            <br />
          </p>
          <button
            onClick={onResetLabelledImagesClick}
            title={
              "Resets skipped images and goes through all labelled images again"
            }
          >
            Reset Labelled Images
          </button>
          <p>
            Resetting Labelled Images will keep all of your labelled data but
            have the "Start" button require all data to be re-labelled. The user
            will see the previous labels for every image.
          </p>
          <UserLabellingTable users={labellingUsers} />
          <h2>Label Version History</h2>
          <Select
            options={labelHistoryTimeFilterOptions}
            value={labelHistoryTimeFilter}
            onChange={onLabelHistoryTimeFilterChange}
          />
          {document.env.featureFlags.LABELING_CHART_TESTING && (
            <div className={styles.endTime}>
              <label className={styles.endTimeLabel}>
                TESTING ONLY - End Time:
              </label>
              <input
                type="datetime-local"
                value={moment(endTime).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) => {
                  const timeFilter = labelHistoryTimeFilter.value;
                  const timeInterval = labelHistoryTimeFilter.interval;
                  const newEndTime = moment(e.target.value, "YYYY-MM-DDTHH:mm");
                  setStartTime(
                    newEndTime
                      .clone()
                      .subtract(1, timeFilter)
                      .startOf(timeInterval)
                      .format(),
                  );
                  setEndTime(newEndTime.format());
                }}
              />
            </div>
          )}

          {loadingPlot && <Loading />}
          {!loadingPlot && userHistoryValues.length === 0 && (
            <p>
              No Labelling History For Last {labelHistoryTimeFilter["value"]}
            </p>
          )}
          {!loadingPlot && userHistoryValues.length > 0 && (
            <Plot
              style={{ marginTop: "15px" }}
              data={userHistoryValues.map((userValues) => ({
                x: historyDates,
                y: userValues.history,
                type: "bar",
                name: userValues.user.name,
                userId: userValues.user.id,
              }))}
              layout={{
                title: "Labelling Activity",
                barmode: "stack",
                yaxis: {
                  title: "Label Versions Created",
                  fixedrange: true,
                  rangemode: "nonnegative",
                  tickformat: ",d",
                },
                xaxis: {
                  title:
                    labelHistoryTimeFilter.interval === "hour"
                      ? "Hour"
                      : "Date",
                  fixedrange: true,
                  type: "date",
                  range: [
                    moment(startTime)
                      .subtract(
                        labelHistoryTimeFilter["xAxisOffsetHours"],
                        "hours",
                      )
                      .format(),
                    moment(endTime)
                      .startOf(labelHistoryTimeFilter["interval"])
                      .add(labelHistoryTimeFilter["xAxisOffsetHours"], "hours")
                      .format(),
                  ],
                },
                margin: {
                  r: 0,
                },
                legend: {
                  x: -0.1,
                  y: 1.15,
                  bgcolor: "#E2E2E2",
                },
                showlegend: true,
                hovermode: "closest",
              }}
              config={{ displayModeBar: false, scrollZoom: false }}
              onClick={onClickUserTime}
            />
          )}
          {!loadingPlot && userHistoryValues.length > 0 && (
            <p>
              The chart displays the number of label versions created by each
              user during a specified time range. The x-axis marks the starting
              point of each time period. For example, a value displayed for
              15:00 in "Day" mode represents the number of label versions
              between 15:00 and 16:00. The values shown are in your current
              timezone and differ for users in other timezones.
              <br />
              <br />
              {startTimeNotice}
            </p>
          )}
        </div>
      </div>
    </Fragment>
  );
}
