import React, { Fragment, useEffect } from "react";

import Select from "react-select";
import Api from "app/js/api";
import Loading from "app/components/Loading/Loading";

import { useSafeState } from "app/js/hooks";
import { Option, VisionModel } from "app/js/types";

interface SelectDetectorMultiProps {
  value: number[];
  setDetectorIds: (ids: number[]) => void;
  isDisabled?: boolean;
}

const SelectDetectorMulti: React.FC<SelectDetectorMultiProps> = ({
  value,
  setDetectorIds,
  isDisabled = false,
}) => {
  const [detectorOptions, setDetectorOptions] = useSafeState<VisionModel[]>([]);
  const [loading, setLoading] = useSafeState(false);
  useEffect(() => {
    const loadDetectors = async () => {
      setLoading(true);
      const response = await Api.detector().all({
        include_public: true,
        limit: 500,
      });
      setDetectorOptions(response.data.results);
      setLoading(false);
    };

    loadDetectors();
  }, [setDetectorOptions, setLoading]);

  const options: Option<number>[] = detectorOptions.map((entry) => ({
    label: `${entry.name} (id:${entry.id})`,
    value: entry.id,
  }));
  const displayValue = value.map((id) => {
    const entry = detectorOptions.find((option) => option.id === id);
    if (entry == null) {
      return null;
    }
    return {
      label: `${entry.name} (id:${entry.id})`,
      value: entry.id,
    };
  });

  return (
    <Fragment>
      {loading && <Loading />}
      {!loading && detectorOptions.length > 0 && (
        <Select
          isMulti
          value={displayValue}
          onChange={(e: Option<number>[]) =>
            setDetectorIds(e.map((selectedOption) => selectedOption.value))
          }
          options={options}
          isDisabled={isDisabled}
        />
      )}
    </Fragment>
  );
};
export default SelectDetectorMulti;
