import React, { useEffect, useRef } from "react";
import { fabric } from "fabric";
import styles from "../ExtractImages.scss";

import { useSafeState, useCanvas } from "app/js/hooks";

import { Action } from "app/pages/LabellingJobs/types";

function InterestingArea({
  video,
  initialInterestingArea,
  setInterestingArea,
}) {
  /* * CANVAS */
  const canvasContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const [action, _setAction] = useSafeState<Action>({
    edit: false,
    type: null,
  });
  const [canvas, _setCanvas] = useCanvas({
    image: video?.thumbnail,
    canvasRef,
    canvasContainerRef,
    action,
    backgroundCallback: ({ canvasWidth, canvasHeight }) => {
      let rect = null;
      if (
        initialInterestingArea &&
        Object.keys(initialInterestingArea).length > 0
      ) {
        rect = new fabric.Rect({
          left: initialInterestingArea.x,
          top: initialInterestingArea.y,
          width: initialInterestingArea.width,
          height: initialInterestingArea.height,
          fill: "red",
          opacity: 0.6,
          hasRotatingPoint: false,
        });
        rect.set(initialInterestingArea);
        setInterestingArea(initialInterestingArea);
      } else {
        rect = new fabric.Rect({
          left: video.width / 4,
          top: video.height / 4,
          width: canvasWidth / 2,
          height: canvasHeight,
          fill: "red",
          opacity: 0.6,
          hasRotatingPoint: false,
        });
        const data = {
          x: Math.round(rect.left),
          y: Math.round(rect.top),
          width: Math.round(rect.width),
          height: Math.round(rect.height),
        };
        rect.set(data);
        setInterestingArea(data);
      }
      canvas.add(rect);
      canvas.renderAll();
    },
  });

  // event listener
  const objectChanged = React.useCallback(
    (event) => {
      const object = event.target;
      const maxWidth = video.thumbnail.width;
      const maxHeight = video.thumbnail.height;

      const left = object.left;
      let width = object.width * object.scaleX;
      const top = object.top;
      let height = object.height * object.scaleY;

      if (width > maxWidth) {
        object.set({ width: maxWidth, scaleX: 1 });
        width = maxWidth;
      }
      if (height > maxHeight) {
        object.set({ height: maxHeight, scaleY: 1 });
        height = maxHeight;
      }

      if (left > maxWidth - width) {
        object.set({ left: maxWidth - width });
      }
      if (top > maxHeight - height) {
        object.set({ top: maxHeight - height });
      }

      if (left < 0) {
        object.set({ left: 0 });
      }
      if (top < 0) {
        object.set({ top: 0 });
      }

      const data = {
        x: Math.round(object.left),
        y: Math.round(object.top),
        width: Math.round(width),
        height: Math.round(height),
      };
      setInterestingArea(data);
      object.setCoords();
      object.saveState();
    },
    [setInterestingArea, video?.thumbnail.height, video?.thumbnail.width],
  );
  useEffect(() => {
    if (!canvas) return;
    canvas.on("object:modified", objectChanged);

    return () => {
      canvas.off("object:modified", objectChanged);
    };
  }, [canvas, objectChanged]);

  if (video == null) {
    return null;
  }

  return (
    <div
      ref={canvasContainerRef}
      className={video ? styles.canvasContainer : styles.hiddenCanvas}
    >
      <canvas ref={canvasRef} width={0} height={0} />
    </div>
  );
}

export default React.memo(InterestingArea);
