import React from "react";

const DownloadIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="20 10 60 65">
      <defs>
        <style>
          {
            "\
            .line {\
              stroke: #dddddd;\
              fill: none;\
              stroke-linecap: round;\
              stroke-linejoin: round;\
              stroke-width: 5px;\
            }\
          "
          }
        </style>
      </defs>
      <rect style={{ fill: "#231f20", opacity: 0 }} width="100" height="100" />
      <polyline className="line" points="25 71 75 71" />
      <polyline className="line" points="50.52 21 49.98 58.89 62 46.96" />
      <line className="line" x1="49.98" y1="58.89" x2="38" y2="47" />
    </svg>
  );
};
export default DownloadIcon;
