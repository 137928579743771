import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";

import Submenu from "app/components/Menus/Submenu/Submenu";
import DatasetDetailImages from "app/pages/DataSets/DatasetDetail/DatasetDetailImages/DatasetDetailImages";
import DatasetDetailImagesUpload from "app/pages/DataSets/DatasetDetail/DatasetDetailImages/DatasetDetailImagesUpload";

const DatasetDetailImagesRoutes = ({ match, dataset, setDataset }) => {
  const datasetId = match.params.id;
  return (
    <div
      style={{
        display: "grid",
        gridTemplateAreas: '"submenu" "content"',
        gridTemplateRows: "min-content auto",
        height: "100%",
      }}
    >
      <Submenu>
        <li>
          <h2>Images</h2>
        </li>
        <li style={{ marginLeft: "auto" }}>
          <NavLink
            to={`/data-sets/${datasetId}/images`}
            activeClassName="active"
            exact
          >
            Image List
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/data-sets/${datasetId}/images/upload`}
            activeClassName="active"
            exact
          >
            Upload Image
          </NavLink>
        </li>
      </Submenu>
      <Switch>
        <Route
          path={`${match.path}/images/upload`}
          exact
          render={() => (
            <DatasetDetailImagesUpload
              match={match}
              dataset={dataset}
              setDataset={setDataset}
            />
          )}
        />
        <Route
          path={`${match.path}/images`}
          render={() => (
            <DatasetDetailImages
              match={match}
              dataset={dataset}
              setDataset={setDataset}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default DatasetDetailImagesRoutes;
