import React from "react";
import classNames from "classnames";
import styles from "./ErrorMessage.scss";
import { Error } from "./types";

interface ErrorMessageProps {
  error: Error;
  className?: string;
  onDismiss?: () => void;
  fieldMap?: Record<string, string>;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  error,
  className,
  onDismiss,
  fieldMap = null,
}) => {
  if (!error || !error.message) return null;

  const errorMessage = error?.response?.data?.message || error.message;
  const renderError = (key, value): string => {
    const name = fieldMap?.[key] || key;
    let fieldError: string;
    if (Array.isArray(value)) {
      fieldError = value.join(", ");
    } else if (typeof value === "object") {
      fieldError = JSON.stringify(value);
    } else {
      fieldError = value;
    }
    return `${name}: ${fieldError}`;
  };

  return (
    <div className={classNames(styles.container, className)}>
      {onDismiss && (
        <button onClick={onDismiss} className={styles.dismiss}>
          x
        </button>
      )}
      {error?.response?.data ? (
        <React.Fragment>
          {Object.entries(error.response.data).map(([key, value]) => {
            return <p key={key}>{renderError(key, value)}</p>;
          })}
        </React.Fragment>
      ) : (
        <p>{errorMessage}</p>
      )}
    </div>
  );
};

export default ErrorMessage;
