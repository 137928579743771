import { Motion } from "app/js/types";
import { SceneData, SceneTaskResult } from "./types";

export const prepSceneData = (
  result: SceneTaskResult,
  interestingData,
  motion: Motion,
): SceneData => {
  const scenes = [...result.interesting_scenes, ...result.uninteresting_scenes];
  function compare(a, b) {
    if (a.cue < b.cue) return -1;
    if (a.cue > b.cue) return 1;
    return 0;
  }
  const sortedScenes = scenes.sort(compare);

  const points = [];
  const rect = [];
  sortedScenes.forEach((entry) => {
    points.push({
      x: entry.cue,
      y: entry[motion],
      max: entry.max_activity,
      min: entry.std_activity,
    });
    points.push({
      x: entry.cue + entry.length,
      y: entry[motion],
      max: entry.max_activity,
      min: entry.std_activity,
    });

    if (entry.avg_activity > result.cut_off_value_transformed) {
      rect.push({
        type: "rect",
        xref: "x",
        yref: "y",
        x0: entry.cue,
        y0:
          entry.std_activity > entry.avg_activity
            ? 0
            : entry.avg_activity - entry.std_activity,
        x1: entry.cue + entry.length,
        y1: entry.avg_activity + entry.std_activity,
        fillcolor: "#ffe4b2",
        opacity: 0.2,
        line: {
          width: 0,
        },
      });
    }
  });

  return {
    scenes: result,
    data: [
      {
        type: "scatter",
        mode: "lines",
        y: interestingData,
        line: {
          color: "#ccc",
        },
        showlegend: false,
      },
      {
        type: "scatter",
        mode: "lines",
        line: {
          dash: "dot",
          color: "#ffc966",
        },
        x: points.map((a) => a.x),
        y: points.map((a) => a.max),
        showlegend: false,
      },
      {
        type: "scatter",
        mode: "lines",
        x: points.map((a) => a.x),
        y: points.map((a) => a.y),
        line: {
          color: "#FF4500",
        },
        name: `Scenes (${result.interesting_scenes.length} interesting / ${result.uninteresting_scenes.length} uninteresting)`,
      },
      {
        type: "scatter",
        mode: "lines",
        y: [result.cut_off_value_transformed, result.cut_off_value_transformed],
        x: [0, points[points.length - 1].x],
        line: {
          color: "#005aff",
        },
        showlegend: false,
      },
      {
        x: [30],
        y: [result.cut_off_value_transformed + 10],
        text: ["Cutoff Point"],
        mode: "text",
        showlegend: false,
      },
    ],
    layout: {
      shapes: rect,
    },
  };
};
