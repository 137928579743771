import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useSafeState } from "app/js/hooks";

import Loading from "app/components/Loading/Loading";
import ResourceDoesNotExist from "app/components/Error/ResourceDoesNotExist";

import Api from "app/js/api";

export default function LabellingRedirect({ match, history }) {
  const jobId = match.params.labellingJobId;
  const [frameId, setFrameId] = useSafeState(undefined);
  const [error, setError] = useSafeState(null);

  useEffect(() => {
    const loadNextFrame = async () => {
      setError(null);
      try {
        const response = await Api.job(jobId).framesToLabel().show();
        setFrameId(response.data.frame ? response.data.frame.id : null);
        setError(null);
      } catch (error) {
        setError(error);
      }
    };

    loadNextFrame();
  }, [jobId, setError, setFrameId]);

  if (error != null) {
    return <ResourceDoesNotExist resourceRoot={"/labelling-jobs"} />;
  }

  if (frameId === undefined) {
    return <Loading />;
  }

  if (frameId === null) {
    alert("No frames left to label");
    return <Redirect to={`/labelling-jobs`} />;
  }

  return <Redirect to={`/labelling-jobs/${jobId}/frame/${frameId}`} />;
}
