import React from "react";
import classNames from "classnames";
import { useSafeState } from "app/js/hooks";

import styles from "app/components/LabeledSwitch/LabeledSwitch.scss";

interface LabeledSwitchProps {
  defaultState?: boolean;
  disabled?: boolean;
  onText?: string;
  offText?: string;
  onChange?: (change: { checked: boolean; value: string }) => void;
  styles?: {
    labeledSwitch?: string;
  };
}

const LabeledSwitch: React.FC<LabeledSwitchProps> = ({
  defaultState = false,
  disabled = false,
  onChange,
  onText = "On",
  offText = "Off",
  styles: stylesProp = null,
}) => {
  const [checked, setChecked] = useSafeState(defaultState);
  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (disabled) {
        return;
      }
      const _checked = !checked;
      if (onChange) {
        onChange({ checked: _checked, value: _checked ? onText : offText });
      }
      setChecked(_checked);
    },
    [disabled, checked, onChange, setChecked, onText, offText],
  );

  return (
    <div
      onClick={onClick}
      className={classNames(styles.labeledSwitch, stylesProp?.labeledSwitch, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
      })}
    >
      <span className={classNames(styles.lever, { [styles.checked]: checked })}>
        <span className={styles.line} />
        <span className={styles.line} />
        <span className={styles.line} />
      </span>
      <span
        className={classNames(styles.label, {
          [styles.checked]: checked,
        })}
      >
        {checked ? onText : offText}
      </span>
    </div>
  );
};

export default LabeledSwitch;
