import React, { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";

import moment from "moment";

import Api from "app/js/api";
import { useLoadedData, useParams, useSafeState, useTitle } from "app/js/hooks";
import { useUserStore } from "app/js/stores";
import {
  DatasetView,
  DatasetViewFrame,
  LabelVersionFilters,
  NumericId,
  Option,
} from "app/js/types";
import Card from "app/components/Card/Card";
import CardList from "app/components/CardList/CardList";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import FormComponent from "app/components/FormComponent/FormComponent";
import Loading from "app/components/Loading/Loading";
import GalleryModal from "app/components/Modal/GalleryModal";
import PageSizeChanger, {
  readPageLengthCookie,
} from "app/components/PageSizeChanger/PageSizeChanger";
import Paginator from "app/components/Paginator/Paginator";
import SelectLabellingJob from "app/components/Selects/SelectLabellingJob";
import { CardDetails, CardTrashButton } from "app/components/Card";
import { JobSelectModal } from "app/components/Modal";
import { getRedirectParameters, serializeUrlParameters } from "app/js/util";
import { CardImage } from "app/components/Card";

const parseDate = (value) => (!!value ? moment(value).format() : "");

interface DatasetViewImagesProps {
  datasetView: DatasetView;
  setDatasetView: (datasetView: DatasetView) => void;
}

export default function DatasetViewImages({
  datasetView,
  setDatasetView,
}: DatasetViewImagesProps): React.ReactElement {
  useTitle(`MoonVision - Dataset View ${datasetView.name} - Images`);

  const [user] = useUserStore();
  const history = useHistory();
  const location = useLocation();
  const MODE = "user_latest";
  const params = useParams();
  // Aliases for individual params to use as hook dependencies
  const paramsPage = parseInt(params.get("page") || "1");
  const paramsLabellingJob = params.get("labellingJob");
  const paramsFrameBefore = params.get("frameBefore");
  const paramsFrameAfter = params.get("frameAfter");
  const paramsLabellingBefore = params.get("labellingBefore");
  const paramsLabellingAfter = params.get("labellingAfter");
  // End of aliases
  const datasetViewId = datasetView.id || "overview";
  // paginator state data
  const PAGE_LENGTH_COOKIE = "moonvision-datasetImagesPageLength";
  const [pageLength, setPageLength] = useSafeState<number>(
    readPageLengthCookie(PAGE_LENGTH_COOKIE),
  );
  const offset = (paramsPage - 1) * pageLength;

  // filters
  // select detection labels (OR)
  const [labels, setLabels] = useSafeState<Option<string>[]>([]);
  const loadLabelOptions = React.useCallback(
    async (setValue) => {
      const filters: LabelVersionFilters = {
        mode: MODE,
      };
      if (paramsLabellingJob) {
        filters["job_ids"] = [parseInt(paramsLabellingJob)];
      }
      const response = await Api.datasetView(datasetViewId).labels(filters);
      setValue(
        Object.keys(response.data.results)
          .sort()
          .map((l) => ({ label: l, value: l })),
      );
    },
    [datasetViewId, paramsLabellingJob],
  );
  const {
    value: labelOptions,
    loading: loadingLabelOptions,
    error: labelOptionsError,
  } = useLoadedData<Option<string>[]>([], loadLabelOptions);

  // frames that are displayed
  const simplifiedLabels = JSON.stringify([...labels].sort());
  const loadFrames = React.useCallback(
    async (setValue, setCount) => {
      const getAllFramesResponse = await Api.datasetView(datasetViewId).frames({
        job_ids: paramsLabellingJob ? [parseInt(paramsLabellingJob)] : null,
        created_before: paramsFrameBefore,
        created_after: paramsFrameAfter,
        label_version_created_before: paramsLabellingBefore,
        label_version_created_after: paramsLabellingAfter,
        mode: MODE,
        or_labels: labels.length > 0 ? labels.map((l) => l.value) : null,
        limit: pageLength,
        offset: offset,
        with_entities: true,
      });
      setValue(getAllFramesResponse.data.results);
      setCount(getAllFramesResponse.data.count);
    },
    // It requires labels instead of simplifiedLabels, but that is a list
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      datasetViewId,
      datasetView.frames_count,
      offset,
      simplifiedLabels, // instead of just `labels` to avoid re-renders
      pageLength,
      paramsFrameAfter,
      paramsFrameBefore,
      paramsLabellingAfter,
      paramsLabellingBefore,
      paramsLabellingJob,
      paramsPage,
      user?.data.user_id,
    ],
  );
  const { value: frames, count, loading, error: frameError } = useLoadedData<
    DatasetViewFrame[]
  >([], loadFrames);

  // img that is shown in its full size when clicking on the images
  const [bigFrameId, setBigFrameId] = useSafeState<number | null>(null);
  const [jobSelectEnabled, setJobSelectEnabled] = useSafeState<boolean>(false);

  const deleteFrameCallback = React.useCallback(
    async (setValue, setCount, frameId) => {
      const deleteConfirmation = confirm(
        "Are you sure you want to delete this frame? All entities from the frame will also be deleted.",
      );
      if (!deleteConfirmation) {
        return;
      }

      await Api.datasetView(datasetViewId).deleteFrame(frameId);
      setDatasetView({
        ...datasetView,
        frames_count: datasetView.frames_count - 1,
      });
    },
    [datasetView, datasetViewId, setDatasetView],
  );
  const { error: deleteError, requestCallback: deleteFrame } = useLoadedData(
    null,
    deleteFrameCallback,
    true,
  );

  const resetOffset = () => {
    // Reset to the first page on any filter change to avoid requesting pages that exceed maximum
    params.set("page", "1");
  };

  const updateParamsData = (key, value) => {
    if (value !== "") {
      params.set(key, value);
    } else {
      params.delete(key);
    }
    resetOffset();
    history.push({
      ...location,
      search: params.toString(),
    });
  };
  const previewFrame = frames.find((frame) => frame.id === bigFrameId);
  const datasetForJobSelect = previewFrame?.dataset;

  const error = frameError || deleteError || labelOptionsError;

  return (
    <div style={{ padding: "25px", overflowY: "auto" }}>
      <FormComponent>
        <label>Labelling Job</label>
        <SelectLabellingJob
          value={parseInt(paramsLabellingJob)}
          setLabellingJob={(labellingJob) =>
            updateParamsData(
              "labellingJob",
              labellingJob ? labellingJob.id : "",
            )
          }
          datasetId={null}
          preloadedJobs={datasetView.labeling_jobs.map((job) => ({
            ...job,
            team: job.team.id,
            dataset: job.dataset.id,
          }))}
        />
        <label>Frame created before:</label>
        <input
          type="datetime-local"
          value={
            paramsFrameBefore
              ? moment(paramsFrameBefore).format(
                  moment.HTML5_FMT.DATETIME_LOCAL,
                )
              : ""
          }
          onChange={(e) => {
            updateParamsData("frameBefore", parseDate(e.target.value));
          }}
        />
        <label>Frame created after:</label>
        <input
          type="datetime-local"
          value={
            paramsFrameAfter
              ? moment(paramsFrameAfter).format(moment.HTML5_FMT.DATETIME_LOCAL)
              : ""
          }
          onChange={(e) => {
            updateParamsData("frameAfter", parseDate(e.target.value));
          }}
        />
        <label>Labelling created before:</label>
        <input
          type="datetime-local"
          value={
            paramsLabellingBefore
              ? moment(paramsLabellingBefore).format(
                  moment.HTML5_FMT.DATETIME_LOCAL,
                )
              : ""
          }
          onChange={(e) => {
            updateParamsData("labellingBefore", parseDate(e.target.value));
          }}
        />
        <label>Labelling created after:</label>
        <input
          type="datetime-local"
          value={
            paramsLabellingAfter
              ? moment(paramsLabellingAfter).format(
                  moment.HTML5_FMT.DATETIME_LOCAL,
                )
              : ""
          }
          onChange={(e) => {
            updateParamsData("labellingAfter", parseDate(e.target.value));
          }}
        />
        {loadingLabelOptions ? (
          <Loading />
        ) : (
          <Fragment>
            <label>Detection Labels (OR):</label>
            <Select
              value={labels}
              onChange={(newLabels: Option<string>[]) => {
                setLabels(newLabels);
                resetOffset();
              }}
              options={labelOptions}
              isMulti
            />
          </Fragment>
        )}
      </FormComponent>
      {loading && <Loading />}
      {error && <ErrorMessage error={error} />}
      {!loading && frames.length > 0 ? (
        <Fragment>
          {count > pageLength && (
            <Paginator
              count={count}
              offset={offset}
              pageLength={pageLength}
              disabled={loading}
            />
          )}
          <CardList>
            {frames.map((frame) => (
              <Card
                key={`${frame.id} - ${frame.job_name}`}
                media={<CardImage frame={frame} />}
                onClick={() => {
                  setBigFrameId(frame.id);
                }}
                hoverButtons={
                  <CardTrashButton
                    onClick={() => {
                      deleteFrame(frame.id);
                    }}
                  />
                }
              >
                <CardDetails>
                  Uploaded: {moment(frame.create_time).format("LLL")}
                  <br />
                  Frame ID: {frame.id}
                  <br />
                  Camera: {frame.stream_name || "-"}
                  <br />
                  Job: {frame.job_name}
                </CardDetails>
              </Card>
            ))}
          </CardList>
          <PageSizeChanger
            pageLength={pageLength}
            setPageLength={setPageLength}
            count={count}
            cookieName={PAGE_LENGTH_COOKIE}
          />
        </Fragment>
      ) : (
        !loading && <Fragment>No Images in Dataset</Fragment>
      )}
      <GalleryModal
        frames={frames}
        activeFrame={bigFrameId}
        setActiveFrame={setBigFrameId}
        loading={loading}
        pageLength={pageLength}
        count={count}
        drawEntities={true}
        handleClick={() => setJobSelectEnabled(true)}
      />
      {bigFrameId && jobSelectEnabled && (
        <JobSelectModal
          onRemove={() => setJobSelectEnabled(false)}
          datasetId={datasetForJobSelect}
          preloadedJobs={datasetView.labeling_jobs
            .filter((job) => job.dataset.id === datasetForJobSelect)
            .map((frame) => ({
              ...frame,
              dataset: frame.dataset.id,
              team: frame.team.id,
            }))}
          makeEntityUrl={(jobId: NumericId, teamId: NumericId) => {
            const paramNames = [
              "page",
              "team",
              "labellingJob",
              "frameBefore",
              "frameAfter",
              "labellingBefore",
              "labellingAfter",
            ];
            const paramsDict: Record<string, string> = {
              // label_version: `${entity.label_version.id}`,
              redirect: "/dataset-views/images",
              redirect_parameters: getRedirectParameters(params, paramNames),
            };
            if (teamId !== null) {
              paramsDict["team"] = `${teamId}`;
            }
            const serializedParams = serializeUrlParameters(paramsDict);
            return `/labelling-jobs/${jobId}/frame/${bigFrameId}?${serializedParams}`;
          }}
          preferredJobId={previewFrame.job_id}
        />
      )}
    </div>
  );
}
