import React, { useEffect, Fragment } from "react";
import { fabric } from "fabric";
import { generateColorProperties } from "app/pages/LabellingJobs/Labelling/helper/colors";
import { exportObject } from "app/pages/LabellingJobs/Labelling/helper/helper";

import { useSafeState } from "app/js/hooks";

import { MousePosition } from "app/pages/LabellingJobs/types";

export default function CreateCircle({ canvas, createdAction }) {
  const [started, setStarted] = useSafeState<boolean>(false);
  const [mouse, setMouse] = useSafeState<MousePosition>({
    x: undefined,
    y: undefined,
  });

  useEffect(() => {
    canvas.on("mouse:down", mouseDown);
    canvas.on("mouse:move", mouseMove);
    canvas.on("mouse:up", mouseUp);

    return () => {
      // clean up
      canvas.off("mouse:down", mouseDown);
      canvas.off("mouse:move", mouseMove);
      canvas.off("mouse:up", mouseUp);
    };
  });

  const mouseDown = (e) => {
    // lets go
    setStarted(true);

    // is another Object selected?
    if (canvas.getActiveObject()) {
      canvas.discardActiveObject().renderAll();
    }

    // make sure you can't select any other object
    canvas.forEachObject((object) => {
      object.set({
        selectable: false,
        lockMovementX: true,
        lockMovementY: true,
        lockRotation: true,
      });
    });

    // get the mouse from this point of origin
    const currentMouse = canvas.getPointer(e);
    // set the origin point for circle
    setMouse({ x: currentMouse.x, y: currentMouse.y });

    // create Circle Object
    const circle = new fabric.Circle({
      data: {
        id: `new_${(Math.random() + 1).toString(36).substring(7)}`,
      },
      radius: 0,
      left: currentMouse.x,
      top: currentMouse.y,
      hasControls: false,
      hasBorders: false,
      ...generateColorProperties("", 0.4, canvas.getZoom()),
    });
    //add width and height of the drawing shape on the cursor
    const cursor = new fabric.Text("r: 0", {
      data: {
        id: "cursorText",
      },
      fontFamily: "sans-serif",
      fill: "#ffffff",
      fontSize: 36 / canvas.getZoom(),
      selectable: false,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      textBackgroundColor: "#5bc0eb",
    });
    //add cursor
    canvas.add(cursor);
    // set cursor starting position
    cursor.set({
      left: currentMouse.x,
      top: currentMouse.y,
    });
    // add it to canvas and define it as selected object
    canvas.add(circle);
    canvas.setActiveObject(circle);
    canvas.renderAll();
  };

  const mouseMove = (e) => {
    // only trigger when you clicked first
    if (!started) {
      return false;
    }

    // get the mouse and the object
    const currentMouse = canvas.getPointer(e);
    const activeObject = canvas.getActiveObject();
    const cursor = canvas._objects.find((obj) => obj.data?.id === "cursorText");
    // calculate the radius
    const radius = Math.sqrt(
      Math.pow(Math.abs(mouse.x - currentMouse.x), 2) +
        Math.pow(Math.abs(mouse.y - currentMouse.y), 2),
    );

    // change the size of the object
    activeObject.set({
      left: mouse.x - radius,
      top: mouse.y - radius,
      radius: radius,
    });
    // set current cursor position and add radius of the circle
    cursor.set({
      left: currentMouse.x,
      top: currentMouse.y,
      text: `r: ${Math.round(activeObject.radius)}`,
    });

    // render the canvas
    canvas.renderAll();
  };
  const mouseUp = (e) => {
    // clean up
    setStarted(false);
    // remove cursor
    canvas.remove(canvas._objects.find((obj) => obj.data?.id === "cursorText"));
    // make sure a minimum size is reached
    if (canvas.getActiveObject().width < 5) {
      canvas.remove(canvas.getActiveObject());
      return;
    }

    // make sure we have border and controls on the circle
    canvas
      .getActiveObject()
      .set({
        hasControls: true,
        hasBorders: true,
      })
      .setCoords(); // set the selectable bounding box

    // return the created object
    createdAction(exportObject(canvas.getActiveObject()));
  };

  return <Fragment />;
}
