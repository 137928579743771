import React, { Fragment } from "react";

export const getContent = (path: string) => {
  switch (path) {
    case "/":
      return (
        <Fragment>
          <p>
            The Moonvision Toolbox allows you to train custom computer vision
            models based on image and video data. These vision models locate
            objects, defects, or other regions of interest by sending images to
            the API. Highly scalable and data-efficient algorithms bring you
            good results fast. Several tools offer support in keeping your
            models up to date with continuous learning.
          </p>
          <p>
            Our platform employs transfer learning, few-shot classification,
            rotation invariant inference, and more. Despite advanced techniques,
            working with the Moonvision Toolbox requires little machine learning
            expertise. The Moonvision Platform is constantly updated by our
            research engineers to bring you new features, maximize efficiency,
            and provide sound deployment options.
          </p>
          <p>For more information, see the Moonvision Toolbox FAQs.</p>
        </Fragment>
      );
    case "/streams":
      return (
        <p>
          Cameras are the external endpoints for your application. You can use
          it to fill your Dataset with Images or detect Objects in realtime
          (premium only).
        </p>
      );
    case "/actionscripts":
      return (
        <Fragment>
          <h2>Actionscripts control workflows</h2>
          <p>
            Processing and labelling dozens of images is a time consuming task
            that cuts into more urgent work. Having a reliable tool to do that
            work for you is key. Action Scripts are just that: powerful
            pre-and-post-processing tools to smooth your production.
          </p>
          <p>
            Written in ES6 JavaScript, Action Scripts allow you to have full
            control over what happens when a camera receives an image that needs
            to be labelled. Action Scripts let you tell the system which
            possible labels can be applied to an image and process the detection
            output from a detector.
          </p>
          <p>
            For you and your production—whether it be manufacturing, gastronomy,
            sales, or any high-traffic work—this means less time spent
            hand-labelling images and more time concentrating on the big
            picture. The applications are limitless.
          </p>
        </Fragment>
      );
    case "/data-sets":
      return (
        <Fragment>
          <p>
            An essential component of machine learning are Datasets. As part of
            our Toolbox, Datasets are there to boost your knowledge about your
            operation and give solutions to your data problems.
          </p>
          <p>
            Simply put, a dataset is a collection of data related to your
            production. That means a superior workflow and stronger
            organizational capabilities. Datasets allow you to organize your
            videos, images, and entities. You can use labelling jobs to add
            labelled entities to a dataset. There are typically two types of
            datasets: training sets and testing sets.
          </p>
          <p>
            A training set is the most important type of set because it is from
            this training that all future outcomes are based. During training,
            the network is taught how to collect data. It then tweaks any
            parameters necessary to provide nearly error-free results.
          </p>
          <p>
            Testing sets are used after the training has been completed. The
            purpose of testing sets are to make sure that the results are
            accurate. Think of them as a final quality filter that is applied
            before production begins. Once training and testing are complete you
            are good to go.
          </p>
          <p>
            Once you have the entities how you like them simply download your
            dataset to train your own detectors or make use of a premium
            subscription to have your detector trained in the cloud.
          </p>
          <p>
            No matter the option you choose, know that your data storage will be
            more efficient. It will always be ready for use in a professional
            production environment.
          </p>
        </Fragment>
      );
    case "/labelling-jobs":
      return (
        <Fragment>
          <p>
            Deep Learning Vision Models usually need thousands of images to
            provide average results. In the Moonvision Toolbox we use Labelling
            Jobs for the interaction between person and machine, cutting time
            and reducing costs. We utilize the dataset images for you to mark
            interesting objects and surfaces with our optimized image annotation
            tool.
          </p>
          <p>
            The recommendation system helps with the very first image, and you
            only need to label a small number of images in order to get great
            results. You can use the human-labelled data seamlessly to train a
            custom model, making the Moonvision Toolbox a great aid in
            generating great models.
          </p>
          <p>
            A dataset typically has numerous labelling jobs that add different
            entities to the collection. Adding a detector to a dataset allows
            real time recommendations to be presented as you label. It is this
            communion of person and computer intelligence that holds the
            potential to thrust your production into the future, staying ahead
            of the curve and maximizing your productivity in an efficient
            manner.
          </p>
          <p>
            Because privacy is vital in the modern world, know that you will get
            training data with the same quality and throughput while your data
            remains private. No snooping, no nonsense. We make sure that your
            data stays secure and belongs only to you. You retain total control
            over who may add entities, see the progress of labelling, and
            perform quality control.
          </p>
        </Fragment>
      );
    case "/labelling-jobs/create":
      return (
        <Fragment>
          <p>
            A labelling job defines who can create training data on certain
            images from a dataset. The recommendation system helps you to get a
            clean and accurate structure of your marked objects. Here are a few
            tips to get you started:
          </p>
          <ul>
            <li>
              Use the shape that best captures the boundaries of your regions or
              objects with the least effort.
            </li>
            <li>Any defect should cover at least 8 pixels.</li>
            <li>
              Think of a good hierarchy on which you’d like to organize your
              regions or objects. This helps with a constantly improving and
              evolving model later on.
            </li>
            <li>
              [In premium: ] You can create objects with special labels that
              mark regions tolerated against incomplete areas, e.g. mark the
              whole area of fabric as fabric.unknown and just annotated parts of
              the smallest defects. This helps in getting first results on
              difficult classes of surface patches faster.
            </li>
          </ul>
          <p>
            In most cases, general guidelines for consistent annotation will
            emerge per project rather quickly. An example guide suitable for the
            “rust” demo is here.
          </p>
        </Fragment>
      );
    case "/detectors":
      return (
        <Fragment>
          <p>
            Objects cannot be detected, tracked, and studied without high
            quality detectors. Having reliable and powerful detectors puts you
            at an advantage against systems that lack them. It might sound
            complicated. We get that. That is why we offer our Toolbox Detectors
            and all necessary training that goes with them. The learning curve
            is mild compared to the benefits earned.
          </p>
          <p>
            Moonvision Toolbox Detectors are deep learning models that perform
            object detection and surface segmentation. The detectors can either
            run in the cloud or be deployed on edge. They can recommend labels
            in Labelling Jobs, detect objects in images, and detect objects in
            processing tasks.
          </p>
          <p>
            The Moonvision Data Science Team continues to improve the accuracy
            of its detectors based on customer feedback and advances in deep
            learning research. These improvements are shipped as new detectors.
          </p>
          <p>
            A model is always retrained on the most recent data. This happens
            either automatically or at deliberately chosen points in time. Each
            training run includes evaluation metrics to track improvements and
            areas that need improvement. This keeps your system up-to-date and
            your operation running smoothly.
          </p>
        </Fragment>
      );
    default:
      return null;
  }
};
