import React from "react";

import Api from "app/js/api";
import Card from "app/components/Card/Card";
import { LabelingJob } from "app/js/types";
import { ListPage, ListPageLoadCallback } from "app/components/ListPage";

const DESCRIPTION_MAX_LENGTH = 100;

const RenderCard = (job, basePath) => {
  return (
    <Card title={job.name} url={`${basePath}/${job.id}`}>
      <p>
        <b>Description:</b>
        {job.description === "" ? (
          <i>No Description</i>
        ) : job.description.length > DESCRIPTION_MAX_LENGTH ? (
          job.description.substring(0, DESCRIPTION_MAX_LENGTH) + "..."
        ) : (
          job.description
        )}
        <br />
        <b>Total Images:</b> {job.total_images}
        <br />
        <b>Remaining Images:</b> {job.remaining_images}
      </p>
    </Card>
  );
};

export default function LabellingJobList() {
  const loadJobsCallback: ListPageLoadCallback<
    LabelingJob[]
  > = React.useCallback(async (setValue, setCount, limit, offset) => {
    const response = await Api.job().all({
      with_frame_counts: true,
      limit,
      offset,
    });
    setValue(response.data.results);
    setCount(response.data.count);
  }, []);

  return (
    <ListPage<LabelingJob>
      pageTitle={"Labelling Jobs"}
      emptyPageTitle={"No Labelling Jobs yet"}
      loadDataCallback={loadJobsCallback}
      showCreate={true}
      createOwnerOnly={true}
      additionalEmptyBoxInfo={
        <strong>
          Make sure you have a Dataset first. No labelling without Data.
        </strong>
      }
      renderCard={RenderCard}
    />
  );
}
