import React from "react";
import styles from "./Step.scss";

interface StepProps {
  headline: string;
  number: string | number;
  active?: boolean;
}

const Step: React.FC<StepProps> = ({
  children,
  headline,
  number,
  active = true,
}) => {
  const activeClass = active ? "" : styles.inactive;
  const setOverflowY = headline === "Select Images" ? styles.overflow : "";
  return (
    <div className={`${styles.container} ${activeClass} ${setOverflowY}`}>
      <div className={styles.side}>
        <div className={styles.number}>{number}</div>
      </div>
      <div className={styles.body}>
        <h2>{headline}</h2>
        {children}
      </div>
    </div>
  );
};
export default Step;
