import React from "react";

interface FieldWrapperProps {
  label: string;
  children: React.ReactNode;
}

const FieldWrapper = ({
  label,
  children,
}: FieldWrapperProps): React.ReactElement => {
  return (
    <React.Fragment>
      <label>{`${label}:`}</label>
      {children}
    </React.Fragment>
  );
};

interface FieldProps {
  label: string;
  children: React.ReactNode;
}

export default function Field({
  label,
  children,
}: FieldProps): React.ReactElement {
  return <FieldWrapper label={label}>{children}</FieldWrapper>;
}
