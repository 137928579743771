import React from "react";
import { Link } from "react-router-dom";

import Api from "app/js/api";
import { LoadedDataCallback, useLoadedData, usePrevious } from "app/js/hooks";
import {
  DatasetViewLabelingJob,
  EntityFilters,
  LabelingJob,
} from "app/js/types";
import {
  RequestableLabelSprite,
  RequestableLabelSpriteProps,
} from "app/components/LabelSprite/LabelSprite";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

import styles from "./EntitySpritesContent.scss";
import { ObjectType } from "./types";

interface SpriteHeaderProps {
  job: DatasetViewLabelingJob;
  label: string;
  searchTerm: string;
}

function SpriteHeader({
  job,
  label,
  searchTerm,
}: SpriteHeaderProps): React.ReactElement {
  let link = `/labelling-jobs/${job.id}/entities`;
  if (label) {
    link += `/${label}`;
  }
  link += `?team=${job.team.id}`;
  if (searchTerm) {
    link += `&search=${searchTerm}`;
  }
  return (
    <div className={styles.spriteHeader}>
      <Link to={link}>{`${job.id}: ${job.team.name} - ${job.name}`}</Link>
    </div>
  );
}

interface SplitSpritesProps
  extends Omit<
    RequestableLabelSpriteProps,
    "jobId" | "paginatorParam" | "jobTeamId"
  > {
  labelingJobs: DatasetViewLabelingJob[];
}

function SplitSprites({
  labelingJobs,
  ...spriteProps
}: SplitSpritesProps): React.ReactElement {
  const previousLabel = usePrevious<string>(spriteProps.label);
  const previousSearchTerm = usePrevious<string>(spriteProps.searchTerm);
  const valueChanged = (prev, current) =>
    (!!prev || !!current) && prev !== current;
  const filterChanged =
    valueChanged(previousLabel, spriteProps.label) ||
    valueChanged(previousSearchTerm, spriteProps.searchTerm);

  const spriteJobsCallback: LoadedDataCallback<
    DatasetViewLabelingJob[]
  > = React.useCallback(
    async (setValue) => {
      const requestParams: EntityFilters = { mode: spriteProps.mode };
      if (spriteProps.searchTerm) {
        requestParams.label_mask = spriteProps.searchTerm;
      } else {
        requestParams.label = spriteProps.label;
      }
      const response = await Api.datasetView(
        spriteProps.datasetViewId || "overview",
      ).jobsWithEntities(requestParams);
      const jobIds = response.data;
      const jobs = labelingJobs.filter((job) => jobIds.includes(job.id));
      setValue(jobs);
    },
    // I assume, that if the id is the same, then labelling jobs are the same too
    // Of course, one could add more jobs to a DatasetView, while this page is open
    // But they won't be re-requested anyway without reloading the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      spriteProps.datasetViewId,
      spriteProps.label,
      spriteProps.mode,
      spriteProps.searchTerm,
    ],
  );
  const { value: spriteJobs, loading, error } = useLoadedData<
    DatasetViewLabelingJob[]
  >([], spriteJobsCallback);

  const pageParams = React.useMemo(
    () => [
      ...spriteProps.redirectParametersNames,
      ...spriteJobs.map((job) => `page${job.id}`),
    ],
    [spriteJobs, spriteProps.redirectParametersNames],
  );

  // loading status does not change right away after changing label/searchTerm, causing unnecessary render
  if (loading || filterChanged) {
    return <Loading />;
  } else if (error !== null) {
    return <ErrorMessage error={error} />;
  }
  const preloadedJobs = labelingJobs.map((job) => ({
    id: job.id,
    name: job.name,
    dataset: job.dataset.id,
    team: job.team.id,
  }));

  return (
    <React.Fragment>
      {spriteJobs.map((job) => (
        <React.Fragment key={job.id}>
          <SpriteHeader
            job={job}
            label={spriteProps.label}
            searchTerm={spriteProps.searchTerm}
          />
          <RequestableLabelSprite
            jobId={job.id}
            paginatorParam={`page${job.id}`}
            jobTeamId={job.team.id}
            preloadedJobs={preloadedJobs}
            {...spriteProps}
            redirectParametersNames={pageParams}
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export const OBJECT_REDIRECT_PARAMETERS = {
  dataset_view: ["team", "search", "search_triggered"],
  dataset: ["team", "search", "search_triggered", "mode", "page"],
  labeling_job: ["team", "search", "search_triggered", "page"],
};

interface EntitySpritesContentProps
  extends Pick<
    RequestableLabelSpriteProps,
    "baseRedirectUrl" | "redirectWithoutLabel" | "mode"
  > {
  label: string;
  searchTerm: string;
  objectId: number;
  objectType: ObjectType;
  jobs?: DatasetViewLabelingJob[] | null;
}

export default function EntitySpritesContent({
  label,
  searchTerm,
  objectId,
  objectType,
  jobs = null,
  ...spritePropsFromParent
}: EntitySpritesContentProps): React.ReactElement {
  const splitByJobs = objectType === "dataset_view" && jobs !== null;

  const datasetJobsCallback: LoadedDataCallback<
    LabelingJob[]
  > = React.useCallback(
    async (setValue) => {
      if (objectType === "dataset") {
        const response = await Api.job().all({
          limit: 500,
          dataset_id: objectId,
        });
        setValue(response.data.results);
      }
    },
    [objectId, objectType],
  );
  const { value: datasetJobs, loading, error } = useLoadedData<LabelingJob[]>(
    [],
    datasetJobsCallback,
  );

  // Some props are based on objectType. They could be left as required - but it is
  // easier to refactor pages, which use this component, with a shorter list of props to manage
  const memoizedSpriteProps = React.useMemo(() => {
    const spriteProps: Omit<
      RequestableLabelSpriteProps,
      "redirectParametersNames"
    > = {
      alwaysShowJobSelector: objectType !== "labeling_job",
      markDisabled: objectType !== "dataset",
      searchTerm,
      label,
      ...spritePropsFromParent,
    };
    switch (objectType) {
      case "dataset_view":
        spriteProps.datasetViewId = objectId || "overview";
        break;
      case "dataset":
        spriteProps.datasetId = objectId;
        spriteProps.preloadedJobs = datasetJobs.map((job) => ({
          id: job.id,
          name: job.name,
          dataset: job.dataset,
          team: job.team,
        }));
        break;
      case "labeling_job":
        spriteProps.jobId = objectId;
        break;
      default:
        break;
    }
    return spriteProps;
  }, [
    label,
    datasetJobs,
    objectId,
    objectType,
    searchTerm,
    spritePropsFromParent,
  ]);
  const memoizedParameterNames = React.useMemo(() => {
    return OBJECT_REDIRECT_PARAMETERS[objectType];
  }, [objectType]);

  if (objectType === "dataset") {
    // We need to check for an emptiness of datasetJobs too, otherwise there will be unnecessary re-renders.
    // What if a dataset has no jobs? Well, no jobs -> no labels -> this step won't be reached at all.
    if (loading || datasetJobs.length === 0) {
      return <Loading />;
    } else if (error !== null) {
      return <ErrorMessage error={error} />;
    }
  }

  if (splitByJobs) {
    return (
      <SplitSprites
        labelingJobs={jobs}
        redirectParametersNames={memoizedParameterNames}
        {...memoizedSpriteProps}
      />
    );
  } else {
    return (
      <RequestableLabelSprite
        redirectParametersNames={memoizedParameterNames}
        {...memoizedSpriteProps}
      />
    );
  }
}
