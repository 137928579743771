import React from "react";
import Api from "app/js/api";
import { RouteComponentProps } from "react-router";
import Button from "app/components/Buttons/Button";
import Header from "app/components/Header/Header";
import styles from "app/pages/AppConfigs/CreateInstance/CreateInstance.scss";

const NAME_INPUT_ID = "CreateInstance-nameInput";

const CreateInstance: React.FC<RouteComponentProps<{ appName: string }>> = ({
  match,
  history,
}) => {
  const nameInputRef = React.useRef(null);

  const onFormSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    async (event) => {
      event.preventDefault();
      const appName = decodeURIComponent(match.params.appName);
      const instanceName = nameInputRef?.current?.value;
      await Api.applicationInstance().store({
        name: instanceName,
        application: appName,
      });
      history.push(`${match}/../..`);
    },
    [match, history],
  );

  return (
    <>
      <Header>
        <h1>App Configs - Create Instances</h1>
      </Header>
      <form className={styles.formGrid} onSubmit={onFormSubmit}>
        <label className={styles.nameLabel} htmlFor={NAME_INPUT_ID}>
          Name
        </label>
        <input
          type="text"
          className={styles.nameInput}
          id={NAME_INPUT_ID}
          ref={nameInputRef}
        />
        <Button className={styles.saveButton}>Save</Button>
      </form>
    </>
  );
};

export default CreateInstance;
