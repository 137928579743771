import React from "react";
import classNames from "classnames";
import Select from "react-select";
import { VisualSampleSetFilterChoices } from "app/js/types";
import CreatorFilterChoiceOption from "app/pages/LabellingJobs/LabellingJobDetail/LabellingJobFilter/CreatorFilterChoiceOption";
import styles from "app/pages/LabellingJobs/LabellingJobDetail/LabellingJobFilter/CreatorFilterSelector.scss";
import { VisualSampleSetFilterChoicesWithIndex } from "app/pages/LabellingJobs/LabellingJobDetail/LabellingJobFilter/types";

interface CreatorFilterSelectorProps {
  className?: string;
  filterChoices: VisualSampleSetFilterChoicesWithIndex[];
  id?: string;
  loading: boolean;
  value: {
    value: number;
    label: React.ReactNode;
  };
  onChange: (
    update: {
      value: number;
      label: React.ReactNode;
    },
    event,
  ) => void;
}

const isSubstring = (str?: string, sub?: string): boolean => {
  if (!sub) {
    return true;
  }
  if (!str) {
    return false;
  }
  return str.toLowerCase().indexOf(sub.toLowerCase()) >= 0;
};

const CreatorFilterSelector: React.FC<CreatorFilterSelectorProps> = ({
  className,
  filterChoices,
  id,
  loading,
  value,
  onChange,
}) => {
  // const [value, setValue] = useSafeState(null);
  return (
    <div className={classNames(styles.container, className)} id={id}>
      <Select
        className={styles.select}
        aria-label="Labels Requested By"
        placeholder="Show labels, requested by..."
        isClearable
        isLoading={loading}
        options={filterChoices.map((fc) => ({
          value: fc.index,
          label: <CreatorFilterChoiceOption {...fc} />,
        }))}
        onChange={onChange}
        value={value}
        // typescript does not like the type of value - but I'm not sure how to
        // define the correct type
        // eslint-disable-next-line tsc/config
        filterOption={(
          option: { value: VisualSampleSetFilterChoices },
          inputValue: string,
        ) =>
          option.value.application_instance_name
            ? isSubstring(option.value.application_instance_name, inputValue)
            : isSubstring(option.value.user_email, inputValue)
        }
      />
    </div>
  );
};

export default CreatorFilterSelector;
