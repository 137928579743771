import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "./Login/Login";
import PasswordReset from "./PasswordReset/PasswordReset";
import Signup from "./Signup/Signup";
import ForgotPassword from "./ForgotPassword/ForgotPassword";

export default function Routes({ match, location }) {
  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/signup" exact component={Signup} />
      <Route path="/password/recover" exact component={ForgotPassword} />
      <Route path="/password/reset/:token" exact component={PasswordReset} />
      {/* if no path hit above, redirect to login */}
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    </Switch>
  );
}
