import React, { useEffect } from "react";
import Box from "app/components/Box/Box";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-javascript";
import Api from "app/js/api";
import { useSafeState } from "app/js/hooks";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import { useUserStore } from "app/js/stores";

export default function StreamActionscript({ stream }) {
  const [user] = useUserStore();
  const [actionScript, setActionScript] = useSafeState(null);
  const [loading, setLoading] = useSafeState(false);
  const [error, setError] = useSafeState(null);

  useEffect(() => {
    const loadActionscript = async (streamId) => {
      setLoading(true);
      try {
        const response = await Api.actionscript(streamId).show();
        setActionScript(response.data);
        setError(null);
      } catch (error) {
        console.error(error);
        setError(error);
      }
      setLoading(false);
    };

    if (stream.action_script) {
      loadActionscript(stream.action_script);
    }
  }, [setActionScript, setError, setLoading, stream, user.data]);

  return (
    <div style={{ padding: "25px" }}>
      <h2>Actionscript</h2>
      {error && <ErrorMessage error={error} />}
      {loading && <Loading />}
      {actionScript && !loading && (
        <Box title={actionScript.name}>
          <pre
            dangerouslySetInnerHTML={{
              __html: highlight(
                actionScript.code,
                languages.javascript,
                "javascript",
              ),
            }}
          />
        </Box>
      )}
      {!loading && !actionScript && (
        <p style={{ margin: "25px" }}>No Script connected</p>
      )}
    </div>
  );
}
