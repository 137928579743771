import { fabric } from "fabric";
import { generateColorProperties } from "app/pages/LabellingJobs/Labelling/helper/colors";
import { Entity } from "app/js/types";

const strokeDashArrayIfNotEnabled = ({ enabled }, zoom: number) =>
  enabled ? {} : { strokeDashArray: [12 / zoom, 12 / zoom] };

export const prepareObject = (entity: Entity, zoom: number): fabric.Object => {
  const commonOptions = {
    ...strokeDashArrayIfNotEnabled(entity, zoom),
    ...generateColorProperties(entity.label, 0.4, zoom),
    label: entity.label,
  };
  const commonData = {
    id: entity.id,
    enabled: entity.enabled,
  };

  // RECT
  if (entity.aabox) {
    const object = transformRect(entity.aabox);
    object.data = { ...(object.data || {}), ...commonData };

    return new fabric.Rect({
      ...object,
      ...commonOptions,
      lockRotation: true,
    });
  }
  // CIRCLE
  if (entity.circle) {
    const object = transformCircle(entity.circle);
    object.data = { ...(object.data || {}), ...commonData };

    return new fabric.Circle({
      ...object,
      ...commonOptions,
      lockRotation: true,
    });
  }
  // POLYGON
  if (entity.contour) {
    const [points, object] = transformPolygon(entity.contour);
    object.data = { ...(object.data || {}), ...commonData };

    return new fabric.Polygon(points, {
      ...object,
      ...commonOptions,
    });
  }

  return null;
};

export const exportObject = (entity) => {
  const common = { enabled: true, ...entity.data };
  switch (entity.type.toLowerCase()) {
    case "circle":
      return { circle: exportCircle(entity), ...common };
    case "rect":
      return { aabox: exportRect(entity), ...common };
    case "polygon":
      return {
        contour: exportPolygon(entity, false),
        ...common,
      };
    default:
      return undefined;
  }
};

// RECT
type Rect = {
  data?: any;
  label?: any;
  type: string;
  left: any;
  top: any;
  width: any;
  height: any;
};
export const transformRect = (rect): Rect => ({
  type: "Rect",
  left: rect.x,
  top: rect.y,
  width: rect.width,
  height: rect.height,
});
export const exportRect = (klass) => {
  let w = klass.width;
  let h = klass.height;
  if (klass.scaleX != null) {
    w = klass.scaleX * w;
  }
  if (klass.scaleY != null) {
    h = klass.scaleY * h;
  }

  return {
    x: Math.round(klass.left),
    y: Math.round(klass.top),
    width: Math.round(w),
    height: Math.round(h),
  };
};

// CIRCLE
type Circle = {
  data?: any;
  label?: any;
  type: string;
  left: any;
  top: any;
  radius: any;
  lockUniScaling: boolean;
  centeredScaling: boolean;
};
export const transformCircle = (circle): Circle => {
  return {
    type: "Circle",
    left: circle.x - circle.radius,
    top: circle.y - circle.radius,
    radius: circle.radius,
    lockUniScaling: true,
    centeredScaling: true,
  };
};
export const exportCircle = (klass) => {
  let radius = klass.radius;
  if (klass.scaleX != null) {
    radius = klass.scaleX * klass.radius;
  }

  return {
    x: Math.round(klass.left + radius),
    y: Math.round(klass.top + radius),
    radius: Math.round(radius),
  };
};
// POLYGON
export const transformPolygon = (coordinates) => {
  let left = 9999999;
  let top = 9999999;

  // prepare absolute points
  coordinates.forEach((coordinate) => {
    if (coordinate[0] < left) left = coordinate[0];

    if (coordinate[1] < top) top = coordinate[1];
  });

  // calculate relative points to top left
  const relativePoints = coordinates.map((coordinate) => {
    return {
      x: coordinate[0] - left,
      y: coordinate[1] - top,
    };
  });

  const points = relativePoints.map(function (o) {
    return fabric.util.object.clone(o);
  });

  // return relative from center
  return [
    points,
    {
      type: "Polygon",
      left: left,
      top: top,
      objectCaching: false,
      hasBorders: false,
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      hasControls: false,
      selectable: true,
      lockUniScaling: true,
      hasRotatingPoint: false,
      perPixelTargetFind: true,
      // sendToBack: true
    },
  ];
};
export const exportPolygon = (klass, stayThere = true) => {
  const left = klass.left;
  const top = klass.top;

  return klass.points.map((points) => {
    const x = points.x + (stayThere ? 0 : left);
    const y = points.y + (stayThere ? 0 : top);
    return [x, y];
  });
};
