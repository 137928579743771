import React, { useEffect, Fragment } from "react";
import moment from "moment";

import Api from "app/js/api";
import Box from "app/components/Box/Box";
import Button from "app/components/Buttons/Button";
import Header from "app/components/Header/Header";
import Loading from "app/components/Loading/Loading";
import FormComponent from "app/components/FormComponent/FormComponent";
import Select from "react-select";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

import styles from "./Settings.scss";
import {
  LoadedDataCallback,
  useLoadedData,
  useSafeState,
  useUserCallback,
} from "app/js/hooks";
import { useUserStore } from "app/js/stores";
import { DeviceAuthRequest, Option, TeamMember, TeamRole } from "app/js/types";

type RoleOption = Option<TeamRole>;

const options: RoleOption[] = [
  { label: "Labeler", value: "labeler" },
  { label: "Creator", value: "creator" },
  { label: "Owner", value: "owner" },
];

interface SettingsTableProps {
  header: string[];
  className?: string | null;
  children: React.ReactNode;
}

const SettingsTable = ({
  header,
  className = "",
  children,
}: SettingsTableProps): React.ReactElement => {
  return (
    <table className={className}>
      <thead>
        <tr>
          {/* Using index as a key, because title can be an empty string */}
          {header.map((title, index) => (
            <th key={index}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

interface PendingAuthRequestsTableProps {
  pendingAuthRequests: DeviceAuthRequest[];
  className?: string | null;
}

export function PendingAuthRequestsTable({
  pendingAuthRequests,
  className = "",
}: PendingAuthRequestsTableProps) {
  const appCount: Record<string, number> = {};
  pendingAuthRequests.forEach((req) => {
    if (appCount[req.application]) {
      appCount[req.application] += 1;
    } else {
      appCount[req.application] = 1;
    }
  });
  const groupedPendingRequests = Object.entries(appCount).map(
    ([appName, count]) => ({
      name: `${appName} (${count} requests)`,
      url: `/settings/auth-request/?app=${encodeURIComponent(appName)}`,
    }),
  );
  return (
    <SettingsTable header={["Application", ""]} className={className}>
      {groupedPendingRequests.map((requestGroup) => (
        <tr key={requestGroup.name}>
          <td>{requestGroup.name}</td>
          <td>
            <a href={requestGroup.url}>Manage</a>
          </td>
        </tr>
      ))}
    </SettingsTable>
  );
}

export default function TeamSettings({ match }) {
  const [user] = useUserStore();
  const [currentTeam, setCurrentTeam] = useSafeState(null);
  // react-router's location does not contain valid search in some cases for whatever reason
  const params = new URLSearchParams(window.location.search);
  const id = parseInt(match.params.id || params.get("team"));
  const [error, setError] = useSafeState(null);

  useEffect(() => {
    const loadCurrentTeam = async (teamId) => {
      try {
        const response = await Api.team(teamId).show();
        setCurrentTeam(response.data);
        setError(null);
      } catch (error) {
        setError(error);
      }
    };

    loadCurrentTeam(id);
  }, [id, setCurrentTeam, setError]);

  const [inviteEmail, setInviteEmail] = useSafeState("");
  const [inviteRole, setInviteRole] = useSafeState("owner");
  const [inviteMessage, setInviteMessage] = useSafeState("");

  const handleInvitationSubmit = async (e) => {
    e.preventDefault();

    setInviteMessage("");
    await Api.team(id).invites().store(inviteEmail, inviteRole);
    setInviteMessage(`Invite sent to ${inviteEmail}`);

    setInviteEmail("");
    setInviteRole("owner");
    window.matomo.push(["trackGoal", 3]);
    loadInvites();
  };

  // Team Members
  const [members, setMembers] = useSafeState<TeamMember[]>([]);
  const loadMembers = React.useCallback(async () => {
    const response = await Api.team(id).members().all();
    setMembers(response.data.results);
  }, [id, setMembers]);
  useEffect(() => {
    loadMembers();
  }, [loadMembers]);

  // Pending Invitations
  const [invites, setInvites] = useSafeState([]);
  const loadInvites = React.useCallback(async () => {
    const response = await Api.team(id).invites().all();
    setInvites(response.data.results);
  }, [id, setInvites]);
  useEffect(() => {
    loadInvites();
  }, [loadInvites]);

  // Pending Auth Requests
  const pendingAuthRequestsCallback: LoadedDataCallback<
    DeviceAuthRequest[]
  > = useUserCallback(
    async (setValue, setCount) => {
      const response = await Api.deviceAuthorizationRequest().pending(id);
      setValue(response.data.results);
      setCount(response.data.count);
    },
    [id],
  );
  const {
    value: pendingAuthRequests,
    count: pendingAuthRequestsCount,
    loading: pendingAuthRequestsLoading,
  } = useLoadedData<DeviceAuthRequest[]>([], pendingAuthRequestsCallback);

  // Approved Auth Requests
  const approvedAuthRequestsCallback: LoadedDataCallback<
    DeviceAuthRequest[]
  > = useUserCallback(
    async (setValue, setCount) => {
      const response = await Api.deviceAuthorizationRequest().approved(id);
      setValue(response.data.results);
      setCount(response.data.count);
    },
    [id],
  );
  const {
    value: approvedAuthRequests,
    count: approvedAuthRequestsCount,
    loading: approvedAuthRequestsLoading,
  } = useLoadedData<DeviceAuthRequest[]>([], approvedAuthRequestsCallback);

  const handleMemberDelete = async (memberId) => {
    await Api.team(id).members(memberId).destroy();
    loadMembers();
  };
  const toggleRole = async (memberId, newRole) => {
    await Api.team(id).members(memberId).update({ role: newRole });
    loadMembers();
  };

  const handleInvitationDelete = async (invitationId) => {
    await Api.team(id).invites(invitationId).destroy();
    loadInvites();
  };

  if (!currentTeam) return <Loading />;

  const roleButtons = [
    ["labeler", "Change to labeler"],
    ["creator", "Change to creator"],
    ["owner", "Change to owner"],
  ];

  return (
    <div>
      <Header showCreate={false}>
        <h1>Team Settings - {currentTeam.name}</h1>
      </Header>
      <div className={styles.content}>
        {error && <ErrorMessage error={error} />}
        <form onSubmit={handleInvitationSubmit}>
          <Box title="Send Invitation">
            <FormComponent>
              {inviteMessage !== "" && (
                <Fragment>
                  <label>Status:</label>
                  <p>{inviteMessage}</p>
                </Fragment>
              )}
              <label htmlFor="email">E-Mail Address</label>
              <input
                type="text"
                name="email"
                value={inviteEmail}
                onChange={(e) => {
                  setInviteEmail(e.target.value);
                }}
              />
              <label htmlFor="email">Role</label>
              <Select
                options={options}
                onChange={(option: RoleOption) => {
                  setInviteRole(option.value);
                }}
                value={options.find((entry) => entry.value === inviteRole)}
              />
            </FormComponent>
            <Button>Send Invitation</Button>
          </Box>
        </form>
        {(pendingAuthRequestsCount > 0 || pendingAuthRequestsLoading) && (
          <Box title="Device Authorization Requests">
            {pendingAuthRequestsLoading ? (
              <Loading />
            ) : (
              <PendingAuthRequestsTable
                pendingAuthRequests={pendingAuthRequests}
                className={styles.table}
              />
            )}
          </Box>
        )}
        <Box title="Mailed Invitations">
          {!invites.length && <span>No Invitations open</span>}
          {invites.length > 0 && (
            <table style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th>E-Mail Address</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {invites.map((invite) => (
                  <tr key={invite.id}>
                    <td>{invite.user.email}</td>
                    <td>
                      {
                        options.find((option) => option.value === invite.role)
                          .label
                      }
                    </td>
                    <td>
                      <Button onClick={() => handleInvitationDelete(invite.id)}>
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Box>
        <Box title={`Device Authorizations (${approvedAuthRequestsCount})`}>
          {approvedAuthRequestsLoading ? (
            <Loading />
          ) : (
            <React.Fragment>
              {approvedAuthRequestsCount === 0 ? (
                <span>No approved Authorization Requests</span>
              ) : (
                <SettingsTable
                  header={["Application", "Approved by", "Approval time", ""]}
                >
                  {approvedAuthRequests.map((authRequest) => (
                    <tr key={authRequest.id}>
                      <td>{authRequest.application}</td>
                      <td>
                        {authRequest.approved_by ? (
                          <span title={authRequest.approved_by}>
                            {authRequest.approver_name}
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {authRequest.approval_time
                          ? moment(authRequest.approval_time).format("LLL")
                          : ""}
                      </td>
                      <td>
                        <a href={`/settings/auth-request/${authRequest.id}`}>
                          Manage
                        </a>
                      </td>
                    </tr>
                  ))}
                </SettingsTable>
              )}
            </React.Fragment>
          )}
        </Box>
        <Box title={`Team Members (${members.length})`}>
          <table style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {members.map((member) => (
                <tr key={member.id}>
                  <td>{member.user.email}</td>
                  <td>
                    {
                      options.find((option) => option.value === member.role)
                        .label
                    }
                  </td>
                  {member.user.id !== user.data.user_id && (
                    <td>
                      {roleButtons.map(
                        ([role, label]) =>
                          member.role !== role && (
                            <Button
                              key={role}
                              onClick={() => toggleRole(member.id, role)}
                            >
                              <span>{label}</span>
                            </Button>
                          ),
                      )}
                      <Button onClick={() => handleMemberDelete(member.id)}>
                        Remove
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </div>
    </div>
  );
}
