import React from "react";
import styles from "./Details.scss";

export function CardLabelStatus(): React.ReactElement {
  return (
    <span
      data-tooltip="Image was not confirmed by a user"
      className={styles.labelStatus}
    >
      <b>A</b>
    </span>
  );
}
