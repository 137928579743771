import React, { useEffect } from "react";
import styles from "./Loading.scss";
import LoadingIcon from "./LoadingIcon";
import { useSafeState } from "app/js/hooks";

interface LoadingProps {
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({ color = "#ececec" }) => {
  const [loadingText, setLoadingText] = useSafeState("Loading");

  useEffect(() => {
    const info1 = setTimeout(function () {
      setLoadingText("working hard");
    }, 3000);

    const info2 = setTimeout(function () {
      setLoadingText("this is an intense process indeed");
    }, 12000);

    return () => {
      clearTimeout(info1);
      clearTimeout(info2);
    };
  }, [setLoadingText]);
  return (
    <div className={styles.container}>
      <div className={styles.component}>
        <LoadingIcon color={color} />
        <p>{loadingText}</p>
      </div>
    </div>
  );
};
export default Loading;
