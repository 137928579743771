import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./Card.scss";
import { CardImageProps, CardLabelStatus, CardVideoProps } from "./components";

interface ConditionalLinkProps {
  className: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  url: string;
  children: React.ReactNode | null;
}

function ConditionalLink({
  className,
  onClick,
  url = "",
  children,
}: ConditionalLinkProps) {
  if (url === "") {
    return (
      <div className={className} onClick={onClick}>
        {children}
      </div>
    );
  } else {
    return (
      <Link className={className} onClick={onClick} to={url}>
        {children}
      </Link>
    );
  }
}

// TODO: Well, technically, all props are optional and it is legal to omit all
//       of them and create an empty Card. But it might be better to define more
//       strict props for different cases (a card with children or with media)
export interface CardProps {
  title?: string;
  selected?: boolean;
  showLabelStatus?: boolean;
  children?: React.ReactNode | null;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  url?: string;
  className?: string;
  media?:
    | React.ReactElement<CardImageProps>
    | React.ReactElement<CardVideoProps>
    | null;
  hoverButtons?: React.ReactNode | null;
  showCheckbox?: boolean;
  checkboxValue?: boolean;
  onCheckboxClick?: (selected: boolean) => void;
}

export default function Card({
  title,
  selected,
  showLabelStatus = false,
  children,
  onClick,
  url = "", // If url is not provided, it should lead to nowhere
  className = "",
  media = null,
  hoverButtons = null,
  showCheckbox = false,
  checkboxValue = false,
  onCheckboxClick = null,
}: CardProps) {
  const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    if (onCheckboxClick !== null) {
      onCheckboxClick(e.target.checked);
    }
  };

  const classes = classNames(
    styles.container,
    {
      [styles.selected]: selected,
      [styles.clickable]: typeof onClick === "function" || url !== "",
    },
    className,
  );

  return (
    <div className={styles.cardWrapper}>
      {showCheckbox && (
        <input
          type="checkbox"
          className={styles.multiModeCheckbox}
          checked={checkboxValue}
          onChange={handleCheckboxChange}
        />
      )}
      <ConditionalLink className={classes} onClick={onClick} url={url}>
        {hoverButtons ? (
          <div className={styles.hoverButtons}>{hoverButtons}</div>
        ) : null}

        {media}
        {title !== undefined || children !== undefined ? (
          <div className={styles.body}>
            {title !== undefined ? <h2>{title}</h2> : null}
            {children}
            {showLabelStatus && <CardLabelStatus />}
          </div>
        ) : null}
      </ConditionalLink>
    </div>
  );
}
