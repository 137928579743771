import React from "react";
import { useHistory } from "react-router-dom";

import Api from "app/js/api";
import { LoadedDataCallback, useLoadedData, useSafeState } from "app/js/hooks";
import { GenericForm, useFormStore } from "app/js/stores";
import {
  DatasetView,
  DatasetViewLabelingJob,
  DatasetViewSave,
} from "app/js/types";

import Button from "app/components/Buttons/Button";
import { Form, FormFields } from "app/components/Form";
import Loading from "app/components/Loading/Loading";
import Table from "app/components/Table/Table";

import styles from "./Edit.scss";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

type InputProps = React.ComponentPropsWithoutRef<"input">;

interface CheckboxProps extends Omit<InputProps, "type"> {
  indeterminate?: boolean;
}

const Checkbox = ({
  indeterminate = false,
  ...props
}: CheckboxProps): React.ReactElement => {
  const inputRef = React.useRef<HTMLInputElement>();
  React.useEffect(() => {
    if (inputRef.current) {
      // This is the only way how one can assign this attribute; it cannot be passed with props
      inputRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate, inputRef]);

  return <input type={"checkbox"} ref={inputRef} {...props} />;
};

interface DatasetViewEditTeam {
  id: number;
  name: string;
  isCollapsed: boolean;
  isSelected: boolean;
  isIndeterminate: boolean;
}

interface DatasetViewForm extends GenericForm {
  name: string;
}

interface DatasetViewEditJob extends DatasetViewLabelingJob {
  isSelected: boolean;
}

interface DatasetViewEditProps {
  datasetView: DatasetView | null;
  setDatasetView: (dataset_view: DatasetView) => void;
}

export default function DatasetViewEdit({
  datasetView,
  setDatasetView,
}: DatasetViewEditProps): React.ReactElement {
  const history = useHistory();

  const loadOverview: LoadedDataCallback<DatasetView> = React.useCallback(
    async (setValue) => {
      if (datasetView.id === 0) {
        setValue(datasetView);
      }
      const response = await Api.datasetView("overview").show();
      setValue(response.data);
    },
    // It should be enough to use id as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datasetView.id],
  );
  const { value: overview, loading } = useLoadedData<DatasetView | null>(
    null,
    loadOverview,
  );

  const [formData] = useFormStore<DatasetViewForm>("datasetView");

  const initiallySelectedJobs =
    datasetView !== null && datasetView.id !== 0
      ? datasetView.labeling_jobs.map((job) => job.id)
      : [];
  const overviewJobs =
    overview !== null ? overview.labeling_jobs.map((job) => job.id) : [];
  const [selectedJobs, setSelectedJobs] = useSafeState<number[]>(
    initiallySelectedJobs,
  );
  const allSelected = overviewJobs.every((jobId) =>
    selectedJobs.includes(jobId),
  );
  const noneSelected = !overviewJobs.some((jobId) =>
    selectedJobs.includes(jobId),
  );
  const someSelected = !allSelected && !noneSelected;

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedJobs(overviewJobs);
    } else {
      setSelectedJobs([]);
    }
  };

  const [collapsedTeams, setCollapsedTeams] = useSafeState<number[]>([]);

  const handleTeamExpand = (team: DatasetViewEditTeam) => {
    let newCollapsedTeams;
    if (team.isCollapsed) {
      newCollapsedTeams = collapsedTeams.filter((value) => value !== team.id);
    } else {
      newCollapsedTeams = [...collapsedTeams, team.id];
    }
    setCollapsedTeams(newCollapsedTeams);
  };

  const groupedByTeamMap: Record<number, DatasetViewEditJob[]> = {};
  if (overview !== null) {
    overview.labeling_jobs.forEach((job) => {
      const previousValue = groupedByTeamMap[job.team.id] || [];
      const newJob: DatasetViewEditJob = {
        ...job,
        isSelected: selectedJobs.includes(job.id),
      };
      if (!newJob.is_deleted || initiallySelectedJobs.includes(newJob.id)) {
        previousValue.push(newJob);
        groupedByTeamMap[job.team.id] = previousValue;
      }
    });
  }

  const handleTeamCheck = (team: DatasetViewEditTeam) => {
    const jobsToChange = groupedByTeamMap[team.id].map((job) => job.id);
    let newSelectedJobs;
    if (team.isSelected) {
      newSelectedJobs = selectedJobs.filter((id) => !jobsToChange.includes(id));
    } else {
      newSelectedJobs = [...selectedJobs, ...jobsToChange];
    }
    setSelectedJobs(newSelectedJobs);
  };

  const handleJobCheck = (job: DatasetViewEditJob) => {
    let newSelectedJobs;
    if (job.isSelected) {
      newSelectedJobs = selectedJobs.filter((id) => id !== job.id);
    } else {
      newSelectedJobs = [...selectedJobs, job.id];
    }
    setSelectedJobs(newSelectedJobs);
  };

  const groupedByTeam = Object.values(groupedByTeamMap).map((jobs) => {
    const teamIsSelected = jobs.some((job) => job.isSelected);
    return {
      team: {
        ...jobs[0].team,
        isCollapsed: collapsedTeams.includes(jobs[0].team.id),
        isSelected: teamIsSelected,
        isIndeterminate: teamIsSelected && !jobs.every((job) => job.isSelected),
      },
      jobs: jobs,
    };
  });
  groupedByTeam.sort((a, b) => (a.team.id > b.team.id ? 1 : -1));

  const submitCallback: LoadedDataCallback<DatasetViewSave> = React.useCallback(
    async (setValue, setCount) => {
      const data: DatasetViewSave = {
        ...formData,
        labeling_job_ids: selectedJobs,
      };
      const response = datasetView.id
        ? await Api.datasetView(datasetView.id).update(data)
        : await Api.datasetView().store(data);
      if (response.data.id) {
        setDatasetView(response.data);
        history.push(`/dataset-views/${response.data.id}`);
      }
    },
    [datasetView.id, formData, history, selectedJobs, setDatasetView],
  );
  const {
    loading: isSaving,
    error: savingError,
    requestCallback: handleSubmit,
  } = useLoadedData<DatasetViewSave>(null, submitCallback, true);

  return (
    <React.Fragment>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
        className={styles.formComponent}
      >
        <Form<DatasetViewForm> formName="datasetView" layout="vertical">
          <FormFields.CharField
            label="Name"
            paramName="name"
            type="text"
            value={datasetView.id !== 0 ? datasetView.name : ""}
          />
        </Form>
        <Button disabled={isSaving}>Save</Button>
        {!isSaving && savingError && <ErrorMessage error={savingError} />}
      </form>
      {loading ? (
        <Loading />
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Job</th>
              <th>Dataset</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2}>
                <div className={styles.selectAllRow}>
                  <Checkbox
                    className={styles.teamCheckbox}
                    checked={allSelected}
                    indeterminate={someSelected}
                    onChange={handleSelectAll}
                  />
                  <b>
                    <i>Select all</i>
                  </b>
                </div>
              </td>
            </tr>
            {groupedByTeam.map((teamData) => (
              <React.Fragment key={teamData.team.id}>
                <tr>
                  <td colSpan={2}>
                    <div>
                      <span
                        className={styles.teamExpander}
                        onClick={() => handleTeamExpand(teamData.team)}
                      >
                        {teamData.team.isCollapsed ? "\u25B6" : "\u25BC"}
                      </span>
                      <Checkbox
                        className={styles.teamCheckbox}
                        checked={teamData.team.isSelected}
                        indeterminate={teamData.team.isIndeterminate}
                        onChange={() => handleTeamCheck(teamData.team)}
                      />
                      <b>{`${teamData.team.name} (${teamData.team.id})`}</b>
                    </div>
                  </td>
                </tr>
                {!teamData.team.isCollapsed && (
                  <React.Fragment>
                    {teamData.jobs.map((job) => (
                      <tr key={`${teamData.team.id}-${job.id}`}>
                        <td>
                          <div className={styles.selectJobRow}>
                            <input
                              type={"checkbox"}
                              className={styles.jobCheckbox}
                              checked={job.isSelected}
                              onChange={() => handleJobCheck(job)}
                            />
                            {job.is_deleted ? (
                              <span
                                className={styles.deletedJob}
                                title={
                                  "Job has been deleted. Deselect it and save the dataset view if you don't want to see this job anymore."
                                }
                              >{`${job.name} (${job.id})`}</span>
                            ) : (
                              <span>{`${job.name} (${job.id})`}</span>
                            )}
                          </div>
                        </td>
                        <td>{`${job.dataset.name} (${job.dataset.id})`}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )}
    </React.Fragment>
  );
}
