import React from "react";
import { Link } from "react-router-dom";

export default function NotTeamMember() {
  return (
    <div>
      <h2>Permission Denied</h2>
      <p>You are not a member of this team.</p>
      <Link to={`/`}>Back to Home</Link>
    </div>
  );
}
