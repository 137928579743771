import React from "react";

interface LoadingIconProps {
  color: string;
}

const LoadingIcon: React.FC<LoadingIconProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <g transform="rotate(-45 100 100)">
        <circle cx="100" cy="100" r="100" fill="black" />
        <circle cx="100" cy="100" r="89" fill={color} />
        <circle cx="100" cy="100" r="67" fill="black" />
        <circle cx="100" cy="100" r="56.3" fill={color} />
        <circle cx="100" cy="100" r="37" fill="black" />
        <circle cx="100" cy="100" r="26.3" fill={color} />
        <line
          x1="92.5"
          y1="0"
          x2="92.5"
          y2="100"
          stroke={color}
          strokeWidth="15"
          strokeLinecap="round"
        />
        <line
          x1="100.25"
          y1="5.5"
          x2="100.25"
          y2="100"
          stroke="black"
          strokeWidth="10.5"
          strokeLinecap="round"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="-45 100 100"
          to="-405 100 100"
          dur="2s"
          repeatCount="indefinite"
        />
      </g>
      <g>
        <circle cx="143.5" cy="56" r="15" fill="black">
          <animate
            attributeName="r"
            begin="0s"
            dur="2s"
            values="15;15;0;0;15;15;15"
            keyTimes="0;.7;.701;.725;.925;.975;1"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="143.5" cy="56" r="4.25" fill={color}>
          <animate
            attributeName="r"
            begin="0s"
            dur="2s"
            values="4.25;4.25;0;0;4.25;4.25"
            keyTimes="0;.7;.701;.725;.925;1"
            repeatCount="indefinite"
          />
        </circle>
      </g>
      <g>
        <circle cx="100" cy="131" r="13" fill="black">
          <animate
            attributeName="r"
            begin="0s"
            dur="2s"
            values="13;13;0;0;13;13"
            keyTimes="0;.27;.271;.335;.535;1"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="100" cy="131" r="2.75" fill={color}>
          <animate
            attributeName="r"
            begin="0s"
            dur="2s"
            values="2.75;2.75;0;0;2.75;2.75"
            keyTimes="0;.27;.271;.335;.535;1"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </svg>
  );
};
export default LoadingIcon;
