import React from "react";

import Image from "app/components/Image/Image";

export default function DatasetDetail({ dataset }) {
  return (
    <div style={{ height: "70%", overflowY: "hidden", display: "grid" }}>
      <div style={{ padding: "25px", overflowY: "auto" }}>
        <Image src={dataset.thumbnail} />
        <p>
          <strong>ID:</strong> {dataset.id}
          <br />
          {/* <strong>created:</strong> {dataset.created_at}
          <br /> */}
          <strong>Image Count:</strong> {dataset.frames_count}
          <br />
          <strong>Video Count:</strong> {dataset.videos_count}
          <br />
          <strong>Entity Count:</strong> {dataset.entity_count}
          <br />
          <strong>Description:</strong> {dataset.description}
        </p>
      </div>
    </div>
  );
}
