import React from "react";

import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

export default function GraphScene({ sceneData }) {
  return (
    <Plot
      useResizeHandler
      style={{ marginTop: "15px", width: "100%" }}
      data={sceneData.data}
      layout={{
        title: "Scenes that maximize seperation in activity",
        yaxis: {
          title: "Activity (avg)",
        },
        margin: {
          r: 0,
        },
        legend: {
          x: -0.1,
          y: 1.15,
          bgcolor: "#E2E2E2",
        },
        autosize: true,
        ...sceneData.layout,
      }}
    />
  );
}
