import React from "react";
import { VisualSampleSetFilters } from "app/js/types";

import CreatorFilterSelector from "./CreatorFilterSelector";
import { useLabellingJobFilterStore } from "./hooks";
import styles from "./LabellingJobFilter.scss";
import { VisualSampleSetFilterChoicesWithIndex } from "./types";

const selectedFilterToQueryParams = (
  selectedFilter?: VisualSampleSetFilterChoicesWithIndex,
  dataset?: number,
  indexer?: number,
): VisualSampleSetFilters | null => {
  if (!selectedFilter) {
    return null;
  }
  const filter: VisualSampleSetFilters = {
    dataset,
    indexer,
    limit: 1,
  };
  if (selectedFilter.application_instance_id) {
    filter.application_instance = selectedFilter.application_instance_id;
  } else {
    filter.user = selectedFilter.user_id;
  }
  return filter;
};

export interface LabellingJobFilterProps {
  dataset?: number;
  labeling_job?: number;
  indexer?: number;
  onChange: (update: VisualSampleSetFilters | null) => void;
}

const LabellingJobFilter: React.FC<LabellingJobFilterProps> = ({
  dataset,
  labeling_job,
  indexer,
  onChange,
}) => {
  const {
    apply,
    filterChoices,
    inputValue,
    selectedFilter,
  } = useLabellingJobFilterStore(labeling_job);

  React.useEffect(() => {
    onChange(selectedFilterToQueryParams(selectedFilter, dataset, indexer));
  }, [selectedFilter, onChange, dataset, indexer]);
  return (
    <div className={styles.bar}>
      <div className={styles.selectByWrapper}>
        <CreatorFilterSelector
          filterChoices={filterChoices.filterChoices}
          id="LabellingJobFilter-CreatorFilterSelector"
          className={styles.creatorFilterSelector}
          loading={filterChoices.loading}
          value={inputValue}
          onChange={apply}
        />
      </div>
    </div>
  );
};

export default LabellingJobFilter;
