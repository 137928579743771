import React from "react";
import { useHistory } from "react-router-dom";

import { useParams, useSafeState } from "app/js/hooks";

import styles from "./LabelSearchBar.scss";
import SearchBar from "./SearchBar";
import LabellingJobFilter from "app/pages/LabellingJobs/LabellingJobDetail/LabellingJobFilter";
import { LabellingJobFilterProps } from "app/pages/LabellingJobs/LabellingJobDetail/LabellingJobFilter/LabellingJobFilter";

const SEARCH_PARAM = "search";

interface LabelSearchBarProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  searchTriggered: boolean;
  setSearchTriggered: (searchTriggered: boolean) => void;
  showRequestedBy?: boolean;
  requestedByProps?: LabellingJobFilterProps | null;
}

export default function LabelSearchBar({
  searchTerm,
  setSearchTerm,
  searchTriggered,
  setSearchTriggered,
  showRequestedBy = false,
  requestedByProps = null,
}: LabelSearchBarProps): React.ReactElement {
  const history = useHistory();
  const params = useParams();
  const paramsSearch = params.get(SEARCH_PARAM) || "";
  const paramsSearchTriggered = params.get("search_triggered");

  const [searchAvailable, setSearchAvailable] = useSafeState<boolean>(
    searchTerm !== "",
  );

  const setSearchTermValue = React.useCallback(
    (newValue: string) => {
      setSearchTerm(newValue);
      if (newValue) {
        params.set(SEARCH_PARAM, newValue);
        if (!paramsSearchTriggered) {
          params.set("search_triggered", "false");
        }
      } else {
        params.delete(SEARCH_PARAM);
      }
      history.replace({
        search: params.toString(),
      });
      setSearchAvailable(newValue !== "");
      if (searchTriggered) {
        // I did not find any way to track all requests in children components and to update
        // the input/button states without triggering additional re-renders
        // Because of that I always allow to edit the input and I cancel any pending
        // requests if the input changed again
        setSearchTriggered(false);
        window.stop();
      }
    },
    [
      history,
      params,
      paramsSearchTriggered,
      searchTriggered,
      setSearchAvailable,
      setSearchTerm,
      setSearchTriggered,
    ],
  );

  React.useEffect(() => {
    if (paramsSearch !== searchTerm) {
      setSearchTermValue(paramsSearch);
    }
  }, [paramsSearch, searchTerm, setSearchTermValue]);

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTermValue(event.currentTarget.value);
    },
    [setSearchTermValue],
  );

  const handleSearchClick = React.useCallback(() => {
    setSearchAvailable(false);
    setSearchTriggered(true);
  }, [setSearchAvailable, setSearchTriggered]);

  return (
    <div className={styles.bar}>
      {showRequestedBy && <LabellingJobFilter {...requestedByProps} />}
      <SearchBar
        wrapperClass={styles.chipBox}
        onClear={(e) => {
          setSearchTermValue("");
        }}
      >
        <input
          className={styles.searchInput}
          type="text"
          onChange={handleSearchChange}
          value={searchTerm}
          onKeyPress={(event) => {
            // Typescript understands, that there is an input event, but cannot cast event target automatically
            const target = event.target as HTMLInputElement;
            if (event.key === "Enter" && target.value) {
              handleSearchClick();
            }
          }}
        />
      </SearchBar>
      <button
        className={styles.searchButton}
        onClick={handleSearchClick}
        disabled={!searchAvailable || searchTriggered}
      >
        Search
      </button>
    </div>
  );
}
