import React, { Fragment } from "react";
import { useUserStore } from "app/js/stores";
import { AreaProps } from "./types";

const AreaOwner: React.FC<AreaProps> = ({ children }) => {
  const [user] = useUserStore();
  if (user.data.role !== "owner") return null;
  return <Fragment>{children}</Fragment>;
};
export default AreaOwner;
