import React from "react";
import SelectTeam from "app/components/Selects/SelectTeam";
import styles from "./User.scss";

import Loading from "app/components/Loading/Loading";

import { useTeamStore, useUserStore } from "app/js/stores";

const User: React.FC = () => {
  const [teams, _teamActions] = useTeamStore();
  const [user, userActions] = useUserStore();

  return (
    <div className={styles.container}>
      {teams !== null && teams.data ? (
        <SelectTeam
          onChange={userActions.setTeam}
          teams={teams.data}
          value={user.data.team_id}
          isDisabled={user.loading}
        />
      ) : (
        <Loading />
      )}
      <button onClick={userActions.logout}>Logout</button>
    </div>
  );
};
export default User;
