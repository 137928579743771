import { Field } from "@rjsf/core";
import DishtrackerModelField from "app/pages/AppConfigs/EditConfigForm/fields/DishtrackerModelField";
import CustomSchemaField from "app/pages/AppConfigs/EditConfigForm/fields/CustomSchemaField";
import NullableStringField from "app/pages/AppConfigs/EditConfigForm/fields/NullableStringField";
import CamMatrixRowField from "app/pages/AppConfigs/EditConfigForm/fields/CamMatrixRowField";
import CustomAnyOfField from "app/pages/AppConfigs/EditConfigForm/fields/CustomAnyOfField";
import AABoxField from "app/pages/AppConfigs/EditConfigForm/fields/AABoxField";

const fields: Record<string, Field> = {
  AABoxField,
  DishtrackerModelField,
  SchemaField: CustomSchemaField,
  NullableStringField,
  CamMatrixRowField,
  AnyOfField: (CustomAnyOfField as unknown) as Field,
};

export default fields;
