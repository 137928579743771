import React, { useRef, useEffect } from "react";

import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

export default function GraphActivity({ interestingData, layout, setLayout }) {
  const plotly = useRef(null);

  const resizeListener = () => {
    plotly.current.resizeHandler();
  };
  useEffect(() => {
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  });
  return (
    <Plot
      ref={plotly}
      useResizeHandler
      style={{ marginTop: "15px", width: "100%" }}
      data={[
        {
          type: "scatter",
          mode: "lines",
          y: interestingData,
        },
      ]}
      layout={{
        autosize: true,
        ...layout,
      }}
      onRelayout={(event) => {
        setLayout((prev) => ({
          ...prev,
          min: event["xaxis.range[0]"],
          max: event["xaxis.range[1]"],
        }));
      }}
    />
  );
}
