import React from "react";
import classNames from "classnames";
import styles from "app/components/Table/Table.scss";

interface TableProps {
  children: React.ReactNode;
  className?: string | null;
}

const Table: React.FC<TableProps> = ({ children, className = null }) => (
  <table className={classNames(styles.table, className)}>{children}</table>
);

export default Table;
