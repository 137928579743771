import React, { useRef, useEffect, useState, useCallback } from "react";
import { fabric } from "fabric";
import isDeepEqual from "fast-deep-equal/react";

import { Image } from "./types";
import { Action, LabellingImage } from "../pages/LabellingJobs/types";
import { useHistory, useLocation } from "react-router-dom";
import { Error } from "app/components/ErrorMessage/types";
import { useUserStore } from "app/js/stores";

// custom hook to safely set the state only when the component is mounted
// removes the memory leak error from react
export function useSafeState<T>(
  initialState: T,
): [T, (arg: React.SetStateAction<T>) => void] {
  const [state, setState] = useState<T>(initialState);

  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  const safeSetState = useCallback(
    (arg: React.SetStateAction<T>) => mountedRef.current && setState(arg),
    [],
  );

  return [state, safeSetState];
}

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

type Canvas = fabric.Canvas;
type CanvasImage = Image | LabellingImage;

interface CanvasProps {
  canvasRef: any;
  canvasContainerRef: any;
  image: CanvasImage;
  action: Action;
  backgroundCallback: (size?: {
    canvasWidth: number;
    canvasHeight: number;
  }) => void;
}

/**
 * Sets up a fabric canvas with zoom and pan support
 * @param {*} props
 */
export function useCanvas(props: CanvasProps): [Canvas, (c: Canvas) => void] {
  const {
    canvasRef, // the canvas element
    canvasContainerRef, // the div that wraps the canvas element,
    image = { image: "", width: 0, height: 0 }, // the background image
    action,
    backgroundCallback = () => undefined, // callback to call, when background image was rendered
  } = props;
  /*
   * CANVAS INIT
   */
  const [canvas, setCanvas] = useState<Canvas>(null);
  const canvasExists = canvasRef?.current != null;

  useEffect(() => {
    const c = new fabric.Canvas(canvasRef.current);
    setCanvas(c);
    // `canvasRef` replaced with `canvasExists`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasExists]);

  const getMinZoom = (c: Canvas, i: CanvasImage) => {
    const height = c.getHeight();
    const width = c.getWidth();
    const canvasRatio = width / height;
    const imageRatio = i.width / i.height;

    if (canvasRatio > imageRatio) {
      return height / i.height;
    } else {
      return width / i.width;
    }
  };

  const containerRendered = canvasContainerRef?.current != null;

  useEffect(() => {
    if (!canvas) return;

    canvas.clear();
    canvas.remove(...canvas.getObjects());
    // canvasContainerRef.current may be undefined if not rendered. `containerRendered` is a check for it
    if (image && image.image && containerRendered) {
      const height = canvasContainerRef.current.offsetHeight;
      const width = canvasContainerRef.current.offsetWidth;
      canvas.selection = false; // disable group selection
      canvas.preserveObjectStacking = true;

      canvas.setWidth(width);
      canvas.setHeight(height);
      const minZoom = getMinZoom(canvas, image);
      canvas.setZoom(minZoom);
      canvas.setBackgroundImage(image.image, () => {
        backgroundCallback({
          canvasWidth: width,
          canvasHeight: height,
        });
        setCanvasBoundary(canvas, image);
        canvas.renderAll();
      });
    } else {
      canvas.setWidth(0);
      canvas.setHeight(0);
    }
    // `canvasContainerRef` replaced with `containerRendered`
    // `image` replaced with `image?.image`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas, image?.image, containerRendered]);

  const addCursorWhenResizing = useCallback(
    (event) => {
      const scaledObject = event.target;
      const currentMouse = canvas.getPointer(event);
      const cursor = new fabric.Text(
        scaledObject.type === "Rect"
          ? `w: ${Math.round(scaledObject.getScaledWidth())}\nh: ${Math.round(
              scaledObject.getScaledHeight(),
            )}`
          : `r: ${Math.round(scaledObject.getScaledWidth() / 2)}`,
        {
          data: {
            id: "cursorText",
          },
          fontFamily: "sans-serif",
          fontSize: 36 / canvas.getZoom(),
          fill: "#ffffff",
          selectable: false,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          textBackgroundColor: "#5bc0eb",
          left: currentMouse.x,
          top: currentMouse.y,
        },
      );

      //remove previous cursor
      canvas._objects
        .filter((obj) => obj.data?.id === "cursorText")
        .forEach((cursorText) => canvas.remove(cursorText));
      //add new cursor with new text values and coordinates
      canvas.add(cursor);
      canvas.renderAll();
    },
    [canvas],
  );

  const setCoords = (canvas: Canvas) =>
    canvas.getObjects().forEach((obj) => obj.setCoords());

  // event handlers
  const objectScaled = useCallback(
    (event) => {
      bindObjectInCanvas(event.target, image.width, image.height);
      event.e.preventDefault();
      event.e.stopPropagation();
    },
    [image.height, image.width],
  );

  const mouseWheelHandler = useCallback(
    function (opt) {
      const delta = -opt.e.deltaY;
      let zoom = canvas.getZoom();

      zoom = zoom + delta / 200;
      const minZoom = getMinZoom(canvas, image);
      if (zoom < minZoom) zoom = minZoom;
      if (zoom > 20) zoom = 20;

      canvas.zoomToPoint(new fabric.Point(opt.e.offsetX, opt.e.offsetY), zoom);
      canvas.getObjects().forEach((obj) => {
        obj.set("strokeWidth", 3 / canvas.getZoom());
        if (obj instanceof fabric.Circle) {
          obj.set("radius", 5 / canvas.getZoom());
        }
        obj.set("cornerSize", 10);
      });
      setCoords(canvas);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      setCanvasBoundary(canvas, image);
      canvas.requestRenderAll();
    },
    [canvas, image],
  );

  const mouseDownHandler = useCallback(
    function (opt) {
      const target = canvas.findTarget(opt, false);
      console.log("mouseDownHandler", { target });
      if (target === undefined && action.edit === false) {
        this.isDragging = true;
        this.lastPosX = opt.e.clientX;
        this.lastPosY = opt.e.clientY;
        setCoords(canvas);
      } else if (action.edit === false && target.type === "Polygon") {
        this.isDragging = true;
        this.lastPosX = opt.e.clientX;
        this.lastPosY = opt.e.clientY;
        setCoords(canvas);
      }
    },
    [action.edit, canvas],
  );

  const mouseMoveHandler = useCallback(
    function (opt) {
      if (this.isDragging) {
        const e = opt.e;
        if (opt.target && opt.target.type === "Polygon") {
          const target = opt.target;
          target.set("left", target.left + e.clientX - this.lastPosX);
          target.set("top", target.top + e.clientY - this.lastPosY);
        } else {
          // never seems to get called?
          this.viewportTransform[4] += e.clientX - this.lastPosX;
          this.viewportTransform[5] += e.clientY - this.lastPosY;
        }

        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
        setCanvasBoundary(canvas, image);
        setCoords(canvas);
      }
    },
    [canvas, image],
  );

  const mouseUpHandler = useCallback(
    function (opt) {
      this.isDragging = false;
      // We need to manually save result
      if (opt.target && opt.target.type === "Polygon") {
        canvas.fire("object:modified", { target: opt.target });
      }
    },
    [canvas],
  );

  // checks if the canvas is in bounce of the wrapper element
  const setCanvasBoundary = (canvas: Canvas, image: CanvasImage) => {
    if (!image || !canvas) return;

    const zoom = canvas.getZoom();
    const canvasWidth = Math.round(canvas.getWidth());
    const canvasHeight = Math.round(canvas.getHeight());
    const imageWidth = Math.round(image.width * zoom);
    const imageHeight = Math.round(image.height * zoom);
    const xAxis = canvas.viewportTransform[4];
    const yAxis = canvas.viewportTransform[5];

    // when the image is smaller than the canvas
    // restrict it to the canvas borders
    if (imageWidth < canvasWidth) {
      canvas.viewportTransform[4] = (canvasWidth - imageWidth) / 2;
    } else {
      // while the image is zoomed in, restrict the pan to the images left edge
      if (xAxis >= 0) {
        canvas.viewportTransform[4] = 0;
      }
      // while the image is zoomed in and not bigger than the offset of canvasWidth - imageWidth
      // restrict the pan to the images right edge
      else if (xAxis < canvasWidth - imageWidth) {
        canvas.viewportTransform[4] = canvasWidth - imageWidth;
      }
    }

    // always set the image to the top of the canvas if the image has the same height
    // as the canvas
    if (imageHeight < canvasHeight) {
      canvas.viewportTransform[5] = 0;
    } else {
      // image is zoomed in and bigger than the canvas
      // check for boundaries on the top and bottom

      // set the image to the top if the image trys to get out of bound on the top
      // and set the image to bottom (canvasHeight - imageHeight) when it gets out of bounds
      // on the bottom
      if (yAxis >= 0) {
        canvas.viewportTransform[5] = 0;
      } else if (yAxis < canvasHeight - imageHeight) {
        canvas.viewportTransform[5] = canvasHeight - imageHeight;
      }
    }
  };

  const objectMoved = useCallback(
    (event) => {
      bindObjectInCanvas(event.target, image.width, image.height);
      event.e.preventDefault();
      event.e.stopPropagation();
    },
    [image.height, image.width],
  );

  // binds the red rect to the video image so it can not go out of bounds
  const bindObjectInCanvas = (
    object,
    imageWidth: number,
    imageHeight: number,
  ) => {
    console.log("bindObjectInCanvas", { object, imageWidth, imageHeight });
    const maxWidth = imageWidth;
    const maxHeight = imageHeight;

    // set offset for moving out the canvas (1 % of object persists in canvas(image))
    const offsetWidth = object.width - 1;
    const offsetHeight = object.height - 1;

    object.setCoords();

    if (object.top < -offsetHeight || object.left < -offsetWidth) {
      object.top = Math.max(
        object.top,
        object.top - (object.top + offsetHeight),
      );
      object.left = Math.max(
        object.left,
        object.left - (object.left + offsetWidth),
      );
    }

    if (
      object.top + object.height > maxHeight + offsetHeight ||
      object.left + object.width > maxWidth + offsetWidth
    ) {
      object.top = Math.min(
        object.top,
        maxHeight - object.height + object.top - object.top + offsetHeight,
      );
      object.left = Math.min(
        object.left,
        maxWidth - object.width + object.left - object.left + offsetWidth,
      );
    }

    object.setCoords();
    object.saveState();
  };

  // event listeners register
  useEffect(() => {
    if (!canvas) return;

    canvas.on("object:moving", objectMoved);
    canvas.on("object:scaling", objectScaled);
    canvas.on("mouse:wheel", mouseWheelHandler);
    canvas.on("mouse:down", mouseDownHandler);
    canvas.on("mouse:move", mouseMoveHandler);
    canvas.on("mouse:up", mouseUpHandler);
    canvas.on("object:scaling", addCursorWhenResizing);

    return () => {
      canvas.off("object:moving", objectMoved);
      canvas.off("object:scaling", objectScaled);
      canvas.off("mouse:wheel", mouseWheelHandler);
      canvas.off("mouse:down", mouseDownHandler);
      canvas.off("mouse:move", mouseMoveHandler);
      canvas.off("mouse:up", mouseUpHandler);
      canvas.off("object:scaling", addCursorWhenResizing);
    };
  }, [
    canvas,
    image?.image,
    action,

    objectMoved,
    objectScaled,
    mouseWheelHandler,
    mouseDownHandler,
    mouseMoveHandler,
    mouseUpHandler,
    addCursorWhenResizing,
  ]);

  return [canvas, setCanvas];
}

export function usePrevious<AnyValue>(value: AnyValue): AnyValue {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: React.MutableRefObject<AnyValue> = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useChanged(value: any): boolean {
  const previousValue = usePrevious(value);
  return value !== previousValue;
}

export function useTitle(title: string) {
  React.useEffect(() => {
    document.title = title;
    return () => {
      document.title = "MoonVision";
    };
  }, [title]);
}

export function useParams(): URLSearchParams {
  const location = useLocation();
  return React.useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);
}

interface UseLoadedDataResponse<T> {
  value: T;
  count: number;
  loading: boolean;
  error: Error;
  requestCallback: (...args: unknown[]) => Promise<void>;
  setValue: (value: T | ((prevValue: T) => T)) => void;
}

export type LoadedDataCallback<T> = (
  setValue: (value: T) => void,
  setCount: (count: number) => void,
  ...args: unknown[]
) => Promise<void>;

export function useLoadedData<T>(
  initialValue: T,
  loadData: LoadedDataCallback<T>,
  callbackOnly = false,
): UseLoadedDataResponse<T> {
  const [value, setValue] = useSafeState<T>(initialValue);
  const [count, setCount] = useSafeState<number>(0);
  const [loading, setLoading] = useSafeState<boolean>(false);
  const [error, setError] = useSafeState<Error>(null);

  const requestCallback = React.useCallback(
    async (...args) => {
      setLoading(true);
      setError(null);
      try {
        await loadData(setValue, setCount, ...args);
      } catch (e) {
        console.error(e);
        setError(e);
      }
      setLoading(false);
    },
    [loadData, setCount, setError, setLoading, setValue],
  );

  React.useEffect(() => {
    if (!callbackOnly) {
      requestCallback();
    }
  }, [callbackOnly, requestCallback]);

  return { value, count, loading, error, requestCallback, setValue };
}

export function useDeepEqualRef<T>(value: T) {
  // Explanation: https://www.benmvp.com/blog/object-array-dependencies-react-useEffect-hook/
  // In short, this is a hack for using objects/arrays as hook dependencies.
  // Just specify ref.current instead of real value in list of dependencies.
  const valueRef = React.useRef<T>(value);

  if (!isDeepEqual(valueRef.current, value)) {
    valueRef.current = value;
  }

  return valueRef;
}

export function useUserCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: React.DependencyList,
): T {
  // Callback that changes on user change - but does not need user data directly
  const [user] = useUserStore();

  // React requires to add `callback` into the list of dependencies
  // But that's a function we make callback for! It is "changeable" by default.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback<T>(callback, [
    user.data?.is_staff,
    user.data?.role,
    user.data?.team_id,
    user.data?.user_id,
    ...deps,
  ]);
}

export function useOnUnmount(callback: () => void): void {
  React.useEffect(() => {
    return () => {
      callback();
    };
    // I want to execute it only on component unmount, so no deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useHistoryParamsPush() {
  const history = useHistory();
  return (params: URLSearchParams) => {
    history.push({
      search: params.toString(),
    });
  };
}

export default {
  useCanvas,
  useLoadedData,
  useSafeState,
  useParams,
  usePrevious,
  useTitle,
  useDeepEqualRef,
  useUserCallback,
};
