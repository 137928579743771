import React from "react";

import Api from "app/js/api";
import Card from "app/components/Card/Card";
import { ListPage } from "app/components/ListPage";

const RenderCard = (dataset, basePath) => {
  return (
    <Card title={dataset.name} url={`${basePath}/${dataset.id}`}>
      <p>
        Images: {dataset.frames_count || 0} <br />
        Videos: {dataset.videos_count || 0}
      </p>
    </Card>
  );
};

export default function DatasetList() {
  const loadDatasetsCallback = React.useCallback(
    async (setValue, setCount, limit, offset) => {
      const response = await Api.dataset().all({ limit, offset });
      setValue(response.data.results);
      setCount(response.data.count);
    },
    [],
  );

  return (
    <ListPage
      pageTitle={"Datasets"}
      emptyPageTitle={"No Datasets yet"}
      showCreate={true}
      loadDataCallback={loadDatasetsCallback}
      renderCard={RenderCard}
    />
  );
}
