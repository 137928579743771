import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import Api from "app/js/api";
import Loading from "app/components/Loading/Loading";

import { useSafeState } from "app/js/hooks";
import { ActionScript, Option } from "app/js/types";

interface SelectActionscriptProps {
  required?: boolean;
  value: any;
  setActionscript: (actionScript: any) => void;
}

const SelectActionscript: React.FC<SelectActionscriptProps> = ({
  required = false,
  value,
  setActionscript,
}) => {
  const [scripts, setScripts] = useSafeState<ActionScript[]>([]);
  const [loading, setLoading] = useSafeState(false);
  useEffect(() => {
    const loadScripts = async () => {
      setLoading(true);
      const response = await Api.actionscript().all({ limit: 500 });
      setScripts(response.data.results);
      setLoading(false);
    };

    loadScripts();
  }, [setLoading, setScripts]);

  let options: Option<number>[] = required
    ? []
    : [{ value: null, label: "no Actionscript" }];
  options = [
    ...options,
    ...scripts.map((entry) => ({
      label: `${entry.name} (id:${entry.id})`,
      value: entry.id,
    })),
  ];

  return (
    <Fragment>
      {loading && <Loading />}
      {!loading && scripts.length === 0 && (
        <Fragment>
          <p>
            You don't have any Actionscripts yet. You can go and create a Action
            Script in the{" "}
            <Link to={`/actionscripts`}>Actionscript Section</Link>.
          </p>
        </Fragment>
      )}
      {!loading && scripts.length > 0 && (
        <Select
          value={scripts
            .filter((entry) => entry.id === value)
            .map((entry) => ({
              value: entry.id,
              label: entry.name,
            }))}
          onChange={(e: Option<number>) => {
            const newScript = scripts.find((entry) => entry.id === e.value);
            setActionscript(newScript);
          }}
          options={options}
        />
      )}
    </Fragment>
  );
};
export default SelectActionscript;
