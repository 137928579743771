import React from "react";
import { useRouteMatch } from "react-router-dom";

import Api from "app/js/api";
import { LoadedDataCallback, useLoadedData, useTitle } from "app/js/hooks";
import { DatasetView } from "app/js/types";

import { Card } from "app/components/Card";
import { ListPage, ListPageLoadCallback } from "app/components/ListPage";
import CardList from "app/components/CardList/CardList";

const RenderCard = (datasetView, basePath) => {
  return (
    <Card title={datasetView.name} url={`${basePath}/${datasetView.id}`}>
      <p>Images: {datasetView.frames_count || 0}</p>
    </Card>
  );
};

export default function DatasetViewList(): React.ReactElement {
  useTitle("MoonVision - Dataset Views");
  const match = useRouteMatch();

  const loadOverview: LoadedDataCallback<DatasetView> = React.useCallback(
    async (setValue) => {
      const response = await Api.datasetView("overview").show();
      setValue(response.data);
    },
    [],
  );
  const { value: overview } = useLoadedData<DatasetView | null>(
    null,
    loadOverview,
  );

  const loadDatasetsCallback: ListPageLoadCallback<
    DatasetView[]
  > = React.useCallback(async (setValue, setCount, limit, offset) => {
    const response = await Api.datasetView().all({ limit, offset });
    setValue(response.data.results);
    setCount(response.data.count);
  }, []);

  return (
    <ListPage<DatasetView>
      pageTitle={"Dataset Views"}
      emptyPageTitle={"No Dataset Views yet"}
      createPath={`${match.url}/overview/edit`}
      loadDataCallback={loadDatasetsCallback}
      renderCard={RenderCard}
      additionalContent={
        <CardList style={{ padding: "25px" }}>
          <Card
            title={"Overview"}
            key={"overview"}
            url={`${match.url}/overview`}
          >
            <p>Images: {overview?.frames_count || 0}</p>
          </Card>
        </CardList>
      }
    />
  );
}
