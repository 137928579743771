import React from "react";
import { AreaFree, AreaOwner, AreaPaid } from "app/componentConstraints";

import Header from "app/components/Header/Header";
import Box from "app/components/Box/Box";
import styles from "./Home.scss";

import Notifications from "app/components/Notifications/Notifications";
import Quickstart from "./Quickstarts/QuickstartLabel";
import GetStarted from "./Quickstarts/GetStarted";
import Changelog from "./Quickstarts/Changelog";
import Tier from "./Tier";

export default function Home({ history }) {
  return (
    <div>
      <Header showCreate={false}>
        <h1>
          Moonvision Toolbox <small>Beta</small>
        </h1>
      </Header>
      <div className={styles.container}>
        <div style={{ padding: "25px" }}>
          <Notifications />
          <GetStarted />
          <Changelog />
          <AreaFree>
            <Tier
              type="free"
              title="You are currently on free tier:"
              theme="green"
            />
            <Tier type="premium" title="Upgrade to Premium:" theme="yellow" />
          </AreaFree>
          <AreaPaid>
            <Tier
              title="Thank you for being our premium customer!"
              theme="green"
            />
          </AreaPaid>
          <Box title="Support">
            <ul>
              <li>Use chat support to contact us or:</li>
              <li>
                Mail us on{" "}
                <a
                  href="mailto:office@moonvision.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  office@moonvision.io
                </a>
              </li>
            </ul>
          </Box>
        </div>
        <AreaOwner>
          <div style={{ minWidth: "480px", maxWidth: "550px" }}>
            <Quickstart history={history} />
          </div>
        </AreaOwner>
      </div>
    </div>
  );
}
