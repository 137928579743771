import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { UserState, useUserStore } from "app/js/stores";

import { isOwner, isPaid } from "./constraints";

interface ConstrainedRouteProps extends RouteProps {
  userConstraint: (user: UserState) => boolean;
}

const ConstrainedRoute: React.FC<ConstrainedRouteProps> = ({
  userConstraint,
  ...props
}) => {
  const [user] = useUserStore();
  if (!userConstraint(user)) return <div>404</div>;
  return <Route {...props} />;
};
export default ConstrainedRoute;

// Aliases for existing Routes
export const RouteOwner: React.FC<RouteProps> = (props) => {
  return <ConstrainedRoute userConstraint={isOwner} {...props} />;
};

export const RoutePaid: React.FC<RouteProps> = (props) => {
  return (
    <ConstrainedRoute
      userConstraint={(user) => isOwner(user) && isPaid(user)}
      {...props}
    />
  );
};
