import React from "react";
import moment from "moment";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import ActionBar from "app/pages/AppConfigs/ActionBar/ActionBar";
import Button from "app/components/Buttons/Button";
import Header from "app/components/Header/Header";
import Table from "app/components/Table/Table";
import { useSafeState } from "app/js/hooks";
import { Option } from "app/js/types";
import {
  useAppConfigs,
  useAppInstance,
  useConfigVersions,
} from "app/pages/AppConfigs/hooks";
import { downloadConfigResponse } from "app/pages/AppConfigs/utils";
import styles from "app/pages/AppConfigs/ConfigVersions/ConfigVersions.scss";

const ConfigVersions: React.FC<RouteComponentProps> = ({ match }) => {
  // eslint-disable-next-line tsc/config
  const appInstanceId = match.params.appInstanceId;
  const appInstance = useAppInstance(appInstanceId);
  const [configFilter, setConfigFilter] = useSafeState<Option<number> | null>(
    null,
  );
  const appConfigs = useAppConfigs(appInstance, configFilter?.label);
  const configVersions = useConfigVersions(appInstance?.application);

  const renderVersion = (versionId) =>
    versionId ? configVersions[versionId] : "";

  const versionOptions = React.useMemo(
    () =>
      Object.keys(configVersions).map((versionId) => ({
        label: configVersions[versionId],
        value: +versionId,
      })),
    [configVersions],
  );

  const onDownloadClick = React.useCallback(
    (event) => {
      const configId = event.currentTarget.dataset.configid;
      const configEntity = appConfigs.find(
        (appConfig) => `${appConfig.id}` === configId,
      );
      downloadConfigResponse(configEntity);
    },
    [appConfigs],
  );

  return (
    <>
      <Header>
        <h1>
          App Configs - {appInstance?.name} ({appInstanceId})
        </h1>
      </Header>
      <ActionBar
        versionOptions={versionOptions}
        onVersionFilterChange={setConfigFilter}
        buttons={
          <Link to="configure">
            <Button>Create Configuration</Button>
          </Link>
        }
      />
      <Table className={styles.table}>
        <thead>
          <tr>
            <th>Creation Date</th>
            <th>Version</th>
            <th>Template Hash</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {appConfigs.map((appConfig) => (
            <tr key={appConfig.id}>
              <td>{moment(appConfig.timestamp).format("LLL")}</td>
              <td>{renderVersion(appConfig.fixed_version)}</td>
              <td>{appConfig.config_template}</td>
              <td>
                <Link
                  to={{
                    pathname: "configure",
                    search: `?fromConfigId=${appConfig.id}`,
                  }}
                >
                  Configure
                </Link>
                {", "}
                <a
                  href="#"
                  onClick={onDownloadClick}
                  data-configid={appConfig.id}
                >
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ConfigVersions;
