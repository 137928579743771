import React from "react";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import NotInterestedIcon from "@mui/icons-material/NotInterested";

import Api from "app/js/api";
import { useParams, usePrevious, useSafeState } from "app/js/hooks";
import {
  Entity,
  EntitySpriteFilters,
  LabelVersionMode,
  NumericId,
  Sprite,
} from "app/js/types";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import { Error } from "app/components/ErrorMessage/types";
import Loading from "app/components/Loading/Loading";
import Paginator from "app/components/Paginator/Paginator";
import { LabelingJobForSelect } from "app/components/Selects/SelectLabellingJob";

import styles from "./LabelSprite.scss";
import { JobSelectModal } from "app/components/Modal";
import {
  getRedirectParameters,
  isLeftClick,
  serializeUrlParameters,
} from "app/js/util";

function makeRedirectUrl(
  jobId: NumericId,
  entity: Entity,
  baseRedirectUrl: string,
  redirectWithoutLabel = false,
  redirectParameters: string,
  teamId: NumericId | null,
): string {
  baseRedirectUrl = baseRedirectUrl.replace(/\/$/, "");
  const params = {
    label_version: entity.label_version.id,
    redirect: redirectWithoutLabel
      ? baseRedirectUrl
      : `${baseRedirectUrl}/${entity.label}`,
    redirect_parameters: redirectParameters,
  };
  if (teamId !== null) {
    params["team"] = teamId;
  }
  const serializedParams = serializeUrlParameters(params);
  return `/labelling-jobs/${jobId}/frame/${entity.frame}?${serializedParams}`;
}

interface EntityEntryProps {
  entity: Entity;
  markDisabled: boolean;
  showJobSelector: boolean;
  link?: string | null;
  onClick?: React.MouseEventHandler<HTMLElement> | null;
}

const EntityEntry: React.FC<EntityEntryProps> = ({
  entity,
  markDisabled,
  showJobSelector,
  link,
  onClick = null,
}) => {
  const handleClick: React.MouseEventHandler<HTMLElement> = React.useCallback(
    (event) => {
      if (showJobSelector && onClick !== null) {
        if (isLeftClick(event)) {
          event.preventDefault();
          onClick(event);
        }
      }
    },
    [onClick, showJobSelector],
  );
  const isDisabled = !entity.enabled && markDisabled;
  const className = classNames(styles.entityTile, {
    [styles.disabled]: isDisabled,
  });
  const notInterestedIcon = !isDisabled ? null : (
    <NotInterestedIcon className={styles.disabledIcon} />
  );

  return (
    <Link to={link} onClick={handleClick} className={className}>
      {notInterestedIcon}
    </Link>
  );
};

interface LabelSpriteWrapperProps {
  children: React.ReactNode;
  sprite: Sprite;
  isLoading: boolean;
}

export function LabelSpriteWrapper({
  children,
  sprite,
  isLoading,
}: LabelSpriteWrapperProps): React.ReactElement {
  // There is a trick (and reason, why we check if there is loading in progress)
  // When we loaded a sprite, but then want to request a next page, previous
  // sprite object is still accessible until the loading of the new page finishes.
  // This allows us to use its width/height and to preserve the area size until
  // the loading of a new sprite.
  const divStyle = React.useMemo(() => {
    const styleObj: Record<string, unknown> = {
      width: `${sprite.width}px`,
      height: `${sprite.height}px`,
    };
    if (!isLoading) {
      styleObj.backgroundColor = "black";
      styleObj.backgroundImage = `url('data:image/png;base64,${sprite.image}')`;
      styleObj.lineHeight = 0;
    }
    return styleObj;
  }, [isLoading, sprite.height, sprite.image, sprite.width]);
  return <div style={divStyle}>{isLoading ? <Loading /> : children}</div>;
}

const filterPreloadedJobs = (
  entity: Entity,
  preloadedJobs: LabelingJobForSelect[] | null,
): LabelingJobForSelect[] => {
  if (preloadedJobs === null) {
    return null;
  }
  // If we allow to choose from all jobs of a DatasetView, one can try to label
  // a frame with a job from another dataset. It is not that simple as it sounds
  // and requires copying a frame object, actually. Better avoid this at all.
  // I don't know frame's dataset here - only frame id. But I can assume, that
  // if a label version belongs to a certain job, then its frame belongs to a
  // dataset of this job. It could have not been labelled otherwise.
  const entityJob = preloadedJobs.find(
    (job) => job.id === entity.label_version.job,
  );
  const entityDataset: number = entityJob?.dataset;
  if (!entityDataset) {
    // That would be a very rare situation, but just in case
    // Typescript will catch missing dataset attribute - but it cannot catch an
    // entity, that does not match any job.
    console.error(`No labeling jobs to choose from for entity ${entity.id}`);
    return [];
  }
  return preloadedJobs.filter((job) => job.dataset === entityDataset);
};

interface LabelSpriteProps {
  sprite: Sprite;
  isLoading: boolean;
  alwaysShowJobSelector: boolean;
  jobId: NumericId | null;
  markDisabled: boolean;
  baseRedirectUrl: string;
  redirectWithoutLabel?: boolean;
  redirectParameters: string;
  preloadedJobs?: LabelingJobForSelect[] | null;
  jobTeamId: NumericId | null;
}

export function LabelSprite({
  sprite,
  isLoading,
  baseRedirectUrl,
  redirectWithoutLabel = false,
  redirectParameters,
  alwaysShowJobSelector,
  jobId,
  markDisabled,
  preloadedJobs = null,
  jobTeamId,
}: LabelSpriteProps): React.ReactElement {
  const [
    entityForJobSelect,
    setEntityForJobSelect,
  ] = useSafeState<Entity | null>(null);

  return (
    <React.Fragment>
      <LabelSpriteWrapper sprite={sprite} isLoading={isLoading}>
        {sprite?.entities?.map((entry) => {
          const entityJobId = jobId || entry.label_version.job;
          return (
            <EntityEntry
              key={`entity-${entry.id}`}
              entity={entry}
              markDisabled={markDisabled}
              showJobSelector={alwaysShowJobSelector || !entityJobId}
              link={makeRedirectUrl(
                entityJobId,
                entry,
                baseRedirectUrl,
                redirectWithoutLabel,
                redirectParameters,
                jobTeamId,
              )}
              onClick={() => setEntityForJobSelect(entry)}
            />
          );
        })}
      </LabelSpriteWrapper>
      {entityForJobSelect !== null && (
        <JobSelectModal
          onRemove={() => setEntityForJobSelect(null)}
          // datasetId is not needed, because jobs are preloaded
          datasetId={null}
          preloadedJobs={filterPreloadedJobs(entityForJobSelect, preloadedJobs)}
          makeEntityUrl={(jobId: NumericId, teamId: NumericId) =>
            makeRedirectUrl(
              jobId,
              entityForJobSelect,
              baseRedirectUrl,
              redirectWithoutLabel,
              redirectParameters,
              teamId,
            )
          }
          preferredJobId={entityForJobSelect.label_version.job}
        />
      )}
    </React.Fragment>
  );
}

type DynamicRequestFilters = Pick<
  EntitySpriteFilters,
  "mode" | "offset" | "label" | "label_mask"
>;
const getRequestFilters = (
  mode: LabelVersionMode,
  offset: number,
  label: string,
  searchTerm: string,
): DynamicRequestFilters => {
  const filters: DynamicRequestFilters = {
    mode,
    offset,
  };
  if (searchTerm) {
    filters.label_mask = searchTerm;
  } else {
    filters.label = label;
  }
  return filters;
};

export interface RequestableLabelSpriteProps {
  datasetViewId?: "overview" | number | null;
  datasetId?: number | null;
  alwaysShowJobSelector: boolean;
  jobId?: number | null;
  markDisabled: boolean;
  baseRedirectUrl: string;
  redirectWithoutLabel?: boolean;
  redirectParametersNames: string[];
  label: string;
  searchTerm: string;
  mode: LabelVersionMode;
  paginatorParam?: string;
  preloadedJobs?: LabelingJobForSelect[] | null;
  jobTeamId?: NumericId | null;
}

export function RequestableLabelSprite({
  datasetViewId = null,
  datasetId = null,
  alwaysShowJobSelector,
  jobId = null,
  markDisabled,
  baseRedirectUrl,
  redirectWithoutLabel = false,
  redirectParametersNames,
  label,
  searchTerm = "",
  mode,
  paginatorParam = "page",
  preloadedJobs = null,
  jobTeamId = null,
}: RequestableLabelSpriteProps): React.ReactElement {
  const history = useHistory();
  const previousLabel = usePrevious(label);
  const previousSearchTerm = usePrevious(searchTerm);
  const [loading, setLoading] = useSafeState<boolean>(false);
  const [error, setError] = useSafeState<Error | null>(null);

  const PAGE_LENGTH = 36;
  const params = useParams();
  const paramsPage = parseInt(params.get(paginatorParam)) || 1;
  const [offset, setOffset] = useSafeState<number>(
    (paramsPage - 1) * PAGE_LENGTH,
  );
  const previousOffset = usePrevious<number>(offset);
  const previousMode = usePrevious(mode);
  const [count, setCount] = useSafeState<number>(0);
  const [sprite, setSprite] = useSafeState<Sprite>(null);

  const displayLabel = searchTerm || label;
  // This combination of checks allows to prevent double rendering,
  // when we switch between filtering by search term and exact label
  const labelChanged = !!label && label !== previousLabel && !searchTerm;
  const searchTermChanged = searchTerm !== previousSearchTerm;
  const offsetChanged = offset !== previousOffset;
  const modeChanged = mode !== previousMode;
  const paramsChanged =
    labelChanged || searchTermChanged || offsetChanged || modeChanged;

  const resetPage = React.useCallback(() => {
    setOffset(0);
    params.delete(paginatorParam);
    history.replace({
      search: params.toString(),
    });
  }, [history, paginatorParam, params, setOffset]);
  React.useEffect(() => {
    if (searchTermChanged && previousSearchTerm !== undefined) {
      resetPage();
    }
  }, [previousSearchTerm, resetPage, searchTermChanged]);

  const [requestFilters, setRequestFilters] = useSafeState<
    DynamicRequestFilters
  >(getRequestFilters(mode, offset, label, searchTerm));
  const [requestFiltersChanged, setRequestFiltersChanged] = useSafeState<
    boolean
  >(true);
  React.useEffect(() => {
    if (paramsChanged) {
      setRequestFilters(getRequestFilters(mode, offset, label, searchTerm));
      setRequestFiltersChanged(true);
    }
  }, [
    label,
    mode,
    offset,
    paramsChanged,
    searchTerm,
    setRequestFilters,
    setRequestFiltersChanged,
  ]);
  // Yep, not a mistake, paramsChanged should be false. This means, that all the changes, which
  // could have happened, happened, and we are rendering with a final set of filters.
  const reloadSprites = !paramsChanged && requestFiltersChanged;
  React.useEffect(() => {
    const loadSprites = async () => {
      setLoading(true);
      setError(null);
      try {
        const filters: EntitySpriteFilters = {
          dataset_ids: datasetId !== null ? [datasetId] : null,
          job_ids: jobId !== null ? [jobId] : null,
          limit: PAGE_LENGTH,
          ...requestFilters,
        };
        const response =
          datasetViewId !== null
            ? await Api.datasetView(datasetViewId).sprite(filters)
            : await Api.entities().sprite(filters);

        if (response.data.count === 0) {
          setError({
            message: `No entities found for label "${displayLabel}" and mode "${mode}"`,
          });
        } else {
          setSprite(response.data.results);
          setCount(response.data.count);
        }
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    if (mode && reloadSprites) {
      console.log(`Request sprites for ${displayLabel}`);
      loadSprites();
    }
  }, [
    datasetViewId,
    datasetId,
    displayLabel,
    jobId,
    mode,
    reloadSprites,
    requestFilters,
    setCount,
    setError,
    setLoading,
    setSprite,
  ]);

  const requestedExtraOffset =
    count && Array.isArray(sprite) && sprite.length === 0;
  React.useEffect(() => {
    if (requestedExtraOffset) {
      resetPage();
    }
  }, [requestedExtraOffset, resetPage]);

  if (loading && !sprite) {
    return <Loading />;
  } else if (error !== null) {
    return <ErrorMessage error={error} />;
  } else if (!displayLabel) {
    return null;
  }

  const redirectParameters = getRedirectParameters(
    params,
    redirectParametersNames,
    (paramName, paramValue) => {
      if (paramName === "mode") {
        return mode;
      } else if (paramName === paginatorParam) {
        return `${offset / PAGE_LENGTH + 1}`;
      } else if (paramName === "team") {
        // For some reason sometimes (most often during integration tests) team does not present in location.search, but still can be found at window.location
        if (paramValue === null) {
          const windowParams = new URLSearchParams(window.location.search);
          paramValue = windowParams.get("team");
        }
        return paramValue;
      } else {
        return paramValue;
      }
    },
  );
  return (
    <React.Fragment>
      {sprite ? (
        <LabelSprite
          sprite={sprite}
          isLoading={loading}
          alwaysShowJobSelector={alwaysShowJobSelector}
          jobId={jobId}
          markDisabled={markDisabled}
          baseRedirectUrl={baseRedirectUrl}
          redirectWithoutLabel={redirectWithoutLabel}
          redirectParameters={redirectParameters}
          preloadedJobs={preloadedJobs}
          jobTeamId={jobTeamId}
        />
      ) : (
        <p>{`No ${mode} for the "${displayLabel}" label.`}</p>
      )}
      {count > PAGE_LENGTH && (
        <Paginator
          onChange={setOffset}
          count={count}
          offset={offset < count ? offset : 0}
          pageLength={PAGE_LENGTH}
          disabled={loading}
          queryParam={paginatorParam}
        />
      )}
    </React.Fragment>
  );
}
