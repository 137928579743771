import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { useParams, usePrevious, useSafeState, useTitle } from "app/js/hooks";
import {
  Dataset,
  DatasetView,
  LabelingJob,
  LabelVersionMode,
} from "app/js/types";

import { LabelSearchBar } from "app/components/SearchBar";
import LabellingMenu, {
  DatasetViewLabelVersionFilters,
} from "app/components/Menus/LabellingMenu/LabellingMenu";

import EntitySpritesContent from "./EntitySpritesContent";
import styles from "./EntitySpritesPage.scss";
import { ObjectType } from "./types";
import { useVisualSampleSet } from "app/pages/LabellingJobs/LabellingJobDetail/LabellingJobFilter/hooks";

const SEARCH_PARAM = "search";
const SEARCH_TRIGGERED_PARAM = "search_triggered";

interface BaseEntitySpritesPageProps {
  baseTitle: string;
  baseUrl: string;
  object: LabelingJob | Dataset | DatasetView;
  objectType: ObjectType;
  selectableMode?: boolean;
  jobId?: number | null;
  showRequestedBy?: boolean;
}

interface LabelingJobEntitySpritesPageProps extends BaseEntitySpritesPageProps {
  objectType: "labeling_job";
  object: LabelingJob;
}

interface DatasetEntitySpritesPageProps extends BaseEntitySpritesPageProps {
  objectType: "dataset";
  object: Dataset;
}

interface DatasetViewEntitySpritesPageProps extends BaseEntitySpritesPageProps {
  objectType: "dataset_view";
  object: DatasetView;
}

type EntitySpritesPageProps =
  | LabelingJobEntitySpritesPageProps
  | DatasetEntitySpritesPageProps
  | DatasetViewEntitySpritesPageProps;

export default function EntitySpritesPage({
  baseTitle,
  baseUrl,
  object,
  objectType,
  selectableMode = false,
  jobId = null,
}: EntitySpritesPageProps): React.ReactElement {
  const history = useHistory();
  const match = useRouteMatch<{ label?: string }>();
  const label = match.params?.label || "";

  const params = useParams();
  const modeParam = params.get("mode") as LabelVersionMode;
  const mode = modeParam || "user_latest";
  const paramsSearch = params.get(SEARCH_PARAM) || "";
  const paramsSearchTriggered =
    (params.get(SEARCH_TRIGGERED_PARAM) || "true") === "true";

  const [searchTerm, setSearchTerm] = useSafeState<string>(paramsSearch);
  const previousSearchTerm = usePrevious<string>(searchTerm);

  const displayLabel = searchTerm || label;
  const titleLabel = displayLabel ? `"${displayLabel}" ` : "";
  useTitle(`${baseTitle} - ${titleLabel}Entities`);

  const searchTriggered = paramsSearch !== "" && paramsSearchTriggered;
  const setSearchTriggered = React.useCallback(
    (value: boolean) => {
      params.set(SEARCH_TRIGGERED_PARAM, `${value}`);
      history.replace({
        search: params.toString(),
      });
    },
    [history, params],
  );

  const showRequestedBy = objectType === "labeling_job";
  const visualSampleSetStore = useVisualSampleSet();
  const requestedLabels = showRequestedBy
    ? visualSampleSetStore?.visualSampleSet?.labels
    : null;
  const job = objectType === "labeling_job" ? (object as LabelingJob) : null;
  const requestedByProps = showRequestedBy
    ? {
        onChange: visualSampleSetStore.setQuery,
        dataset: job.dataset,
        indexer: job.indexer,
        labeling_job: job.id,
      }
    : null;
  const datasetView =
    objectType === "dataset_view" ? (object as DatasetView) : null;
  const labelingJobs = datasetView ? datasetView.labeling_jobs : null;

  const setMode = React.useCallback(
    (mode) => {
      params.set("mode", mode);
      history.replace({
        search: params.toString(),
      });
    },
    [history, params],
  );
  useEffect(() => {
    if (modeParam === null && selectableMode) {
      setMode("user_latest");
    }
  }, [modeParam, selectableMode, setMode]);

  const objectId = object.id;
  const labelFilters = React.useMemo<DatasetViewLabelVersionFilters>(() => {
    const filters: DatasetViewLabelVersionFilters = { mode };
    switch (objectType) {
      case "dataset_view":
        filters.datasetViewId = objectId || "overview";
        break;
      case "dataset":
        filters.dataset_ids = [objectId];
        break;
      case "labeling_job":
        filters.job_ids = [objectId];
        break;
      default:
        break;
    }
    return filters;
  }, [mode, objectId, objectType]);

  const renderSprites = !!(
    label ||
    searchTriggered ||
    searchTerm === previousSearchTerm
  );
  return (
    <React.Fragment>
      <LabelSearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchTriggered={searchTriggered}
        setSearchTriggered={setSearchTriggered}
        showRequestedBy={showRequestedBy}
        requestedByProps={requestedByProps}
      />
      <div className={styles.container}>
        <LabellingMenu
          baseUrl={baseUrl}
          labelFilters={labelFilters}
          className={styles.menu}
          labelSearchTerm={searchTerm}
          mode={selectableMode ? modeParam : null}
          setMode={setMode}
          jobId={jobId}
          requestedLabels={requestedLabels}
        />
        <div className={styles.content}>
          {displayLabel ? (
            renderSprites ? (
              <EntitySpritesContent
                label={label}
                searchTerm={searchTriggered ? searchTerm : ""}
                objectId={objectId}
                objectType={objectType}
                jobs={labelingJobs}
                baseRedirectUrl={baseUrl}
                redirectWithoutLabel={label === ""}
                mode={mode}
              />
            ) : (
              <React.Fragment>
                Click on "Search" button or press Enter to request sprites for
                the search term
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              Select a label to display Entities for it
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
