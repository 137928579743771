import React from "react";

import styles from "./Media.scss";

import Image, { CanvasImageObject } from "app/components/Image/Image";
import { Frame, Image as ImageType, LabelVersion } from "app/js/types";

export interface CardVideoProps {
  source: string;
}

export function CardVideo({ source }: CardVideoProps): React.ReactElement {
  return (
    <video className={styles.video} controls>
      <source src={source} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}

interface CardImageFrame extends Pick<Frame, "entities"> {
  image: Pick<ImageType, "image" | "width" | "height">;
}

interface BaseCardImageProps {
  // The idea behind these 3 props is the following: if you want to draw entities,
  // you pass either frame or label version, because you need several attributes from them.
  // If you don't need entities, just pass image source as a string, even if you have
  // frame/label version object in parent component - because why would you pass the whole object in this case?
  frame?: CardImageFrame | null;
  labelVersion?: LabelVersion | null;
  source?: string | null;
  withWrapper?: boolean;
}

interface FrameCardImageProps extends BaseCardImageProps {
  frame: CardImageFrame;
  labelVersion?: null;
  source?: null;
}

interface LabelVersionCardImageProps extends BaseCardImageProps {
  frame?: null;
  labelVersion: LabelVersion;
  source?: null;
}

interface SourceCardImageProps extends BaseCardImageProps {
  frame?: null;
  labelVersion?: null;
  source: string;
}

export type CardImageProps =
  | FrameCardImageProps
  | LabelVersionCardImageProps
  | SourceCardImageProps;

export function CardImage({
  frame = null,
  labelVersion = null,
  source = null,
  withWrapper,
}: CardImageProps): React.ReactElement {
  let src: string;
  let image: CanvasImageObject;
  if (frame !== null) {
    src = frame.image.image;
    image = {
      image: frame.image.image,
      width: frame.image.width,
      height: frame.image.height,
      entities: frame.entities,
    };
  } else if (labelVersion !== null) {
    src = labelVersion.frame.image_path;
    image = {
      image: labelVersion.frame.image_path,
      width: labelVersion.frame.image_width,
      height: labelVersion.frame.image_height,
      entities: labelVersion.entities,
    };
  } else if (source !== null) {
    src = source;
    image = null;
  }
  return (
    <Image src={src} alt={src} canvasImage={image} withWrapper={withWrapper} />
  );
}
