import React from "react";
import classNames from "classnames";

const NavPillsSelect = (props) => {
  const { id, options, value, onChange, onBlur, onFocus } = props;
  const onClick = React.useCallback(
    (event) => {
      onChange(+event.target.dataset.value);
    },
    [onChange],
  );

  if (!options?.enumOptions) {
    return null;
  }

  return (
    <ul className="nav nav-pills" id={id} onBlur={onBlur} onFocus={onFocus}>
      {options.enumOptions.map(({ label, value: _value }) => (
        <li
          role="button"
          className={classNames({
            active: `${value}` === `${_value}`,
          })}
          key={`${label} ${value}`}
        >
          <a onClick={onClick} data-value={_value}>
            {label}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default NavPillsSelect;
