import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";

import AreaPaid from "app/componentConstraints/AreaPaid";
import Submenu from "app/components/Menus/Submenu/Submenu";
import DatasetDetailVideos from "./DatasetDetailVideos";
import DatasetDetailVideosAdd from "./DatasetDetailVideosAdd";
import DatasetDetailVideosUpload from "./DatasetDetailVideosUpload";

export default function Routes({ match, dataset, setDataset }) {
  const datasetId = match.params.id;
  return (
    <div
      style={{
        display: "grid",
        gridTemplateAreas: '"submenu" "content"',
        gridTemplateRows: "min-content auto",
        height: "100%",
      }}
    >
      <AreaPaid>
        <Submenu>
          <li>
            <h2>Videos</h2>
          </li>
          <li style={{ marginLeft: "auto" }}>
            <NavLink
              to={`/data-sets/${datasetId}/videos/add`}
              activeClassName="active"
            >
              Add existing Videos
            </NavLink>
          </li>
          <li style={{ marginLeft: "auto" }}>
            <NavLink
              to={`/data-sets/${datasetId}/videos/upload`}
              activeClassName="active"
            >
              Upload Video
            </NavLink>
          </li>
        </Submenu>
      </AreaPaid>
      <Switch>
        <Route path={`${match.path}`} exact component={DatasetDetailVideos} />
        <Route
          path={`${match.path}/add`}
          exact
          component={DatasetDetailVideosAdd}
        />
        <Route
          path={`${match.path}/upload`}
          exact
          render={(props) => (
            <DatasetDetailVideosUpload
              {...props}
              dataset={dataset}
              setDataset={setDataset}
            />
          )}
        />
      </Switch>
    </div>
  );
}
