import React from "react";
import Cookies from "js-cookie";

import styles from "./PageSizeChanger.scss";

const PAGE_LENGTH = 10;

export const readPageLengthCookie = (
  cookieName: string,
  defaultValue: number = PAGE_LENGTH,
) => {
  const pageLengthCookie = Cookies.get(cookieName);
  return pageLengthCookie ? parseInt(pageLengthCookie) : defaultValue;
};

interface PageSizeChangerProps {
  pageLength: number;
  setPageLength: (number) => void;
  count: number;
  cookieName: string;
  defaultPageLength?: number;
}

/**
 * Add buttons to change a size of a page for card list.
 *
 * Component goes with a helper function `readPageLengthCookie` to read the
 * initial value. They cannot be combined, because the page size variable will
 * most likely be needed outside of the component.
 *
 * Usage:
 * ```jsx
 * import PageSizeChanger, { readPageLengthCookie } from "app/components/PageSizeChanger/PageSizeChanger";
 *
 * const PAGE_LENGTH_COOKIE = "<a name of your cookie>";
 * const [pageLength, setPageLength] = useSafeState<number>(readPageLengthCookie(PAGE_LENGTH_COOKIE));
 *
 * return (
 *   <PageSizeChanger
 *     pageLength={pageLength}
 *     setPageLength={setPageLength}
 *     count={<state var for the total number of items>}
 *     cookieName={PAGE_LENGTH_COOKIE}
 *   />
 * );
 * ```
 */
const PageSizeChanger: React.FC<PageSizeChangerProps> = ({
  pageLength,
  setPageLength,
  count,
  cookieName,
  defaultPageLength = PAGE_LENGTH,
}) => {
  const savePageLength = React.useCallback(
    (value: number) => {
      Cookies.set(cookieName, `${value}`, { expires: 365 });
      setPageLength(value);
    },
    [cookieName, setPageLength],
  );

  const handlePageLengthIncrease = () => {
    savePageLength(pageLength + defaultPageLength);
  };
  const handlePageLengthDecrease = () => {
    savePageLength(Math.max(pageLength - defaultPageLength, defaultPageLength));
  };

  return (
    <React.Fragment>
      {pageLength > defaultPageLength && count > defaultPageLength && (
        <div
          className={styles.pageSizeChange}
          onClick={handlePageLengthDecrease}
        >
          &#9650;
        </div>
      )}
      {pageLength < count && (
        <div
          className={styles.pageSizeChange}
          onClick={handlePageLengthIncrease}
        >
          &#9660;
        </div>
      )}
    </React.Fragment>
  );
};

export default PageSizeChanger;
