import React, { useEffect, useRef, Fragment } from "react";
import classNames from "classnames";
import styles from "./TaskStatus.scss";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import Api from "app/js/api";

import { useSafeState } from "app/js/hooks";
import { DaskTask } from "app/js/types";

interface TaskStatusProps {
  // The second object may come from ProcessingTasks
  rawTask: DaskTask | { status: null | undefined };
  statusUpdate: (task: DaskTask) => void;
  handleError?: () => void;
}

const TaskStatus: React.FC<TaskStatusProps> = ({
  rawTask,
  statusUpdate,
  handleError = null,
}) => {
  const [task, setTask] = useSafeState<DaskTask>(null);

  const [messages, setMessages] = useSafeState<string>("");
  const [loading, setLoading] = useSafeState(false);
  const [error, setError] = useSafeState(null);
  const stopLoading = useRef(false);

  useEffect(() => {
    if (
      rawTask !== null &&
      rawTask !== undefined &&
      rawTask.status !== null &&
      rawTask.status !== undefined
    ) {
      stopLoading.current = false;
      setTask(rawTask as DaskTask);
    }
    return () => {
      stopLoading.current = true;
    };
  }, [rawTask, setTask]);

  const loadTask = React.useCallback(
    async (id) => {
      setLoading(true);

      if (stopLoading.current === true) {
        return;
      }

      try {
        if (id) {
          const response = await Api.task(id).show();
          const data = response.data;
          setTask(data);

          switch (data.status) {
            case "pending":
              setMessages(`Task pending`);
              setTimeout(() => {
                loadTask(id);
              }, 1000);
              break;

            case "saving":
              setMessages(`Task saving`);
              setTimeout(() => {
                loadTask(id);
              }, 1000);
              break;

            case "finished":
              if (data.result == null) {
                setMessages(`Task saving`);
                setTimeout(() => {
                  loadTask(id);
                }, 1000);
              }
              setMessages("Task finished");
              statusUpdate(data);
              setLoading(false);
              break;

            case "error":
              setMessages(`Task ${id} ERROR`);
              console.error(data.result);
              setError(data.result);
              setLoading(false);
              handleError && handleError();
              break;

            default:
              break;
          }
        }
        setError(null);
      } catch (error) {
        setError(error);
        setMessages(`Task ${id} ERROR`);
        setLoading(false);
        handleError && handleError();
      }
    },
    [handleError, setError, setLoading, setMessages, setTask, statusUpdate],
  );

  const taskExists = task != null;
  useEffect(() => {
    // no task there yet
    if (!taskExists) {
      return;
    }
    loadTask(task.task_key);
  }, [taskExists, task?.task_key, loadTask]);

  if (!messages && !loading) {
    return <Fragment />;
  }

  const messageClass = classNames({
    [styles.loading]: loading,
  });
  return (
    <div className={styles.container}>
      <h3>Task Status</h3>
      {error && <ErrorMessage error={error} />}
      {!!messages && <p className={messageClass}>{messages}</p>}
      {loading && <Loading color="white" />}
    </div>
  );
};

export default TaskStatus;
