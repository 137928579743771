import React, { useEffect, Fragment } from "react";
import moment from "moment";
import Select from "react-select";

import Api from "app/js/api";
import { Card, CardDetails } from "app/components/Card";
import CardList from "app/components/CardList/CardList";
import FormComponent from "app/components/FormComponent/FormComponent";
import ImageModal from "app/components/Modal/ImageModal";
import Paginator from "app/components/Paginator/Paginator";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

import { useParams, useSafeState, useUserCallback } from "app/js/hooks";
import { FrameFilters, Frame } from "app/js/types";
import { CardImage, CardTrashButton } from "app/components/Card";

export default function StreamImages({ match, history }) {
  const params = useParams();
  const streamId = match.params.id;
  const [loading, setLoading] = useSafeState(false);
  const PAGE_LENGTH = 10;
  const [offset, setOffset] = useSafeState(0);
  const [count, setCount] = useSafeState(0);
  const [error, setError] = useSafeState(null);

  const [labels, setLabels] = useSafeState([]);
  const [labelOptions, setLabelOptions] = useSafeState([]);
  const [loadingLabelOptions, setLoadingLabelOptions] = useSafeState<boolean>(
    false,
  );
  const [images, setImages] = useSafeState<Frame[]>([]);
  const [bigImg, setBigImg] = useSafeState(undefined);

  useEffect(() => {
    const loadLabelOptions = async () => {
      setLoadingLabelOptions(true);

      try {
        const response = await Api.labelversion().labels({
          stream_id: streamId,
          mode: "auto",
        });
        setLabelOptions(
          Object.keys(response.data.results).map((l) => ({
            label: l,
            value: l,
          })),
        );
      } catch (error) {
        setError(error);
      }

      setLoadingLabelOptions(false);
    };

    loadLabelOptions();
  }, [setError, setLabelOptions, setLoadingLabelOptions, streamId]);

  const loadImages = useUserCallback(async () => {
    setLoading(true);

    const labelsList = labels.map((l) => l.value);
    try {
      const filters: FrameFilters = {
        created_before: params.get("before") || null,
        created_after: params.get("after") || null,
        stream_id: streamId,
        limit: PAGE_LENGTH,
        offset,
      };
      if (labelsList.length > 0) {
        filters.or_labels = labelsList;
      }
      const response = await Api.frame().all(filters);
      setImages(response.data.results);
      setCount(response.data.count);
      setError(null);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [
    labels,
    offset,
    params,
    setCount,
    setError,
    setImages,
    setLoading,
    streamId,
  ]);

  useEffect(() => {
    loadImages();
  }, [loadImages]);

  const deleteFrame = async (frameId) => {
    const deleteConfirmation = confirm(
      "Are you sure you want to delete this frame? All entities from the frame will also be deleted.",
    );
    if (!deleteConfirmation) {
      return;
    }

    try {
      await Api.frame(frameId).destroy();
      setError(null);
    } catch (error) {
      setError(error);
      return;
    }
    loadImages();
  };

  const onRangeUpdate = (newRange) => {
    if (newRange.before && newRange.before !== "") {
      params.set("before", moment(newRange.before).format());
    } else {
      params.delete("before");
    }
    if (newRange.after && newRange.after !== "") {
      params.set("after", moment(newRange.after).format());
    } else {
      params.delete("after");
    }
    history.push({
      location,
      search: params.toString(),
    });
  };

  return (
    <div style={{ padding: "25px" }}>
      <FormComponent>
        <label>Created after:</label>
        <input
          type="datetime-local"
          value={
            params.get("after")
              ? moment(params.get("after")).format(
                  moment.HTML5_FMT.DATETIME_LOCAL,
                )
              : ""
          }
          onChange={(e) => {
            onRangeUpdate({ after: e.target.value });
          }}
        />

        <label>Created before:</label>
        <input
          type="datetime-local"
          value={
            params.get("before")
              ? moment(params.get("before")).format(
                  moment.HTML5_FMT.DATETIME_LOCAL,
                )
              : ""
          }
          onChange={(e) => {
            onRangeUpdate({ before: e.target.value });
          }}
        />
        {loadingLabelOptions ? (
          <Loading />
        ) : (
          <Fragment>
            <label>Detection Labels (OR):</label>
            <Select
              value={labels}
              onChange={setLabels}
              options={labelOptions}
              isMulti
            />
          </Fragment>
        )}
      </FormComponent>
      {loading && <Loading />}
      {error && <ErrorMessage error={error} />}
      {!loading && images.length > 0 ? (
        <Fragment>
          {count > PAGE_LENGTH && (
            <Paginator
              onChange={setOffset}
              count={count}
              offset={offset}
              pageLength={PAGE_LENGTH}
              disabled={loading}
            />
          )}
          <CardList>
            {images.map((frame) => (
              <Card
                key={frame.id}
                media={<CardImage source={frame.image.image} />}
                onClick={() => {
                  setBigImg(frame.image.image);
                }}
                hoverButtons={
                  <CardTrashButton
                    onClick={() => {
                      deleteFrame(frame.id);
                    }}
                  />
                }
              >
                <CardDetails>
                  Uploaded: {moment(frame.create_time).format("LLL")}
                </CardDetails>
              </Card>
            ))}
          </CardList>
          <ImageModal img={bigImg} setImg={setBigImg} />
        </Fragment>
      ) : (
        !loading && <Fragment>No Images in Camera</Fragment>
      )}
    </div>
  );
}
