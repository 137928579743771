import React from "react";
import { createStore, useStore } from "react-hookstore";
import decode from "jwt-decode";
import { Action as HistoryAction, History, Location } from "history";
import moment from "moment";

import Api from "app/js/api";
import { StoreTeam, StoreUser } from "app/js/types";

interface State<T> {
  loading: boolean;
  errors: Error | null;
  data: T | null;
}

interface Action {
  type: string;
  [key: string]: any;
}

// TODO: Make just an `Action` by updating user and team stores
interface StandardAction {
  type: string;
  error?: boolean | null | undefined;
  payload?: unknown;
  meta?: unknown;
}

// User Store
export type UserState = State<StoreUser>;

const userReducer = (state: UserState, action: Action): UserState => {
  let decodedToken = null;
  switch (action.type) {
    case "NO_TOKEN":
      return {
        loading: false,
        errors: null,
        data: null,
      };
    case "SET_TOKEN":
      try {
        decodedToken = decode(action.token) as StoreUser;
      } catch (error) {
        return {
          loading: false,
          errors: error,
          data: null,
        };
      }
      const teamTokenCorrect = onLocationOrUserChange(
        history.location,
        action,
        decodedToken,
      );
      if (window.matomo) {
        window.matomo.push(["setUserId", decodedToken.email]);
        window.matomo.push(["trackPageView"]);
      }
      if (!teamTokenCorrect) {
        // Team is not correct, token will be refreshed for the team and then set
        return {
          ...state,
          data: { ...state.data },
        };
      }
      Api.saveToken(action.token);
      teamActions.loadTeams();
      return {
        loading: false,
        errors: null,
        data: decodedToken,
      };
    case "REQUEST_CHANGE_TEAM":
      return {
        loading: true,
        errors: null,
        data: { ...state.data },
      };
    case "RECEIVE_CHANGE_TEAM_ERROR":
      return {
        loading: false,
        errors: action.error,
        data: { ...state.data },
      };
    case "RECEIVE_CHANGE_TEAM":
      try {
        decodedToken = decode(action.response.data.token) as StoreUser;
      } catch (error) {
        return {
          loading: false,
          errors: error,
          data: null,
        };
      }
      Api.saveToken(action.response.data.token);
      onLocationOrUserChange(history.location, action, decodedToken);
      teamActions.loadTeams();
      return {
        loading: false,
        errors: null,
        data: decodedToken,
      };
    case "LOGOUT":
      Api.removeToken();
      if (window.matomo) {
        window.matomo.push(["resetUserId"]);
        window.matomo.push(["trackPageView"]);
      }
      return {
        loading: false,
        errors: null,
        data: null,
      };
    default:
      return {
        ...state,
        data: { ...state.data },
      };
  }
};

const userStore = createStore(
  "user",
  { data: null, loading: true, errors: null },
  userReducer,
);
let history: History = null;

const onLocationOrUserChange = function (
  location: Location,
  action: Action | HistoryAction,
  user: StoreUser = null,
) {
  if (user === null) {
    user = userStore.getState().data;
  }

  const params = new URLSearchParams(location.search);
  const paramsTeamId = !params.get("team")
    ? null
    : parseInt(params.get("team"));
  if (
    user &&
    (!paramsTeamId || paramsTeamId !== user.team_id) &&
    user.team_id
  ) {
    if (
      paramsTeamId &&
      paramsTeamId !== user.team_id &&
      (typeof action === "string" || action.type !== "RECEIVE_CHANGE_TEAM")
    ) {
      // User specified desired team in URL, set team to be that
      userActions.setTeam(paramsTeamId);
      return false;
    } else {
      // Set team on every page
      params.set("team", String(user.team_id));
      history.replace({
        search: params.toString(),
      });
      return true;
    }
  }
  return true;
};

const userActions = {
  listenForTeamChanges(h: History) {
    history = h;
    history.listen(onLocationOrUserChange);
  },
  async loadTokenIfPresentAndRefresh() {
    const token = Api.getToken();

    if (token == null) {
      userStore.dispatch({
        type: "NO_TOKEN",
      });
      return;
    }

    // check if token expired
    const decodedToken = decode(token as string) as StoreUser;
    if (moment(decodedToken.exp * 1000) < moment()) {
      Api.removeToken();
      userStore.dispatch({
        type: "NO_TOKEN",
      });
      return;
    }

    // load initial token
    userStore.dispatch({
      type: "SET_TOKEN",
      token,
    });

    // refresh token, need a way to know team to switch
    /*
    const response = await Api.user().refreshToken(decodedToken.team_id);
    userStore.dispatch({
      type: 'SET_TOKEN',
      token: response.data.token,
    })
    */
  },
  async setToken(token: string) {
    userStore.dispatch({
      type: "SET_TOKEN",
      token,
    });
  },
  async logout() {
    userStore.dispatch({
      type: "LOGOUT",
    });
  },
  async setTeam(id: number) {
    userStore.dispatch({
      type: "REQUEST_CHANGE_TEAM",
    });
    try {
      const response = await Api.team(id).switch();
      userStore.dispatch({
        type: "RECEIVE_CHANGE_TEAM",
        response,
      });
    } catch (error) {
      userStore.dispatch({
        type: "RECEIVE_CHANGE_TEAM_ERROR",
        error,
      });
    }
  },
};

// Without an explicit return type typescript derives it as a union in places,
// which use this function
export function useUserStore(): [UserState, typeof userActions] {
  const [state] = useStore(userStore);
  return [state, userActions];
}

// Teams Store
type TeamState = State<StoreTeam[]>;

const teamReducer = (state: TeamState, action: Action): TeamState => {
  switch (action.type) {
    case "REQUEST_TEAMS":
      return {
        loading: true,
        errors: null,
        data: [...state.data],
      };
    case "RECEIVE_TEAMS_ERROR":
      return {
        loading: false,
        errors: action.error,
        data: [],
      };
    case "RECEIVE_TEAMS":
      const teams = action.response.data.results;
      return {
        loading: false,
        errors: null,
        data: teams,
      };
    default:
      return {
        ...state,
        data: [...state.data],
      };
  }
};

const teamStore = createStore(
  "teams",
  { data: [], loading: false, errors: null },
  teamReducer,
);

const teamActions = {
  async loadTeams() {
    teamStore.dispatch({
      type: "REQUEST_TEAMS",
    });
    try {
      const response = await Api.team().all();
      teamStore.dispatch({
        type: "RECEIVE_TEAMS",
        response,
      });
    } catch (error) {
      teamStore.dispatch({
        type: "RECEIVE_TEAMS_ERROR",
        error,
      });
    }
  },
};

// Without an explicit return type typescript derives it as a union in places,
// which use this function
export function useTeamStore(): [TeamState, typeof teamActions] {
  const [state] = useStore(teamStore);
  return [state, teamActions];
}

// Form store
export interface GenericForm {
  [field: string]: unknown;
}
interface FormState {
  [formName: string]: GenericForm;
}

interface FormAction extends StandardAction {
  payload?: Record<string, any>;
  meta: {
    formName: string;
  };
}

const formReducer = (state: FormState, action: FormAction): FormState => {
  const formName = action.meta.formName;
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [formName]: {
          ...state[formName],
          [action.payload.fieldName]: action.payload.value,
        },
      };
    case "CLEAN_FIELD":
      const newFormData = { ...state[formName] };
      delete newFormData[action.payload.fieldName];
      return {
        ...state,
        [formName]: newFormData,
      };
    case "CLEAN_FORM":
      return {
        ...state,
        [formName]: {},
      };
    default:
      return { ...state };
  }
};

const formStore = createStore("forms", {}, formReducer);

interface FormActions {
  setField: (name: string, value: unknown) => void;
  cleanField: (name: string) => void;
  cleanForm: () => void;
}
const useFormActions = (formName: string): FormActions => {
  const setField = React.useCallback(
    (name, value) => {
      formStore.dispatch({
        type: "SET_FIELD",
        payload: {
          fieldName: name,
          value,
        },
        meta: {
          formName,
        },
      });
    },
    [formName],
  );
  const cleanField = React.useCallback(
    (name) => {
      formStore.dispatch({
        type: "CLEAN_FIELD",
        payload: {
          fieldName: name,
        },
        meta: {
          formName,
        },
      });
    },
    [formName],
  );
  const cleanForm = React.useCallback(() => {
    formStore.dispatch({
      type: "CLEAN_FORM",
      meta: {
        formName,
      },
    });
  }, [formName]);
  return {
    setField,
    cleanField,
    cleanForm,
  };
};

// Without an explicit return type typescript derives it as a union in places,
// which use this function
export function useFormStore<FormData extends GenericForm>(
  formName: string,
): [FormData, FormActions] {
  const [state] = useStore(formStore);
  const actions = useFormActions(formName);
  // Maybe, it will be better to explicitly define all form types instead of generic objects
  // But for now it is how it is
  return [state[formName] as FormData, actions];
}
