import React, { useEffect, Fragment, useCallback } from "react";
import { fabric } from "fabric";
import { generateColorProperties } from "app/pages/LabellingJobs/Labelling/helper/colors";
import { exportObject } from "app/pages/LabellingJobs/Labelling/helper/helper";

import { useSafeState } from "app/js/hooks";

const finalizePolygon = ({
  id,
  activeLine,
  canvas,
  lineArray,
  pointArray,
  setActiveLine,
}) => {
  // get all the points
  const points = [];
  pointArray.forEach((point) => {
    points.push({
      x: point.left,
      y: point.top,
    });
    // remove them
    canvas.remove(point);
  });
  // get all the Lines
  lineArray.forEach((line) => {
    // remove every line
    canvas.remove(line);
  });

  // remove the current Polygon + Line
  canvas.remove(canvas.getActiveObject());
  canvas.remove(activeLine);
  setActiveLine(null);

  // create fresh Polygon
  const polygon = new fabric.Polygon(points, {
    data: { id },
    hasBorders: false,
    hasControls: false,
    type: "Polygon",
    left: 0,
    top: 0,
    ...generateColorProperties("", 0.4, canvas.getZoom()),
  });
  canvas.add(polygon);
  canvas.setActiveObject(polygon);
  canvas.renderAll();
};

export default function CreatePolygon({ canvas, createdAction }) {
  // input state from user
  const [_started, setStarted] = useSafeState(false);
  const [_mouse, setMouse] = useSafeState({ x: undefined, y: undefined });

  // state of polygon that gets built
  const [pointArray, setPointArray] = useSafeState<fabric.Circle[]>([]);
  const [lineArray, setLineArray] = useSafeState<fabric.Line[]>([]);
  const [activeLine, setActiveLine] = useSafeState<fabric.Line>(null);

  const addPoint = useCallback(
    (options) => {
      // we need an ID to identify the first point
      const random = Math.floor(Math.random() * (9999999 - 99 + 1)) + 99;
      const id = new Date().getTime() + random;

      const { x, y } = canvas.getPointer(options.e);

      // create the point object
      const circle = new fabric.Circle({
        fill: "rgba(0, 0, 0, 0.5)",
        radius: 5 / canvas.getZoom(),
        stroke: "#333333",
        strokeWidth: 3 / canvas.getZoom(),
        left: x,
        top: y,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: "center",
        originY: "center",
        data: {
          id,
          customType: "helper",
        },
        objectCaching: false,
      });

      // We are just getting STARTED
      if (pointArray.length === 0) {
        circle.set({
          fill: "red",
        });

        // make sure you can't select any other object
        canvas.forEachObject((object) => {
          object.set({
            selectable: false,
            lockMovementX: true,
            lockMovementY: true,
            lockRotation: true,
          });
        });
      }

      // first draft of active line
      const points = [x, y, x, y];
      const line = new fabric.Line(points, {
        strokeWidth: 3 / canvas.getZoom(),
        fill: "#999999",
        stroke: "#999999",
        data: {
          class: "line",
          customType: "helper",
        },
        originX: "center",
        originY: "center",
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      });

      const polyPoint = [
        {
          x: options.e.layerX,
          y: options.e.layerY,
        },
      ];
      const polygon = new fabric.Polygon(polyPoint, {
        data: {
          customType: "helper",
          id: `new_${(Math.random() + 1).toString(36).substring(7)}`,
        },
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
        ...generateColorProperties("", 0.4, canvas.getZoom()),
      });

      // add polygon
      canvas.add(polygon);
      canvas.setActiveObject(polygon);
      setActiveLine(line);
      setPointArray([...pointArray, circle]);
      setLineArray([...lineArray, line]);

      canvas.add(circle);
      if (pointArray.length !== 0) {
        // keep first circle in front so that it can be clicked and polygon finished
        circle.sendToBack();
      }
      canvas.add(line);
    },
    [canvas, lineArray, pointArray, setActiveLine, setLineArray, setPointArray],
  );

  const mouseDown = useCallback(
    (e) => {
      // lets go
      setStarted(true);

      // get the mouse from this point of origin
      const currentMouse = canvas.getPointer(e);
      // set the origin point for polygon
      setMouse({ x: currentMouse.x, y: currentMouse.y });

      const targetId = e.target?.data?.id;
      const firstPointId = pointArray?.[0]?.data?.id;
      if (firstPointId && targetId && targetId === firstPointId) {
        // THE END
        finalizePolygon({
          id: firstPointId,
          activeLine,
          canvas,
          lineArray,
          pointArray,
          setActiveLine,
        });

        const activeObject = canvas.getActiveObject();

        // clean up
        setStarted(false);

        // return the created object
        createdAction(exportObject(activeObject));
      } else {
        // lets add a point if not finished
        addPoint(e);
      }
    },
    [
      activeLine,
      addPoint,
      canvas,
      createdAction,
      lineArray,
      pointArray,
      setActiveLine,
      setMouse,
      setStarted,
    ],
  );

  const mouseMove = useCallback(
    (e) => {
      const currentMouse = canvas.getPointer(e);

      // set the line that follows
      if (activeLine && activeLine.data?.class === "line") {
        activeLine.set({
          x2: currentMouse.x,
          y2: currentMouse.y,
        });
      }

      // calculate the length of the active line
      const activeLineLength = (activeLine) => {
        if (activeLine) {
          return Math.round(
            Math.sqrt(
              Math.pow(activeLine.x2 - activeLine.x1, 2) +
                Math.pow(activeLine.y2 - activeLine.y1, 2),
            ),
          );
        } else {
          return 0;
        }
      };

      const cursor = new fabric.Text(`l: ${activeLineLength(activeLine)}`, {
        data: { id: "cursorText" },
        fontFamily: "sans-serif",
        fontSize: 36 / canvas.getZoom(),
        fill: "#ffffff",
        selectable: false,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        textBackgroundColor: "#5bc0eb",
        left: currentMouse.x,
        top: currentMouse.y,
      });
      // remove previous cursor
      canvas.remove(
        canvas._objects.find((obj) => obj.data?.id === "cursorText"),
      );
      // add new cursor with updated values
      canvas.add(cursor);
      canvas.renderAll();
    },
    [activeLine, canvas],
  );

  const indicatePolygonClosing = useCallback(
    (e, mouseOver) => {
      // pointArray.length > 1 - because we don't want to indicate the color change for polygon closing after the creation of the first circle
      if (
        pointArray.length > 1 &&
        e.target &&
        e.target.data?.id === pointArray[0].data?.id &&
        lineArray.length > 0 &&
        activeLine
      ) {
        lineArray.forEach((line) => {
          line.set("stroke", mouseOver ? "red" : "#999999");
        });
        activeLine.set("stroke", mouseOver ? "red" : "#999999");
        canvas.renderAll();
      }
    },
    // We use pointArray.length and lineArray.length as dependencies instead of values
    // themselves. But this triggers eslint warning.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pointArray.length, activeLine, lineArray.length, canvas],
  );

  // we update document handler everytime we have a new point
  useEffect(() => {
    canvas.on("mouse:down", mouseDown);
    canvas.on("mouse:move", mouseMove);
    canvas.on("mouse:over", (e) => indicatePolygonClosing(e, true));
    canvas.on("mouse:out", (e) => indicatePolygonClosing(e, false));

    return () => {
      // clean up
      canvas.off("mouse:down", mouseDown);
      canvas.off("mouse:move", mouseMove);
      canvas.off("mouse:over", (e) => indicatePolygonClosing(e, true));
      canvas.off("mouse:out", (e) => indicatePolygonClosing(e, false));
    };
  }, [pointArray.length, canvas, indicatePolygonClosing, mouseDown, mouseMove]);

  useEffect(() => {
    return () => {
      // make sure no unfinished polygon is there
      canvas.getObjects().forEach((object) => {
        if (object.data?.customType === "helper") canvas.remove(object);
      });
    };
  }, [canvas]);

  return <Fragment />;
}
