import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";

import moment from "moment";
import Api from "app/js/api";
import { useSafeState } from "app/js/hooks";
import Header from "app/components/Header/Header";
import Paginator from "app/components/Paginator/Paginator";
import Table from "app/components/Table/Table";
import Loading from "app/components/Loading/Loading";
import { ProcessingTask } from "./types";

const taskTypeMap = {
  "extract-images": "Extract Images",
  "create-auto-entities": "Create Auto Entities",
};

export default function ProcessingTaskList({ match }) {
  const [processingTasks, setProcessingTasks] = useSafeState<ProcessingTask[]>(
    [],
  );
  const [loading, setLoading] = useSafeState<boolean>(false);
  const PAGE_LENGTH = 10;
  const [offset, setOffset] = useSafeState<number>(0);
  const [count, setCount] = useSafeState<number>(0);

  const loadProcessingTasks = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await Api.processingTask().all({
        limit: PAGE_LENGTH,
        offset,
      });
      setProcessingTasks(response.data.results);
      setCount(response.data.count);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [offset, setCount, setLoading, setProcessingTasks]);
  useEffect(() => {
    loadProcessingTasks();
  }, [loadProcessingTasks]);

  return (
    <Fragment>
      <Header basePath={match.path} showCreate={true}>
        <h1>Processing Tasks</h1>
        {count > PAGE_LENGTH && (
          <Paginator
            onChange={setOffset}
            count={count}
            offset={offset}
            pageLength={PAGE_LENGTH}
            disabled={loading}
          />
        )}
      </Header>
      {loading && <Loading />}
      {!loading && processingTasks.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Task</th>
              <th>Date</th>
              <th>Video ID</th>
            </tr>
          </thead>
          <tbody>
            {processingTasks.map((task) => (
              <tr key={task.id}>
                <td>{task.id}</td>
                <td>
                  <Link to={`${match.url}/${task.id}`}>
                    {taskTypeMap[task.type]}
                  </Link>
                </td>
                <td>{moment(task.create_time).format("LLL")}</td>
                <td>{task.metadata?.sampleVideo?.id || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        !loading && <p style={{ padding: "0 25px 0 25px" }}>Empty</p>
      )}
    </Fragment>
  );
}
