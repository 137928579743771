import React from "react";

import { DatasetView } from "app/js/types";
import { EntitySpritesPage } from "app/components/EntitySpritesPage";

interface RouteProps {
  datasetView: DatasetView;
}

export default function Routes({ datasetView }: RouteProps) {
  const datasetViewId = datasetView.id || "overview";

  return (
    <EntitySpritesPage
      baseTitle={`MoonVision - Dataset View ${datasetView.name}`}
      baseUrl={`/dataset-views/${datasetViewId}/entities`}
      object={datasetView}
      objectType={"dataset_view"}
    />
  );
}
