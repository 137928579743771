import React from "react";
import {
  NavLink,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from "react-router-dom";

import { default as datasetStyles } from "app/pages/DataSets/DatasetDetail/DatasetDetail.scss";
import Header from "app/components/Header/Header";
import Loading from "app/components/Loading/Loading";
import Submenu from "app/components/Menus/Submenu/Submenu";
import Api from "app/js/api";
import { LoadedDataCallback, useLoadedData } from "app/js/hooks";
import { DatasetView } from "app/js/types";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

import Overview from "./Overview/Overview";
import Entities from "./Entities/Routes";
import { Images } from "./Images";
import { Download } from "./Download";
import { Edit } from "./Edit";

export default function Routes({
  match,
}: RouteComponentProps<{ id: string }>): React.ReactElement {
  const history = useHistory();
  const datasetViewId =
    match.params.id === "overview" ? "overview" : parseInt(match.params.id);
  const baseUrl = match.url;

  const loadDatasetView: LoadedDataCallback<DatasetView> = React.useCallback(
    async (setValue) => {
      const response = await Api.datasetView(datasetViewId).show();
      setValue(response.data);
    },
    [datasetViewId],
  );

  const {
    value: datasetView,
    loading,
    error,
    setValue: setDatasetView,
  } = useLoadedData<DatasetView | null>(null, loadDatasetView);

  if (error !== null) {
    return (
      <div className={datasetStyles.container}>
        <ErrorMessage error={error} />
      </div>
    );
  } else if (loading || datasetView === null) {
    return (
      <div className={datasetStyles.container}>
        <Loading />
      </div>
    );
  }

  const editPath = `${baseUrl}/edit`;
  return (
    <div className={datasetStyles.container}>
      <Header
        basePath={match.url}
        editPath={window.location.pathname !== editPath ? editPath : null}
        className={datasetStyles.header}
      >
        <h1>Dataset View - {datasetView.name}</h1>
      </Header>
      <Submenu>
        <li>
          <NavLink exact={true} to={baseUrl} activeClassName="active">
            Overview
          </NavLink>
        </li>
        <li>
          <NavLink to={`${baseUrl}/entities`} activeClassName="active">
            {`Entities (${datasetView.entity_count})`}
          </NavLink>
        </li>
        <li>
          <NavLink to={`${baseUrl}/images`} activeClassName="active">
            {`Images (${datasetView.frames_count})`}
          </NavLink>
        </li>
        <li>
          <NavLink to={`${baseUrl}/download`} activeClassName="active">
            Download
          </NavLink>
        </li>
        {datasetViewId !== "overview" && (
          <li style={{ marginLeft: "auto" }}>
            <button
              onClick={() => {
                if (
                  confirm(
                    `Delete Dataset View "${datasetView.name}". Are you sure?`,
                  )
                ) {
                  Api.datasetView(datasetViewId)
                    .destroy()
                    .then((_response) => {
                      history.push("/dataset-views");
                    });
                }
              }}
            >
              Delete
            </button>
          </li>
        )}
      </Submenu>
      <div className={datasetStyles.content}>
        <Switch>
          <Route path={match.path} exact>
            <Overview datasetView={datasetView} />
          </Route>
          <Route path={`${match.path}/entities/:label?`}>
            <Entities datasetView={datasetView} />
          </Route>
          <Route path={`${match.path}/images`} exact>
            <Images datasetView={datasetView} setDatasetView={setDatasetView} />
          </Route>
          <Route path={`${match.path}/download`} exact>
            <Download datasetView={datasetView} />
          </Route>
          <Route path={`${match.path}/edit`} exact>
            <Edit datasetView={datasetView} setDatasetView={setDatasetView} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
