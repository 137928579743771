import React, { useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";

import Api from "app/js/api";
import { useSafeState } from "app/js/hooks";
import { useTeamStore, useUserStore } from "app/js/stores";

import Header from "app/components/Header/Header";
import Box from "app/components/Box/Box";
import Button from "app/components/Buttons/Button";
import { Error } from "app/components/ErrorMessage/types";
import FormComponent from "app/components/FormComponent/FormComponent";
import Notifications from "app/components/Notifications/Notifications";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import SelectTeam from "app/components/Selects/SelectTeam";

import styles from "./Settings.scss";

const role_options = [
  { label: "Labeler", value: "labeler" },
  { label: "Creator", value: "creator" },
  { label: "Owner", value: "owner" },
];

export default function UserSettings() {
  const [user, userActions] = useUserStore();
  const [teams, teamActions] = useTeamStore();
  const [allTeams, setAllTeams] = useSafeState([]);

  // Invites
  const [error, setError] = useSafeState<Error | null>(null);
  const [
    contactInformationError,
    setContactInformationError,
  ] = useSafeState<Error | null>(null);
  const [
    changePasswordError,
    setChangePasswordError,
  ] = useSafeState<Error | null>(null);

  // PROFILE
  const [userProfile, setUserProfile] = useSafeState({ name: "", email: "" });

  const loadUserProfile = async () => {
    const response = await Api.user().self();
    setUserProfile(response.data);
  };

  const loadAllTeamsIfStaff = async () => {
    if (user.data && user.data.is_staff) {
      const all = true;
      const response = await Api.team().all(all);
      setAllTeams(response.data.results);
    }
  };

  useEffect(() => {
    loadUserProfile();
    loadAllTeamsIfStaff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeUser = (e) => {
    setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
  };

  const handleContactInformationSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await Api.user(user.data.user_id).update(userProfile);
      setUserProfile(response.data);
      alert("Settings were saved");
    } catch (error) {
      setContactInformationError(error);
    }
  };

  // TEAM
  const [createTeam, setTeam] = useSafeState({ name: "" });

  const handleChangeTeamName = (e) => {
    setTeam({ ...createTeam, name: e.target.value });
  };

  const [passwords, setPasswords] = useSafeState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const handleChangePasswords = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };
  const handleChangePasswordClick = async (e) => {
    e.preventDefault();
    setChangePasswordError(null);

    if (passwords.password === passwords.confirmPassword) {
      try {
        await Api.user(user.data.user_id).update({
          old_password: passwords.oldPassword,
          new_password: passwords.password,
        });
        setPasswords({ oldPassword: "", password: "", confirmPassword: "" });
        alert("Password updated.");
      } catch (error) {
        setChangePasswordError(error);
      }
    } else {
      console.error("Passwords are not the same.");
      setChangePasswordError({ message: "Passwords are not the same." });
    }
  };
  const handleTeamCreate = async (e) => {
    e.preventDefault();
    setError(null);

    if (createTeam.name) {
      await Api.team().store(createTeam);
      teamActions.loadTeams();
      setTeam({ name: "" });
    }
  };

  const handleTeamDelete = async (delete_team) => {
    setError(null);
    if (!confirm("Are you sure?")) {
      return;
    }
    try {
      await Api.team().members(delete_team.team_member.id).destroy();
      await teamActions.loadTeams();

      if (delete_team.id === user.data.team_id) {
        let newTeamId = null;
        if (teams.data.length > 0) {
          newTeamId = teams.data[0].id;
        }
        userActions.setTeam(newTeamId);
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div>
      <Header showCreate={false}>
        <h1>User Settings</h1>
      </Header>

      <div className={styles.content}>
        <h1>Profile</h1>
        <Notifications />
        <form onSubmit={handleContactInformationSubmit}>
          <Box title="Contact Information">
            <ErrorMessage error={contactInformationError} />
            <FormComponent>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={userProfile.name}
                onChange={handleChangeUser}
              />
              <label htmlFor="email">E-Mail Address</label>
              <input
                type="email"
                name="email"
                value={userProfile.email}
                onChange={handleChangeUser}
                disabled
              />
            </FormComponent>
            <Button>Update</Button>
          </Box>
        </form>
        <h1>Team Managment</h1>
        <form onSubmit={handleTeamCreate}>
          <Box title="Create Team">
            <ErrorMessage error={error} />
            <FormComponent>
              <label htmlFor="teamName">Team Name</label>
              <input
                type="text"
                name="teamName"
                value={createTeam.name}
                onChange={handleChangeTeamName}
              />
            </FormComponent>
            <Button>Create</Button>
          </Box>
        </form>
        <Box title="Current Teams">
          <table style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {teams.data.map((team) => {
                return (
                  <tr key={team.id}>
                    <td>{team.name}</td>
                    <td>
                      {
                        role_options.find(
                          (option) => option.value === team.team_member.role,
                        ).label
                      }
                    </td>
                    <td>
                      {team.team_member.role === "owner" && (
                        <NavLink to={`/settings/teams/${team.id}`}>
                          Settings
                        </NavLink>
                      )}
                    </td>
                    <td>
                      <Button onClick={() => handleTeamDelete(team)}>
                        Leave Team
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
        <h1>Security</h1>
        <form onSubmit={handleChangePasswordClick}>
          <Box title="Set Password">
            <ErrorMessage error={changePasswordError} />
            <FormComponent>
              <label htmlFor="password">Old Password</label>
              <input
                id="oldPassword"
                name="oldPassword"
                type="password"
                value={passwords.oldPassword}
                onChange={handleChangePasswords}
              />
              <label htmlFor="password">New Password</label>
              <input
                id="password"
                name="password"
                type="password"
                value={passwords.password}
                onChange={handleChangePasswords}
              />
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                value={passwords.confirmPassword}
                onChange={handleChangePasswords}
              />
            </FormComponent>
            <Button>Change Password</Button>
          </Box>
        </form>
        {user.data && user.data.is_staff && (
          <Fragment>
            <h1>All Teams</h1>
            <SelectTeam
              onChange={userActions.setTeam}
              teams={allTeams}
              value={user.data.team_id}
              isDisabled={user.loading}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
}
