import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CanvasStatic from "app/components/CanvasStatic/CanvasStatic";

export default function SampleUpload({ match }) {
  const [images, setImages] = useState([]);
  const token = match.params.token;

  const onDrop = useCallback(
    (acceptedFiles) => {
      // new upload, new images
      setImages([]);

      // go through new images
      acceptedFiles.forEach((entry, index) => {
        // prepare form data
        const formData = new FormData();
        formData.append("file", entry);
        formData.append("token", token);

        // send request
        fetch("/api/v1/devices/image", {
          method: "POST",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
          },
          body: formData,
        })
          .then((response) => {
            return response.json();
          })
          .then((jsonData) => {
            setImages((prev) => [...prev, jsonData]);
          });
      });
    },
    [token],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div style={{ display: "block", margin: "20px auto" }}>
      <div
        style={{
          border: "dashed 2px #ccc",
          borderRadius: "2px",
          padding: "10px",
          margin: "10% auto 20px",
          width: "80%",
          maxWidth: "600px",
          textAlign: "center",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
      <CanvasStatic images={images} />
    </div>
  );
}
