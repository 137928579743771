import React from "react";
import { Switch, Route } from "react-router-dom";

import SampleUpload from "./SampleUpload/SampleUpload";
import StreamView from "app/components/StreamView/StreamView";

export default function Routes({ match }) {
  return (
    <Switch>
      <Route path="/p/test/:token" exact component={SampleUpload} />
      <Route
        path="/p/(stream|streams)/:token"
        exact
        render={({ match }) => (
          <StreamView stream={match.params.token} fullscreen={true} />
        )}
      />
    </Switch>
  );
}
