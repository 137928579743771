import React, { Fragment } from "react";

import Api from "app/js/api";
import Header from "app/components/Header/Header";
import FormComponent from "app/components/FormComponent/FormComponent";
import Button from "app/components/Buttons/Button";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import Loading from "app/components/Loading/Loading";

import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import {
  LoadedDataCallback,
  useLoadedData,
  useSafeState,
  useUserCallback,
} from "app/js/hooks";
import { ActionScript } from "app/js/types";

export default function ActionscriptForm({ match, history }) {
  const prefill = `module.exports = {
  // getLabels is run before detection. Use this function to programatically
  // set which labels should be considered in the detection results.
  // Expects a json list of strings or a future that resolves to a json list of strings to be returned.
  // It is important that this function runs quickly as it will add to the total
  // detection time.
  getLabels: () => {
    console.log('Start') // Logs will show up in the camera's logs for every detection
    // Example getting data from an API, there is also mv.post
    // Both use axios (https://github.com/axios/axios) under the hood and accept
    // the same arugments as axios.
    const ret = mv.get('${window.location.protocol}//${document.env.siteHost}/script_sample/').then((response) => {
      // Success, reponse data: { "labels": ['Label 1", "Label 2", "Label 3", "Label 4"]}
      return response.data.labels
    }).catch((e) => {
      console.log(e, e.stack)
      return [] // defaults to using all labels seen in dataset
    })
    return ret
  },
  // processEntities is run after detection. Use this function to modify the return
  // format if your aplication requires detected entities to have a certain format
  // or send detection results to another API.
  // It is important that this function runs quickly as it will add to the total
  // detection time.
  processEntities: (entities) => {
    return entities
  }
}`;

  const actionscriptId = match.params.id;
  const [submitLoading, setSubmitLoading] = useSafeState(false);
  const [submitError, setSubmitError] = useSafeState<Error>(null);

  const actionscriptCallback: LoadedDataCallback<ActionScript> = useUserCallback(
    async (setValue) => {
      if (actionscriptId) {
        const response = await Api.actionscript(actionscriptId).show();
        setValue(response.data);
      }
    },
    [actionscriptId],
  );
  const {
    value: actionscript,
    loading,
    error,
    setValue: setActionscript,
  } = useLoadedData<ActionScript>(null, actionscriptCallback);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitLoading(true);
    setSubmitError(null);
    try {
      // Edit mode
      if (actionscriptId) {
        await Api.actionscript(actionscriptId).update(actionscript);
        return history.push(`/actionscripts/${actionscriptId}`);
      }
      // Create Mode
      const res = await Api.actionscript().store(actionscript);
      history.push(`/actionscripts/${res.data.id}`);
    } catch (error) {
      setSubmitError(error);
    }
    setSubmitLoading(false);
  };

  const displayLoading = loading || submitLoading;
  const displayError = error || submitError;

  return (
    <Fragment>
      <Header>
        {!loading && (
          <h1>
            Actionscript -{actionscriptId ? actionscript?.name : "Create"}
          </h1>
        )}
        {loading && <h1>Loading...</h1>}
      </Header>
      {displayError && <ErrorMessage error={displayError} />}
      {displayLoading && <Loading />}
      {!displayLoading && (
        <form onSubmit={handleSubmit} style={{ padding: "25px" }}>
          <FormComponent>
            <label htmlFor="name" className="form-label">
              Actionscript Name
            </label>
            <input
              value={actionscript?.name || ""}
              onChange={(evt) =>
                setActionscript({
                  ...actionscript,
                  name: evt.target.value,
                })
              }
            />
          </FormComponent>
          <Editor
            value={actionscript?.code || prefill}
            onValueChange={(code) =>
              setActionscript({
                ...actionscript,
                code,
              })
            }
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              background: "white",
              fontFamily: "monospace",
              fontSize: 12,
            }}
          />
          <p>
            Controls actions that can be executed on detected entities. The
            getLabels function passes a list of acceptable labels before
            detection. The processEntities function receives the result of a
            detection on its image.
          </p>
          <Button>Save</Button>
        </form>
      )}
    </Fragment>
  );
}
