import React, { useEffect, Fragment } from "react";

import Api from "app/js/api";
import Header from "app/components/Header/Header";
import Table from "app/components/Table/Table";
import Paginator from "app/components/Paginator/Paginator";
import Loading from "app/components/Loading/Loading";
import { useSafeState } from "app/js/hooks";
import { useUserStore } from "app/js/stores";

export default function DetectorList({ match }) {
  const [user] = useUserStore();
  const [detectors, setDetectors] = useSafeState(undefined);
  const PAGE_LENGTH = 10;
  const [offset, setOffset] = useSafeState(0);
  const [count, setCount] = useSafeState(0);

  const [loading, setLoading] = useSafeState(false);

  useEffect(() => {
    const loadDetectors = async () => {
      setLoading(true);
      const response = await Api.detector().all({
        include_public: true,
        limit: PAGE_LENGTH,
        offset,
      });

      setDetectors(response.data.results);
      setCount(response.data.count);
      setLoading(false);
    };

    loadDetectors();
  }, [offset, setCount, setDetectors, setLoading, user.data]);

  return (
    <Fragment>
      <Header basePath={match.path} showCreate={false}>
        <h1>Models</h1>
        {count > PAGE_LENGTH && (
          <Paginator
            onChange={setOffset}
            count={count}
            offset={offset}
            pageLength={PAGE_LENGTH}
            disabled={loading}
          />
        )}
      </Header>
      {loading && <Loading />}
      {!loading && detectors && detectors.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Backbone</th>
              <th>Trained</th>
              <th>Public</th>
            </tr>
          </thead>
          <tbody>
            {detectors.map((detector) => (
              <tr key={detector.id}>
                <td>{detector.id}</td>
                <td>{detector.name}</td>
                <td>{detector.backbone}</td>
                <td>
                  {detector.trained_for} ({detector.training_run} runs)
                </td>
                <td>{detector.owner_id === null ? "yes" : "no"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        !loading &&
        detectors &&
        detectors.length === 0 && (
          <p style={{ padding: "0 25px 0 25px" }}>No Models</p>
        )
      )}
    </Fragment>
  );
}
