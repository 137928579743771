import React, { useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Select from "react-select";
import moment from "moment";

import Api from "app/js/api";
import { useSafeState } from "app/js/hooks";
import { Option } from "app/js/types";

interface SelectLabelVersionProps {
  jobId: number;
  frameId: string;
}

const SelectLabelVersion: React.FC<SelectLabelVersionProps> = ({
  jobId,
  frameId,
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const labelVersion = parseInt(params.get("label_version")) || null;
  const [labelVersions, setLabelVersions] = useSafeState<Option<number>[]>([]);

  const requestAllVersions = useCallback(async () => {
    const response = await Api.labelversion().all({
      job_ids: [jobId],
      frame_ids: [parseInt(frameId)],
    });

    if (response.data.results.length > 0) {
      setLabelVersions(
        response.data.results.map((labelVersion, index) => {
          let versionStatus = "";
          if (labelVersion.auto) {
            versionStatus = " (auto)";
          } else if (labelVersion.qualified) {
            versionStatus = " (qualified)";
          } else {
            versionStatus = " (unqualified)";
          }
          const versionTime = moment(labelVersion.create_time).format("LLL");
          return {
            label: `Created on ${versionTime} by ${labelVersion.user.name}${versionStatus}`,
            value: labelVersion.id,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frameId, jobId]);

  useEffect(() => {
    requestAllVersions();
  }, [requestAllVersions]);

  const onChangeHandle = (selectedOption: Option<number>) => {
    params.set("label_version", `${selectedOption.value}`);
    history.push({
      search: params.toString(),
    });
  };

  return labelVersions.length > 0 ? (
    <Select
      options={labelVersions}
      value={labelVersions.find((o) => o.value === labelVersion)}
      onChange={(e: Option<number>) => onChangeHandle(e)}
    />
  ) : (
    <li>Version: Latest Version</li>
  );
};

export default SelectLabelVersion;
