import React from "react";
import { Switch, Route } from "react-router-dom";

import DatasetForm from "./DatasetForm";
import DatasetDetail from "./DatasetDetail/Routes";
import DatasetList from "./DatasetList";

export default function Routes({ match }) {
  return (
    <Switch>
      <Route path={match.path} exact component={DatasetList} />
      <Route path={`${match.path}/create`} exact component={DatasetForm} />
      <Route path={`${match.path}/:id/edit`} component={DatasetForm} />
      <Route path={`${match.path}/:id`} component={DatasetDetail} />
    </Switch>
  );
}
