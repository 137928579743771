import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";

import Api from "app/js/api";

import Header from "app/components/Header/Header";
import Submenu from "app/components/Menus/Submenu/Submenu";
import Loading from "app/components/Loading/Loading";
import ResourceDoesNotExist from "app/components/Error/ResourceDoesNotExist";

import DatasetDetail from "app/pages/DataSets/DatasetDetail/DatasetDetail/DatasetDetail";
import DatasetDetailImagesRoutes from "app/pages/DataSets/DatasetDetail/DatasetDetailImages/Routes";
import DatasetDetailVideos from "app/pages/DataSets/DatasetDetail/DatasetDetailVideos/Routes";
import DatasetDetailEntitiesRoutes from "app/pages/DataSets/DatasetDetail/DatasetDetailEntities/Routes";
import DatasetDetailDownload from "app/pages/DataSets/DatasetDetail/DatasetDetailDownload";

import { LoadedDataCallback, useLoadedData } from "app/js/hooks";
import styles from "app/pages/DataSets/DatasetDetail/DatasetDetail.scss";
import { DatasetWithEntityCount } from "app/js/types";

const DatasetDetailRoutes = ({ match, history }) => {
  const datasetId = match.params.id;

  // Load Dataset Details
  const datasetRequestCallback: LoadedDataCallback<DatasetWithEntityCount> = React.useCallback(
    async (setValue) => {
      const response = await Api.dataset(datasetId).show();
      const newDataset = response.data;
      const labelsResponse = await Api.labelversion().labels({
        dataset_ids: [parseInt(datasetId)],
        mode: "user_latest",
      });
      const labels: Record<string, number> = labelsResponse.data.results;
      let entity_count = 0;
      if (labels && Object.keys(labels).length > 0) {
        entity_count = Object.entries(labels)
          .map(([_label, count], _i) => count)
          .reduce((a, b) => a + b, 0);
      }
      setValue({ ...newDataset, entity_count });
    },
    [datasetId],
  );
  // Setting a dummy object as default, because the further code assumes that we always have an object
  const {
    value: dataset,
    loading,
    error,
    setValue: setDataset,
  } = useLoadedData<DatasetWithEntityCount>(
    {
      name: "",
      entity_count: 0,
      url: "",
      id: 0,
      description: "",
      videos_count: 0,
      scenes_count: 0,
      frames_count: 0,
    },
    datasetRequestCallback,
  );

  if (error) {
    return <ResourceDoesNotExist resourceRoot={"/data-sets"} />;
  }
  const datasetIsLoaded = !loading && dataset.id !== 0;
  return (
    <div className={styles.container}>
      <Header
        basePath={match.url}
        showCreate={false}
        showEdit={true}
        className={styles.header}
      >
        {!loading && <h1>Dataset - {dataset.name}</h1>}
        {loading && <Loading />}
      </Header>
      <Submenu>
        <li>
          <NavLink
            exact={true}
            to={`/data-sets/${datasetId}`}
            activeClassName="active"
          >
            Overview
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/data-sets/${datasetId}/entities`}
            activeClassName="active"
          >
            Entities{" "}
            {dataset.entity_count ? `(${dataset.entity_count})` : "(0)"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/data-sets/${datasetId}/images`}
            activeClassName="active"
          >
            Images {dataset.frames_count ? `(${dataset.frames_count})` : "(0)"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/data-sets/${datasetId}/videos`}
            activeClassName="active"
          >
            Videos {dataset.videos_count ? `(${dataset.videos_count})` : "(0)"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/data-sets/${datasetId}/download`}
            activeClassName="active"
          >
            Download
          </NavLink>
        </li>
        <li style={{ marginLeft: "auto" }}>
          <button
            onClick={() => {
              if (confirm(`Delete Dataset "${dataset.name}". Are you sure?`)) {
                Api.dataset(datasetId)
                  .destroy()
                  .then((_response) => {
                    history.push("/data-sets");
                  });
              }
            }}
          >
            Delete
          </button>
        </li>
      </Submenu>
      <div className={styles.content}>
        {!datasetIsLoaded ? (
          <Loading />
        ) : (
          <Switch>
            <Route path={`${match.path}/entities/:label?`}>
              <DatasetDetailEntitiesRoutes dataset={dataset} />
            </Route>
            <Route
              path={`${match.path}/images`}
              render={() => (
                <DatasetDetailImagesRoutes
                  match={match}
                  dataset={dataset}
                  setDataset={setDataset}
                />
              )}
            />
            <Route
              path={`${match.path}/videos`}
              render={(props) => (
                <DatasetDetailVideos
                  {...props}
                  dataset={dataset}
                  setDataset={setDataset}
                />
              )}
            />
            <Route path={`${match.path}/download`} exact>
              <DatasetDetailDownload />
            </Route>
            <Route
              path={match.path}
              render={() => <DatasetDetail dataset={dataset} />}
            />
          </Switch>
        )}
      </div>
    </div>
  );
};

export default DatasetDetailRoutes;
