import React, { Fragment } from "react";
import { useUserStore } from "app/js/stores";
import { AreaProps } from "./types";

const AreaStaffOrPaid: React.FC<AreaProps> = ({ children }) => {
  const [user] = useUserStore();
  if (!user.data.is_staff && user.data.subscriptions.indexOf("premium") < 0)
    return null;
  return <Fragment>{children}</Fragment>;
};
export default AreaStaffOrPaid;
