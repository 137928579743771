import React from "react";
import Box from "app/components/Box/Box";

export default function Tier({ type = null, title, theme }) {
  const intro = (
    <p>
      We are constantly improving our services. You currently have access to the
      following features:
    </p>
  );

  const premiumIntro = (
    <p>
      We know how to create high accuracy models with only little data. The
      premium tier enables toolsets which allow fast and massive data generation
      for custom model training. This allows regular model updates with little
      effort using the following modules:
    </p>
  );

  const free = (
    <ul>
      <li>
        <strong>Image Annotation Tool</strong>
        <ul>
          <li>Bounding Boxes, Circles and Polygons</li>
          <li>Object Detection Labelling</li>
          <li>Surface Labelling</li>
          <li>Advanced Recommendation System</li>
        </ul>
      </li>
      <li>
        <strong>Data Management</strong>
        <ul>
          <li>Create and Edit Annotations for custom Models</li>
          <li>Unlimited undo possibility</li>
          <li>Download labelled data anytime</li>
        </ul>
      </li>
      <li>
        <strong>Team Management</strong>
        <ul>
          <li>Manage Tasks in Labelling Jobs</li>
          <li>Work on datasets as a team</li>
          <li>Manage team workflows</li>
        </ul>
      </li>
    </ul>
  );

  const premium = (
    <ul>
      <li>
        <strong>Data Gathering from Video</strong>
        <ul>
          <li>Motion Blur Avoidance</li>
          <li>Best Snapshot Extraction</li>
          <li>Automated Scene Cutting</li>
        </ul>
      </li>
      <li>
        <strong>Preprocessing via Clustering</strong>
        <ul>
          <li>Cluster Similar Objects automatically</li>
          <li>Create ground truth labels in batches efficiently</li>
        </ul>
      </li>
      <li>
        <strong>Automated Entity Generation</strong>
        <ul>
          <li>Discover known objects in new video and image data</li>
          <li>Use a library of pretrained segmentation and detection models</li>
          <li>Refine results quickly</li>
        </ul>
      </li>
      <li>
        <strong>Model Training</strong>
        <ul>
          <li>Create training runs for custom models</li>
          <li>Execute the model on the platform</li>
          <li>Download model for offline usage</li>
        </ul>
      </li>
      <li>
        <strong>End to End Workflow</strong>
        <ul>
          <li>Use Action Scripts for pre and post processing</li>
          <li>Integrate with any other systems</li>
          <li>
            Use the Coding View application logic in your business or production
            process
          </li>
        </ul>
      </li>
    </ul>
  );

  switch (type) {
    case "free":
      return (
        <Box title={title} theme={theme}>
          {intro}
          {free}
        </Box>
      );
    case "premium":
      return (
        <Box title={title} theme={theme}>
          {premiumIntro}
          {premium}
        </Box>
      );
    default:
      return (
        <Box title={title} theme={theme}>
          {intro}
          {free}
          {premium}
        </Box>
      );
  }
}
