/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import React from "react";
import ReactDOM from "react-dom";
import App from "app/App";
import "sass/app.global.scss";

/*
import * as Sentry from "@sentry/browser";
Sentry.init({
    dsn: "https://9927c18586b5491984ba536d5afaf8a0@sentry.io/1401425"
});
*/

if (document.getElementById("react_app")) {
  ReactDOM.render(<App />, document.getElementById("react_app"));
}
