import AnyOfField from "@rjsf/core/lib/components/fields/MultiSchemaField";

class CustomAnyOfField extends AnyOfField {
  getMatchingOption(formData, options = undefined) {
    for (const i in options) {
      const o = options[i];
      if (o.properties) {
        for (const key in o.properties) {
          if (o.properties[key].hasOwnProperty("const")) {
            if (formData?.[key] === o.properties[key].const) {
              return i;
            }
          }
        }
      }
    }
    return super.getMatchingOption(formData, options);
  }
}

export default CustomAnyOfField;
