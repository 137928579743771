import React from "react";
import Editor, { Monaco, OnChange } from "@monaco-editor/react";
import { useSafeState } from "app/js/hooks";

interface EditConfigPlainProps {
  value: string;
  onChange: OnChange;
  jsonSchema?: Record<string, any>;
}

const updateJsonSchema = (
  monaco?: Monaco,
  jsonSchema?: Record<string, any>,
) => {
  if (monaco) {
    if (jsonSchema) {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemaValidation: "warning",
        schemas: [
          {
            uri: "inmemory://model/schema.json",
            fileMatch: ["*", "**/*"],
            schema: jsonSchema,
          },
        ],
      });
    } else {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemaValidation: "ignore",
      });
    }
  }
};

const EditConfigPlain: React.FC<EditConfigPlainProps> = ({
  value,
  onChange,
  jsonSchema,
}) => {
  const [monaco, setMonaco] = useSafeState<Monaco>(null);

  const handleEditorDidMount = (editor, monaco) => {
    setMonaco(monaco);
  };

  React.useEffect(() => {
    updateJsonSchema(monaco, jsonSchema);
  }, [monaco, jsonSchema]);

  return (
    <Editor
      height={`calc(100% - 12rem)`}
      theme="vs-dark"
      language="json"
      value={value}
      onChange={onChange}
      onMount={handleEditorDidMount}
    />
  );
};

export default EditConfigPlain;
