import React from "react";
import { Link } from "react-router-dom";
import { LinkOwner } from "app/componentConstraints";
import styles from "./Header.scss";

export interface HeaderProps {
  basePath?: string;
  // TODO: Eventually I'd like to replace basePath and flags with createPath/editPath,
  //       because that seems to be more flexible
  createPath?: string;
  editPath?: string;
  showCreate?: boolean;
  createOwnerOnly?: boolean;
  showEdit?: boolean;
  className?: string;
  createText?: string;
}

const Header: React.FC<HeaderProps> = ({
  children,
  basePath = "",
  createPath = "",
  editPath = "",
  showCreate = false,
  createOwnerOnly = false,
  showEdit = false,
  className = "",
  createText = "Create",
}) => {
  return (
    <div className={`${styles.header} ${className}`}>
      {children}
      {(showCreate || createPath) &&
        (createOwnerOnly ? (
          <div className={styles.actions}>
            <LinkOwner to={createPath || `${basePath}/create`}>
              {createText}
            </LinkOwner>
          </div>
        ) : (
          <div className={styles.actions}>
            <Link to={createPath || `${basePath}/create`}>{createText}</Link>
          </div>
        ))}
      {(showEdit || editPath) && (
        <div className={styles.actions}>
          <Link to={editPath || `${basePath}/edit`}>Edit</Link>
        </div>
      )}
    </div>
  );
};
export default Header;
