import React from "react";
import { Switch, Route } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import AppInstances from "app/pages/AppConfigs/AppInstances/AppInstances";
import ConfigVersions from "app/pages/AppConfigs/ConfigVersions/ConfigVersions";
import CreateInstance from "app/pages/AppConfigs/CreateInstance/CreateInstance";
import EditConfig from "app/pages/AppConfigs/EditConfig/EditConfig";

const AppConfigRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={match.path} component={AppInstances} exact />
    <Route
      path={`${match.path}/create-instance/:appName`}
      component={CreateInstance}
      exact
    />
    <Route
      path={`${match.path}/:appInstanceId/config-versions`}
      component={ConfigVersions}
      exact
    />
    <Route
      path={`${match.path}/:appInstanceId/configure`}
      component={EditConfig}
      exact
    />
  </Switch>
);

export default AppConfigRoutes;
