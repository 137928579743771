import React from "react";

import { useParamValue } from "../hooks";
import { BaseField, OmitConflictingProps } from "../types";
import Field from "../Field";

type InputAttributes = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type CharFieldProps = BaseField<string> &
  OmitConflictingProps<InputAttributes>;

export const CharField = ({
  label,
  isRequired,
  isDisabled,
  isUsedInParams,
  paramName,
  value,
  onChange,
  ...inputProps
}: CharFieldProps): React.ReactElement => {
  const convertFromParam = (v: string): string => v;
  const convertToParam = (v: string): string => v;

  const [fieldValue, setFieldValue] = useParamValue<string>({
    paramName,
    initialValue: value,
    isUsedInParams,
    convertFromParam,
    convertToParam,
    onChange,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFieldValue(newValue);
  };

  return (
    <Field label={label}>
      <input
        value={fieldValue}
        onChange={handleChange}
        type={"text"}
        required={isRequired}
        disabled={isDisabled}
        {...inputProps}
      />
    </Field>
  );
};
