import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import Api from "app/js/api";
import Table from "app/components/Table/Table";
import Header from "app/components/Header/Header";
import Box from "app/components/Box/Box";
import Paginator from "app/components/Paginator/Paginator";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

import { useSafeState } from "app/js/hooks";
import { getContent } from "app/components/Support/helper";
import { useUserStore } from "app/js/stores";

export default function StreamList({ match, history }) {
  const [user] = useUserStore();
  const [streams, setStreams] = useSafeState(undefined);
  const [loading, setLoading] = useSafeState(false);
  const PAGE_LENGTH = 10;
  const [offset, setOffset] = useSafeState(0);
  const [count, setCount] = useSafeState(0);
  const [error, setError] = useSafeState(null);

  useEffect(() => {
    const loadStreams = async () => {
      setLoading(true);
      try {
        const response = await Api.stream().all({ limit: PAGE_LENGTH, offset });
        setStreams(response.data.results);
        setCount(response.data.count);
        setError(null);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    loadStreams();
  }, [offset, setCount, setError, setLoading, setStreams, user.data]);

  return (
    <Fragment>
      <Header basePath={match.path} showCreate={true}>
        <h1>Cameras</h1>
        {count > PAGE_LENGTH && (
          <Paginator
            onChange={setOffset}
            count={count}
            offset={offset}
            pageLength={PAGE_LENGTH}
            disabled={loading}
          />
        )}
      </Header>
      {error && <ErrorMessage error={error} />}
      {loading && <Loading />}
      {!loading && streams && streams.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Dataset ID</th>
              <th>Camera Scale</th>
              <th>Detection Type</th>
            </tr>
          </thead>
          <tbody>
            {streams.map((stream) => (
              <tr key={stream.id}>
                <td>
                  <Link to={`streams/${stream.stream}`}>{stream.name}</Link>
                </td>
                <td>{stream.dataset}</td>
                <td>{stream.scale}</td>
                <td>{stream.detection_type || "No Detection"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        !loading &&
        streams &&
        streams.length === 0 && (
          <div style={{ margin: "25px", maxWidth: "620px" }}>
            <Box title="No Cameras yet">
              {getContent(history.location.pathname)}
              <strong>Make sure you have a Dataset first.</strong>
            </Box>
          </div>
        )
      )}
    </Fragment>
  );
}
