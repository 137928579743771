import React, { useRef, useEffect } from "react";
import classNames from "classnames";
import { debounce } from "app/js/util";
import { useSafeState } from "app/js/hooks";
import { Entity, Sprite } from "app/js/types";
import styles from "app/components/CanvasStatic/CanvasStatic.scss";
import { stringToColor } from "app/pages/LabellingJobs/Labelling/helper/colors";

const entityColor = (entity: { label: string }): string =>
  entity?.label ? stringToColor(entity?.label, 1) : "rgb(0,0,0)";

function indexOfMin(arr) {
  if (arr.length === 0) {
    return -1;
  }
  let min = arr[0];
  let minIndex = 0;
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] < min) {
      minIndex = i;
      min = arr[i];
    }
  }
  return minIndex;
}

const drawLabel = (
  ctx: CanvasRenderingContext2D,
  label: string,
  location: { x: number; y: number },
  fillStyle: string,
  font = "10pt sans-serif",
) => {
  ctx.fillStyle = fillStyle;
  ctx.font = font;
  ctx.fillText(label, location.x, location.y);
};

const drawAABox = (
  ctx: CanvasRenderingContext2D,
  entity: Entity,
  proportion: number,
  lastHeight: number,
) => {
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = entityColor(entity);
  if (!entity.enabled) {
    ctx.setLineDash([5, 5]);
  }
  ctx.rect(
    entity.aabox.x * proportion,
    entity.aabox.y * proportion + lastHeight,
    entity.aabox.width * proportion,
    entity.aabox.height * proportion,
  );
  ctx.closePath();
  ctx.stroke();
  // clear line dash
  ctx.setLineDash([1, 0]);

  drawLabel(
    ctx,
    entity.label,
    {
      x: entity.aabox.x * proportion + 2,
      y: lastHeight + entity.aabox.y * proportion + 13,
    },
    entityColor(entity),
  );
};

const drawContour = (
  ctx: CanvasRenderingContext2D,
  entity: Entity,
  proportion: number,
  lastHeight: number,
) => {
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = entityColor(entity);
  if (!entity.enabled) {
    ctx.setLineDash([5, 5]);
  }
  entity.contour.forEach(([x, y], index) => {
    if (index === 0) {
      ctx.moveTo(x * proportion, y * proportion + lastHeight);
    } else {
      ctx.lineTo(x * proportion, y * proportion + lastHeight);
    }
  });
  ctx.closePath();
  ctx.stroke();
  // clear line dash
  ctx.setLineDash([1, 0]);
  const minIndex = indexOfMin(
    entity.contour.map(([x, y]) => Math.pow(x, 2) + Math.pow(y, 2)),
  );
  drawLabel(
    ctx,
    entity.label,
    {
      x: entity.contour[minIndex][0] * proportion + 3,
      y: lastHeight + entity.contour[minIndex][1] * proportion + 3,
    },
    entityColor(entity),
  );
};

const drawBox = (
  ctx: CanvasRenderingContext2D,
  entity: Entity,
  proportion: number,
  lastHeight: number,
) => {
  ctx.save();
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = entityColor(entity);
  if (!entity.enabled) {
    ctx.setLineDash([5, 5]);
  }
  ctx.translate(
    entity.box.x * proportion,
    entity.box.y * proportion + lastHeight,
  );
  ctx.rotate((entity.box.rotation * Math.PI) / 180);
  ctx.rect(
    (-entity.box.width / 2) * proportion,
    (-entity.box.height / 2) * proportion,
    entity.box.width * proportion,
    entity.box.height * proportion,
  );
  ctx.closePath();
  ctx.stroke();
  // clear line dash
  ctx.setLineDash([1, 0]);
  ctx.restore();
  drawLabel(
    ctx,
    entity.label,
    {
      x: entity.box.x * proportion,
      y: lastHeight + entity.box.y * proportion - 5,
    },
    entityColor(entity),
  );
};

const drawCircle = (
  ctx: CanvasRenderingContext2D,
  entity: Entity,
  proportion: number,
  lastHeight: number,
) => {
  ctx.save();
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = entityColor(entity);
  if (!entity.enabled) {
    ctx.setLineDash([5, 5]);
  }
  ctx.arc(
    entity.circle.x * proportion,
    entity.circle.y * proportion,
    entity.circle.radius * proportion,
    0,
    2 * Math.PI,
  );
  ctx.stroke();
  // clear line dash
  ctx.setLineDash([1, 0]);
  ctx.restore();
  drawLabel(
    ctx,
    entity.label,
    {
      x: entity.circle.x * proportion - entity.circle.radius * proportion + 5,
      y: lastHeight + entity.circle.y * proportion,
    },
    entityColor(entity),
  );
};

interface CanvasStaticProps {
  images: Sprite[];
  className?: string;
}

const CanvasStatic: React.FC<CanvasStaticProps> = ({ images, className }) => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const [resizeCheck, setResizeCheck] = useSafeState<false | number>(false);

  // resize canvas on window resize
  useEffect(() => {
    const smoothSizing = debounce(function () {
      // All the taxing stuff you do
      canvasRef.current.width = 0;
      setResizeCheck(Math.random());
    }, 250);
    window.addEventListener("resize", smoothSizing);
  }, [setResizeCheck]);

  useEffect(() => {
    if (!images.length) return;

    const ctx = canvasRef.current.getContext("2d");
    const parentNode = canvasRef.current.parentNode as Element;
    const canvasWidth = parentNode.clientWidth - 50;

    // set canvas accordingly
    canvasRef.current.width = canvasWidth;
    canvasRef.current.height = images.reduce(
      (total, obj) => total + (obj.height * canvasWidth) / obj.width,
      0,
    );

    images.forEach((imageRaw, index) => {
      // get height until that position
      let lastHeight = 0;
      for (let i = 0; i < index; i++) {
        lastHeight += (images[i].height * canvasWidth) / images[i].width;
      }
      // get context and paint image on canvas
      const image = new Image();
      image.onload = function () {
        // get proportion for resizing
        const originalImageWidth = imageRaw.width || image.width;
        const originalImageHeight = imageRaw.height || image.height;
        const proportion = canvasWidth / originalImageWidth;

        // check if we get the height from porperty at all (otherwise set canvas height)
        if (!imageRaw.height) {
          canvasRef.current.height = originalImageHeight * proportion;
        }

        ctx.drawImage(
          image,
          0,
          lastHeight,
          originalImageWidth * proportion,
          originalImageHeight * proportion,
        );

        // draw the entities
        if (imageRaw.entities && imageRaw.entities.length > 0) {
          imageRaw.entities.forEach((entity) => {
            // AABOX
            if (entity.aabox) {
              drawAABox(ctx, entity, proportion, lastHeight);
            }
            // CONTOUR
            if (entity.contour) {
              drawContour(ctx, entity, proportion, lastHeight);
            }
            // BOX
            if (entity.box) {
              drawBox(ctx, entity, proportion, lastHeight);
            }
            // CIRCLE
            if (entity.circle) {
              drawCircle(ctx, entity, proportion, lastHeight);
            }
          });
        }
      };

      if (imageRaw.image.startsWith("http")) {
        image.src = imageRaw.image;
      } else {
        image.src = `data:image/png;base64,${imageRaw.image}`;
      }
    });
  }, [images, resizeCheck]);

  const classes = classNames(styles.container, className);

  return <canvas className={classes} ref={canvasRef} height={0} width={0} />;
};

export default CanvasStatic;
