import React from "react";
import Box from "app/components/Box/Box";

export default function () {
  return (
    <Box title="Get Started">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/LmxhLYAHWRM"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Box>
  );
}
