import React from "react";

export default function NotFound() {
  console.log("404 Not Found");
  return (
    <div>
      <p>Not Found | 404</p>
    </div>
  );
}
