import React from "react";
import styles from "./MessageBox.scss";

interface MessageBoxProps {
  messages: string[];
}

const MessageBox: React.FC<MessageBoxProps> = ({ messages }) => {
  return (
    <ul className={styles.container}>
      {messages.map((entry, i) => (
        <li key={i}>{entry}</li>
      ))}
    </ul>
  );
};
export default MessageBox;
