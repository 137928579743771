import { UserState } from "app/js/stores";

export const isOwner = (user: UserState): boolean => {
  return user.data.role === "owner";
};

export const isPaid = (user: UserState): boolean => {
  return user.data.subscriptions.includes("premium");
};

export const isStaff = (user: UserState): boolean => {
  return user.data.is_staff;
};
