import React from "react";

import { EntitySpritesPage } from "app/components/EntitySpritesPage";
import { LabelingJob } from "app/js/types";

interface LabellingJobEntitiesProps {
  job: LabelingJob;
}

const LabellingJobEntities: React.FC<LabellingJobEntitiesProps> = ({ job }) => {
  const jobId = job.id;

  return (
    <EntitySpritesPage
      // TODO: Use proper name
      baseTitle={`MoonVision - Labeling Job ${jobId}`}
      baseUrl={`/labelling-jobs/${jobId}/entities`}
      object={job}
      objectType={"labeling_job"}
      jobId={jobId}
    />
  );
};

export default LabellingJobEntities;
