import React from "react";
import { Switch, Route } from "react-router-dom";

import ActionscriptList from "./ActionscriptList";
import ActionscriptForm from "./ActionscriptForm";
import ActionscriptDetail from "./ActionscriptDetail";

export default function Routes({ match }) {
  return (
    <Switch>
      <Route path={match.path} exact component={ActionscriptList} />
      <Route path={`${match.path}/create`} exact component={ActionscriptForm} />
      <Route
        path={`${match.path}/:id/edit`}
        exact
        component={ActionscriptForm}
      />
      <Route path={`${match.path}/:id`} exact component={ActionscriptDetail} />
    </Switch>
  );
}
