import React from "react";

import { useParamValue } from "../hooks";
import { BaseField, OmitConflictingProps } from "../types";
import Field from "../Field";

type InputAttributes = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export type TextareaProps = BaseField<string> &
  OmitConflictingProps<InputAttributes>;

export const Textarea = ({
  label,
  isRequired,
  isDisabled,
  isUsedInParams,
  paramName,
  value,
  onChange,
  ...inputProps
}: TextareaProps): React.ReactElement => {
  const convertFromParam = (v: string): string => v;
  const convertToParam = (v: string): string => v;

  const [fieldValue, setFieldValue] = useParamValue<string>({
    paramName,
    initialValue: value,
    isUsedInParams,
    convertFromParam,
    convertToParam,
    onChange,
  });

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setFieldValue(newValue);
  };

  return (
    <Field label={label}>
      <textarea
        value={fieldValue}
        onChange={handleChange}
        required={isRequired}
        disabled={isDisabled}
        {...inputProps}
      />
    </Field>
  );
};
