import React, { Fragment, useEffect } from "react";

import Select from "react-select";
import Api from "app/js/api";
import Loading from "app/components/Loading/Loading";

import { useSafeState } from "app/js/hooks";
import {
  Camera,
  CameraFilters,
  CameraSelectOption,
  Option,
} from "app/js/types";

interface BaseSelectStreamProps<StreamType extends CameraSelectOption> {
  required?: boolean;
  value: string;
  setStream: (value: StreamType) => void;
  isDisabled: boolean;
  datasetId?: number;
  // Avoid this for new components! This option is mostly for backend compatibility, because
  // some of the existing components were requiring a complete camera object.
  useFullEndpointForOptions: boolean;
}

function BaseSelectStream<StreamType extends CameraSelectOption>({
  required = false,
  value,
  setStream,
  isDisabled,
  datasetId,
  useFullEndpointForOptions = false,
}: BaseSelectStreamProps<StreamType>): React.ReactElement {
  const [streams, setStreams] = useSafeState<StreamType[]>([]);
  const [loading, setLoading] = useSafeState(false);

  useEffect(() => {
    const loadStreams = async () => {
      setLoading(true);
      try {
        const filters: CameraFilters = {
          limit: 500,
        };
        if (datasetId) {
          filters.dataset_id = datasetId;
        }
        const response = useFullEndpointForOptions
          ? await Api.stream().all(filters)
          : await Api.stream().selectOptions(filters);
        setStreams(response.data.results as StreamType[]);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    loadStreams();
  }, [datasetId, setLoading, setStreams, useFullEndpointForOptions]);

  let options: Option<string>[] = required
    ? []
    : [{ value: null, label: "no Camera" }];
  if (streams) {
    options = [
      ...options,
      ...streams.map((entry) => ({
        label: `${entry.name}`,
        value: entry.stream,
      })),
    ];
  } else {
    options = [{ value: null, label: "no Camera" }];
  }

  return (
    <Fragment>
      {loading && <Loading />}
      {!loading && streams && streams.length > 0 && (
        <Select
          value={options.find((entry) => entry.value === value)}
          onChange={(e: Option<string>) => {
            const newStream = streams.find((entry) => entry.stream === e.value);
            setStream(newStream as StreamType);
          }}
          options={options}
          isDisabled={isDisabled}
        />
      )}
    </Fragment>
  );
}

export type SelectStreamProps = Omit<
  BaseSelectStreamProps<CameraSelectOption>,
  "useFullEndpointForOptions"
>;

export default function SelectStream(
  props: SelectStreamProps,
): React.ReactElement {
  return BaseSelectStream<CameraSelectOption>({
    ...props,
    useFullEndpointForOptions: false,
  });
}

type FullCameraSelectStreamProps = Omit<
  BaseSelectStreamProps<Camera>,
  "useFullEndpointForOptions"
>;

export function FullCameraSelectStream(
  props: FullCameraSelectStreamProps,
): React.ReactElement {
  return BaseSelectStream<Camera>({
    ...props,
    useFullEndpointForOptions: true,
  });
}
