import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import Slider from "react-slick";

import CanvasStatic from "app/components/CanvasStatic/CanvasStatic";
import Loading from "app/components/Loading/Loading";
import Modal from "app/components/Modal/Modal";
import { useSafeState } from "app/js/hooks";
import { Frame, Image } from "app/js/types";
import styles from "app/components/Modal/GalleryModal.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ArrowProps {
  // Default props that will be set by react-slick itself
  // They are "optional" because otherwise typescripts starts to complain that
  // we do not set them explicitly
  className?: string;
  currentSlide?: number;
  ["data-role"]?: string;
  onClick?: () => void;
  slideCount?: number;
  style?: Record<string, any>;
  // Our custom props
  hasMore: boolean;
  loadMore: () => void;
  isPrev: boolean;
  setCurrentFrame: (index: number) => void;
  sliderRef: any;
}

const Arrow: React.FC<ArrowProps> = ({
  className,
  currentSlide,
  onClick,
  slideCount,
  hasMore,
  loadMore,
  isPrev,
  setCurrentFrame,
  sliderRef,
  ...props
}) => {
  const isNext = !isPrev;
  className = classNames("slick-arrow", styles.sliderArrow, {
    "slick-prev": isPrev,
    [styles.sliderArrowPrev]: isPrev,
    "slick-next": isNext,
    [styles.sliderArrowNext]: isNext,
    "slick-disabled": className.includes("slick-disabled") && !hasMore,
  });
  const switchToNewSlide = React.useCallback(() => {
    const hasPrev = isPrev && currentSlide === 0;
    const hasNext = isNext && currentSlide === slideCount - 1;
    if ((hasPrev || hasNext) && hasMore) {
      loadMore();
    } else {
      const newIndex = isPrev ? currentSlide - 1 : currentSlide + 1;
      sliderRef.current.slickGoTo(newIndex);
      setCurrentFrame(newIndex);
    }
  }, [
    currentSlide,
    hasMore,
    isNext,
    isPrev,
    loadMore,
    setCurrentFrame,
    slideCount,
    sliderRef,
  ]);

  const handleClick = (event?: React.SyntheticEvent) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    switchToNewSlide();
  };

  React.useEffect(() => {
    const downHandler = ({ key }) => {
      const isLeftArrowClick = key === "ArrowLeft" && isPrev;
      const isRightArrowClick = key === "ArrowRight" && isNext;
      if (isLeftArrowClick || isRightArrowClick) {
        switchToNewSlide();
      }
    };

    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [isNext, isPrev, switchToNewSlide]);

  return (
    <button
      type={"button"}
      onClick={handleClick}
      className={className}
      {...props}
    >
      {" "}
      {isPrev ? "Prev" : "Next"}
    </button>
  );
};

interface GalleryFrame extends Pick<Frame, "id" | "entities"> {
  image: Pick<Image, "image" | "width" | "height">;
}

interface SlideProps {
  frame: GalleryFrame;
  handleClick?: () => void;
}

const ImageSlide: React.FC<SlideProps> = ({ frame, handleClick }) => {
  return (
    <img
      src={frame.image.image}
      alt={frame.image.image}
      onClick={(event) => {
        if (!!handleClick && event.button === 0) {
          event.stopPropagation();
          handleClick();
        }
      }}
    />
  );
};

const CanvasSlide: React.FC<SlideProps> = ({ frame, handleClick }) => {
  return (
    <React.Fragment>
      {/*
      Add a transparent image on top of a canvas. The only purpose of it
      is to provide correct media for right-click downloading functionality.
      */}
      <img
        src={frame.image.image}
        alt={frame.image.image}
        onClick={(event) => {
          if (!!handleClick && event.button === 0) {
            event.stopPropagation();
            handleClick();
          }
        }}
      />
      <CanvasStatic
        images={[
          {
            image: frame.image.image,
            width: frame.image.width,
            height: frame.image.height,
            entities: frame.entities,
          },
        ]}
      />
    </React.Fragment>
  );
};

type SliderDirection = "prev" | "next" | null;

interface GalleryModalProps {
  frames: GalleryFrame[];
  activeFrame: number | null;
  setActiveFrame: (frameId: number | null) => void;
  loading: boolean;
  pageLength: number;
  count: number;
  drawEntities: boolean;
  handleClick?: () => void;
}

export default function GalleryModal({
  frames,
  activeFrame,
  setActiveFrame,
  loading,
  pageLength,
  count,
  drawEntities,
  handleClick,
}: GalleryModalProps) {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paramsPage = params.get("page");
  const paramsPageInt = paramsPage ? parseInt(paramsPage) : null;

  const [direction, setDirection] = useSafeState<SliderDirection>(null);

  const slider = React.useRef();

  const setCurrentFrame = (index: number) => {
    /*
    The call is commented, but left here for clarity.
    This is how it _should have_ worked: every time we go to another slide,
    the reference to a current image in state should have been changed.
    The problem is that if we uncomment this call, some flickering appears.
    This happens because technically when we update a state, the component
    is re-rendered. if we do not update the state, animations are smooth.
    And keeping state inconsistent is not a big problem, because we do not
    use that bigFrameId for anything else except the displaying of the
    initial slide.
    */
    // setActiveFrame(frames[index].id);
  };

  React.useEffect(() => {
    if (activeFrame && !frames.find((f) => f.id === activeFrame)) {
      if (direction === "prev") {
        setActiveFrame(frames[frames.length - 1].id);
        setDirection(null);
      } else if (direction === "next") {
        setActiveFrame(frames[0].id);
        setDirection(null);
      }
    }
  }, [activeFrame, direction, frames, setActiveFrame, setDirection]);

  if (!activeFrame) {
    return null;
  }

  const hasPrev = paramsPageInt && paramsPageInt > 1;
  const hasNext = paramsPageInt && paramsPageInt * pageLength < count;

  const loadPrev = () => {
    if (paramsPageInt > 1) {
      setDirection("prev");
      params.set("page", `${paramsPageInt - 1}`);
      history.replace({
        search: params.toString(),
      });
    }
  };
  const loadNext = () => {
    const nextPage = paramsPageInt + 1;
    if (nextPage <= Math.ceil(count / pageLength)) {
      setDirection("next");
      params.set("page", `${nextPage}`);
      history.replace({
        search: params.toString(),
      });
    }
  };

  const handleModalClose = () => {
    setActiveFrame(null);
  };

  const activeIndex = frames.findIndex((frame) => frame.id === activeFrame);
  const sliderSettings = {
    draggable: false,
    slide: "div",
    className: classNames({
      [styles.imageSlider]: !drawEntities,
      [styles.canvasSlider]: drawEntities,
    }),
    initialSlide: activeIndex,
    infinite: false,
    nextArrow: (
      <Arrow
        hasMore={hasNext}
        loadMore={loadNext}
        isPrev={false}
        setCurrentFrame={setCurrentFrame}
        sliderRef={slider}
      />
    ),
    prevArrow: (
      <Arrow
        hasMore={hasPrev}
        loadMore={loadPrev}
        isPrev={true}
        setCurrentFrame={setCurrentFrame}
        sliderRef={slider}
      />
    ),
  };

  return (
    <Modal onRemove={handleModalClose} removeClick={true}>
      <div className={styles.container}>
        {loading && <Loading />}
        {!loading && (
          <Slider ref={slider} {...sliderSettings}>
            {frames.map((frame) =>
              drawEntities ? (
                <CanvasSlide
                  key={frame.id}
                  frame={frame}
                  handleClick={handleClick}
                />
              ) : (
                <ImageSlide
                  key={frame.id}
                  frame={frame}
                  handleClick={handleClick}
                />
              ),
            )}
          </Slider>
        )}
      </div>
    </Modal>
  );
}
