import React from "react";
import Api from "app/js/api";
import { useSafeState } from "app/js/hooks";
import { VisualSampleSetFilterChoicesWithIndex } from "app/pages/LabellingJobs/LabellingJobDetail/LabellingJobFilter/types";
import { VisualSampleSet, VisualSampleSetFilters } from "app/js/types";

export const useFilterChoices = (labeling_job?: number) => {
  const [filterChoices, setFilterChoices] = useSafeState<
    VisualSampleSetFilterChoicesWithIndex[]
  >([]);
  const [loading, setLoading] = useSafeState<boolean>(true);
  const reload = React.useCallback(() => {
    setLoading(true);
    Api.visualSampleSet()
      .filterChoices({ labeling_job })
      .then((response) => {
        setFilterChoices(
          response.data?.map((item, index) => ({ ...item, index })),
        );
        setLoading(false);
      });
  }, [labeling_job, setFilterChoices, setLoading]);
  React.useEffect(() => {
    reload();
  }, [reload]);
  return { filterChoices, loading, reload };
};

interface InputValue {
  value: number;
  label: React.ReactNode;
}

export const useLabellingJobFilterStore = (labeling_job?: number) => {
  const filterChoices = useFilterChoices(labeling_job);
  const [inputValue, setInputValue] = useSafeState<InputValue | null>(null);
  const [filterValue, setFilterValue] = useSafeState<
    VisualSampleSetFilterChoicesWithIndex
  >(null);
  const apply = React.useCallback(
    (value: InputValue | null) => {
      setInputValue(value);
      if (value) {
        setFilterValue(filterChoices.filterChoices[value.value]);
      } else {
        setFilterValue(null);
      }
    },
    [setInputValue, setFilterValue, filterChoices.filterChoices],
  );
  return {
    apply,
    filterChoices,
    inputValue,
    selectedFilter: filterValue,
  };
};

export const useVisualSampleSet = () => {
  const [
    visualSampleSetQuery,
    setVisualSampleSetQuery,
  ] = useSafeState<VisualSampleSetFilters | null>(null);

  const [
    visualSampleSet,
    setVisualSampleSet,
  ] = useSafeState<VisualSampleSet | null>(null);
  React.useEffect(() => {
    if (visualSampleSetQuery) {
      Api.visualSampleSet()
        .all(visualSampleSetQuery)
        .then((response) => {
          const result = response?.data?.results?.[0] || null;
          if (!result) {
            console.error("VisualSampleSet query without result", {
              visualSampleSetQuery,
            });
          }
          setVisualSampleSet(response?.data?.results?.[0] || null);
        });
    } else {
      setVisualSampleSet(null);
    }
  }, [setVisualSampleSet, visualSampleSetQuery]);
  return {
    setQuery: setVisualSampleSetQuery,
    visualSampleSet,
  };
};
