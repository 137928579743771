export const generateColorProperties = (label, opacity = 0.4, zoom = 1) => {
  return {
    stroke:
      label && label !== ""
        ? stringToColor(label, opacity * 2.5)
        : "rgba(255,255,0,0.8)",
    fill: "rgba(0,0,0,0)",
    strokeWidth: 3 / zoom,
    strokeUniform: true,
    cornerColor:
      label && label !== ""
        ? stringToColor(label, opacity)
        : "rgba(255,255,0,0.8)",
    cornerSize: 10,
    hasRotatingPoint: false,
    transparentCorners: false,
    hasBorders: false,
    polygonPointSize: 5 / zoom,
  };
};
export const stringToColor = (input, opacity = 1) =>
  intToRgba(stringToInt(input), opacity);
const intToRgba = (number, opacity = 0.5) => {
  number >>>= 0;
  const b = number & 0xff,
    g = (number & 0xff00) >>> 8,
    r = (number & 0xff0000) >>> 16,
    a = opacity;
  return "rgba(" + [r, g, b, a].join(",") + ")";
};
const stringToInt = (input) => {
  let hash = 0;
  if (input.length === 0) return hash;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};
