import React, { useEffect } from "react";
import styles from "./Modal.scss";

interface ModalProps {
  onRemove: () => void;
  removeClick?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  onRemove,
  removeClick = false,
}) => {
  const onRemoveRef = React.useRef(onRemove);

  useEffect(() => {
    const keydown = (e) => {
      if (e.key === "Escape") {
        onRemove();
      }
    };
    document.addEventListener("keydown", keydown);
    return () => {
      document.removeEventListener("keydown", keydown);
    };
    // To add `onRemove` as a dependency we would have to add `useCallback` in
    // all places which use this component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRemoveRef]);

  return (
    <div className={styles.container} onClick={removeClick ? onRemove : null}>
      {children}
      <button className={styles.removeModal} onClick={onRemove}>
        x
      </button>
    </div>
  );
};
export default Modal;
