import React from "react";

const PlusIcon: React.FC<React.ComponentPropsWithoutRef<"svg">> = (props) => {
  // top left point of bounding rect
  const tl = { x: 0, y: 0 };
  // thickness of plus
  const t = 10;
  // width, how far to extend plus from center
  const w = 25;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 60 60"
      {...props}
    >
      <defs />
      <g>
        {/* Draws plus starting from top, left most point */}
        <path
          d={`M${tl.x + w} ${tl.y} H${tl.x + w + t} V${tl.y + w} H${
            tl.x + w + t + w
          } V${tl.y + w + t} H${tl.x + w + t} V${tl.y + w + t + w} H${
            tl.x + w
          } V${tl.y + w + t} H${tl.x} V${tl.y + w} H${tl.x + w} V${tl.y}`}
          fill="#000000"
          stroke="#000000"
          strokeMiterlimit="10"
          pointerEvents="none"
        />
      </g>
    </svg>
  );
};
export default PlusIcon;
