import React from "react";

export default function ({
  stroke = "#dddddd",
  fill = "#6f6f6f",
  undo = false,
}) {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 128 100"
      style={{
        enableBackground: "new 0 0 128 100", // eslint-disable-line tsc/config
        transform: undo ? "scale(-1)" : "scale(1)",
      }}
    >
      <path
        d="M73.9,50.9v10.9c0,0,17.1-13,31.9-23.4C91.1,28,74,15,74,15v11.7c0,0-19.4,0.1-28.4,0.1
	c-12.5,0-21.9,8.6-23.3,21.1c-0.6,5.9,0.6,11.5,2.8,17c2.9,7.3,7,13.8,11.8,19.8c0.1,0.1,0.2,0.2,0.5,0.3c-0.1-0.4-0.2-0.8-0.4-1.3
	c-1.5-4.9-2.5-9.8-2.5-15c0-3.3,0.5-6.5,2.1-9.5c2.9-5.4,7.5-8.2,13.5-8.3c7.5-0.2,15,0,22.5,0C73,50.9,73.9,50.9,73.9,50.9z"
        fill={fill}
        stroke={stroke}
        strokeMiterlimit={10}
        strokeWidth={6}
      />
    </svg>
  );
}
