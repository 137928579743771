import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Api from "app/js/api";
import Image from "app/components/Image/Image";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import Box from "app/components/Box/Box";
import { useSafeState } from "app/js/hooks";
import Loading from "app/components/Loading/Loading";

export default function StreamDetail({ stream, loadStream, match }) {
  const [actionScript, setActionScript] = useSafeState(null);
  const [loadingActionScript, setLoadingActionScript] = useSafeState(false);
  const [error, setError] = useSafeState(null);

  useEffect(() => {
    const loadActionScript = async () => {
      setLoadingActionScript(true);
      try {
        const response = await Api.actionscript(stream.action_script).show();
        setActionScript(response.data);
        setError(null);
      } catch (error) {
        console.error(error);
        setError(error);
      }
      setLoadingActionScript(false);
    };

    if (stream.action_script != null) {
      loadActionScript();
    }
  }, [setActionScript, setError, setLoadingActionScript, stream]);

  return (
    <div style={{ padding: "25px" }}>
      {error && <ErrorMessage error={error} />}
      <Box>
        {stream.thumbnail && <Image src={stream.thumbnail} alt="Thumbnail" />}
        <p>
          <b>Stream ID:</b> {stream.stream}
          <br />
          <b>Camera Scale:</b>{" "}
          {stream.scale ? `${stream.scale} dots per meter` : ""}
          <br />
          <b>Source Url:</b> {stream.source_url}
        </p>
      </Box>
      <Box title="API Endpoint for Recognition & Collection">
        <p>
          <strong>API Endpoint (POST): </strong>
          {`${window.location.protocol}//${document.env.siteHost}/api/cameras/${stream.stream}/label_image/`}
        </p>
        <p>
          <strong>file: </strong>(Binary Image File)
        </p>
        <pre
          dangerouslySetInnerHTML={{
            __html: `curl  -X POST \\\n -H "Content-Type: multipart/form-data" \\\n -H "Accept: application/json" \\\n -F  "image=@/path/to/file.png" \\\n ${window.location.protocol}//${document.env.siteHost}/api/cameras/${stream.stream}/label_image/`,
          }}
        />
        <p>If you send an Image to the Endpoint, following will happen:</p>
        <ul>
          {stream.dataset ? (
            <li>Image is connected to Dataset {stream.dataset}.</li>
          ) : (
            <li>
              Image is <strong>not</strong> connected to any Dataset (
              <Link to={`${match.url}/edit`}>Edit Camera</Link> to Change it)
            </li>
          )}
          {stream.save_image ? (
            <li>Image will be stored to Dataset automatically.</li>
          ) : (
            <li>
              Image will <strong>not</strong> be stored in any Dataset (
              <Link to={`${match.url}/edit`}>Edit Camera</Link> to Change it)
            </li>
          )}
          {stream.general_detection_details ||
          stream.graph_detection_details ? (
            <li>Objects/surfaces will be detected.</li>
          ) : (
            <li>
              Objects/surfaces will <strong>not</strong> be detected by any
              Detector. (<Link to={`${match.url}/edit`}>Edit Camera</Link> to
              Change it)
            </li>
          )}
          {loadingActionScript && <Loading />}
          {!loadingActionScript &&
            actionScript &&
            (stream.action_script ? (
              <li>
                The code in{" "}
                <Link to={`${match.url}/script`}>
                  Actionscript {actionScript.name}
                </Link>{" "}
                is run for label preperation and post processing.
              </li>
            ) : (
              <li>
                <strong>No Code</strong> will be run during request. (
                <Link to={`${match.url}/edit`}>Edit Camera</Link> to Change it)
              </li>
            ))}
        </ul>
      </Box>
    </div>
  );
}
