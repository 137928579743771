import React from "react";
import classNames from "classnames";
import styles from "app/pages/AppConfigs/EditConfigForm/fields/AABoxField.scss";

const isInvalid = (
  index: number,
  formData?: number[],
  schema?: { default?: number[]; items: { minimum?: number }[] },
): boolean => {
  // not invalid if the formData is completely unset
  if (!formData) {
    return false;
  }
  const value = Number.parseInt(`${formData[index]}`);
  if (isNaN(value)) {
    return true;
  }
  const minimum = schema?.items?.[index]?.minimum || 0;
  return value < minimum;
};

/** We want to treat empty string, null, and undefined but not 0 as unset values. */
const isUnsetValue = (v: string | number | null | undefined): boolean =>
  v === "" || v === null || v === undefined;

const AABoxField = (props) => {
  const { idSchema, formData, onChange, schema } = props;
  const _onChange = React.useCallback(
    (event) => {
      const newValue = formData
        ? formData.map((v) => (isUnsetValue(v) ? null : +v))
        : schema.items.map((_) => null);
      newValue[event.target.dataset.index] = isUnsetValue(event.target.value)
        ? null
        : +event.target.value;
      const allUnset = newValue.every(isUnsetValue);
      if (allUnset) {
        onChange(undefined);
      } else {
        onChange(newValue);
      }
    },
    [formData, schema.items, onChange],
  );

  React.useEffect(() => {
    // The default formData produced by rjsf is an array of undefined
    // which is not a valid data for AABox data. We need to update it
    // from `[undefined,undefined,undefined,undefined]` to `undefined`
    // so it becomes a valid value for optional AABox data.
    const allUnset = formData?.every(isUnsetValue);
    if (allUnset) {
      onChange(undefined);
    }
  }, [formData, onChange]);

  return (
    <fieldset
      className="field field-array field-array-fixed-items"
      id={idSchema.$id}
    >
      <legend id="root_aabox_detection__title">{schema.title}</legend>
      <div className="row array-item-list">
        {schema.items.map((itemSchema: { minimum?: number }, index) => (
          <div className="array-item" key={`${idSchema.$id}_${index}`}>
            <div className="col-xs-12">
              <div className="form-group field field-integer">
                <input
                  className={classNames("form-control", {
                    [styles.invalidField]: isInvalid(index, formData, schema),
                  })}
                  data-index={index}
                  id={`${idSchema.$id}_${index}`}
                  // We only want to show the placeholder if formData is unset
                  placeholder={(!formData && schema?.default?.[index]) || ""}
                  type="number"
                  step="1"
                  min={itemSchema?.minimum}
                  value={
                    formData?.[index] || formData?.[index] === 0
                      ? formData?.[index]
                      : ""
                  }
                  onChange={_onChange}
                  // required=""
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default AABoxField;
