import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styles from "./Login.scss";
import Button from "app/components/Buttons/Button";
import Box from "app/components/Box/Box";
import FormComponent from "app/components/FormComponent/FormComponent";

import Api from "app/js/api";
import { useUserStore } from "app/js/stores";
import { useSafeState } from "app/js/hooks";

export default function Login(props) {
  const [_user, userActions] = useUserStore();
  const [userLogin, setUser] = useSafeState({
    email: "",
    password: "",
    loading: false,
    errors: null,
  });

  const history = useHistory();
  const location = useLocation<{ from: Location } | undefined>();
  const { from } = location.state || { from: { pathname: "/" } };

  const login = async (e) => {
    e.preventDefault();
    try {
      const response = await Api.user().login(
        userLogin.email,
        userLogin.password,
      );
      userActions.setToken(response.data.token);
      history.replace(from);
    } catch (error) {
      setUser({
        ...userLogin,
        loading: false,
        errors: {
          message: error.response.data.non_field_errors[0],
        },
      });
    }
  };

  const handleChange = (e) => {
    setUser({ ...userLogin, [e.target.name]: e.target.value });
  };

  return (
    <form className={styles.container} onSubmit={login}>
      <Box title="Login" error={userLogin.errors}>
        <FormComponent>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            name="email"
            onChange={handleChange}
            value={userLogin.email}
            placeholder="user@example.org"
          />
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            name="password"
            onChange={handleChange}
            value={userLogin.password}
            placeholder="***********"
          />
        </FormComponent>
        <Button onClick={login}>Login</Button>
      </Box>
      <div className={styles.links}>
        <Link to="/signup">Sign up</Link>
        <Link to="/password/recover">Forgot Password</Link>
      </div>
    </form>
  );
}
