import React from "react";

import TrashButton from "app/components/Buttons/TrashButton";

import styles from "./Buttons.scss";
import PlusIcon from "app/components/Icons/PlusIcon";
import { LinkButton } from "app/components/Buttons";

interface CardTrashButtonProps {
  onClick: () => void;
}

export function CardTrashButton({
  onClick,
}: CardTrashButtonProps): React.ReactElement {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onClick();
  };

  return <TrashButton className={styles.button} onClick={handleClick} />;
}

interface CardAddButtonProps {
  link: string;
  title: string;
}

export function CardAddButton({
  link,
  title,
}: CardAddButtonProps): React.ReactElement {
  return (
    <LinkButton link={link} title={title}>
      <PlusIcon width="20px" height="20px" />
    </LinkButton>
  );
}
