import React, { useEffect, Fragment } from "react";

import AutoEntities from "./AutoEntities/AutoEntities";
import ExtractImages from "./ExtractImages/ExtractImages";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import { Error } from "app/components/ErrorMessage/types";
import Loading from "app/components/Loading/Loading";
import Api from "app/js/api";
import { useSafeState } from "app/js/hooks";
import { ProcessingTask } from "./types";

export default function ProcessingTaskDetail({ match, ...props }) {
  const taskId = match.params.id;
  const [processingTask, setProcessingTask] = useSafeState<ProcessingTask>(
    null,
  );
  const [loading, setLoading] = useSafeState<boolean>(true);
  const [error, setError] = useSafeState<Error>(null);

  useEffect(() => {
    const loadTask = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await Api.processingTask(taskId).show();
        setProcessingTask(response.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    loadTask();
  }, [setError, setLoading, setProcessingTask, taskId]);

  let taskBody = <p>Invalid task</p>;
  if (processingTask) {
    if (processingTask.type === "extract-images") {
      taskBody = <ExtractImages processingTask={processingTask} {...props} />;
    } else if (processingTask.type === "create-auto-entities") {
      taskBody = <AutoEntities processingTask={processingTask} {...props} />;
    }
  }

  return (
    <Fragment>
      {loading && <Loading />}
      {error && <ErrorMessage error={error} />}
      {!loading && !error ? taskBody : null}
    </Fragment>
  );
}
