import React, { Fragment, useEffect } from "react";
import Select from "react-select";

import Api from "app/js/api";
import Loading from "app/components/Loading/Loading";
import { useSafeState } from "app/js/hooks";
import { useUserStore } from "app/js/stores";
import { Option, User } from "app/js/types";

interface SelectUserProps {
  value: string;
  setUser: (user: User) => void;
}

const SelectUser: React.FC<SelectUserProps> = ({ value, setUser }) => {
  const [teamUsers, setTeamUsers] = useSafeState<User[]>([]);
  const [user] = useUserStore();
  const [loading, setLoading] = useSafeState(false);
  useEffect(() => {
    const loadTeamUsers = async () => {
      setLoading(true);
      const response = await Api.team(user.data.team_id).members().all();
      const teamUsers = response.data.results.map(
        (teamMember) => teamMember.user,
      );
      setTeamUsers(teamUsers);
      setLoading(false);
    };

    loadTeamUsers();
  }, [setLoading, setTeamUsers, user.data]);

  return (
    <Fragment>
      {loading && <Loading />}
      {!loading && teamUsers.length > 0 && (
        <Select
          value={teamUsers
            .filter((entry) => `${entry.id}` === value)
            .map((entry) => ({
              label: `${entry.name} (${entry.email})`,
              value: entry.id,
            }))}
          onChange={(e: Option<number>) => {
            const newUser = teamUsers.find((entry) => entry.id === e.value);
            setUser(newUser);
          }}
          options={teamUsers.map((entry) => ({
            label: `${entry.name} (${entry.email})`,
            value: entry.id,
          }))}
        />
      )}
    </Fragment>
  );
};
export default SelectUser;
