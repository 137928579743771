import React, { useEffect, Fragment } from "react";

import MessageBox from "app/components/MessageBox/MessageBox";
import CanvasStatic from "app/components/CanvasStatic/CanvasStatic";

import { useSafeState } from "app/js/hooks";
import { useUserStore } from "app/js/stores";
import { Sprite } from "app/js/types";

interface StreamViewProps {
  stream: string;
  fullscreen?: boolean;
}

const StreamView: React.FC<StreamViewProps> = ({
  stream,
  fullscreen = false,
}) => {
  const [user] = useUserStore();
  const [imageWithEntities, setImageWithEntities] = useSafeState<Sprite>(null);
  const [message, setMessage] = useSafeState<string>(null);
  const [_webSocket, setWebSocket] = useSafeState(null);

  const onWebSocketMessage = (event) => {
    console.log("Message Received");
    const socketData = JSON.parse(event.data);
    setImageWithEntities({
      ...socketData.message,
    });
  };

  const initWebSocket = async () => {
    try {
      setMessage("Connecting...");
      const socketUrl = `ws${document.env.sslSocket === "True" ? "" : "s"}://${
        document.env.siteHost
      }/ws/cameras/${stream}`;
      console.log("Connecting to:", socketUrl);
      const socket = new WebSocket(
        `ws${window.location.protocol === "http:" ? "" : "s"}://${
          document.env.siteHost
        }/ws/cameras/${stream}`,
      );
      socket.onmessage = onWebSocketMessage;
      socket.onerror = () => {
        console.log("socket.onerror");
        setMessage("Connection failure. Refresh to try again.");
      };
      socket.onopen = () => {
        console.log("socket.onopen");
        setMessage("Connected. Waiting for images...");
      };
      socket.onclose = () => {
        console.log("socket.onclose");
        setMessage("Connection closed. Refresh to reconnect.");
      };
      setWebSocket(socket);
      return socket;
    } catch (error) {
      setMessage("Connection failure. Refresh to try again.");
    }
  };

  useEffect(() => {
    let socket: Promise<WebSocket> = null;
    if (stream !== null) {
      socket = initWebSocket();
    }
    return () => {
      if (socket !== null) {
        Promise.resolve(socket).then((s) => s.close());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream, user.data]);

  // Fullscreen Mode
  if (fullscreen) {
    return (
      <CanvasStatic images={imageWithEntities ? [imageWithEntities] : []} />
    );
  }

  return (
    <Fragment>
      <MessageBox messages={[message]} />
      <CanvasStatic images={imageWithEntities ? [imageWithEntities] : []} />
    </Fragment>
  );
};
export default StreamView;
