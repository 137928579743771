import React from "react";
import moment from "moment";

import Api from "app/js/api";
import CardList from "app/components/CardList/CardList";
import VideoCard from "app/components/Card/VideoCard";
import Loading from "app/components/Loading/Loading";
import Paginator from "app/components/Paginator/Paginator";
import Button from "app/components/Buttons/Button";
import DateRangeInput from "app/components/Inputs/DateRangeInput";
import TimeRangeInput from "app/components/Inputs/TimeRangeInput";
import WeekdayInput from "app/components/Inputs/WeekdayInput";

import { LoadedDataCallback, useLoadedData, useSafeState } from "app/js/hooks";
import { Video } from "app/js/types";

export default function DatasetDetailVideosAdd({ match }) {
  const datasetId = match.params.id;

  const [selectedVideos, setSelectedVideos] = useSafeState([]);
  const PAGE_LENGTH = 10;
  const [offset, setOffset] = useSafeState(0);

  // Filters
  const [showFilter, setShowFilter] = useSafeState(false);
  const [dateRange, setDateRange] = useSafeState({
    before: null,
    after: null,
  });
  const [timeRange, setTimeRange] = useSafeState({
    before: "23:59",
    after: "00:00",
  });
  const [weekdays, setWeekdays] = useSafeState([1, 2, 3, 4, 5, 6, 7]);

  const videoRequestCallback: LoadedDataCallback<Video[]> = React.useCallback(
    async (setValue, setCount) => {
      const data = {
        start_time_before: dateRange.before
          ? moment(dateRange.before).format()
          : null,
        start_time_after: dateRange.after
          ? moment(dateRange.after).format()
          : null,
        week_day: weekdays,
        time_of_day_before: timeRange.before,
        time_of_day_after: timeRange.after,
        limit: PAGE_LENGTH,
        offset,
        exclude_dataset_ids: [parseInt(datasetId)],
      };
      const response = await Api.video().all(data);

      setValue(response.data.results);
      setCount(response.data.count);
    },
    [
      datasetId,
      dateRange.after,
      dateRange.before,
      offset,
      timeRange.after,
      timeRange.before,
      weekdays,
    ],
  );
  const {
    value: videos,
    count,
    loading,
    requestCallback: loadVideos,
  } = useLoadedData<Video[]>([], videoRequestCallback);
  const onExistingVideoClick = (id) => {
    const index = selectedVideos.indexOf(id);
    if (index > -1) {
      selectedVideos.splice(index, 1);
      setSelectedVideos([...selectedVideos]);
    } else {
      selectedVideos.push(id);
      setSelectedVideos([...selectedVideos]);
    }
  };

  const onAddvideos = async (e) => {
    try {
      await Api.dataset(datasetId).videos().add({ ids: selectedVideos });
      alert("You added the Videos successfully");
      loadVideos();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ padding: "0 25px 0 25px" }}>
      <h2>Connect Videos to this Dataset:</h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "10px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <label>
          <input
            type="checkbox"
            onChange={() => {
              setShowFilter((prev) => !prev);
            }}
          />{" "}
          Filter Videos
        </label>
        {!loading && selectedVideos.length > 0 && (
          <Button onClick={onAddvideos}>Add Videos</Button>
        )}
      </div>
      {showFilter && (
        <div>
          <h4>Filter Videos by Time</h4>
          <DateRangeInput
            range={dateRange}
            onRangeUpdate={(range) => {
              setDateRange((prev) => ({ ...prev, ...range }));
            }}
          />
          <WeekdayInput
            weekdays={weekdays}
            onWeekdaysUpdate={(newWeekdays) => {
              setWeekdays(newWeekdays);
            }}
          />
          <TimeRangeInput
            range={timeRange}
            onRangeUpdate={(range) => {
              setTimeRange((prev) => ({ ...prev, ...range }));
            }}
          />
        </div>
      )}
      {loading && <Loading />}
      {count > PAGE_LENGTH && (
        <Paginator
          onChange={setOffset}
          count={count}
          offset={offset}
          pageLength={PAGE_LENGTH}
          disabled={loading}
        />
      )}
      <CardList>
        {!loading &&
          videos.map((video) => (
            <VideoCard
              key={video.id}
              video={video}
              selected={selectedVideos.indexOf(video.id) > -1}
              onClick={() => onExistingVideoClick(video.id)}
            />
          ))}
      </CardList>
      {!loading && videos.length === 0 && <p>No Videos available</p>}
    </div>
  );
}
