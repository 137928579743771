import React from "react";
import styles from "./Box.scss";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import { Error } from "app/components/ErrorMessage/types";

interface BoxProps {
  title?: string;
  error?: Error;
  theme?: string;
  className?: string;
}

const Box: React.FC<BoxProps> = ({
  title,
  error,
  theme,
  children,
  className,
}) => {
  return (
    <div className={`${styles.box} ${styles[theme]} ${className}`}>
      {title && (
        <div className={styles.header}>
          <h2>{title}</h2>
        </div>
      )}
      <div className={styles.content}>
        {error && <ErrorMessage error={error} />}
        <div className={styles.grid}>{children}</div>
      </div>
    </div>
  );
};
export default Box;
