import React from "react";
import { Switch, Route } from "react-router-dom";

import LabellingJobList from "./LabellingJobList/LabellingJobList";
import LabellingJobForm from "./LabellingJobForm/LabellingJobForm";
import LabellingJobDetail from "./LabellingJobDetail/Routes";

import LabellingRedirect from "./Labelling/LabellingRedirect";
import Labelling from "./Labelling/Labelling";

export default function LabellingJobs({ match }) {
  return (
    <Switch>
      <Route path={match.path} exact component={LabellingJobList} />
      <Route path={`${match.path}/create`} exact component={LabellingJobForm} />
      <Route
        path={`${match.path}/:labellingJobId/edit`}
        exact
        component={LabellingJobForm}
      />
      <Route
        path={`${match.path}/:labellingJobId/start`}
        exact
        component={LabellingRedirect}
      />
      <Route
        path={`${match.path}/:labellingJobId/frame/:frameId`}
        exact
        component={Labelling}
      />
      <Route
        path={`${match.path}/:labellingJobId`}
        component={LabellingJobDetail}
      />
    </Switch>
  );
}
