import React from "react";
import { useHistory } from "react-router-dom";

import { useSafeState } from "app/js/hooks";
import { NumericId } from "app/js/types";
import Button from "app/components/Buttons/Button";
import Modal from "./Modal";
import styles from "app/components/LabelSprite/LabelSprite.scss";
import SelectLabellingJob, {
  LabelingJobForSelect,
} from "app/components/Selects/SelectLabellingJob";

interface JobSelectModalProps {
  onRemove: () => void;
  datasetId: number;
  preloadedJobs?: LabelingJobForSelect[] | null;
  makeEntityUrl: (jobId: NumericId, teamId: NumericId) => string;
  preferredJobId: number | null;
}

export default function JobSelectModal({
  onRemove,
  datasetId,
  preloadedJobs = null,
  makeEntityUrl,
  preferredJobId = null,
}: JobSelectModalProps): React.ReactElement {
  const history = useHistory();
  const [
    selectedJob,
    setSelectedJob,
  ] = useSafeState<LabelingJobForSelect | null>(
    !!preferredJobId && !!preloadedJobs
      ? preloadedJobs.find((job) => job.id === preferredJobId)
      : null,
  );

  const link =
    selectedJob !== null
      ? makeEntityUrl(selectedJob.id, selectedJob.team)
      : null;
  return (
    <Modal onRemove={onRemove}>
      <div className={styles.jobSelectBackground}>
        <div className={styles.jobSelectForm}>
          <h3>Label with Labelling Job</h3>
          <SelectLabellingJob
            required={true}
            value={selectedJob?.id || null}
            setLabellingJob={(job) => {
              setSelectedJob(job);
            }}
            datasetId={datasetId}
            preloadedJobs={preloadedJobs}
            preferredJobId={preferredJobId}
          />
          <Button
            className={styles.jobSelectButton}
            disabled={selectedJob === null}
            onClick={() => history.push(link)}
          >
            Go To Image
          </Button>
        </div>
      </div>
    </Modal>
  );
}
