import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { useLoadedData, useSafeState } from "app/js/hooks";
import { useUserStore } from "app/js/stores";

import Box from "app/components/Box/Box";
import { CardProps } from "app/components/Card";
import CardList from "app/components/CardList/CardList";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import Header, { HeaderProps } from "app/components/Header/Header";
import Loading from "app/components/Loading/Loading";
import Paginator from "app/components/Paginator/Paginator";
import { getContent } from "app/components/Support/helper";

import styles from "./ListPage.scss";

export type ListPageLoadCallback<T> = (
  setValue: (value: T) => void,
  setCount: (count: number) => void,
  limit: number,
  offset: number,
  ...args: unknown[]
) => Promise<void>;

interface ListPageProps<T> extends HeaderProps {
  pageTitle: string;
  emptyPageTitle: string;
  loadDataCallback: ListPageLoadCallback<T[]>;
  renderCard: (cardItem: T, basePath: string) => React.ReactElement<CardProps>;
  keyAttr?: string;
  additionalEmptyBoxInfo?: React.ReactElement;
  additionalContent?: React.ReactElement;
}

export default function ListPage<T>({
  pageTitle,
  emptyPageTitle,
  loadDataCallback,
  renderCard,
  keyAttr = "id",
  additionalEmptyBoxInfo = null,
  additionalContent = null,
  ...headerProps
}: ListPageProps<T>): React.ReactElement {
  const [user] = useUserStore();

  const history = useHistory();
  const match = useRouteMatch();
  headerProps = headerProps || {};
  const basePath =
    headerProps.basePath != null ? headerProps.basePath : match.path;
  headerProps.basePath = basePath;

  const PAGE_LENGTH = 10;
  const [offset, setOffset] = useSafeState<number>(0);

  const loadWithOffset = React.useCallback(
    async (setValue, setCount) => {
      // React does not allow extra dependencies for callbacks, so "using" this dependency
      const _hack = user.data;
      await loadDataCallback(setValue, setCount, PAGE_LENGTH, offset);
    },
    [loadDataCallback, offset, user.data],
  );

  const { value: values, count, loading, error } = useLoadedData<T[]>(
    [],
    loadWithOffset,
  );

  let contentElement;
  if (loading) {
    contentElement = <Loading />;
  } else if (error) {
    contentElement = <ErrorMessage error={error} />;
  } else {
    contentElement =
      values && values.length ? (
        <CardList style={{ padding: "25px" }}>
          {values.map((obj) => (
            // key is not passed as a prop, so I cannot set it inside renderCard
            // I have to assign it here explicitly. And because I don't know in general,
            // which field this object uses as an id, I get it from props
            <React.Fragment key={obj[keyAttr]}>
              {renderCard(obj, basePath)}
            </React.Fragment>
          ))}
        </CardList>
      ) : (
        <div className={styles.emptyBox}>
          <Box title={emptyPageTitle}>
            {getContent(history.location.pathname)}
            {additionalEmptyBoxInfo}
          </Box>
        </div>
      );
  }

  return (
    <React.Fragment>
      <Header {...headerProps}>
        <h1>{pageTitle}</h1>
        {count > PAGE_LENGTH && (
          <Paginator
            onChange={setOffset}
            count={count}
            offset={offset}
            pageLength={PAGE_LENGTH}
            disabled={loading}
          />
        )}
      </Header>
      {additionalContent}
      {contentElement}
    </React.Fragment>
  );
}
