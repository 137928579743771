import React from "react";
import styles from "./LogModal.scss";
import Modal from "./Modal";

interface LogModalProps {
  log: string[];
  title: string;
  onRemoveLog: () => void;
}

const LogModal: React.FC<LogModalProps> = ({ log, title, onRemoveLog }) => {
  if (!log) {
    return null;
  }

  return (
    <Modal onRemove={onRemoveLog} removeClick={false}>
      <div className={styles.container}>
        <h2>{title} Log</h2>
        <ol>
          {log.map((line) => (
            <li>{line}</li>
          ))}
        </ol>
      </div>
    </Modal>
  );
};
export default LogModal;
