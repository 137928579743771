import React, { Fragment } from "react";
import moment from "moment";

import FormComponent from "app/components/FormComponent/FormComponent";

interface Range {
  after?: string;
  before?: string;
}

interface DateRangeInputProps {
  range: Range;
  onRangeUpdate: (range: Range) => void;
}

const DateRangeInput: React.FC<DateRangeInputProps> = ({
  range,
  onRangeUpdate,
}) => {
  return (
    <Fragment>
      <FormComponent>
        <label>Date from:</label>
        <input
          type="date"
          value={
            range.after ? moment(range.after).format(moment.HTML5_FMT.DATE) : ""
          }
          onChange={(e) => {
            onRangeUpdate({ after: e.target.value });
          }}
        />
        <label>Date to:</label>
        <input
          type="date"
          value={
            range.before
              ? moment(range.before).format(moment.HTML5_FMT.DATE)
              : ""
          }
          onChange={(e) => {
            onRangeUpdate({ before: e.target.value });
          }}
        />
      </FormComponent>
    </Fragment>
  );
};
export default DateRangeInput;
