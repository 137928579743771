import React, { Dispatch, Fragment, SetStateAction } from "react";
import classNames from "classnames";
import CancelIcon from "@mui/icons-material/Cancel";
import Loading from "app/components/Loading/Loading";
import SelectMode from "app/components/Selects/SelectMode";
import { useSafeState } from "app/js/hooks";
import { Entity, LabelVersionLabels } from "app/js/types";
import styles from "app/pages/LabellingJobs/Labelling/components/EntityList/EntityList.scss";

interface EntityDayaProps {
  entity: Entity;
}

const EntityData: React.FC<EntityDayaProps> = ({ entity }) => {
  const showShapeSize = (entity) => {
    if (entity.aabox) {
      return (
        <div key={`aabox_show_size_${entity.id}`}>
          {entity.aabox.width}x{entity.aabox.height}
        </div>
      );
    } else if (entity.circle) {
      return (
        <div key={`circle_show_size_${entity.id}`}>
          r: {entity.circle.radius}
        </div>
      );
    } else if (entity.contour) {
      //get min and max coordinate values of X and Y axis of the polygon shape
      //get min and max of x
      const minX = entity.contour
        .map((el) => el[0])
        .reduce(function (acc, cur) {
          // return min x coordinate value
          return Math.min(acc, cur);
        });
      const maxX = entity.contour
        .map((el) => el[0])
        .reduce(function (acc, cur) {
          // return max x coordinate value
          return Math.max(acc, cur);
        });
      const maxWidth = minX && maxX ? Math.round(Math.abs(maxX - minX)) : null;

      //get min and max of y
      const minY = entity.contour
        .map((el) => el[1])
        .reduce(function (acc, cur) {
          // return min y coordinate value
          return Math.min(acc, cur);
        });
      const maxY = entity.contour
        .map((el) => el[1])
        .reduce(function (acc, cur) {
          // return max y coordinate value
          return Math.max(acc, cur);
        });
      const maxHeight = minY && maxY ? Math.round(Math.abs(maxY - minY)) : null;
      if (maxWidth && maxHeight) {
        return (
          <div key={`polygon_show_size_${entity.id}`}>
            {maxWidth}x{maxHeight}
          </div>
        );
      } else return;
    } else return;
  };

  const formatConfidence = (value: number): string => {
    let roundedValue = value * 100;
    roundedValue = Math.round(roundedValue * 100) / 100;
    return `${roundedValue}%`;
  };

  return (
    <div className={styles.entityData}>
      <span>{entity && showShapeSize(entity)}</span>
      {entity.confidence != null && (
        <div className={styles.confidenceWrapper}>
          <span className={styles.confidenceLabel}>Confidence:</span>
          <div className={styles.confidence}>
            <div
              className={styles.confidenceBar}
              style={{ width: formatConfidence(entity.confidence) }}
            />
            <span className={styles.confidenceValue}>
              {formatConfidence(entity.confidence)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

interface EntityListProps {
  activeEntityId: number;
  setActiveEntityId: (entityId: number) => void;
  entities: Entity[];
  setEntities: Dispatch<SetStateAction<Entity[]>>;
  typeHandler: (event, id, value?) => void;
  inputLabel: React.Ref<HTMLInputElement>;
  recommendations: string[][];
  labels: LabelVersionLabels;
  loading: boolean;
  showModeSelect?: boolean;
}

const EntityList: React.FC<EntityListProps> = ({
  activeEntityId,
  setActiveEntityId,
  entities,
  setEntities,
  typeHandler,
  inputLabel,
  recommendations,
  labels,
  loading,
  children,
  showModeSelect = true,
}) => {
  const [activeIndex, setActiveIndex] = useSafeState<number>(-1);
  const keydown = (event) => {
    const { key } = event;

    const entityIndex = entities.findIndex(
      (entry) => entry.id === activeEntityId,
    );

    switch (key) {
      case "Tab":
        if (entityIndex === -1) {
          return;
        }
        event.preventDefault();
        setActiveIndex((prev) => {
          if (prev + 1 < totalLabelLength(entityIndex)) {
            return prev + 1;
          } else {
            return 0;
          }
        });
        break;
      case "Enter":
        if (activeIndex !== -1) {
          event.preventDefault();
          if (getCleanedRecommendationList(entityIndex).length > activeIndex) {
            // find value
            entities[entityIndex].label = getCleanedRecommendationList(
              entityIndex,
            )[activeIndex];
          } else {
            const ObjectIndex =
              activeIndex - getCleanedRecommendationList(entityIndex).length;
            const ObjectArray = getCleanedLabelList(entityIndex);
            entities[entityIndex].label = ObjectArray[ObjectIndex];
          }
          setEntities(JSON.parse(JSON.stringify(entities)));
          setActiveIndex(-1);
        }

        // check if last entity
        if (entityIndex === entities.length - 1) {
          setActiveEntityId(null);
        } else {
          setActiveEntityId(entities[entityIndex + 1].id);
        }
        break;
      default:
        setActiveIndex(-1);
    }
  };

  const totalLabelLength = (entityIndex) => {
    return (
      getCleanedRecommendationList(entityIndex).length +
      getCleanedLabelList(entityIndex).length
    );
  };

  const getCleanedRecommendationList = (entityIndex) => {
    if (!recommendations[entityIndex]) return [];

    return recommendations[entityIndex]
      .filter((entry) => entry.includes(entities[entityIndex].label))
      .filter((entry) => entry !== entities[entityIndex].label);
  };
  const getCleanedLabelList = (entityIndex) => {
    return Object.keys(labels)
      .filter((entry) => entry.includes(entities[entityIndex].label))
      .filter((entry) => {
        if (recommendations[entityIndex]) {
          return !recommendations[entityIndex].includes(entry);
        }
        return true;
      })
      .filter((entry) => entry !== entities[entityIndex].label);
  };

  const onEnabledCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const enabled = event.target.checked;
    const entityId = event.target.dataset.entity;
    setEntities(
      entities.map((entity) => {
        if (`${entity.id}` === `${entityId}`) {
          return { ...entity, enabled };
        } else {
          return entity;
        }
      }),
    );
  };

  return (
    <div className={styles.container} onKeyDown={keydown} tabIndex={0}>
      <div className={styles.metabox}>
        <ul>{children}</ul>
      </div>
      {loading && <Loading color="#fff" />}
      {!loading && showModeSelect && (
        <Fragment>
          <SelectMode />
          <br />
        </Fragment>
      )}
      {!loading && !entities.length && (
        <p>Add a shape for the labelling List first.</p>
      )}
      {!loading &&
        entities.length > 0 &&
        entities.map((entity, index) => (
          <div
            key={`EntityList item ${entity.id}`}
            className={classNames(styles.field, {
              [styles.active]: entity.id === activeEntityId,
              [styles.disabled]: !entity.enabled,
            })}
          >
            <input
              type="checkbox"
              defaultChecked={entity.enabled}
              className={styles.enabledCheckbox}
              onChange={onEnabledCheckboxChange}
              data-entity={entity.id}
            />
            {entity && entity.id !== activeEntityId && (
              <Fragment>
                <input
                  value={entity.label}
                  placeholder="Tell me what that is..."
                  onClick={(e) => {
                    setActiveEntityId(entity.id);
                  }}
                  onChange={(e) => {
                    typeHandler(e, entity.id);
                  }}
                  className={styles.fieldInput}
                />
              </Fragment>
            )}
            {entity.id === activeEntityId && (
              <Fragment>
                <input
                  ref={inputLabel}
                  value={entity.label}
                  onChange={(e) => {
                    typeHandler(e, entity.id);
                  }}
                  className={styles.fieldInput}
                />
                {totalLabelLength(index) > 0 && (
                  <div className={styles.autocomplete}>
                    <Fragment>
                      {getCleanedRecommendationList(index).map(
                        (entry, entryIndex) => (
                          <div
                            key={`EntityList recommendation ${entry}`}
                            className={
                              entryIndex === activeIndex
                                ? styles.activeLabel
                                : styles.recommendation
                            }
                            onClick={(event) => {
                              typeHandler(null, entity.id, entry);
                            }}
                          >
                            {entry}
                          </div>
                        ),
                      )}
                    </Fragment>
                    <Fragment>
                      {getCleanedLabelList(index).map((entry, entryIndex) => (
                        <div
                          key={`EntityList label ${entry}`}
                          onClick={(event) => {
                            typeHandler(null, entity.id, entry);
                          }}
                          className={
                            entryIndex +
                              getCleanedRecommendationList(index).length ===
                            activeIndex
                              ? styles.activeLabel
                              : " "
                          }
                        >
                          {entry}
                        </div>
                      ))}
                    </Fragment>
                  </div>
                )}
              </Fragment>
            )}
            <button
              className={styles.delete}
              onClick={() => {
                setEntities((prev: Entity[]) => {
                  prev.splice(index, 1);
                  return JSON.parse(JSON.stringify(prev));
                });
              }}
            >
              <CancelIcon className={styles.deleteIcon} />
            </button>
            <EntityData entity={entity} />
          </div>
        ))}
    </div>
  );
};

export default EntityList;
