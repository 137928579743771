import React, { Fragment, useCallback } from "react";

import Api from "app/js/api";
import Header from "app/components/Header/Header";
import Button from "app/components/Buttons/Button";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

import {
  LoadedDataCallback,
  useLoadedData,
  useUserCallback,
} from "app/js/hooks";
import { Dataset } from "app/js/types";
import { Form, FormFields } from "app/components/Form";

export default function DataSetForm({ match, history }) {
  const datasetId = match.params.id;

  // State Handling Logic
  const loadDatasetCallback = useUserCallback<LoadedDataCallback<Dataset>>(
    async (setValue) => {
      if (datasetId) {
        const response = await Api.dataset(datasetId).show();
        setValue(response.data);
      }
    },
    [datasetId],
  );
  const {
    value: dataset,
    loading,
    error,
    setValue: setDataset,
  } = useLoadedData<Dataset>(
    {
      url: "",
      id: null,
      name: "",
      description: "",
      videos_count: 0,
      scenes_count: 0,
      frames_count: 0,
    },
    loadDatasetCallback,
  );

  const submitDatasetCallback = React.useCallback(
    async (setValue, setCount) => {
      if (datasetId) {
        // Edit mode
        await Api.dataset(datasetId).update(dataset);
        history.push(`/data-sets/${datasetId}`);
      } else {
        // Create Mode
        const res = await Api.dataset(datasetId).store(dataset);
        history.push(`/data-sets/${res.data.id}`);
      }
    },
    [dataset, datasetId, history],
  );
  const {
    loading: submitDatasetLoading,
    error: submitDatasetError,
    requestCallback: submitDataset,
  } = useLoadedData(null, submitDatasetCallback, true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await submitDataset();
  };

  const onChangeInputHandle = useCallback(
    (value: Partial<Dataset>) => {
      setDataset((prevDataset: Dataset) => {
        return { ...prevDataset, ...value };
      });
    },
    [setDataset],
  );

  const isLoading = loading || submitDatasetLoading;
  const anyError = error || submitDatasetError;

  // render
  return (
    <Fragment>
      <Header>
        {loading ? (
          <h1>Loading...</h1>
        ) : (
          <h1>Datasets - {datasetId ? dataset.name : "Create"}</h1>
        )}
      </Header>
      {anyError && <ErrorMessage error={anyError} />}
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit} style={{ padding: "25px" }}>
          <Form
            formName={"datasetEdit"}
            layout={"vertical"}
            onFormUpdate={onChangeInputHandle}
          >
            <FormFields.CharField
              label="Name"
              paramName="name"
              value={dataset.name}
            />
            <FormFields.Textarea
              label="Description"
              paramName="description"
              value={dataset.description}
            />
          </Form>
          <Button>Save</Button>
        </form>
      )}
    </Fragment>
  );
}
