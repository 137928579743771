import React from "react";
const CamMatrixRowField = (props) => {
  const { idSchema, formData, onChange } = props;
  const _onChange = React.useCallback(
    (event) => {
      const newValue = [...formData];
      newValue[event.target.dataset.index] = event.target.value;
      onChange(newValue.map((value) => +value));
    },
    [onChange, formData],
  );
  return (
    <fieldset
      className="field field-array field-array-of-number"
      id={idSchema.$id}
    >
      <div className="row array-item-list">
        {formData.map((value, index) => (
          <div
            className="array-item"
            key={`${idSchema.$id} ${props.index} ${index}`}
          >
            <div className="col-xs-4">
              <div className="form-group field field-number">
                <input
                  className="form-control"
                  id={`${idSchema.$id}_${index}`}
                  required
                  placeholder=""
                  type="number"
                  step="any"
                  value={value || ""}
                  data-index={index}
                  onChange={_onChange}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default CamMatrixRowField;
