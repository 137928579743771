import React from "react";
import classNames from "classnames";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

import styles from "./SearchBar.scss";

interface SearchBarProps {
  children: React.ReactNode;
  onClear: (event: React.MouseEvent<HTMLElement>) => void;
  wrapperClass?: string;
}

export default function SearchBar({
  children,
  onClear,
  wrapperClass = null,
}: SearchBarProps): React.ReactElement {
  return (
    <div className={styles.field}>
      <span className={styles.iconBox}>
        <SearchIcon className={styles.icon} />
      </span>
      <div className={classNames(styles.chipBox, wrapperClass)}>{children}</div>
      <span
        className={classNames(styles.iconBox, styles.clearButton)}
        onClick={onClear}
      >
        <ClearIcon className={styles.icon} />
      </span>
    </div>
  );
}
