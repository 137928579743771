import React from "react";

import { CameraSelectOption } from "app/js/types";

import SelectStream, {
  SelectStreamProps,
} from "app/components/Selects/SelectStream";

import { useParamValue } from "../hooks";
import { BaseField, OmitConflictingProps } from "../types";
import Field from "../Field";

export type CameraFieldProps = BaseField<string> &
  Omit<OmitConflictingProps<SelectStreamProps>, "setStream">;

export const CameraField = ({
  label,
  isRequired,
  isDisabled,
  isUsedInParams,
  paramName,
  value,
  onChange,
  ...inputProps
}: CameraFieldProps): React.ReactElement => {
  const convertFromParam = (v: string): string => v;
  const convertToParam = (v: string): string => v;

  const [fieldValue, setFieldValue] = useParamValue<string>({
    paramName,
    initialValue: value,
    isUsedInParams,
    convertFromParam,
    convertToParam,
    onChange,
  });

  const handleChange = (camera: CameraSelectOption) => {
    const newValue = camera?.stream || null;
    setFieldValue(newValue);
  };

  return (
    <Field label={label}>
      <SelectStream
        value={fieldValue}
        setStream={handleChange}
        required={isRequired}
        isDisabled={isDisabled}
        {...inputProps}
      />
    </Field>
  );
};
