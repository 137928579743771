import React from "react";
import Api from "app/js/api";
import { AppInstance, VisualSampleSetFilterChoices } from "app/js/types";
import { useSafeState } from "app/js/hooks";
import moment from "moment";

import styles from "./CreatorFilterChoiceOption.scss";

interface ApplicationInstanceHook {
  applicationInstance: AppInstance;
  loading: boolean;
}

const useApplicationInstance = (
  applicationInstanceId: number | null = null,
): ApplicationInstanceHook => {
  const [data, setData] = useSafeState<ApplicationInstanceHook>({
    applicationInstance: null,
    loading: true,
  });
  React.useEffect(() => {
    if (applicationInstanceId) {
      setData({
        applicationInstance: null,
        loading: true,
      });
      Api.applicationInstance(applicationInstanceId)
        .show()
        .then((response) => {
          setData({
            applicationInstance: response.data,
            loading: false,
          });
        });
    } else {
      setData({
        applicationInstance: null,
        loading: false,
      });
    }
  }, [applicationInstanceId, setData]);
  return data;
};

interface CreatorFilterChoiceLabelProps {
  typeLabel: string;
  value: string;
  hint: string;
  lastRequestTime: string;
}

const CreatorFilterChoiceLabel: React.FC<CreatorFilterChoiceLabelProps> = ({
  typeLabel,
  value,
  hint,
  lastRequestTime,
}) => {
  const relativeTime = moment(lastRequestTime).fromNow();
  return (
    <div className={styles.container} title={hint}>
      <div>
        <label className={styles.type}>{typeLabel}</label>
        <span className={styles.value} title={hint}>
          {value}
        </span>
      </div>
      <div className={styles.containerRight}>
        <span className={styles.value}>{relativeTime}</span>
      </div>
    </div>
  );
};

const CreatorFilterChoiceOption: React.FC<VisualSampleSetFilterChoices> = (
  filterChoice,
) => {
  const { applicationInstance, loading } = useApplicationInstance(
    filterChoice.application_instance_id,
  );
  if (filterChoice.application_instance_id) {
    if (!loading) {
      return (
        <CreatorFilterChoiceLabel
          typeLabel="Labels requested by App Instance"
          value={`${filterChoice.application_instance_name} (${applicationInstance?.application})`}
          hint={filterChoice.user_email}
          lastRequestTime={filterChoice.last_request_time}
        />
      );
    } else {
      return <div className={styles.container}>Loading ...</div>;
    }
  } else {
    return (
      <CreatorFilterChoiceLabel
        typeLabel="Labels requested by User"
        value={filterChoice.user_name || filterChoice.user_email}
        hint={filterChoice.user_email}
        lastRequestTime={filterChoice.last_request_time}
      />
    );
  }
};

export default CreatorFilterChoiceOption;
