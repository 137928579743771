import React, { useEffect, Fragment } from "react";
import { Prompt } from "react-router-dom";
import Api from "app/js/api";
import Step from "app/components/Step/Step";
import Card from "app/components/Card/Card";
import CardList from "app/components/CardList/CardList";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import Dropzone from "app/components/Dropzone/Dropzone";

import { useSafeState } from "app/js/hooks";
import styles from "./DatasetDetailImagesUpload.scss";
import SelectStream from "app/components/Selects/SelectStream";
import { CardImage } from "app/components/Card";

const UPLOAD_FAILED = "Upload Failed";
const UPLOAD_PREVENT_NAVIGATION =
  "File upload in progress. Stopping will cause only " +
  "some of the images to be uplaoded. Are you sure you want to leave?";
export default function DatasetDetailImagesUpload({
  match,
  dataset,
  setDataset,
}) {
  const datasetId = match.params.id;

  const [error, setError] = useSafeState(null);

  // Image Upload
  const [selectedStreamId, setSelectedStreamId] = useSafeState<string>(null);
  const [files, setFiles] = useSafeState([]);
  const [progress, setProgress] = useSafeState([]);
  const [uploadInProgress, setUploadInProgress] = useSafeState(false);

  window.onbeforeunload = function () {
    if (!uploadInProgress) {
      return null;
    }
    return UPLOAD_PREVENT_NAVIGATION;
  };

  const onDrop = async (rawFiles) => {
    window.matomo.push(["trackGoal", 1]);
    setUploadInProgress(true);

    setFiles((prev) => {
      const newFiles = rawFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      return [...prev, ...newFiles];
    });

    // For loop ensures file upload is one at a time to not overload server
    for (let i = 0; i < rawFiles.length; ++i) {
      const file = rawFiles[i];
      await Api.dataset(datasetId)
        .images()
        .store({
          stream_id: selectedStreamId,
          file: file,
          progress: (evt) => {
            if (evt.lengthComputable) {
              setProgress((prev) => ({
                ...prev,
                [file.name]: Math.round((evt.loaded / evt.total) * 100),
              }));
            }
          },
        })
        .then((response) => {
          setProgress((prev) => ({
            ...prev,
            [file.name]: "Upload Complete",
          }));
        })
        .then((response) => {
          setDataset({ ...dataset, frames_count: ++dataset.frames_count });
        })
        .catch((error) => {
          console.log("failure", error);
          setProgress((prev) => ({
            ...prev,
            [file.name]: UPLOAD_FAILED,
          }));
          setError(error);
        });
    }
    setUploadInProgress(false);
  };

  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      window.onbeforeunload = undefined;
    };
    // I don't want to debug this, so leaving as is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Step
        number={1}
        headline="Select a Stream you want to connect the Images with"
      >
        <SelectStream
          value={selectedStreamId}
          setStream={(cameraOption) => setSelectedStreamId(cameraOption.stream)}
          isDisabled={false}
          datasetId={datasetId}
          required
        />
      </Step>
      <Step number={2} headline="Select Images" active={!!selectedStreamId}>
        {error && <ErrorMessage error={error} />}
        {files.length > 0 && (
          <p>
            Uploaded{" "}
            {files.reduce(
              (sum, file) =>
                progress[file.name] === "Upload Complete" ? sum + 1 : sum,
              0,
            )}{" "}
            files out of {files.length}
          </p>
        )}
        <Prompt
          when={uploadInProgress}
          // prompt is shown twice because we add the team to the location the second time, only show for first
          message={(location) =>
            location.search === "" ? UPLOAD_PREVENT_NAVIGATION : true
          }
        />
        {selectedStreamId ? (
          <Fragment>
            <Dropzone onDrop={onDrop} accept="image/*" />
            <CardList>
              {files.map((file) => (
                <Card
                  key={file.name}
                  media={<CardImage source={file.preview} />}
                  title={
                    typeof progress[file.name] === "number"
                      ? `Uploading ${progress[file.name]}%`
                      : progress[file.name]
                  }
                  className={
                    progress[file.name] === UPLOAD_FAILED ? styles.card : ""
                  }
                />
              ))}
            </CardList>
          </Fragment>
        ) : (
          <p>Please add a Stream first.</p>
        )}
      </Step>
    </Fragment>
  );
}
