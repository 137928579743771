import React from "react";
import { Link } from "react-router-dom";

import Table from "app/components/Table/Table";
import { DatasetView } from "app/js/types";
import { useTitle } from "app/js/hooks";

import styles from "./Overview.scss";

interface OverviewProps {
  datasetView: DatasetView;
}

export default function Overview({
  datasetView,
}: OverviewProps): React.ReactElement {
  useTitle(`MoonVision - Dataset View ${datasetView.name}`);

  let labelingJobs = datasetView.labeling_jobs;
  if (datasetView.id === 0) {
    labelingJobs = labelingJobs.filter((job) => !job.is_deleted);
  }
  return (
    <React.Fragment>
      {labelingJobs.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>Job</th>
              <th>Dataset</th>
              <th>Team</th>
            </tr>
          </thead>
          <tbody>
            {labelingJobs.map((job) => (
              <tr key={job.id}>
                <td>
                  <Link to={`/labelling-jobs/${job.id}?team=${job.team.id}`}>
                    {job.is_deleted ? (
                      <span
                        className={styles.deletedJob}
                        title={
                          "Job has been deleted. Deselect it and save the dataset view if you don't want to see this job anymore."
                        }
                      >{`${job.name} (${job.id})`}</span>
                    ) : (
                      <span>{`${job.name} (${job.id})`}</span>
                    )}
                  </Link>
                </td>
                <td>{`${job.dataset.name} (${job.dataset.id})`}</td>
                <td>{`${job.team.name} (${job.team.id})`}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p style={{ padding: "0 25px 0 25px" }}>No Labeling Jobs</p>
      )}
    </React.Fragment>
  );
}
