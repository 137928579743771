import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import decode from "jwt-decode";
import { ApiModels } from "app/js/api";
import { StoreUser } from "app/js/types";

const OAuthToken = "OAuthToken";
const OAuthAccessToken = "OAuthAccessToken";

interface ApiClient extends ApiModels, AxiosInstance {
  client: any; // ApiClient, but I don't want to mess with recursive types
  getToken: () => string | void;
  saveToken: (token: string) => void;
  removeToken: () => void;
}

export default class Client {
  /**
   *  initializes base parameters for the client.
   *  all other params will be used as axios options
   *
   *  @params {object} models - request models of entities
   */
  accessToken: string | null;
  models: ApiModels;
  client: AxiosInstance;

  constructor({ ...options }: AxiosRequestConfig = {}) {
    this.accessToken = null;
    // Ideally, .useModels() should be called before .build() and this property should be set
    this.models = null;

    this.client = this.buildClient(options);

    this.init();
    return this;
  }

  buildClient(options?: AxiosRequestConfig): AxiosInstance {
    return axios.create({
      ...options,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
      },
    });
  }

  useModels(models: new (client: Client) => ApiModels): Client {
    this.models = new models(this);
    return this;
  }

  init(): Client {
    this.client.interceptors.request.use(
      (config) => {
        const token = this.getToken();
        if (token != null) {
          config.headers.Authorization = `JWT ${token}`;
        }
        return config;
      },
      (err) => Promise.reject(err),
    );
    return this;
  }

  getClient(): AxiosInstance {
    return this.client;
  }

  build(): ApiClient {
    /*
    this.models is a class instance, not a simple object. {...this.models}
    does not preserve methods. So, I have manually walk through all methods and
    reconstruct models as a simple object.
    Also, since model methods refer to `this.client`, I have to add this object
    as property as well.
    */
    const keys = Object.getOwnPropertyNames(Object.getPrototypeOf(this.models));
    const models = keys.reduce((classAsObj, key) => {
      if (key !== "constructor") {
        classAsObj[key] = this.models[key];
      }
      return classAsObj;
    }, {});
    return {
      client: this,
      ...models,
      ...this.client,
      getToken: this.getToken,
      saveToken: this.saveToken,
      removeToken: this.removeToken,
    } as ApiClient;
  }

  getToken(): string | void {
    try {
      const token = localStorage.getItem(OAuthAccessToken);
      const decodedToken = decode(token) as StoreUser;
      if (decodedToken.version !== parseInt(document.env.tokenVersion)) {
        localStorage.removeItem(OAuthAccessToken);
        window.location.reload();
        return;
      }
      this.accessToken = token;
      return this.accessToken;
    } catch (error) {
      console.log("Token Error", error);
      return localStorage.removeItem(OAuthAccessToken);
    }
  }

  saveToken(accessToken: string): void {
    this.accessToken = accessToken;
    localStorage.removeItem(OAuthToken);
    localStorage.setItem(OAuthAccessToken, accessToken);
  }

  removeToken(): void {
    this.accessToken = null;
    localStorage.removeItem(OAuthAccessToken);
  }
}
