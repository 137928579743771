import { Widget } from "@rjsf/core";
import Select from "react-select";
import Api from "app/js/api";
import {
  withResource,
  streamResponseTransformer,
} from "app/pages/AppConfigs/EditConfigForm/widgets/withResource";
import FixedValue from "app/pages/AppConfigs/EditConfigForm/widgets/FixedValue";
import NavPillsSelect from "app/pages/AppConfigs/EditConfigForm/widgets/NavPillsSelect";

const widgets: Record<string, Widget> = {
  DatasetSelect: withResource(Select, Api.dataset()),
  LabellingJobSelect: withResource(Select, Api.job()),
  StreamSelect: withResource(Select, Api.stream(), streamResponseTransformer),
  FixedValue,
  NavPillsSelect,
};

export default widgets;
