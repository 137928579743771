import React from "react";

interface LogoProps {
  className?: string;
  style?: Record<string, any>;
}

const Logo: React.FC<LogoProps> = ({ className, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 442.9 442.8"
      style={style}
    >
      <title>MoonVision Logo</title>{" "}
      <path
        d="M225.3,5.9a220.66,220.66,0,0,0-153,61.3,10.59,10.59,0,0,0-1,1.2,11.51,11.51,0,0,0-1.7,1.4,11.89,11.89,0,0,0,0,16.9L217.4,234.5a11.91,11.91,0,0,0,16.8-16.9l-39.3-39.3A58.22,58.22,0,1,1,250,280.7a28.69,28.69,0,0,0-47.7.9A58.42,58.42,0,0,1,166.9,228c0-2.1.1-4.3.3-6.4a12,12,0,0,0-23.8-2.6c-.3,3-.5,6-.5,9A82.3,82.3,0,0,0,197,305.2a13.32,13.32,0,0,0,1.9.5,28.71,28.71,0,0,0,55.1-1.1,10.88,10.88,0,0,0,2.4-.7,82.12,82.12,0,1,0-79-142.8l-30.8-30.8a124.81,124.81,0,0,1,143.3-9.5,32.23,32.23,0,0,0-1.4,9.3A33,33,0,0,0,331,161.7a124.78,124.78,0,1,1-218.2,10.8A12,12,0,0,0,91.3,162a147.33,147.33,0,0,0-15.1,65.4c0,82,66.7,148.6,148.6,148.6s148.6-66.7,148.6-148.6A148.63,148.63,0,0,0,349.8,147l-.1-.1a32.93,32.93,0,0,0-45.4-45,148.61,148.61,0,0,0-174.9,11.5L95.2,78.6a196.29,196.29,0,0,1,130-48.8c108.9,0,197.6,88.6,197.6,197.6A197.57,197.57,0,0,1,225.2,425C116.3,425,27.6,336.4,27.6,227.4A197.56,197.56,0,0,1,59,120.4a11.94,11.94,0,1,0-20.1-12.9A221.19,221.19,0,0,0,3.8,227.3c0,122.1,99.3,221.4,221.4,221.4A221.43,221.43,0,0,0,446.7,227.3C446.7,105.2,347.4,5.9,225.3,5.9Zm1.3,297a5.8,5.8,0,1,1,5.8-5.8A5.8,5.8,0,0,1,226.6,302.9Zm95.2-182.2a9.3,9.3,0,1,1-9.3,9.3A9.28,9.28,0,0,1,321.8,120.7Z"
        transform="translate(-3.8 -5.9)"
        fill="white"
        fillOpacity="1"
      />
    </svg>
  );
};
export default Logo;
