import React from "react";
import Api from "app/js/api";
import Table from "app/components/Table/Table";
import { downloadConfigResponse } from "app/pages/AppConfigs/utils";
import styles from "app/pages/AppConfigs/AppInstances/AppInstancesTable.scss";
import { AppInstance } from "app/pages/AppConfigs/types";
import { Link } from "react-router-dom";

interface AppInstancesTableProps {
  appInstances: AppInstance[];
  createInstanceUrl: string;
  moonboxAppName: string;
}

const onDownloadClick: React.MouseEventHandler<HTMLElement> = async (event) => {
  const appInstanceId = parseInt(event.currentTarget.dataset.appinstanceid);
  const response = await Api.applicationConfiguration().all({
    application: appInstanceId,
  });
  const configEntity = response?.data?.results?.[0];
  downloadConfigResponse(configEntity);
};

const AppInstancesTable: React.FC<AppInstancesTableProps> = ({
  appInstances,
  createInstanceUrl,
  moonboxAppName,
}) => {
  return (
    <div key={`moonboxApp-${moonboxAppName}`}>
      <h2 className={styles.moonboxAppName}>{moonboxAppName}</h2>
      <div className={styles.createInstanceLink}>
        <Link to={createInstanceUrl}>Create Instance</Link>
      </div>
      <Table className={styles.table}>
        <thead>
          <tr>
            <th>Application Instance</th>
            <th>{/*Version*/}</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {appInstances.map((appInstance) => (
            <tr key={`appInstance-${appInstance.id}`}>
              <td>
                <Link to={`/app-configs/${appInstance.id}/config-versions`}>
                  {`${appInstance.name} (${appInstance.id})`}
                </Link>
              </td>
              <td />
              <td>
                <Link to={`/app-configs/${appInstance.id}/configure`}>
                  Configure
                </Link>
                {", "}
                <a
                  href="#"
                  onClick={onDownloadClick}
                  data-appinstanceid={appInstance.id}
                >
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AppInstancesTable;
