import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import Api from "app/js/api";
import {
  LoadedDataCallback,
  useLoadedData,
  useParams,
  useUserCallback,
} from "app/js/hooks";
import { DeviceAuthRequest, DeviceAuthRequestStatus } from "app/js/types";
import Button from "app/components/Buttons/Button";
import Header from "app/components/Header/Header";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import { Form, FormFields } from "app/components/Form";

import styles from "./DeviceAuthRequest.scss";
import { GenericForm, useFormStore, useUserStore } from "app/js/stores";

interface RouteMatch {
  auth_request_id: string;
}

const STATUS_NAMES: Record<DeviceAuthRequestStatus, string> = {
  pending: "Pending",
  approved: "Approved",
  declined: "Rejected",
};

interface DeviceAuthRequestForm extends GenericForm {
  application: string;
  team: number;
  user_code: string;
}

export default function DeviceAuthRequest(): React.ReactElement {
  const match = useRouteMatch<RouteMatch>();
  const params = useParams();
  const paramsApp = decodeURIComponent(params.get("app"));
  const paramsUserCode = params.get("user_code");
  const history = useHistory();
  const [user] = useUserStore();

  const authRequestId = match.params?.auth_request_id
    ? parseInt(match.params.auth_request_id)
    : null;
  const deviceAuthRequestCallback: LoadedDataCallback<DeviceAuthRequest> = useUserCallback(
    async (setValue) => {
      if (authRequestId === null) {
        return;
      }
      const response = await Api.deviceAuthorizationRequest().show(
        authRequestId,
      );
      setValue(response.data);
    },
    [authRequestId],
  );
  const {
    value: deviceAuthRequest,
    loading,
    error,
    setValue: setDeviceAuthRequest,
  } = useLoadedData<DeviceAuthRequest | null>(null, deviceAuthRequestCallback);

  const [formData] = useFormStore<DeviceAuthRequestForm>("deviceAuthRequest");

  const updateStatusCallback: LoadedDataCallback<DeviceAuthRequest> = useUserCallback(
    async (
      setValue,
      setCount,
      status: DeviceAuthRequestStatus,
      message: string,
    ) => {
      const response = authRequestId
        ? await Api.deviceAuthorizationRequest().update(
            authRequestId,
            formData.user_code,
            status,
          )
        : await Api.deviceAuthorizationRequest().updateNoId(
            formData.application,
            formData.user_code,
            status,
          );
      alert(message);
      if (!authRequestId) {
        history.push(`/settings/auth-request/${response.data.id}`);
      }
      setDeviceAuthRequest(response.data);
    },
    [
      authRequestId,
      formData?.application,
      formData?.user_code,
      history,
      setDeviceAuthRequest,
    ],
  );
  const {
    loading: updating,
    error: updateError,
    requestCallback: updateStatus,
  } = useLoadedData<DeviceAuthRequest>(null, updateStatusCallback, true);

  const handleApproveAuthRequest = () => {
    updateStatus("approved", "Device Authorization Request has been approved");
  };
  const handleDeclineAuthRequest = () => {
    updateStatus("declined", "Device Authorization Request has been declined");
  };

  if ((!deviceAuthRequest && !!authRequestId) || loading) {
    return <Loading />;
  } else if (error) {
    return <ErrorMessage error={error} />;
  }

  const requestStatus = deviceAuthRequest?.status || null;

  return (
    <div>
      <Header showCreate={false}>
        <h1>
          {deviceAuthRequest !== null
            ? `Device Authorization Request - ${deviceAuthRequest.application}`
            : "Device Authorization Request"}
        </h1>
      </Header>
      <div className={styles.content}>
        {updateError && <ErrorMessage error={updateError} />}
        <Form<DeviceAuthRequestForm>
          formName="deviceAuthRequest"
          layout="vertical"
        >
          <FormFields.CharField
            label="Application"
            paramName="application"
            value={deviceAuthRequest?.application || paramsApp}
          />
          <FormFields.CharField
            label="Team"
            paramName="team"
            readOnly
            value={`${deviceAuthRequest?.team || user.data.team_id}`}
          />
          <FormFields.CharField
            label="User Code"
            paramName="user_code"
            value={deviceAuthRequest?.user_code || paramsUserCode}
          />
          {requestStatus !== null && (
            <React.Fragment>
              <label>Status:</label>
              {STATUS_NAMES[requestStatus]}
            </React.Fragment>
          )}
          <div className={styles.buttonContainer}>
            {requestStatus !== "approved" && (
              <Button
                big
                onClick={handleApproveAuthRequest}
                disabled={updating}
              >
                Accept
              </Button>
            )}
            {requestStatus !== "declined" && (
              <Button
                big
                onClick={handleDeclineAuthRequest}
                disabled={updating}
              >
                {requestStatus === "approved" ? "Revoke" : "Reject"}
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
}
