import React from "react";
import classNames from "classnames";
import { Creatable } from "react-select";
import { RouteComponentProps } from "react-router";

import Api from "app/js/api";
import Button from "app/components/Buttons/Button";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import Header from "app/components/Header/Header";
import LabeledSwitch from "app/components/LabeledSwitch/LabeledSwitch";
import ActionBar from "app/pages/AppConfigs/ActionBar/ActionBar";
import EditConfigForm from "app/pages/AppConfigs/EditConfigForm/EditConfigForm";
import EditConfigPlain from "app/pages/AppConfigs/EditConfigPlain/EditConfigPlain";
import { useSafeState } from "app/js/hooks";
import { AppConfig, ConfigTemplateVersion, Option } from "app/js/types";
import {
  useAppInstance,
  useConfigTemplateVersionById,
  useConfigVersions,
} from "app/pages/AppConfigs/hooks";
import { downloadText } from "app/pages/AppConfigs/utils";
import styles from "app/pages/AppConfigs/EditConfig/EditConfig.scss";
import actionBarStyles from "app/pages/AppConfigs/ActionBar/ActionBar.scss";

const useExistingConfig = ({ appInstanceId, fromConfigId }) => {
  const [existingConfig, setExistingConfig] = useSafeState<AppConfig>(null);
  const [existingConfigVersion, setExistingConfigVersion] = useSafeState<
    ConfigTemplateVersion
  >(null);
  const [loaded, setLoaded] = useSafeState<boolean>(false);

  React.useEffect(() => {
    setLoaded(false);
    setExistingConfig(null);
    const applicationConfigurationPromise = fromConfigId
      ? // load specific config
        Api.applicationConfiguration(fromConfigId)
          .show()
          .then((resp) => resp?.data)
      : // load most recent config
        Api.applicationConfiguration()
          .all({
            limit: 1,
            application: appInstanceId,
          })
          .then((resp) => resp?.data?.results?.[0]);
    applicationConfigurationPromise
      .then((data) => {
        if (data) {
          setExistingConfig(data);
          if (data.fixed_version) {
            return Api.configTemplateVersion(data.fixed_version)
              .show()
              .then((resp) => {
                setExistingConfigVersion(resp.data);
              });
          }
        } else {
          setExistingConfig(null);
        }
      })
      .then(() => setLoaded(true));
  }, [
    appInstanceId,
    fromConfigId,
    setExistingConfig,
    setExistingConfigVersion,
    setLoaded,
  ]);

  return { existingConfig, existingConfigVersion, setExistingConfig, loaded };
};

const useConfigTemplate = (configTemplateId) => {
  const [configTemplate, setConfigTemplate] = useSafeState<Record<string, any>>(
    null,
  );

  React.useEffect(() => {
    setConfigTemplate(null);
    if (configTemplateId) {
      Api.configTemplate(configTemplateId)
        .show()
        .then((response) => {
          setConfigTemplate(response.data);
        });
    }
  }, [configTemplateId, setConfigTemplate]);

  return configTemplate;
};

const EditConfig: React.FC<RouteComponentProps<{ appInstanceId: string }>> = ({
  match,
  history,
}) => {
  const { appInstanceId } = match.params;
  const fromConfigId = +new URLSearchParams(history.location.search).get(
    "fromConfigId",
  );
  const submitRef = React.useRef<() => void>();

  const [selectedVersion, setSelectedVersion] = useSafeState<Option<number>>(
    null,
  );
  const [editorType, setEditorType] = useSafeState<"Code" | "Form">("Form");
  const [configText, setConfigText] = useSafeState<string>(null);
  const [error, setError] = useSafeState(null);

  const { existingConfig, existingConfigVersion, loaded } = useExistingConfig({
    appInstanceId,
    fromConfigId,
  });
  const appInstance = useAppInstance(appInstanceId);
  const configVersions = useConfigVersions(appInstance?.application);
  const versionData = useConfigTemplateVersionById(selectedVersion?.value);

  const versionOptions = React.useMemo(
    () => [
      { value: null, label: "\0" },
      ...Object.keys(configVersions).map((versionId) => ({
        label: configVersions[versionId],
        value: +versionId,
      })),
    ],
    [configVersions],
  );

  React.useEffect(() => {
    if (loaded) {
      if (existingConfigVersion) {
        setSelectedVersion(
          versionOptions.find(
            (item) => item && item.label === existingConfigVersion.version,
          ),
        );
      } else {
        if (!existingConfig?.config_template) {
          // default to latest version if there is no previous config
          setSelectedVersion(versionOptions?.[versionOptions.length - 1]);
        }
      }
    }
  }, [
    existingConfig,
    existingConfigVersion,
    loaded,
    setSelectedVersion,
    versionOptions,
  ]);

  const existingConfigText = React.useMemo(() => {
    if (existingConfig?.configuration) {
      return JSON.stringify(existingConfig?.configuration, null, 4);
    } else {
      return "";
    }
  }, [existingConfig]);

  React.useEffect(() => {
    setConfigText(existingConfigText);
  }, [existingConfigText, setConfigText]);

  const hasChanged =
    configText !== existingConfigText ||
    (existingConfigVersion?.version || null) !==
      (selectedVersion?.label && selectedVersion?.label !== "\x00"
        ? selectedVersion?.label
        : null);

  const configTemplateId = /^[0-9a-fA-F]{64}$/.exec(selectedVersion?.label)
    ? selectedVersion.label
    : versionData?.config_template || existingConfig?.config_template;

  const configTemplate = useConfigTemplate(configTemplateId);

  const oneLabeledSwitchChange = React.useCallback(
    ({ value }) => {
      setEditorType(value);
    },
    [setEditorType],
  );

  const onConfigChange = React.useCallback(
    (value, _event) => {
      setConfigText(value);
    },
    [setConfigText],
  );

  const onDownloadClick = React.useCallback(() => {
    const appName = appInstance.application;
    const instanceId = appInstance.id;
    const timestamp = Date.now();
    const filename = `${appName}_${instanceId}_t${timestamp}.json`.replace(
      " ",
      "-",
    );
    downloadText(configText, filename, "application/json;charset=utf-8");
  }, [appInstance, configText]);

  const onDiscardChangesClick = React.useCallback(() => {
    setConfigText(existingConfigText);
    setSelectedVersion(
      versionOptions.find(
        (item) => item.label === existingConfig?.fixed_version,
      ),
    );
  }, [
    setConfigText,
    existingConfig,
    existingConfigText,
    setSelectedVersion,
    versionOptions,
  ]);

  const onSaveClick = React.useCallback(() => {
    const application_instance = appInstanceId;
    const timestamp = new Date().toJSON();
    let configuration = {};
    try {
      configuration = JSON.parse(configText);
    } catch {
      alert("You cannot save an App Config, because it has an invalid syntax");
      return;
    }
    const fixed_version = versionData?.id;
    if (editorType === "Form" && submitRef.current) {
      submitRef.current();
    }
    const request = {
      config_template: configTemplateId,
      application_instance: parseInt(application_instance),
      timestamp,
      configuration,
    };
    if (fixed_version) {
      request["fixed_version"] = fixed_version;
    }
    setError(null);
    Api.applicationConfiguration()
      .store(request)
      .then(() => {
        history.push("config-versions");
      })
      .catch(setError);
  }, [
    appInstanceId,
    configTemplateId,
    configText,
    submitRef,
    history,
    versionData,
    setError,
    editorType,
  ]);

  const onDismissErrorClick = React.useCallback(() => {
    setError(null);
  }, [setError]);

  return (
    <>
      <Header>
        <h1>{`App Configs - ${appInstance?.name} (${appInstance?.id}) - Configure`}</h1>
      </Header>
      <ActionBar
        select={
          <Creatable
            escapeClearsValue
            isClearable
            className={actionBarStyles.select}
            onChange={setSelectedVersion}
            options={versionOptions}
            // typescript complains that number is not assignable - although, it should be
            // eslint-disable-next-line tsc/config
            defaultValue={existingConfigVersion?.id}
            // Unfortunately setting defaultInputValue to show the
            // default value filters the options which breaks the dropdown
            placeholder={selectedVersion?.label || "Version..."}
            // the defaultValue is only recognised on the first
            // render after the component is mounted
            key={`existingConfigVersion${existingConfigVersion?.id}`}
          />
        }
        additionalNodes={
          <div className={styles.labeledSwitchWrapper}>
            <LabeledSwitch
              styles={{ labeledSwitch: styles.labeledSwitch }}
              defaultState={true}
              onText="Form"
              offText="Code"
              onChange={oneLabeledSwitchChange}
            />
          </div>
        }
        buttons={
          <>
            <Button onClick={onDownloadClick}>Download</Button>
            <Button
              disabled={!hasChanged}
              className={classNames({ [styles.disabledButton]: !hasChanged })}
              onClick={onDiscardChangesClick}
            >
              Discard Changes
            </Button>
            <Button
              disabled={!hasChanged}
              className={classNames({ [styles.disabledButton]: !hasChanged })}
              onClick={onSaveClick}
            >
              Save
            </Button>
          </>
        }
      />
      {loaded && editorType === "Form" && (
        <EditConfigForm
          application={appInstance?.application}
          jsonSchema={configTemplate?.template}
          value={configText}
          onChange={onConfigChange}
          submitRef={submitRef}
        />
      )}
      {loaded && editorType === "Code" && (
        <>
          <EditConfigPlain
            value={configText}
            onChange={onConfigChange}
            jsonSchema={configTemplate?.template}
          />
          <ErrorMessage
            error={error}
            className={styles.error}
            onDismiss={onDismissErrorClick}
          />
        </>
      )}
    </>
  );
};

export default EditConfig;
