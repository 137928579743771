import React, { useEffect, Fragment } from "react";
import moment from "moment";

import {
  Card,
  CardAddButton,
  CardDetails,
  CardImage,
} from "app/components/Card";
import CardList from "app/components/CardList/CardList";
import Paginator from "app/components/Paginator/Paginator";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

import { useChanged, useSafeState } from "app/js/hooks";
import Api from "app/js/api";
import { Frame } from "app/js/types";
import PageSizeChanger, {
  readPageLengthCookie,
} from "app/components/PageSizeChanger/PageSizeChanger";
import GalleryModal from "app/components/Modal/GalleryModal";
import { Error } from "app/components/ErrorMessage/types";
import styles from "app/pages/LabellingJobs/LabellingJobDetail/LabellingJobLabelledImages.scss";
import { Button } from "app/components/Buttons";

export default function LabellingJobSkippedImages({ job, history }) {
  const [loading, setLoading] = useSafeState<boolean>(false);
  const [error, setError] = useSafeState(null);
  const PAGE_LENGTH_COOKIE = "moonvision-skippedImagesPageLength";
  const [pageLength, setPageLength] = useSafeState<number>(
    readPageLengthCookie(PAGE_LENGTH_COOKIE),
  );
  const [offset, setOffset] = useSafeState<number>(0);
  const [count, setCount] = useSafeState<number>(0);

  const [images, setImages] = useSafeState<Frame[]>([]);
  const [bigFrameId, setBigFrameId] = useSafeState<number | null>(null);

  const [imageUnskipMode, setImageUnskipMode] = useSafeState<boolean>(false);
  const [selectedImages, setSelectedImages] = useSafeState<number[]>([]);
  const [bulkOperationInProcess, setBulkOperationInProcess] = useSafeState<
    boolean
  >(false);
  const [bulkOperationError, setBulkOperationError] = useSafeState<Error>(null);

  const offsetChanged = useChanged(offset);
  React.useEffect(() => {
    if (imageUnskipMode && offsetChanged) {
      alert("Page parameters changed, multi-select mode will be disabled.");
      setSelectedImages([]);
      setImageUnskipMode(false);
    }
  }, [imageUnskipMode, offsetChanged, setImageUnskipMode, setSelectedImages]);

  const loadImages = React.useCallback(async () => {
    setLoading(true);

    try {
      const response = await Api.job(job.id)
        .framesToLabel()
        .skippedImages({ limit: pageLength, offset, with_entities: true });
      setImages(response.data.results);
      setCount(response.data.count);
      setError(null);
    } catch (error) {
      setError(error);
      console.error(error);
    }
    setLoading(false);
  }, [offset, job, pageLength, setLoading, setImages, setCount, setError]);

  useEffect(() => {
    loadImages();
  }, [loadImages]);

  const handleUnskipModeClick = async () => {
    if (imageUnskipMode) {
      if (selectedImages.length > 0) {
        setBulkOperationInProcess(true);
        setBulkOperationError(null);
        try {
          await Api.job(job.id).framesToLabel().bulkUnskip(selectedImages);
          setSelectedImages([]);
          setImageUnskipMode(false);
          loadImages();
        } catch (e) {
          console.log(e);
          setBulkOperationError({ message: "Operation failed" });
        }
        setBulkOperationInProcess(false);
      } else {
        // Nothing to save, just disable the mode
        setBulkOperationError(null);
        setImageUnskipMode(false);
      }
    } else {
      setImageUnskipMode(true);
    }
  };
  const onImageSelect = (frameId: number, isSelected: boolean) => {
    if (isSelected) {
      setSelectedImages([...selectedImages, frameId]);
    } else {
      setSelectedImages(selectedImages.filter((f) => f !== frameId));
    }
  };
  const handleUnskipAllClick = async () => {
    setBulkOperationInProcess(true);
    setBulkOperationError(null);
    try {
      await Api.job(job.id).framesToLabel().unskipAll();
      loadImages();
    } catch (e) {
      console.log(e);
      setBulkOperationError({ message: "Operation failed" });
    }
    setBulkOperationInProcess(false);
  };
  const handleUnqualifyAllClick = async () => {
    setBulkOperationInProcess(true);
    setBulkOperationError(null);
    try {
      await Api.job(job.id).framesToLabel().unqualifyAll();
      loadImages();
    } catch (e) {
      console.log(e);
      setBulkOperationError({ message: "Operation failed" });
    }
    setBulkOperationInProcess(false);
  };

  return (
    <div style={{ overflowY: "auto", height: "100%" }}>
      <div style={{ padding: "25px" }}>
        {loading && <Loading />}
        {error && <ErrorMessage error={error} />}
        {!loading && images.length > 0 ? (
          <Fragment>
            {count > pageLength && (
              <Paginator
                onChange={setOffset}
                count={count}
                offset={offset}
                pageLength={pageLength}
                disabled={loading}
              />
            )}
            <CardList>
              {images.map((frame) => (
                <Card
                  key={frame.id}
                  media={<CardImage frame={frame} />}
                  onClick={() => {
                    setBigFrameId(frame.id);
                  }}
                  showCheckbox={imageUnskipMode}
                  checkboxValue={selectedImages.includes(frame.id)}
                  onCheckboxClick={(value) => onImageSelect(frame.id, value)}
                  showLabelStatus={frame.status === "auto"}
                  hoverButtons={
                    <CardAddButton
                      link={`/labelling-jobs/${job.id}/frame/${
                        frame.id
                      }?redirect=/labelling-jobs/${
                        job.id
                      }/skipped-images&redirect_parameters=page=${
                        offset / pageLength + 1
                      }`}
                      title={"Label Image"}
                    />
                  }
                >
                  <CardDetails>
                    Uploaded: {moment(frame.create_time).format("LLL")}
                    <br />
                    Frame ID: {frame.id}
                    <br />
                    Camera: {frame.stream_name || "-"}
                  </CardDetails>
                </Card>
              ))}
            </CardList>
            <PageSizeChanger
              pageLength={pageLength}
              setPageLength={setPageLength}
              count={count}
              cookieName={PAGE_LENGTH_COOKIE}
            />
            <div className={styles.buttonsContainer}>
              <div className={styles.buttons}>
                <Button
                  onClick={handleUnskipModeClick}
                  disabled={bulkOperationInProcess}
                >
                  {imageUnskipMode
                    ? "Unskip selected images"
                    : "Select images to unskip"}
                </Button>
                <Button
                  onClick={handleUnskipAllClick}
                  disabled={bulkOperationInProcess}
                >
                  Unskip all images
                </Button>
                <Button
                  onClick={handleUnqualifyAllClick}
                  disabled={bulkOperationInProcess}
                >
                  Unqualify all images
                </Button>
              </div>
              {bulkOperationError && (
                <ErrorMessage error={bulkOperationError} />
              )}
            </div>
          </Fragment>
        ) : (
          !loading && <Fragment>No Skipped Images</Fragment>
        )}
        <GalleryModal
          frames={images}
          activeFrame={bigFrameId}
          setActiveFrame={setBigFrameId}
          loading={loading}
          pageLength={pageLength}
          count={count}
          drawEntities={false}
        />
      </div>
    </div>
  );
}
