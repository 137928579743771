import React from "react";

import classNames from "classnames";

import styles from "./FormComponent.scss";

interface FormComponentProps {
  children: React.ReactNode;
  layout?: "vertical" | "horizontal";
}

const FormComponent: React.FC<FormComponentProps> = ({
  children,
  layout = "vertical",
}) => {
  const classes = classNames(styles.container, {
    [styles.verticalContainer]: layout === "vertical",
    [styles.horizontalContainer]: layout === "horizontal",
  });
  return <div className={classes}>{children}</div>;
};
export default FormComponent;
