import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import ProcessingTaskCreate from "./ProcessingTaskCreate";
import AutoEntities from "./AutoEntities/AutoEntities";
import ExtractImages from "./ExtractImages/ExtractImages";
import ProcessingTaskDetail from "./ProcessingTaskDetail";
import ProcessingTaskList from "./ProcessingTaskList";
import Api from "app/js/api";

export const processingTasks = [
  {
    name: "Extract Images",
    description: "Extract images to be used for labelling",
    link: "extract-images",
    component: ExtractImages,
    type: "extract-images",
  },
  {
    name: "Create Auto Entities",
    description: "Use a detector to create a new version of auto entities",
    link: "create-auto",
    component: AutoEntities,
    type: "create-auto-entities",
  },
];

const createRoutes = ({ match, history }) => {
  const onTaskCreate = async (task) => {
    const response = await Api.processingTask().store(task.type);
    history.push(`/processing-tasks/${response.data.id}`);
  };
  return (
    <Fragment>
      <Route
        path={`${match.path}`}
        exact
        render={(props) => (
          <ProcessingTaskCreate
            onClick={onTaskCreate}
            processingTasks={processingTasks}
            {...props}
          />
        )}
      />
      {processingTasks.map((task) => (
        <Route
          key={task.name}
          path={`${match.path}/${task.link}`}
          exact
          component={task.component}
        />
      ))}
    </Fragment>
  );
};

export default function Routes({ match }) {
  return (
    <Switch>
      <Route path={match.path} exact component={ProcessingTaskList} />
      <Route path={`${match.path}/create`} component={createRoutes} />
      <Route
        path={`${match.path}/:id`}
        exact
        component={ProcessingTaskDetail}
      />
    </Switch>
  );
}
