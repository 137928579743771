import React from "react";
import { Link } from "react-router-dom";

interface ResourceDoesNotExistProps {
  resourceRoot: string;
}

const ResourceDoesNotExist: React.FC<ResourceDoesNotExistProps> = ({
  resourceRoot,
}) => {
  return (
    <div>
      <h2>Resource Not Found</h2>
      <p>
        Either your current team cannot see this resource or it does not exist.
      </p>
      <Link to={resourceRoot}>Back to resource list</Link>
    </div>
  );
};
export default ResourceDoesNotExist;
