import React, { useEffect, Fragment } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import {
  ConstrainedRoute,
  RouteOwner,
  RoutePaid,
  isOwner,
  isStaff,
} from "app/componentConstraints";
import history from "app/js/history";
import moment from "moment";
import "moment/locale/en-gb";

// components
import SideBar from "./components/SideBar/SideBar";

// pages
import NotTeamMember from "./pages/Error/NotTeamMember";
import Home from "./pages/Home/Home";
import ActionscriptRoutes from "./pages/Actionscripts/Routes";
import StreamRoutes from "./pages/Streams/Routes";
import DataSetRoutes from "./pages/DataSets/Routes";
import DatasetViewRoutes from "./pages/DatasetViews/Routes";
import DetectorList from "./pages/Detectors/DetectorList";
import LabellingJobRoutes from "./pages/LabellingJobs/Routes";
import ProcessingTaskRoutes from "./pages/ProcessingTasks/Routes";
import AuthenticationRoutes from "./pages/Authentication/Routes";
import PublicRoutes from "./pages/OtherPages/Public/Routes";
import SettingsRoutes from "./pages/Settings/Routes";
import NotFound from "./pages/OtherPages/NotFound";
import Loading from "app/components/Loading/Loading";

// Tracking
import ReactPiwik from "react-piwik";

import styles from "./App.scss";
import { useUserStore } from "app/js/stores";
import AppConfigRoutes from "./pages/AppConfigs/Routes";

moment.locale("en-gb");

// Tracking (global variable)
const piwik = new ReactPiwik({
  url: "https://moonvision.matomo.cloud",
  siteId: 1,
  trackErrors: true,
});
window.matomo = ReactPiwik;

export default function App() {
  const [user, userActions] = useUserStore();
  const loadEnv = () => {
    const settings: HTMLElement = document.querySelector("#app-settings");
    // Using object spread, because dataset is DOMStringMap, and I need an object
    document.env = { ...settings.dataset };
    document.env.featureFlags = JSON.parse(document.env.featureFlagsJson);
  };
  useEffect(() => {
    userActions.listenForTeamChanges(history);
    userActions.loadTokenIfPresentAndRefresh();
  }, [userActions]);
  if (document.env == null) {
    loadEnv();
  }

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "af8262b6-9ce9-4fc6-b506-e3fe6616c995";

    (function () {
      const d = document;
      const s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  if (user.loading) {
    return <Loading />;
  }

  return (
    <Router history={piwik.connectToHistory(history)}>
      <Fragment>
        {user.data &&
          user.errors !== null &&
          location.pathname !== "/not-team-member" && (
            <Redirect to="/not-team-member" />
          )}
        {user.data && (
          <div className={styles.container}>
            <SideBar history={history} />
            <div className={styles.content}>
              <Switch>
                <Route path="/" exact component={Home} />
                <RouteOwner
                  path="/actionscripts"
                  component={ActionscriptRoutes}
                />
                <RouteOwner path="/streams" component={StreamRoutes} />
                <RouteOwner path="/data-sets" component={DataSetRoutes} />
                <ConstrainedRoute
                  userConstraint={(user) => isOwner(user) || isStaff(user)}
                  path="/dataset-views"
                  component={DatasetViewRoutes}
                />
                <RouteOwner path="/detectors" component={DetectorList} />
                <Route path="/labelling-jobs" component={LabellingJobRoutes} />
                <RoutePaid
                  path="/processing-tasks"
                  component={ProcessingTaskRoutes}
                />
                <RouteOwner path="/app-configs" component={AppConfigRoutes} />
                <Route path="/settings" component={SettingsRoutes} />
                <Route
                  path="/not-team-member"
                  exact
                  component={NotTeamMember}
                />
                {/* Redirect to home when accessing login  */}
                <Route
                  path="/login"
                  exact
                  render={() => {
                    return <Redirect to="/" />;
                  }}
                />
                <Route
                  path="/signup"
                  exact
                  render={() => {
                    return <Redirect to="/" />;
                  }}
                />
                <Route path="/p" component={PublicRoutes} />
                <Route path="/" component={NotFound} />
              </Switch>
            </div>
          </div>
        )}
        {!user.data && (
          <div className={styles.content}>
            <Switch>
              <Route path="/p" component={PublicRoutes} />
              <Route path="/" component={AuthenticationRoutes} />
            </Switch>
          </div>
        )}
      </Fragment>
    </Router>
  );
}
