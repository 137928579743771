import React from "react";
import Api from "app/js/api";
import { useSafeState } from "app/js/hooks";
import { AppConfig, AppInstance, ConfigTemplateVersion } from "app/js/types";
import { ConfigVersionsMap } from "app/pages/AppConfigs/types";

export const useAppInstance = (appInstanceId): AppInstance | null => {
  const [appInstance, setAppInstance] = useSafeState<AppInstance | null>(null);

  const loadAppInstance = React.useCallback(
    async (id) => {
      const response = await Api.applicationInstance(id).show();
      setAppInstance(response.data);
    },
    [setAppInstance],
  );

  React.useEffect(() => {
    loadAppInstance(appInstanceId);
  }, [appInstanceId, loadAppInstance]);

  return appInstance;
};

const loadAppConfigs = async (appInstance: AppInstance, filter) => {
  const request = { application: appInstance.id, limit: 10000 };
  if (filter) {
    if (/^[0-9a-fA-F]{64}$/.exec(filter)) {
      request["template"] = filter;
    } else {
      const configVersion = await Api.configTemplateVersion().all({
        version: filter,
        application: appInstance.application,
      });
      request["template"] = configVersion.data.results[0].config_template;
    }
  }
  const response = await Api.applicationConfiguration().all(request);
  return response.data.results;
};

export const useAppConfigs = (
  appInstance: AppInstance | null,
  filter,
): AppConfig[] => {
  const [appConfigs, setAppConfigs] = useSafeState<AppConfig[]>([]);
  React.useEffect(() => {
    if (appInstance) {
      loadAppConfigs(appInstance, filter).then((_appConfigs) =>
        setAppConfigs(_appConfigs),
      );
    }
  }, [appInstance, filter, setAppConfigs]);

  return appConfigs;
};

export const useConfigVersions = (application): ConfigVersionsMap => {
  const [configVersions, setConfigVersions] = useSafeState<ConfigVersionsMap>(
    [],
  );

  const loadConfigVersions = React.useCallback(
    async (application) => {
      if (!application) {
        setConfigVersions([]);
        return;
      }
      const response = await Api.configTemplateVersion().all({
        application,
        limit: 10000,
      });
      const _configVersions = [];
      response.data.results.forEach(({ id, version }) => {
        _configVersions[id] = version;
      });
      setConfigVersions(_configVersions);
    },
    [setConfigVersions],
  );

  React.useEffect(() => {
    loadConfigVersions(application);
  }, [application, loadConfigVersions]);

  return configVersions;
};

export const useConfigTemplateVersion = (
  application: string,
  version: string,
): ConfigTemplateVersion | null => {
  const [configTemplateVersion, setConfigTemplateVersion] = useSafeState<
    ConfigTemplateVersion
  >(null);
  React.useEffect(() => {
    if (!application || !version) {
      setConfigTemplateVersion(null);
      return;
    }
    Api.configTemplateVersion()
      .all({ application, version, limit: 1 })
      .then((response) => {
        const versionData = response?.data?.results?.[0];
        setConfigTemplateVersion(versionData);
      });
  }, [application, version, setConfigTemplateVersion]);
  return configTemplateVersion;
};

export const useConfigTemplateVersionById = (
  versionId: number,
): ConfigTemplateVersion | null => {
  const [configTemplateVersion, setConfigTemplateVersion] = useSafeState<
    ConfigTemplateVersion
  >(null);
  React.useEffect(() => {
    if (versionId && /^[0-9]+$/.exec(`${versionId}`)) {
      Api.configTemplateVersion(versionId)
        .show()
        .then((response) => {
          const versionData = response?.data;
          setConfigTemplateVersion(versionData);
        });
    } else {
      setConfigTemplateVersion(null);
    }
  }, [versionId, setConfigTemplateVersion]);
  return configTemplateVersion;
};
