import React from "react";
import { Switch, Route } from "react-router-dom";

import DeviceAuthRequest from "./DeviceAuthRequest";
import UserSettings from "./UserSettings";
import TeamSettings from "./TeamSettings";

export default function Routes({ match }) {
  return (
    <Switch>
      <Route path={`${match.path}/profile`} exact component={UserSettings} />
      <Route path={`${match.path}/team`} exact component={TeamSettings} />
      <Route path={`${match.path}/teams/:id`} exact component={TeamSettings} />
      <Route
        path={`${match.path}/auth-request`}
        exact
        component={DeviceAuthRequest}
      />
      <Route
        path={`${match.path}/auth-request/:auth_request_id`}
        exact
        component={DeviceAuthRequest}
      />
    </Switch>
  );
}
