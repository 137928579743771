import React, { Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import styles from "app/components/User/User.scss";
import { Option, Team } from "app/js/types";

type SelectTeamProps = RouteComponentProps<string> & {
  teams: Team[];
  value: number;
  isDisabled: boolean;
  onChange: (team: number) => void;
};

const SelectTeam: React.FC<SelectTeamProps> = (props) => {
  const options: Option<number>[] = props.teams.map((team) => ({
    label: team.name + ` (id: ` + team.id + `)`,
    value: team.id,
  }));

  const onChangeHandle = (selectedOption: Option<number>) => {
    props.onChange(selectedOption.value);
  };

  // to be changed later (will be put in scss)
  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      minHeight: "30px",
      backgroundColor: styles.$gray,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "30px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: "5px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "5px",
    }),
  };
  return (
    <Fragment>
      <Select
        styles={customStyles}
        onChange={onChangeHandle}
        isDisabled={props.isDisabled}
        options={options}
        value={options.find((team) => team.value === props.value)}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: "red",
            neutral80: "white",
          },
        })}
      />
    </Fragment>
  );
};

export default withRouter(SelectTeam);
