import React, { Fragment } from "react";
import InputRange from "react-input-range";
import Select from "react-select";
import styles from "../ExtractImages.scss";
import Button from "app/components/Buttons/Button";
import "react-input-range/lib/css/index.css";
import { Option } from "app/js/types";

const sceneCharacteristicOptions: Option<string>[] = [
  {
    label: "Continuous",
    value: "avg_activity",
  },
  {
    label: "Direction Change",
    value: "std_activity",
  },
  {
    label: "Burst",
    value: "max_activity",
  },
];

export default function SceneConfiguration({
  sceneInput,
  setSceneInput,
  loadSceneData,
  sampleVideo,
  disabled,
}) {
  const handleChange = (name, value) => {
    setSceneInput({ ...sceneInput, [name]: value });
  };

  return (
    <Fragment>
      <div className={styles.input}>
        <div className={styles.grid}>
          <label>Duration of a scene:</label>
          <InputRange
            step={0.1}
            maxValue={100}
            minValue={0.1}
            value={sceneInput.movementTime}
            onChange={(value: { min: number; max: number }) => {
              handleChange("movementTime", {
                min: Math.round(value.min * 1e1) / 1e1,
                max: Math.round(value.max * 1e1) / 1e1,
              });
            }}
            disabled={disabled}
          />
        </div>
        <small>Minimum and maximum time in secs.</small>
      </div>
      <div>
        <label
          style={{
            display: "block",
            marginBottom: "10px",
          }}
        >
          <input
            type="checkbox"
            checked={disabled ? true : sceneInput.advancedOptions}
            value={disabled ? true : sceneInput.advancedOptions}
            onChange={() => {
              handleChange("advancedOptions", !sceneInput.advancedOptions);
            }}
            disabled={disabled}
          />{" "}
          advanced options
        </label>
        {(sceneInput.advancedOptions || disabled) && (
          <Fragment>
            <div className={styles.input}>
              <div className={styles.grid}>
                <label>Look ahead time:</label>
                <InputRange
                  step={0.1}
                  maxValue={100}
                  minValue={0}
                  value={sceneInput.windowSize}
                  onChange={(value: number) => {
                    handleChange("windowSize", Math.round(value * 1e1) / 1e1);
                  }}
                  disabled={disabled}
                />
              </div>
              <small>For fine tuning scene boundaries in sec.</small>
            </div>
            <div className={styles.input}>
              <div className={styles.grid}>
                <label>Smoothness:</label>
                <InputRange
                  maxValue={30}
                  minValue={0}
                  value={sceneInput.smooth}
                  onChange={(value) => {
                    handleChange("smooth", value);
                  }}
                  disabled={disabled}
                />
              </div>
              <small>For fine tuning scene boundaries.</small>
            </div>
            <div className={styles.input}>
              <div className={styles.grid}>
                <label>Cut off:</label>
                <InputRange
                  step={0.1}
                  maxValue={1}
                  minValue={-1}
                  value={sceneInput.cutOff}
                  onChange={(value: number) => {
                    handleChange("cutOff", Math.round(value * 1e1) / 1e1);
                  }}
                  disabled={disabled}
                />
              </div>
              <small>Advanced option</small>
            </div>
            <div className={styles.input}>
              <div className={styles.grid}>
                <label>Scene Characteristics:</label>
                <Select
                  value={sceneCharacteristicOptions.find(
                    (option) => option.value === sceneInput.motionType,
                  )}
                  onChange={(event: Option<string>) => {
                    handleChange("motionType", event.value);
                  }}
                  options={sceneCharacteristicOptions}
                  isDisabled={disabled}
                />
              </div>
              <small>Choose which type of activity to select for.</small>
            </div>
          </Fragment>
        )}
        <Button
          onClick={(event) => {
            loadSceneData(sceneInput);
          }}
          disabled={disabled}
        >
          Calculate Scenes
        </Button>
      </div>
    </Fragment>
  );
}
