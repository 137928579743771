import React from "react";

const NullableStringField = (props) => {
  const { idSchema, name, formData, onChange, uiSchema } = props;
  const _onChange = React.useCallback(
    (event) => {
      onChange(event.target.value || null);
    },
    [onChange],
  );
  return (
    <div className="form-group field field-string">
      <label className="control-label" htmlFor={idSchema.$id}>
        {name}
      </label>
      <input
        className="form-control"
        id={idSchema.$id}
        placeholder={uiSchema?.["ui:placeholder"]}
        type="text"
        value={formData || ""}
        onChange={_onChange}
      />
    </div>
  );
};

export default NullableStringField;
