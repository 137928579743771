import React from "react";
import Dropzone, { DropzoneOptions } from "react-dropzone";
import styles from "./Dropzone.scss";

interface DashDropzoneProps extends Pick<DropzoneOptions, "onDrop"> {
  accept: string;
}

const DashDropzone: React.FC<DashDropzoneProps> = ({ onDrop, accept }) => {
  return (
    <Dropzone accept={accept} onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={styles.zone}>
          <input {...getInputProps()} />
          <p>Drop files here</p>
        </div>
      )}
    </Dropzone>
  );
};
export default DashDropzone;
