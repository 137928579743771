import React from "react";

import Box from "app/components/Box/Box";
import Button from "app/components/Buttons/Button";
import FormComponent from "app/components/FormComponent/FormComponent";

import Api from "app/js/api";
import { useSafeState } from "app/js/hooks";

import styles from "./PasswordReset.scss";

export default function PasswordReset({ history, match }) {
  const [resetData, setResetData] = useSafeState({
    password: "",
    passwordConfirmation: "",
  });
  const [error, setError] = useSafeState(null);
  const onReset = async () => {
    setError(null);
    if (resetData.password !== resetData.passwordConfirmation) {
      setError({ message: "Passwords do not match." });
      return;
    }
    try {
      await Api.user().resetPassword(match.params.token, {
        new_password: resetData.password,
      });
      history.push("/login");
      setError(null);
    } catch (error) {
      setError(error);
    }
  };

  const handleChange = (e) => {
    setResetData({ ...resetData, [e.target.name]: e.target.value });
  };

  return (
    <div className={styles.container}>
      <Box title="Forgot Password" error={error}>
        <FormComponent>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            name="password"
            placeholder="***********"
            value={resetData.password}
            onChange={handleChange}
          />
          <label htmlFor="password">Password confirmation</label>
          <input
            id="passwordConfirmation"
            type="password"
            name="passwordConfirmation"
            placeholder="***********"
            value={resetData.passwordConfirmation}
            onChange={handleChange}
          />
        </FormComponent>
        <Button onClick={onReset}>Reset</Button>
      </Box>
    </div>
  );
}
