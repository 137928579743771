import React, { useEffect, Fragment } from "react";
import { fabric } from "fabric";
import { generateColorProperties } from "app/pages/LabellingJobs/Labelling/helper/colors";
import { exportObject } from "app/pages/LabellingJobs/Labelling/helper/helper";
import { MousePosition } from "app/pages/LabellingJobs/types";

import { useSafeState } from "app/js/hooks";

export default function CreateRect({ canvas, createdAction }) {
  const [started, setStarted] = useSafeState(false);
  const [mouse, setMouse] = useSafeState<MousePosition>({
    x: undefined,
    y: undefined,
  });

  useEffect(() => {
    canvas.on("mouse:down", mouseDown);
    canvas.on("mouse:move", mouseMove);
    canvas.on("mouse:up", mouseUp);

    return () => {
      // clean up
      canvas.off("mouse:down", mouseDown);
      canvas.off("mouse:move", mouseMove);
      canvas.off("mouse:up", mouseUp);
    };
  });

  const mouseDown = (e) => {
    // lets go
    setStarted(true);

    // is another Object selected?
    if (canvas.getActiveObject()) {
      canvas.discardActiveObject().renderAll();
    }

    // make sure you can't select any other object
    canvas.forEachObject((object) => {
      object.set({
        selectable: false,
        lockMovementX: true,
        lockMovementY: true,
        lockRotation: true,
      });
    });

    // get the mouse from this point of origin
    const currentMouse = canvas.getPointer(e);
    // set the origin point for rect
    setMouse({ x: currentMouse.x, y: currentMouse.y });

    // create Rect Object
    const rect = new fabric.Rect({
      data: {
        id: `new_${(Math.random() + 1).toString(36).substring(7)}`,
      },
      width: 0,
      height: 0,
      left: currentMouse.x,
      top: currentMouse.y,
      hasControls: false,
      hasBorders: false,
      ...generateColorProperties("", 0.4, canvas.getZoom()),
    });

    //add width and height of the drawing shape on the cursor
    const cursor = new fabric.Text("w: 0\nh: 0", {
      data: {
        id: "cursorText",
      },
      fontFamily: "sans-serif",
      fontSize: 36 / canvas.getZoom(),
      fill: "#ffffff",
      selectable: false,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      textBackgroundColor: "#5bc0eb",
    });
    //add cursor
    canvas.add(cursor);
    // set cursor starting position
    cursor.set({
      left: currentMouse.x,
      top: currentMouse.y,
    });
    // add it to canvas and define it as selected object
    canvas.add(rect);
    canvas.setActiveObject(rect);
    canvas.renderAll();
  };

  const mouseMove = (e) => {
    // only trigger when you clicked first
    if (!started) {
      return false;
    }

    // get the mouse and the object
    const currentMouse = canvas.getPointer(e);
    const activeObject = canvas.getActiveObject();
    const cursor = canvas._objects.find((obj) => obj.data?.id === "cursorText");

    if (mouse.x > currentMouse.x) {
      activeObject.set({ left: Math.abs(currentMouse.x) });
    }
    if (mouse.y > currentMouse.y) {
      activeObject.set({ top: Math.abs(currentMouse.y) });
    }

    activeObject.set({
      width: Math.abs(mouse.x - currentMouse.x),
    });
    activeObject.set({
      height: Math.abs(mouse.y - currentMouse.y),
    });
    // set current cursor position and add the height and width of the rect
    cursor.set({
      left: currentMouse.x,
      top: currentMouse.y,
      text: `w: ${Math.round(activeObject.width)}\nh: ${Math.round(
        activeObject.height,
      )}`,
    });
    // render the canvas
    canvas.renderAll();
  };
  const mouseUp = (e) => {
    // clean up
    setStarted(false);
    // remove cursor
    canvas.remove(canvas._objects.find((obj) => obj.data?.id === "cursorText"));
    // make sure a minimum size is reached
    if (
      canvas.getActiveObject().width < 3 ||
      canvas.getActiveObject().height < 3
    ) {
      canvas.remove(canvas.getActiveObject());
      return;
    }

    // make sure we have border and controls on the rect
    canvas
      .getActiveObject()
      .set({
        hasControls: true,
        hasBorders: true,
      })
      .setCoords(); // set the selectable bounding box

    // return the created object
    const activeObject = canvas.getActiveObject();
    createdAction(exportObject(activeObject));
  };

  return <Fragment />;
}
