import React from "react";

export default function ({ stroke = "#dddddd", fill = "#6f6f6f" }) {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 128 100"
      style={{
        enableBackground: "new 0 0 128 100", // eslint-disable-line tsc/config
      }}
    >
      <polygon
        points="88.5,15 39.5,15 15,50 39.5,85 88.5,85 113,50 "
        fill={fill}
        stroke={stroke}
        strokeMiterlimit={10}
        strokeWidth={6}
      />
    </svg>
  );
}
