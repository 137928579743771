import React from "react";
import styles from "./ImageModal.scss";
import Modal from "./Modal";

interface ImageModalProps {
  img: string;
  setImg: (img: string | null) => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ img, setImg }) => {
  if (!img) {
    return null;
  }

  return (
    <Modal onRemove={() => setImg(null)} removeClick={true}>
      <div className={styles.image}>
        <img src={img} />
      </div>
    </Modal>
  );
};
export default ImageModal;
