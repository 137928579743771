import React, { useEffect, Fragment } from "react";
import Api from "app/js/api";
import CardList from "app/components/CardList/CardList";
import VideoCard from "app/components/Card/VideoCard";
import Loading from "app/components/Loading/Loading";
import Paginator from "app/components/Paginator/Paginator";

import { useSafeState } from "app/js/hooks";
import { Video } from "app/js/types";

export default function DatasetDetailVideos({ match }) {
  const datasetId = match.params.id;
  const [loading, setLoading] = useSafeState(false);
  const PAGE_LENGTH = 10;
  const [offset, setOffset] = useSafeState<number>(0);
  const [count, setCount] = useSafeState<number>(0);

  const [videos, setVideos] = useSafeState<Video[]>([]);

  useEffect(() => {
    const loadVideos = async () => {
      setLoading(true);
      const response = await Api.video().all({
        dataset_ids: [parseInt(datasetId)],
        limit: PAGE_LENGTH,
        offset,
      });
      const new_videos = response.data.results.map((video) => {
        video["date"] = new Date(video.create_time);
        return video;
      });
      setVideos(new_videos);
      setCount(response.data.count);
      setLoading(false);
    };

    loadVideos();
  }, [datasetId, offset, setCount, setLoading, setVideos]);

  return (
    <div style={{ padding: "25px", overflowY: "auto" }}>
      {loading && <Loading />}
      {!loading && videos.length > 0 ? (
        <Fragment>
          <CardList>
            {videos.map((video) => (
              <VideoCard key={video.id} video={video} detailed={true} />
            ))}
          </CardList>
          {count > PAGE_LENGTH && (
            <Paginator
              onChange={setOffset}
              count={count}
              offset={offset}
              pageLength={PAGE_LENGTH}
              disabled={loading}
            />
          )}
        </Fragment>
      ) : (
        !loading && <Fragment>No Videos in Dataset</Fragment>
      )}
    </div>
  );
}
