import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import Select from "react-select";
import Api from "app/js/api";
import Loading from "app/components/Loading/Loading";

import { useSafeState } from "app/js/hooks";
import { Dataset, Option } from "app/js/types";

interface SelectDatasetProps {
  required?: boolean;
  value: number;
  setDataset: (dataset: Dataset) => void;
  isDisabled?: boolean;
  excludeDatasetId?: number;
}

const SelectDataset: React.FC<SelectDatasetProps> = ({
  required = false,
  value,
  setDataset,
  isDisabled = false,
  excludeDatasetId = null,
}) => {
  const [datasets, setDatasets] = useSafeState<Dataset[]>([]);
  const [loading, setLoading] = useSafeState(false);
  useEffect(() => {
    const loadDatasets = async () => {
      setLoading(true);
      const response = await Api.dataset().all({ limit: 500 });
      setDatasets(response.data.results);
      setLoading(false);
    };

    loadDatasets();
  }, [setDatasets, setLoading]);

  let options: Option<number>[] = required
    ? []
    : [{ value: null, label: "no Dataset" }];
  options = [
    ...options,
    ...datasets.map((entry) => ({
      label: `${entry.name} (id:${entry.id})`,
      value: entry.id,
    })),
  ];

  return (
    <Fragment>
      {loading && <Loading />}
      {!loading && datasets.length === 0 && (
        <Fragment>
          <p>
            You don't have any Datasets yet. You can go and create a dataset in
            the <Link to={`/data-sets`}>Dataset Section</Link>.
          </p>
        </Fragment>
      )}
      {!loading && datasets.length > 0 && (
        <Select
          value={datasets
            .filter((entry) => entry.id === value)
            .map((entry) => ({
              value: entry.id,
              label: `${entry.name} (id:${entry.id})`,
            }))}
          onChange={(e: Option<number>) => {
            const newDataset = datasets.find((entry) => entry.id === e.value);
            setDataset(newDataset);
          }}
          options={
            excludeDatasetId
              ? options.filter((entry) => entry.value !== excludeDatasetId)
              : options
          }
          isDisabled={isDisabled}
        />
      )}
    </Fragment>
  );
};
export default SelectDataset;
