import React from "react";

export default function ({ stroke = "#dddddd", fill = "#6f6f6f" }) {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 128 100"
      style={{
        enableBackground: "new 0 0 128 100", // eslint-disable-line tsc/config
      }}
    >
      <rect
        x="15"
        y="15"
        width="98"
        height="70"
        fill={fill}
        stroke={stroke}
        strokeMiterlimit={10}
        strokeWidth={6}
      />
    </svg>
  );
}
