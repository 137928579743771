import React from "react";
import Select from "react-select";
import { Props as SelectProps } from "react-select/lib/Select";

import { Option } from "app/js/types";

import { useParamValue } from "../hooks";
import { BaseField, OmitConflictingProps } from "../types";
import Field from "../Field";

export type SelectFieldProps = BaseField<string> &
  OmitConflictingProps<SelectProps>;

export const SelectField = ({
  label,
  isRequired,
  isDisabled,
  isUsedInParams,
  paramName,
  value,
  onChange,
  options,
  ...inputProps
}: SelectFieldProps): React.ReactElement => {
  const convertFromParam = (v: string): string => v;
  const convertToParam = (v: string): string => v;

  const [fieldValue, setFieldValue] = useParamValue<string>({
    paramName,
    initialValue: value,
    isUsedInParams,
    convertFromParam,
    convertToParam,
    onChange,
  });

  const handleChange = (option: Option<string>) => {
    setFieldValue(option.value);
  };

  return (
    <Field label={label}>
      <Select
        onChange={handleChange}
        options={options}
        value={options.find((o) => o.value === fieldValue)}
        {...inputProps}
      />
    </Field>
  );
};
