import React from "react";
import classNames from "classnames";
import styles from "./LinkButton.scss";

interface LinkButtonProps extends React.ComponentPropsWithoutRef<"a"> {
  className?: string;
  big?: boolean;
  link: string;
}

export default function LinkButton({
  children,
  className,
  big,
  link,
  ...props
}: LinkButtonProps): React.ReactElement {
  const onClick = (e: React.MouseEvent) => {
    // I need to stop click event propagation, so that it didn't click on whatever is below the button
    e.stopPropagation();
  };
  return (
    <a
      className={classNames(styles.button, className, { [styles.big]: big })}
      role="button"
      href={link}
      onClick={onClick}
      {...props}
    >
      {children}
    </a>
  );
}
