import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { UserState, useUserStore } from "app/js/stores";

import { isOwner, isPaid } from "./constraints";

// style attribute exists in NavLinkProps - but we use it differently and expect
// another type
type LinkProps = Omit<NavLinkProps, "style"> & {
  style?: React.CSSProperties;
};

interface ConstrainedLinkProps extends LinkProps {
  userConstraint: (user: UserState) => boolean;
}

const ConstrainedLink: React.FC<ConstrainedLinkProps> = ({
  userConstraint,
  ...props
}) => {
  const [user] = useUserStore();
  if (!userConstraint(user)) return null;
  return (
    <li style={props.style}>
      <NavLink {...props} />
    </li>
  );
};
export default ConstrainedLink;

// Aliases for existing Links
export const LinkOwner: React.FC<LinkProps> = (props) => {
  return <ConstrainedLink userConstraint={isOwner} {...props} />;
};

export const LinkPaid: React.FC<LinkProps> = (props) => {
  return (
    <ConstrainedLink
      userConstraint={(user) => isOwner(user) && isPaid(user)}
      {...props}
    />
  );
};
