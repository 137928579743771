import React, { useEffect, Fragment } from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import { LinkOwner, AreaOwner } from "app/componentConstraints";

import Header from "app/components/Header/Header";
import Loading from "app/components/Loading/Loading";
import ResourceDoesNotExist from "app/components/Error/ResourceDoesNotExist";
import Submenu from "app/components/Menus/Submenu/Submenu";

import Api from "app/js/api";
import { useUserStore } from "app/js/stores";
import { useSafeState } from "app/js/hooks";
import { LabelingJob } from "app/js/types";

// Pages
import LabellingJobEntities from "./LabellingJobEntities/LabellingJobEntities";
import LabellingJobDetail from "./LabellingJobDetail/LabellingJobDetail";
import LabellingJobLabelledImages from "./LabellingJobLabelledImages";
import LabellingJobRemainingImages from "./LabellingJobRemainingImages";
import LabellingJobSkippedImages from "./LabellingJobSkippedImages";

import styles from "./LabellingJobDetail.scss";

export default function Routes({ match, history }) {
  const jobId = match.params.labellingJobId;
  const [user] = useUserStore();

  // Load Job Info
  const [job, setJob] = useSafeState<LabelingJob>(null);
  const [loading, setLoading] = useSafeState(false);
  const [error, setError] = useSafeState(null);

  const loadJob = React.useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await Api.job(jobId).show(true);
      const newJob = response.data;
      setJob(newJob);
      setError(null);
    } catch (error) {
      console.error(error);
      setError(error);
    }
    setLoading(false);
  }, [jobId, setError, setJob, setLoading]);
  useEffect(() => {
    loadJob();
  }, [jobId, loadJob, user.data]);

  if (error) {
    return <ResourceDoesNotExist resourceRoot={"/data-sets"} />;
  }

  return (
    <div className={styles.container}>
      <Header basePath={match.url} showEdit={user.data.role === "owner"}>
        {!loading && <h1>Labelling Job {job && ` - ${job.name}`}</h1>}
        {loading && <Loading />}
      </Header>
      <Submenu>
        <li>
          <NavLink
            exact={true}
            to={`/labelling-jobs/${jobId}`}
            activeClassName="active"
          >
            Overview
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/labelling-jobs/${jobId}/entities`}
            activeClassName="active"
          >
            Entities
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/labelling-jobs/${jobId}/remaining-images`}
            activeClassName="active"
          >
            Remaining Images
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/labelling-jobs/${jobId}/labelled-images`}
            activeClassName="active"
          >
            Labelled Images
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/labelling-jobs/${jobId}/skipped-images`}
            activeClassName="active"
          >
            Skipped Images
          </NavLink>
        </li>
        <li className={styles.start}>
          <NavLink
            to={`/labelling-jobs/${jobId}/start`}
            activeClassName="active"
          >
            Start Labelling
          </NavLink>
        </li>
        <AreaOwner>
          <li style={{ marginLeft: "auto" }}>
            <button
              onClick={() => {
                if (confirm("Are you sure?")) {
                  Api.job(jobId)
                    .destroy()
                    .then((response) => {
                      history.push("/labelling-jobs");
                    });
                }
              }}
            >
              Delete
            </button>
          </li>
        </AreaOwner>
        {job && job.dataset && (
          <LinkOwner to={`/data-sets/${job.dataset}`}>
            Dataset {job.dataset}
          </LinkOwner>
        )}
        {job && job.indexer && (
          <LinkOwner to={`/detectors/${job.indexer}`}>
            Embedder {job.indexer}
          </LinkOwner>
        )}
      </Submenu>
      <div className={styles.content}>
        <Switch>
          <Route
            path={`${match.path}/entities/:label?`}
            render={(props) => (
              <Fragment>
                {loading && <Loading />}
                {!loading && job && job.dataset && (
                  <LabellingJobEntities job={job} />
                )}
              </Fragment>
            )}
          />
          <Route
            path={match.path}
            exact
            render={(props) => (
              <Fragment>
                {loading && <Loading />}
                {!loading && job && <LabellingJobDetail job={job} {...props} />}
              </Fragment>
            )}
          />
          <Route
            path={`${match.path}/remaining-images/`}
            exact
            render={(props) => (
              <Fragment>
                {loading && <Loading />}
                {!loading && job && (
                  <LabellingJobRemainingImages job={job} {...props} />
                )}
              </Fragment>
            )}
          />
          <Route
            path={`${match.path}/labelled-images/`}
            exact
            component={LabellingJobLabelledImages}
          />
          <Route
            path={`${match.path}/skipped-images/`}
            exact
            render={(props) => (
              <Fragment>
                {loading && <Loading />}
                {!loading && job && (
                  <LabellingJobSkippedImages job={job} {...props} />
                )}
              </Fragment>
            )}
          />
        </Switch>
      </div>
    </div>
  );
}
