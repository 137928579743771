import React from "react";
import { Dataset } from "app/js/types";
import { EntitySpritesPage } from "app/components/EntitySpritesPage";

interface RouteProps {
  dataset: Dataset | null;
}

export default function Routes({ dataset }: RouteProps) {
  const datasetId = dataset.id;

  return (
    <EntitySpritesPage
      // TODO: Use proper name
      baseTitle={`MoonVision - Dataset ${datasetId}`}
      baseUrl={`/data-sets/${datasetId}/entities`}
      object={dataset}
      objectType={"dataset"}
      selectableMode
    />
  );
}
