import React from "react";
import Select from "react-select";
import classNames from "classnames";
import validateFormData from "@rjsf/core/lib/validate";
import { utils } from "@rjsf/core";
import Api from "app/js/api";
import NavPillsSelect from "app/pages/AppConfigs/EditConfigForm/widgets/NavPillsSelect";
import { useSafeState } from "app/js/hooks";
import {
  defaultResponseTransformer,
  withResource,
} from "app/pages/AppConfigs/EditConfigForm/widgets/withResource";
import styles from "app/pages/AppConfigs/EditConfigForm/fields/DishtrackerModelField.scss";

const ModelSelectContainer = ({
  errorSchema,
  value,
  idSchema,
  schema,
  onChange,
  trained_for,
}) => {
  const ModelSelect = React.useMemo(
    () =>
      withResource(Select, Api.detector(), defaultResponseTransformer, {
        trained_for: trained_for,
      }),
    [trained_for],
  );

  return (
    <div
      className={classNames("form-group field field-number", {
        "field-error has-error has-danger": errorSchema?.id,
      })}
    >
      <label className="control-label" htmlFor={idSchema.$id}>
        {schema.title}
        <span className="required">*</span>
      </label>
      <ModelSelect
        value={value}
        onChange={onChange}
        id={idSchema.$id}
        className={classNames({
          [styles.error]: errorSchema?.id,
        })}
      />
      {errorSchema?.id && (
        <div>
          <ul className="error-detail bs-callout bs-callout-info">
            {errorSchema?.id.__errors.map((err, i) => (
              <li className="text-danger" key={i}>
                {err}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const DishtrackerModelField = (props) => {
  const { required, schema, uiSchema, errorSchema, idSchema, registry } = props;
  const [selected, setSelected] = useSafeState<number>(
    utils.getMatchingOption(props.formData, schema.anyOf, registry.definitions),
  );

  const selectedSchema = React.useMemo(() => {
    const destructuredSchemas = [];
    schema.anyOf.forEach((subSchema, i) => {
      const {
        anyOf,
        format,
        properties: unfilteredProperties,
        ...otherKeys
      } = schema;
      const properties = { trained_for: unfilteredProperties.trained_for };
      subSchema.required.forEach((propName) => {
        properties[propName] = unfilteredProperties[propName];
      });
      // delete properties["id"];
      destructuredSchemas[i] = {
        ...otherKeys,
        ...subSchema,
        title: undefined,
        properties,
      };
    });
    return destructuredSchemas[selected];
  }, [schema, selected]);

  const propsOnChange = props.onChange;
  const onChange = React.useCallback(
    (data) => {
      const propertyNames = Object.keys(selectedSchema.properties);
      const filteredFormData = {};
      Object.entries(data)
        .filter(([key, _value]) => propertyNames.indexOf(key) >= 0)
        .forEach(([key, value]) => {
          filteredFormData[key] = value;
        });
      propsOnChange(filteredFormData);
    },
    [selectedSchema, propsOnChange],
  );

  const formData = React.useMemo(() => {
    const fd = { ...props.formData };
    for (const key in schema.properties) {
      const constValue = schema.properties[key]?.const;
      if (constValue !== undefined) {
        fd[key] = constValue;
      }
    }
    return fd;
  }, [props.formData, schema?.properties]);

  React.useEffect(() => {
    if (!formData && schema?.properties) {
      if (Object.keys(formData).length) {
        onChange(formData);
      }
    }
  }, [formData, schema, onChange]);

  const onIdChange = (id) => {
    onChange({ ...formData, id });
  };

  const ObjectField = registry?.fields?.ObjectField;

  return (
    <fieldset id={idSchema.$id}>
      <legend id={`${idSchema.$id}__title`}>
        {schema.title}
        {required && <span className="required">*</span>}
      </legend>
      <div className="form-group field field-string">
        <NavPillsSelect
          id={`${idSchema.$id}__select`}
          options={{
            enumOptions: schema.anyOf.map(({ title }, index) => ({
              value: index,
              label: title,
            })),
          }}
          value={selected}
          onChange={setSelected}
        />
      </div>
      {schema.anyOf[selected].title === "File based" && ObjectField && (
        <ObjectField
          schema={selectedSchema}
          uiSchema={uiSchema}
          idSchema={idSchema}
          formData={formData}
          onChange={onChange}
          errorSchema={
            (errorSchema &&
              validateFormData(formData, selectedSchema)?.errorSchema) ||
            {}
          }
          registry={registry}
        />
      )}
      {schema.anyOf[selected].title === "Platform based" && ObjectField && (
        <>
          <div className="form-group field field-string">
            <ObjectField
              schema={selectedSchema}
              uiSchema={uiSchema}
              idSchema={idSchema}
              formData={formData}
              onChange={onChange}
              errorSchema={
                (errorSchema &&
                  validateFormData(formData, selectedSchema)?.errorSchema) ||
                {}
              }
              registry={registry}
            />
            {schema?.properties?.id && (
              <ModelSelectContainer
                errorSchema={
                  (errorSchema &&
                    validateFormData(formData, selectedSchema)?.errorSchema) ||
                  {}
                }
                value={formData?.id}
                schema={schema?.properties?.id}
                idSchema={idSchema.id}
                trained_for={formData?.trained_for}
                onChange={onIdChange}
              />
            )}
          </div>
        </>
      )}
    </fieldset>
  );
};

export default DishtrackerModelField;
