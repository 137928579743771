import React, { Fragment, useEffect } from "react";

import Select from "react-select";
import Api from "app/js/api";
import Loading from "app/components/Loading/Loading";

import { useSafeState } from "app/js/hooks";
import { Option, VisionModel, VisionModelTrainedFor } from "app/js/types";

interface SelectDetectorProps {
  required?: boolean;
  value: number;
  setDetector: (detector: VisionModel) => void;
  trainedFor: VisionModelTrainedFor;
  isDisabled?: boolean;
}

const SelectDetector: React.FC<SelectDetectorProps> = ({
  required = false,
  value,
  setDetector,
  trainedFor,
  isDisabled,
}) => {
  const [detectors, setDetectors] = useSafeState<VisionModel[]>([]);
  const [loading, setLoading] = useSafeState(false);
  useEffect(() => {
    const loadDetectors = async () => {
      setLoading(true);
      const response = await Api.detector().all({
        include_public: true,
        trained_for: trainedFor,
        limit: 500,
      });
      setDetectors(response.data.results);
      setLoading(false);
    };

    loadDetectors();
  }, [setDetectors, setLoading, trainedFor]);

  let defaultLabel = "no Model";
  if (trainedFor === "detection") {
    defaultLabel = "no Detector";
  } else if (trainedFor === "embedding") {
    defaultLabel = "no Embedder";
  }
  let options: Option<number>[] = required
    ? []
    : [{ value: null, label: defaultLabel }];
  options = [
    ...options,
    ...detectors.map((entry) => ({
      label: `${entry.name} (id:${entry.id})`,
      value: entry.id,
    })),
  ];

  return (
    <Fragment>
      {loading && <Loading />}
      {!loading && (
        <Select
          value={detectors
            .filter((entry) => entry.id === value)
            .map((entry) => ({
              value: entry.id,
              label: `${entry.name} (id: ${entry.id})`,
            }))}
          onChange={(e: Option<number>) => {
            const newDetector = detectors.find((entry) => entry.id === e.value);
            setDetector(newDetector);
          }}
          options={options}
          isDisabled={isDisabled}
        />
      )}
    </Fragment>
  );
};
export default SelectDetector;
