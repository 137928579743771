import React from "react";

import Card from "./Card";
import { Video } from "app/js/types";
import { useSafeState } from "app/js/hooks";

import styles from "./VideoCard.scss";
import { CardVideo } from "app/components/Card/components";

interface DataProps {
  video: Video;
}

const SimpleData: React.FC<DataProps> = ({ video }) => {
  return (
    <p>
      ID: {video.id}
      <br />
      Size: {video.width}x{video.height}
      <br />
      FPS: {video.fps}
    </p>
  );
};

const DetailedData: React.FC<DataProps> = ({ video }) => {
  const [infoShown, setInfoShown] = useSafeState<boolean>(false);

  const handleInfoClick = () => {
    setInfoShown(true);
  };

  const handleHideClick = () => {
    setInfoShown(false);
  };

  const convertDate = (date: Date): string | null => {
    return date ? `${date.toDateString()} ${date.toLocaleTimeString()}` : null;
  };
  const uploadTime = new Date(video.upload_time);

  return (
    <React.Fragment>
      <p>
        ID: {video.id}
        <br />
        Create Time: {convertDate(video.date)}
        <br />
        Camera: {video.camera_name}
      </p>
      {!infoShown && (
        <p className={styles.infoButton} onClick={handleInfoClick}>
          Show more info
        </p>
      )}
      {infoShown && (
        <p className={styles.infoButton} onClick={handleHideClick}>
          X
        </p>
      )}
      {infoShown && (
        <p>
          Name: {video.name}
          <br />
          Size: {video.width}x{video.height}
          <br />
          FPS: {video.fps && Math.round(video.fps * 100) / 100}
          <br />
          Upload Time: {convertDate(uploadTime)}
        </p>
      )}
    </React.Fragment>
  );
};

interface VideoCardProps {
  video: Video;
  selected?: boolean;
  onClick?: () => void;
  detailed?: boolean;
}

const VideoCard: React.FC<VideoCardProps> = ({
  video,
  selected,
  onClick,
  detailed = false,
}) => {
  return (
    <Card
      media={<CardVideo source={video.video} />}
      title={
        video.date
          ? `${video.date.toDateString()} ${video.date.toLocaleTimeString()}`
          : null
      }
      selected={selected}
      onClick={onClick}
      className={detailed ? styles.appendableCard : null}
    >
      {detailed ? <DetailedData video={video} /> : <SimpleData video={video} />}
    </Card>
  );
};

export default VideoCard;
