import React, { Fragment } from "react";
import FormComponent from "app/components/FormComponent/FormComponent";

interface Range {
  after?: string;
  before?: string;
}

interface TimeRangeInputProps {
  range: Range;
  onRangeUpdate: (range: Range) => void;
}

const TimeRangeInput: React.FC<TimeRangeInputProps> = ({
  range,
  onRangeUpdate,
}) => {
  return (
    <Fragment>
      <FormComponent>
        <label>Time from:</label>
        <input
          type="time"
          value={range.after}
          onChange={(e) => {
            onRangeUpdate({ after: e.target.value });
          }}
        />
        <label>Time to:</label>
        <input
          type="time"
          value={range.before}
          onChange={(e) => {
            onRangeUpdate({ before: e.target.value });
          }}
        />
      </FormComponent>
    </Fragment>
  );
};
export default TimeRangeInput;
