import React, { useEffect, Fragment } from "react";
import Select from "react-select";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import moment, { unitOfTime } from "moment";

import Api from "app/js/api";
import FormComponent from "app/components/FormComponent/FormComponent";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import Loading from "app/components/Loading/Loading";
import { useSafeState } from "app/js/hooks";
import { LabelHistoryFrequency } from "app/js/types";

const Plot = createPlotlyComponent(Plotly);

interface FrequencyFilterOption {
  label: string;
  value: LabelHistoryFrequency;
  isDisabled?: boolean;
}

const frequencyFilterOptions: FrequencyFilterOption[] = [
  {
    label: "Hourly",
    value: "hour",
  },
  {
    label: "Daily",
    value: "day",
  },
  {
    label: "Monthly",
    value: "month",
  },
];

interface TimeRangeFilterOption {
  label: string;
  value: unitOfTime.Base;
  defaultFrequency: LabelHistoryFrequency;
  xAxisOffsetHours: number;
  disabledFrequencies: LabelHistoryFrequency[];
}

const timeRangeFilterOptions: TimeRangeFilterOption[] = [
  {
    label: "Last 24 hours",
    value: "day",
    defaultFrequency: "hour",
    xAxisOffsetHours: 0.8,
    disabledFrequencies: ["day", "month"],
  },
  {
    label: "Last Week",
    value: "week",
    defaultFrequency: "day",
    xAxisOffsetHours: 8,
    disabledFrequencies: ["month"],
  },
  {
    label: "Last Month",
    value: "month",
    defaultFrequency: "day",
    xAxisOffsetHours: 12,
    disabledFrequencies: ["month"],
  },
  {
    label: "Last Year",
    value: "year",
    defaultFrequency: "day",
    xAxisOffsetHours: 24,
    disabledFrequencies: ["hour"],
  },
];

const defaultFilterOption = timeRangeFilterOptions[1];

export default function StreamImageTracking({ streamId }) {
  const [error, setError] = useSafeState(null);
  const [trackingTimeFilter, setTrackingTimeFilter] = useSafeState(
    defaultFilterOption,
  );
  const [frequencyFilter, setFrequencyFilter] = useSafeState(
    frequencyFilterOptions.find(
      (option) => option.value === defaultFilterOption.defaultFrequency,
    ),
  );
  const [trackingDates, setTrackingDates] = useSafeState([]);
  const [trackingData, setTrackingData] = useSafeState(null);
  const [startTime, setStartTime] = useSafeState(
    moment()
      .subtract(1, trackingTimeFilter["value"])
      .startOf(frequencyFilter["value"])
      .format(),
  );
  const [endTime, setEndTime] = useSafeState(moment().format());
  const [loadingPlot, setLoadingPlot] = useSafeState(true);

  useEffect(() => {
    const loadTracking = async () => {
      setLoadingPlot(true);
      const timeInterval = frequencyFilter["value"];
      const buckets = Math.ceil(
        // eslint-disable-next-line tsc/config
        moment.duration(moment(endTime) - moment(startTime)).as(timeInterval),
      );
      const dates = Array.apply(0, new Array(buckets)).map((v, i) => {
        const d = moment(startTime).startOf(timeInterval);
        d.add(i, timeInterval);
        return d.format();
      });
      setTrackingDates(dates);

      try {
        const response = await Api.stream(streamId).trackingHistogram({
          frequency: timeInterval,
          start_time: startTime,
          end_time: endTime,
        });
        const responseTrackingData = response.data.results;

        if (responseTrackingData.data.length === 0) {
          setTrackingData(null);
          setLoadingPlot(false);
          return;
        }

        const formattedData = [];
        for (
          let i = 0;
          i < responseTrackingData.duration_ms_buckets.length + 1;
          i++
        ) {
          formattedData.push(
            responseTrackingData.data.map((bucket) => bucket[i]),
          );
        }
        responseTrackingData.data = formattedData;
        setTrackingData(responseTrackingData);
      } catch (error) {
        setError(error);
        console.error(error);
      }
      setLoadingPlot(false);
    };

    loadTracking();
  }, [
    endTime,
    setError,
    setLoadingPlot,
    setTrackingData,
    setTrackingDates,
    startTime,
    streamId,
    trackingTimeFilter,
    frequencyFilter,
  ]);

  const onTrackingTimeFilterChange = (newFilter) => {
    const timeFilter = newFilter.value;
    const timeInterval = newFilter.defaultFrequency;
    setStartTime(
      moment().subtract(1, timeFilter).startOf(timeInterval).format(),
    );
    setEndTime(moment().format());
    setFrequencyFilter(
      frequencyFilterOptions.find((option) => option.value === timeInterval),
    );
    setTrackingTimeFilter(newFilter);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onClickTracking = () => {};

  return (
    <Fragment>
      {error && <ErrorMessage error={error} />}
      <FormComponent>
        <label>Time Range</label>
        <Select
          options={timeRangeFilterOptions}
          value={trackingTimeFilter}
          onChange={onTrackingTimeFilterChange}
        />
        <label>Frequency</label>
        <Select
          options={frequencyFilterOptions.map((option) => {
            if (
              trackingTimeFilter.disabledFrequencies.indexOf(option.value) >= 0
            ) {
              option.isDisabled = true;
            } else {
              option.isDisabled = false;
            }
            return option;
          })}
          value={frequencyFilter}
          onChange={setFrequencyFilter}
          isOptionDisabled={(option: FrequencyFilterOption) =>
            option.isDisabled
          }
        />
      </FormComponent>

      {loadingPlot && <Loading />}
      {!loadingPlot && !trackingData && (
        <p>No Images for Last {trackingTimeFilter["value"]}</p>
      )}
      {!loadingPlot && trackingData && (
        <Plot
          style={{ marginTop: "15px" }}
          data={trackingData.data.map((values, idx) => ({
            x: trackingDates,
            y: values,
            name: `${
              idx < trackingData.duration_ms_buckets.length
                ? "<=" + trackingData.duration_ms_buckets[idx]
                : ">" +
                  trackingData.duration_ms_buckets[
                    trackingData.duration_ms_buckets.length - 1
                  ]
            }ms`,
            hovertemplate: "%{y} Image(s) on %{x}",
            stackgroup: "group",
          }))}
          layout={{
            title: "Camera Images",
            yaxis: {
              title: "Images Processed",
              fixedrange: true,
            },
            xaxis: {
              title: "Date",
              fixedrange: true,
              type: "date",
              range: [
                moment(startTime)
                  .subtract(trackingTimeFilter["xAxisOffsetHours"], "hours")
                  .format(),
                moment(endTime)
                  .startOf(frequencyFilter.value)
                  .add(trackingTimeFilter["xAxisOffsetHours"], "hours")
                  .format(),
              ],
            },
            margin: {
              r: 0,
            },
            legend: {
              x: -0.1,
              y: 1.35,
              bgcolor: "#E2E2E2",
            },
            showlegend: true,
            hovermode: "closest",
          }}
          config={{ displayModeBar: false, scrollZoom: false }}
          onClick={onClickTracking}
        />
      )}
    </Fragment>
  );
}
