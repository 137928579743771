import React, { useEffect } from "react";
import classNames from "classnames";
import { useSafeState } from "app/js/hooks";
import Logo from "app/components/Icons/Logo";
import styles from "./Image.scss";
import { Entity } from "app/js/types";
import CanvasStatic from "app/components/CanvasStatic/CanvasStatic";

export interface CanvasImageObject {
  image: string;
  width: number;
  height: number;
  entities: Entity[];
}

interface WrapperProps {
  withWrapper: boolean;
}

const Wrapper: React.FC<WrapperProps> = ({ withWrapper, children }) => {
  if (withWrapper) {
    return <div className={styles.imageWrapper}>{children}</div>;
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

interface ImageProps {
  src: string;
  alt?: string;
  canvasImage?: CanvasImageObject;
  withWrapper?: boolean;
}

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  canvasImage,
  withWrapper = true,
}) => {
  const [prepSrc, setPrepSrc] = useSafeState<string>(src);

  useEffect(() => {
    if (src !== prepSrc && src !== null && prepSrc !== null) {
      setPrepSrc(src);
    }
  }, [prepSrc, setPrepSrc, src]);

  const canvasStaticClasses = classNames({
    [styles.canvasImage]: withWrapper,
  });
  return (
    <Wrapper withWrapper={withWrapper || !prepSrc}>
      {prepSrc ? (
        <React.Fragment>
          {canvasImage ? (
            /*
            Add a transparent image on top of a canvas. To let users
            download the image without entities rendered onto it.
            */
            <React.Fragment>
              <img
                src={src}
                alt={alt}
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  height: "inherit",
                  opacity: "0%",
                }}
                onError={(e) => {
                  setPrepSrc(null);
                }}
              />
              <CanvasStatic
                images={[canvasImage]}
                className={canvasStaticClasses}
              />
            </React.Fragment>
          ) : (
            <img
              style={{
                height: "100%",
                width: "100%",
                objectPosition: "center",
                objectFit: "contain",
              }}
              src={prepSrc}
              onError={(e) => {
                setPrepSrc(null);
              }}
              alt={alt}
            />
          )}
        </React.Fragment>
      ) : (
        <Logo
          style={{
            display: "block",
            height: "100%",
            opacity: 0.5,
            margin: "auto",
          }}
        />
      )}
    </Wrapper>
  );
};
export default Image;
