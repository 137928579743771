import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { privacyText, termsText, Countries } from "./helper";

import Button from "app/components/Buttons/Button";
import Box from "app/components/Box/Box";
import FormComponent from "app/components/FormComponent/FormComponent";

import styles from "./Signup.scss";

import Api from "app/js/api";

import { useSafeState } from "app/js/hooks";
import { useUserStore } from "app/js/stores";

interface SignupUser {
  teamname: string;
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  acceptTermsAndConditions: boolean;
  acceptPrivacyPolicy: boolean;
}

export default function Signup(props) {
  const [_user, userActions] = useUserStore();
  const [signupUser, setSignupUser] = useSafeState<SignupUser>({
    teamname: "",
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    acceptTermsAndConditions: false,
    acceptPrivacyPolicy: false,
  });
  const [error, setError] = useSafeState(null);
  const [captchaPassed, setCaptchaPassed] = useSafeState<boolean>(false);

  const invite = location.search.replace("?invite=", "");

  const recaptchaRef = React.useRef<ReCAPTCHA>();
  const register = async (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();
    setCaptchaPassed(false);

    if (!signupUser.acceptTermsAndConditions) {
      setError({
        message: "Please accept the Terms and Conditions",
      });
      return;
    }

    if (!signupUser.acceptPrivacyPolicy) {
      setError({
        message: "Please accept the Privacy Policy",
      });
      return;
    }

    if (signupUser.password !== signupUser.passwordConfirmation) {
      setError({
        message: "Password and Password confirmation do not match",
      });
      return;
    }

    try {
      const data = {
        name: signupUser.name,
        email: signupUser.email,
        password: signupUser.password,
        recaptcha: recaptchaValue,
      };
      if (signupUser.teamname !== "") {
        data["team_name"] = signupUser.teamname;
      }
      const response = await Api.user().register(data);
      userActions.setToken(response.data.token);
      setError(null);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const handleChange = (e) => {
    setSignupUser({ ...signupUser, [e.target.name]: e.target.value });
  };

  const [lang, setLang] = useSafeState<string>("EN");

  const handlePassedCaptcha = (value) => {
    setCaptchaPassed(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <img src="http://wordpress-180436-526713.cloudwaysapps.com/wp-content/uploads/2018/06/Asset-18.svg" />
        <h2>Computer Vision Toolbox</h2>
        <p>
          The Moonvision Toolbox is a web-based computer vision toolbox designed
          for end-to-end surface analysis and object detection tasks. It is a
          powerful tool for data management, human in the loop processes and
          model training / deployment.
        </p>
        <p>Sign up to find out more - it's free.</p>
      </div>
      <form onSubmit={register}>
        <Box title="Sign up" error={error}>
          <FormComponent>
            {!invite && (
              <Fragment>
                <label htmlFor="teamname">Teamname</label>
                <input
                  id="teamname"
                  type="text"
                  name="teamname"
                  placeholder="teamname"
                  value={signupUser.teamname}
                  onChange={handleChange}
                />
              </Fragment>
            )}
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              name="name"
              placeholder="user"
              value={signupUser.name}
              onChange={handleChange}
            />
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="admin@example.org"
              value={signupUser.email}
              onChange={handleChange}
            />
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              name="password"
              placeholder="***********"
              value={signupUser.password}
              onChange={handleChange}
            />
            <label htmlFor="password">Password confirmation</label>
            <input
              id="passwordConfirmation"
              type="password"
              name="passwordConfirmation"
              placeholder="***********"
              value={signupUser.passwordConfirmation}
              onChange={handleChange}
            />
            <label htmlFor="country">Country</label>
            <Countries setLang={setLang} />
            <input
              id="termsAndConditions"
              name="termsAndConditions"
              checked={signupUser.acceptTermsAndConditions}
              type="checkbox"
              style={{ justifySelf: "end" }}
              onChange={() =>
                setSignupUser({
                  ...signupUser,
                  acceptTermsAndConditions: !signupUser.acceptTermsAndConditions,
                })
              }
            />
            <label htmlFor="termsAndConditions">{termsText(lang)}</label>
            <input
              id="privacyPolicy"
              name="privacyPolicy"
              checked={signupUser.acceptPrivacyPolicy}
              type="checkbox"
              style={{ justifySelf: "end" }}
              onChange={() =>
                setSignupUser({
                  ...signupUser,
                  acceptPrivacyPolicy: !signupUser.acceptPrivacyPolicy,
                })
              }
            />
            <label htmlFor="privacyPolicy">{privacyText(lang)}</label>
          </FormComponent>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={document.env.recaptchaToken}
            onChange={handlePassedCaptcha}
          />
          <Button disabled={!captchaPassed}>Sign up</Button>
        </Box>
        <div className={styles.links}>
          <Link to="/auth/login">To Login</Link>
        </div>
      </form>
    </div>
  );
}
